import InfoBox from '../../ui/InfoBox'
import { ReactComponent as CopyIcon } from '../../../assets/icon/general/Vector-70-paste.svg'
import { ReactComponent as WarningIcon } from '../../../assets/icon/general/Vector-31-warning.svg'

const BankTransfer = () => {
  return (
    <>
      <InfoBox
        info={'Please transfer $3,600.00 to the following bank account.'}
      />
      <div className="bank-transfer__container">
        <div>
          <h1 className="offer-body__input-label">Bank Name</h1>
          <div style={{ height: '.8rem' }} />
          <div className="offer-body__form-input">
            <h1>DBS SG</h1>
            <CopyIcon />
          </div>
        </div>
        <div>
          <h1 className="offer-body__input-label">Bank Swift Code</h1>
          <div style={{ height: '.8rem' }} />
          <div className="offer-body__form-input">
            <h1>DBSSSGSGXXX</h1>
            <CopyIcon />
          </div>
        </div>
        <div>
          <h1 className="offer-body__input-label">Account Name</h1>
          <div style={{ height: '.8rem' }} />
          <div className="offer-body__form-input">
            <h1>P.LEASE P L</h1>
            <CopyIcon />
          </div>
        </div>
        <div>
          <h1 className="offer-body__input-label">Account Number</h1>
          <div style={{ height: '.8rem' }} />
          <div className="offer-body__form-input">
            <h1>822115168003</h1>
            <CopyIcon />
          </div>
        </div>
        <div>
          <h1 className="offer-body__input-label">Unique Reference Code</h1>
          <div style={{ height: '.8rem' }} />
          <div className="offer-body__form-input">
            <h1>W03Z89</h1>
            <CopyIcon />
          </div>
        </div>
        <div className="offer-body__warning-container">
          <div style={{ width: '1.6rem' }}>
            <WarningIcon className="offer-body__warning-icon" />
          </div>
          <p className="offer-body__warning-text">
            Please input your unique reference code during your transfer so that
            we can identify your funds swiftly.
          </p>
        </div>
      </div>
    </>
  )
}

export default BankTransfer
