import React, { useState } from 'react'
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide'
import DummyImage from '../../assets/images/image 5.png'
import '../../assets/css/components/ui/chatHead.css'

function ChatHead(props) {
  const [isActive, setIsActive] = useState(true)
  const [labelType, setLabelType] = useState('pending')

  const labelTypeHandler = () => {
    if (labelType === 'pending') {
      return 'overlay__content overlay__content--pending'
    } else if (labelType === 'offer') {
      return 'overlay__content overlay__content--offer'
    } else {
      return 'overlay__content'
    }
  }

  return (
    <div>
      <div className="lease-chat-list-container">
        <div className="lease-chat-wrapper">
          <div className="box-chat-lease">
            <Splide
              hasTrack={false}
              options={{
                perPage: 2,
                pagination: false,
                autoWidth: true,
                arrows: false,
              }}
            >
              <SplideTrack>
                <SplideSlide>
                  <div
                    className={
                      isActive ? 'box__lease box__lease--active' : 'box__lease'
                    }
                  >
                    <img src={DummyImage} alt="dummy" />
                    <div className="overlay">
                      <p>Suites @ Guillemard</p>
                    </div>
                  </div>
                </SplideSlide>

                <SplideSlide>
                  <div
                    className={
                      !isActive ? 'box__lease box__lease--active' : 'box__lease'
                    }
                  >
                    <img src={DummyImage} alt="dummy" />
                    <div className="overlay">
                      <p>79B Toa Payoh Central</p>
                    </div>
                  </div>
                </SplideSlide>
              </SplideTrack>
            </Splide>
          </div>
        </div>
      </div>

      <div className="chat-user-row">
        <div className="chat-user-slider">
          <Splide
            hasTrack={false}
            options={{
              perPage: 2,
              pagination: false,
              autoWidth: true,
              arrows: false,
            }}
          >
            <SplideTrack>
              <SplideSlide>
                <div className="chat-user-item ">
                  <div className="chat-user-item_name">
                    <p>DC</p>
                  </div>
                  <div className="chat-indicator"></div>
                </div>
              </SplideSlide>

              <SplideSlide>
                <div className="chat-user-item chat-user-item_active">
                  <div className="chat-user-item_name">
                    <p>WZ</p>
                  </div>
                  <div className="chat-indicator"></div>
                </div>
              </SplideSlide>
            </SplideTrack>
          </Splide>
        </div>
      </div>
    </div>
  )
}

export default ChatHead
