import React from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const SkeletonCardLoad = ({height}) => {
  return (
    <SkeletonTheme baseColor="#1e2434" highlightColor="#4f5e84" borderRadius={8}>
        <Skeleton height={height} />
    </SkeletonTheme>
  )
}

export default SkeletonCardLoad