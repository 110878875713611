import React from 'react'
import '../../assets/css/pages/LeasePage/viewGuide.css'

import { useNavigate } from 'react-router-dom'

import { ReactComponent as Back } from '../../assets/icon/general/Vector-1-back.svg'
import { ReactComponent as Info } from '../../assets/icon/general/Vector-18-info.svg'
import { accordionData } from './dummyData'
import AccordionItem from './components/AccordionItem'

const ViewGuide = () => {
  const navigate = useNavigate()

  return (
    <div className="listing-guide">
      <div className="guide-top-bar">
        <div className="guide-top-bar-icon" onClick={() => navigate(-1)}>
          <Back />
        </div>

        <p className="text-xl">Listing Guide</p>
      </div>

      <div className="lease-content">
        <div className="guide-information-box">
          <div className="guide-information-box_icon">
            <Info />
          </div>
          <p className="text-sm guide-information-box_desc">
            It is highly recommended for you to read the listing guide before
            you list your property. For the most efficient listing process,
            please have your property photos and property ownership documents
            ready.
          </p>
        </div>

        <div className="accordion-guide">
          {accordionData.map(({ title, content, index }) => (
            <AccordionItem title={title} content={content} key={index} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default ViewGuide
