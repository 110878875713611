import React, { useState, useEffect } from 'react'
import { ReactComponent as PenIcon } from '../../assets/icon/general/Vector-49-pen.svg'
import '../../assets/css/components/FirstTimePage/onboardingOutlite3.css'
import Modal from '../ui/Modal'
import userServices from '../../services/userService'
import userPhotoService from '../../services/userPhotoService'
import authService from '../../services/AuthService'
import { identificationTypeOptions, industryTypeOptions } from '../../services/valuesService'

function OnboardingOutlet3({ directEditPersonal, onBoard1, onBoard2 }) {
  const [isShow, setIsShow] = useState(false)
  const [isUseSingpass, setIsUseSingpass] = useState(false)
  const [account, setAccount] = useState([])
  const userType1 = 'tenant'
  const userType2 = 'landlord'

  const fetchAccountData = async (id) => {
    const accountData = await userServices.getOneFromId(id)
    setAccount(accountData)
  }

  const handleUpload = async (id, photo) => {
    photo[0].map(async (file, index) => {
      const res = await userPhotoService.uploadPhoto(file);
      await userServices.createUserPhotos(id, res.data.url, 1)
    })
    photo[1].map(async (file) => {
      const res = await userPhotoService.uploadPhoto(file);
      await userServices.createUserPhotos(id, res.data.url, 2)
    })
  }

  const handleSubmit = async (id, data) => {
    await userServices.updateOneUser(id, data)
  }

  useEffect(() => {
    fetchAccountData(localStorage.getItem(authService._userIdKey))
  }, [])

  const modalHandler = () => {
    try {
      handleUpload(account.id, onBoard2)
      handleSubmit(account.id, onBoard1)
      setIsShow(true)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className="onboarding__outlet-3">
      {isShow && (
        <Modal
          show={isShow}
          user={userType2}
          type="onboarding"
          hide={() => setIsShow(false)}
        />
      )}

      <div className="onboarding__outlet-3-head">
        <h1>Review</h1>
        <p className="p1">
          {isUseSingpass
            ? 'Please review your information retrieved via Singpass.'
            : 'Please check that all details entered are accurate and images (if any) uploaded are clear.'}
        </p>
      </div>

      <div className="onboarding__outlet-3-personal">
        <div className="personal__head">
          <p>Personal Information</p>

          {!isUseSingpass && (
            <button
              className="personal__edit"
              onClick={() => directEditPersonal(0)}
            >
              <PenIcon />
            </button>
          )}
        </div>

        <div className="personal__info">
          <p>FULL NAME</p>
          <p>{account.name}</p>
        </div>
        <div className="personal__info">
          <p>NATIONALITY</p>
          <p>{onBoard1.nationality}</p>
        </div>
        <div className="personal__info">
          <p>IDENTIFICATION TYPE</p>
          <p>
            {
              identificationTypeOptions.map((ident) => {
                if (ident.id === parseInt(onBoard1.identification_type)) {
                  return ident.label;
                }
              })
            }
          </p>
        </div>
        <div className="personal__info">
          <p>IDENTIFICATION NUMBER</p>
          <p>{onBoard1.identification_number}</p>
        </div>
        <div className="personal__info">
          <p>INDUSTRY / EMPLOYMENT SECTOR</p>
          <p>
            {
              industryTypeOptions.map((industry) => {
                if (industry.id === parseInt(onBoard1.industry)) {
                  return industry.label;
                }
              })
            }
          </p>
        </div>
      </div>

      {!isUseSingpass && (
        <div className="onboarding__outlet-3-identity">
          <div className="identity__head">
            <p>Proof of Identity</p>

            <button
              className="identity__edit"
              onClick={() => directEditPersonal(1)}
            >
              <PenIcon />
            </button>
          </div>

          <div className="identity__proof">
            <p>IDENTITY DOCUMENT</p>

            <div className="identity__preview">
              {onBoard2[0].map((file, index) => (
                <div className="identity__preview-image" key={index}>
                  <img src={URL.createObjectURL(file)} />
                </div>
              ))}
            </div>
          </div>

          <div className="identity__proof">
            <p>IDENTITY DOCUMENT</p>

            <div className="identity__preview">
              <div className="identity__preview-image">
                {onBoard2[1].map((file, index) => (
                  <div className="identity__preview-image" key={index}>
                    <img src={URL.createObjectURL(file)} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="onboarding__button">
        <button
          onClick={modalHandler}
          className="button__style-3 button__style-3--active"
          type="button"
        >
          Submit for Verification
        </button>
      </div>
    </div>
  )
}

export default OnboardingOutlet3
