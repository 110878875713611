import React from 'react'
import '../../../assets/css/components/LeasePage/Tenant/leaseTenantCurrentOutlet2.css'
import LeaseCurrentBox from './LeaseCurrentBox'
import Button from '../../ui/Button'

function LeaseTenantCurrentOutlet2() {
	return (
		<div className='current2'>
			<LeaseCurrentBox title="Manage Schedule" outlet="schedule" />

			<div className="schedule__items">
				<p className='head__title'>Upcoming</p>

				<div className="schedule__container">
					<div className='schedule__year'>2022</div>

					<div className="schedule__wrapper">
						<div className="schedule__card">
							<div className="card__left">
								<div className="date">
									<div className="date__month">
										<span>20</span>
										<span>Feb</span>
									</div>

									<div className="date__day">
										Mon
									</div>
								</div>
							</div>

							<div className="card__right">
								<div className="time-label">
									<span>02:30 PM - 04:00 PM</span>
								</div>

								<div className="content">
									<span>LAST SERVICED: 18 NOV 2022</span>
									<span>Aircon Servicing</span>
								</div>

								<div className='actions'>
									<Button directURL="/" buttonName="Reschedule" color="orange" type={2} allow={true} />
								</div>
							</div>
						</div>
					</div>
				</div>

			</div>

			<div className="schedule__items">
				<p className='head__title'>Past</p>

				<div className="schedule__container">
					<div className='schedule__year'>2022</div>

					<div className="schedule__wrapper">
						<div className="schedule__card">
							<div className="card__left">
								<div className="date">
									<div className="date__month">
										<span>18</span>
										<span>Nov</span>
									</div>

									<div className="date__day">
										Fri
									</div>
								</div>
							</div>

							<div className="card__right">
								<div className="time-label">
									<span>10:30 PM - 12:00 PM</span>

									<div className='label__wrapper label--contract'>
										<div className='label'></div>
										<p>Pending Proof</p>
									</div>
								</div>

								<div className="content">
									<span>LAST SERVICED: 17 AUG 2022</span>
									<span>Aircon Servicing</span>
								</div>

								<div className='actions'>
									<Button directURL="/" buttonName="Upload Receipt / Invoice" type={2} allow={true} />
								</div>
							</div>
						</div>

						<div className="schedule__card schedule__card--proof">
							<div className="card__left">
								<div className="date">
									<div className="date__month">
										<span>18</span>
										<span>Nov</span>
									</div>

									<div className="date__day">
										Fri
									</div>
								</div>
							</div>

							<div className="card__right">
								<div className="time-label">
									<span>10:30 PM - 12:00 PM</span>

									<div className='label__wrapper label--contract'>
										<div className='label'></div>
										<p>Pending Proof</p>
									</div>
								</div>

								<div className="content">
									<span>LAST SERVICED: 17 AUG 2022</span>
									<span>Aircon Servicing</span>
								</div>

								<div className='actions'>
									<Button directURL="/" buttonName="Upload Receipt / Invoice" type={2} allow={true} />
								</div>
							</div>
						</div>

						<div className="schedule__card schedule__card--complete">
							<div className="card__left">
								<div className="date">
									<div className="date__month">
										<span>18</span>
										<span>Nov</span>
									</div>

									<div className="date__day">
										Fri
									</div>
								</div>
							</div>

							<div className="card__right">
								<div className="time-label">
									<span>10:30 PM - 12:00 PM</span>

									<div className='label__wrapper label--contract'>
										<div className='label'></div>
										<p>Pending Proof</p>
									</div>
								</div>

								<div className="content">
									<span>LAST SERVICED: 17 AUG 2022</span>
									<span>Aircon Servicing</span>
								</div>

								<div className='actions'>
									<Button directURL="/" buttonName="Upload Receipt / Invoice" type={2} allow={true} />
								</div>
							</div>
						</div>
					</div>
				</div>

			</div>
		</div>
	)
}

export default LeaseTenantCurrentOutlet2