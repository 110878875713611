import InfoBox from '../ui/InfoBox'
import ManageRequestPic from '../../assets/images/vector__manage-request.png'
import { ReactComponent as ChatLogo } from '../../assets/icon/navigation/Vector-3.svg'
import { ReactComponent as WarningIcon } from '../../assets/icon/general/Vector-31-warning.svg'
import { useState, useEffect } from 'react'
import ModalManageOffer from './Modals/modalManageOffer'

import ModalViewing from './Modals/modalViewing'
import ModalReject from './Modals/modalReject'
import ModalExtend from './Modals/modalExtend'
import authService from '../../services/AuthService'
import tenantLeasePropService from '../../services/tenantLeasePropService'
import userServices from '../../services/userService'
import { tenantLeaseStatusOption, timeCounterFromNow } from '../../utils'
import Skeleton from 'react-loading-skeleton'

const ManageRequestOffer = () => {
  const [modal, setModal] = useState(false)
  const [actionModal, setActionModal] = useState('')
  const [typeModal, setTypeModal] = useState('')
  const [index, setIndex] = useState()
  const [tenantSentData, setTenantSentData] = useState()
  const [userSentData, setUserSentData] = useState()

  const [arrayOfTenantProfileId, setArrayOfTenantProfileId] = useState(null)

  // const [modalReject, setModalReject] = useState(false)
  // const [modalExtend, setModalExtend] = useState(false)

  const modalOfferAcceptHandler = (index) => {
    setTenantSentData(tenantLease[index])
    setUserSentData(user[index])
    setActionModal('accept')
    setTypeModal('offer')
    setModal(!modal)
  }

  const modalOfferRejectHandler = (index) => {
    setTenantSentData(tenantLease[index])
    setUserSentData(user[index])
    setActionModal('reject')
    setTypeModal('offer')
    setModal(!modal)
  }

  const modalOfferExtendHandler = () => {
    setActionModal('extend')
    setTypeModal('offer')
    setModal(!modal)
  }

  const [tenantLease, setTenantLease] = useState(null)
  const [user, setUser] = useState([])

  async function fetchTenantLeaseProp() {
    const user_profile_id = authService.getLoggedUserData().user_profile_id // Use service

    const tenantResponse =
      await tenantLeasePropService.getTenantLeasePropBasedOnProfileId(
        user_profile_id
      )

    const tenantLeasePropOnOffer = tenantResponse.filter((data) => (data.offer_status === 1 || data.offer_status === 2))

    setTenantLease(tenantLeasePropOnOffer)
  }

  async function fetchTenantInfo(array) {
    const res = []
    for (let i = 0; i < array.length; i++) {
      const response = await userServices.getOneFromProfile(array[i])
      res.push(response)
    }
    setUser(res)
  }

  // First, load data on page load
  useEffect(() => {
    fetchTenantLeaseProp()
  }, [])

  useEffect(() => {
    if(tenantLease){
      const profileIds = tenantLease.map(
        (data) => data.user_profile_tenant_id
      )

      if(profileIds) setArrayOfTenantProfileId(profileIds)
    }
  }, [tenantLease])

  useEffect(() => {
    if(arrayOfTenantProfileId){
      fetchTenantInfo(arrayOfTenantProfileId)
    }
  }, [arrayOfTenantProfileId])
  
  // useEffect(() => {
  //   fetchTenantLeaseProp()
  //   const arrayOfTenantProfileId = tenantLease.map(
  //     (data) => data.user_profile_tenant_id
  //   )
  //   fetchTenantInfo(arrayOfTenantProfileId)
  // }, [tenantLease])

  return (
    <div className="manage-request__body">
      {/* {modal && <ModalViewing hide={() => setModal(false)} />} */}
      {modal && (
        <ModalManageOffer
          data_user={userSentData}
          data_lease={tenantSentData}
          action={actionModal}
          type={typeModal}
          hide={() => setModal(false)}
        />
      )}

      {/* {modalReject && <ModalReject hide={() => setModalReject(false)} />} */}
      {/* {modalExtend && <ModalExtend hide={() => setModalExtend(false)} />} */}
      <InfoBox
        info={
          'Offers will be rejected automatically if there is no response within 48 hours of the time of request. You may extend the offer period if required.'
        }
      />
      <div className="manage-request__section-container">
        <div className="manage-request__flex-container">
          <div className="manage-request__reminder-image">
            <img src={ManageRequestPic} alt="illust1" />
          </div>
          <div className="manage-request__reminder-container">
            <h1 className="manage-request__reminder-text-upper">
              A gentle reminder...
            </h1>
            <p className="manage-request__reminder-text-down">
              You have {tenantLease?.length} offers that require your attention.
            </p>
          </div>
        </div>
        {tenantLease && user ? (
          tenantLease.map((data, index) => {
            const created_date = new Date(data.created_date)
            created_date.setDate(created_date.getDate() + 2)
            const between_time = timeCounterFromNow(created_date)
            const classNameDot = (() => {
              switch(data.offer_status){
                case 1:
                  return 'pending';
                case 2: 
                  return 'accepted'
                case 3: 
                  return 'rejected'
              }
            })();

            return (
              <>
                <div className="manage-request__section-container">
                  <div className="manage-request__warning-container">
                    <div style={{ width: '1.6rem' }}>
                      <WarningIcon className="manage-request__warning-icon" />
                    </div>
                    <p className="manage-request__warning-text">
                      Request expiring in {between_time}
                    </p>
                  </div>
                  <div className="manage-request__status-container">
                    <div
                      className={
                        `manage-request__status-dot ${classNameDot}`
                      }
                    ></div>
                    <h1
                      className={
                        'manage-request__status-text' +
                        `${
                          data.offer_status === 1
                            ? ' pending-text'
                            : ` ${
                                tenantLeaseStatusOption[data.offer_status]
                              }-text`
                        }`
                      }
                    >
                      {tenantLeaseStatusOption[data.offer_status]}
                    </h1>
                  </div>
                  <div className="manage-request__name-container">
                    <h1 className="manage-request__name-text">
                      {user[index] ? user[index].name : "Loading..."}
                    </h1>
                    <div className="manage-request__comment-container">
                      <h1 className="manage-request__currency">SGD</h1>
                      <h1 className="manage-request__mny-amount">
                        ${data.monthly_rental}
                      </h1>
                      <ChatLogo className="manage-request__comment-icon" />
                    </div>
                  </div>
                  <button className="manage-request__accept-btn">
                    Letter of Intent
                  </button>
                  {
                    data.offer_status === 2 && (
                      <button className="manage-request__accept-btn">
                        Sign Tenancy Agreement
                      </button>
                    )
                  }
                  {data.offer_status === 1 ? (
                    <div className="manage-request__btn-container">
                      <button
                        value={index}
                        className="manage-request__reject-btn"
                        onClick={(e) => {
                          modalOfferExtendHandler(e.target.value)
                        }}
                      >
                        Extend
                      </button>
                      <button
                        value={index}
                        className="manage-request__reject-btn"
                        onClick={(e) => {
                          modalOfferRejectHandler(e.target.value)
                        }}
                      >
                        Reject
                      </button>
                      <button
                        className="manage-request__accept-btn"
                        onClick={(e) => {
                          modalOfferAcceptHandler(e.target.tabIndex)
                        }}
                      >
                        Accept
                      </button>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </>
            )
          })
        ) : (
          <>No Data</>
        )}
      </div>
    </div>
  )
}

export default ManageRequestOffer
