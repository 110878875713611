import React from 'react'
import BackButton from './BackButton'
import '../../assets/css/components/ui/headBack.css'

function HeadBack(props) {
	return (
		<div className='head-back'>
			<BackButton directURL={props.backLink} size="small" />
			<h1>{props.title}</h1>
		</div>
	)
}

export default HeadBack