import React from 'react'
import InfoBox from '../../../components/ui/InfoBox'

import { ReactComponent as Check } from '../../../assets/icon/general/Vector-42-check.svg'
import { ReactComponent as Alert } from '../../../assets/icon/general/Vector-Info-Box.svg'
import { SECURITY_DEPOSIT_AGREEMENT } from '../schema'

const SecurityDeposit = ({ suggestedEdit, onClick }) => {
  return (
    <div>
      <div className="agreement-content">
        <InfoBox info="This Tenancy Agreement (TA) between “Tenant” Wong Zhenyi (*****567A) and “Landlord” Wilson Chan Yi De (*****890B) will be legally binding once both parties have signed the TA and once the necessary stamp duty has been paid." />
        <div className="agreement-item_box">
          <div className="agreement-item_heading">
            <p>2.2 / Security Deposit</p>
          </div>
          {SECURITY_DEPOSIT_AGREEMENT.map((item, index) => (
            <div className="agreement-item_content" key={index}>
              <div>
                <p>{item.name}</p>
              </div>
              <div>
                <p>{item.value}</p>
              </div>
            </div>
          ))}
        </div>

        <div className="agreement-item_box">
          <div className="text-lg bold" style={{ marginBottom: '1rem' }}>
            <p>Tenant agrees to...</p>
          </div>
          <div className="agreement-item_desc">
            <p>
              Pay the security deposit of{' '}
              <span className="agreement-item_highlight">S$7,200</span>,
              equivalent to 2 month(s) rental to the landlord via P.Lease upon
              signing the Tenancy Agreement. Should the tenant fail to perform
              and/or comply with any of the conditions of this agreement, the
              landlord shall be entitled to deduct an amount from the security
              deposit to remedy the breach and the balance amount after
              deduction shall be refunded without interest to the tenant within{' '}
              <span className="agreement-item_highlight">14 days </span>from the
              expiry or termination of the agreement.
            </p>
          </div>
        </div>
      </div>

      <div className="agreement-button_container">
        <div style={{ flex: 2 }}>
          <div className="tenant-details-button">
            <Alert />
            <p className="text-md bold">Reject & Suggest Edit</p>
          </div>
        </div>

        <div style={{ flex: 1 }}>
          <div className="tenant-details-offerBtn" onClick={onClick}>
            <Check />
            <p className="text-md bold">Accept</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SecurityDeposit
