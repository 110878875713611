import axios from 'axios'
import { apiBaseURL } from '../utils'

const createOne = async (prop_id, id) => {
  let data = JSON.stringify({
    property_id: `${prop_id}`,
    furnishing_id: `${id}`,
  })

  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: `${apiBaseURL}/property_furnishings`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: data,
  }

  axios
    .request(config)
    .then((response) => {
      console.log(response)
    })
    .catch((error) => {
      console.log(error)
    })
}

const propertyFurnishingServices = { createOne }

export default propertyFurnishingServices
