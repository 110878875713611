import React, { useEffect, useState } from 'react'
import { ReactComponent as RetriveSingpassIcon } from '../../assets/logo/retrive singpass.svg'
import { ReactComponent as CheckIcon } from '../../assets/icon/general/Vector-42-check.svg'
import '../../assets/css/components/FirstTimePage/onboardingOutlite1.css'
import { useForm } from 'react-hook-form'
import userServices from '../../services/userService'
import authService from '../../services/AuthService'
import { identificationTypeOptions, industryTypeOptions } from '../../services/valuesService'

function OnboardingOutlet1({ directEditPersonal, directEditOB1, onBoard1Data }, ) {
  const [isFilled, setIsFilled] = useState(false)
  const [isVerify, setIsVerify] = useState(false)
  const [ account, setAccount ] = useState([])

  const { register, handleSubmit, watch, formState: { errors } } = useForm();

  const [identificationNumber, setIdentificationNumber] = useState(onBoard1Data.identification_number);
  const [nationality, setNationality] = useState(onBoard1Data.nationality)
  const [identificationType, setIdentificationType] = useState(onBoard1Data.identification_type)
  const [industry, setIndustry] = useState(onBoard1Data.industry)

  const _handleSubmit = async (formData) => {
    try {
      directEditOB1(formData);
      directEditPersonal(1);
    } catch (error) {
      console.log(error);
    }
  }

  const fetchAccountData = async (id) => {
    const accountData = await userServices.getOneFromId(id);
    setAccount(accountData);
  }

  useEffect(() => {
    console.log(nationality == "Singapore");
    fetchAccountData(localStorage.getItem(authService._userIdKey));
  }, [])

  useEffect(() => {
    if(account.email === ""){
      setIsVerify(false)
    }else{
      setIsVerify(true)
    }
  })

  useEffect(() => {
    if(identificationNumber === undefined || identificationNumber === "") setIsFilled(false) 
    else setIsFilled(true)
  }, [identificationNumber])
  

  return (
    <div className="onboarding__outlet">
      <div className="onboarding__outlet-head">
        <p>Contact Information</p>
      </div>

      <div className="onboarding__outlet-form">
        <form className="onboarding__form" onSubmit={handleSubmit(_handleSubmit)}>
          <div className="form-input">
            <label>
              FULL NAME
              <input
                className="input__text"
                type="text"
                placeholder="Type here..."
                disabled
                value={account.name}
              ></input>
            </label>
          </div>

          <div className="form-input">
            <label>
              MOBILE NUMBER
              <div className="form-input__number">
                <select>
                  <option value="+62">+62</option>
                  <option value="+66">+66</option>
                </select>

                <div className="form-input__input-number">
                  <input
                    className="input__text"
                    type="text"
                    placeholder="Type here..."
                    disabled
                    value={account.mobile_number}
                  ></input>

                  <div className="form-input__check-icon">
                    <CheckIcon />
                  </div>
                </div>
              </div>
            </label>
          </div>

          <div className="form-input">
            <label>
              EMAIL ADDRESS
              <div className="form-input__input-email">
                <input
                  className="input__text"
                  type="text"
                  placeholder="Type here..."
                  disabled
                  value={account.email}
                ></input>

                {!isVerify ? (
                  <button className="form-input__input-email-verify">
                    Verify
                  </button>
                ) : (
                  <div className="form-input__check-icon">
                    <CheckIcon />
                  </div>
                )}
              </div>
            </label>
          </div>

          <div className="onboarding__outlet-retrive-container">
            <p className="p1">Personal Details</p>

            {/* <div className="onboarding__outlet-retrive">
              <button
                type="button"
                className="onboarding__outlet-retrive-button"
              >
                <RetriveSingpassIcon />
              </button>
            </div>

            <p className="p2">or enter the details below</p> */}
          </div>

          <div className="form-input">
            <label>
              NATIONALITY
              <select className="input__select" {...register("nationality")}>
                <option value="Singapore" selected={nationality == "Singapore" ? true : false}>Singapore</option>
                <option value="Indonesia" selected={nationality == "Indonesia" ? true : false}>Indonesia</option>
              </select>
            </label>
          </div>

          <div className="form-input">
            <label>
              IDENTIFICATION TYPE
              <select className="input__select" {...register("identification_type")}>
                {
                  identificationTypeOptions.map((ident) => {
                    return <option value={ident.id} selected={parseInt(ident.id) === parseInt(identificationType)}>{ident.label}</option>
                  })
                }
              </select>
            </label>
          </div>

          <div className="form-input">
            <label>
              IDENTIFICATION NUMBER
              <input
                className="input__text"
                type="text"
                placeholder="Type here..."
                {...register("identification_number")}
                value={identificationNumber}
                onChange={(e) => setIdentificationNumber(e.target.value)}
              ></input>
            </label>
          </div>

          <div className="form-input">
            <label>
              INDUSTRY / EMPLOYMENT SECTOR
              <select className="input__select" {...register("industry", {
                setValueAs: value => parseInt(value),
              })}>
                {
                  industryTypeOptions.map((ind) => {
                    return <option value={ind.id} selected={parseInt(ind.id) === parseInt(industry)}>{ind.label}</option>
                  })
                }
              </select>
            </label>
          </div>

          <div className="onboarding__button">
            <input
              className={`${
                isFilled
                  ? 'button__style-3 button__style-3--active'
                  : 'button__style-3'
              }`}
              type="submit"
              value="Continue"
            />
          </div>
        </form>
      </div>
    </div>
  )
}

export default OnboardingOutlet1
