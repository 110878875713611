import React from 'react'
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide'
import { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom'

import '../../assets/css/components/ui/cardProperty.css'
import ImgPlaceholder from '../../assets/images/image 5.png'
import { ReactComponent as CommentIcon } from '../../assets/icon/general/Vector-6-chatbubble.svg'
import { ReactComponent as LoveIcon } from '../../assets/icon/general/Vector-15-love.svg'
import { ReactComponent as LoveFilled } from '../../assets/icon/general/Vector-69-love.svg'
import { ReactComponent as BedIcon } from '../../assets/icon/general/Vector-4-bed.svg'
import { ReactComponent as BathIcon } from '../../assets/icon/general/Vector-85-bathtub.svg'
import { ReactComponent as EyeIcon } from '../../assets/icon/general/Vector-30-eye.svg'
import { ReactComponent as PlusIcon } from '../../assets/icon/general/Vector-plus.svg'
import { ReactComponent as FeaturedIcon } from '../../assets/icon/general/Vector-22-featured.svg'
import { ReactComponent as ImageIcon } from '../../assets/icon/general/Vector-34-image.svg'
import { ReactComponent as SwipeIcon } from '../../assets/icon/general/Vector-35-swipe.svg'
import propertyService from '../../services/PropertyService'
import moment from 'moment'
import { propertyRentalTypeOptions } from '../../services/valuesService'
import userServices from '../../services/userService'
import SkeletonCardLoad from '../utils/SkeletonCardLoad'
import propertyLikedService from '../../services/propertyLikedService'
import authService from '../../services/AuthService'
import axios from 'axios'
import { apiBaseURL } from '../../utils'

function CardProperty({ type, data }) {
  const [propertyPhotos, setPropertyPhotos] = useState([])
  const [landlord, setLandlord] = useState()
  const [likedProperties, setLikedProperties] = useState([])
  const [likeID, setLikeID] = useState()
  const [liked, setLiked] = useState(false)
  const [locationData, setLocationData] = useState()

  const defaultPhoto = {
    asset_location: "https://placehold.co/600x400"
  }

  const [isLoading, setIsLoading] = useState(true);

  const fetchLandlordData = async (id) => {
    const landlordData = await userServices.getOneFromProfile(id);
    setLandlord(landlordData);
  }

  const fetchPropertiesPhoto = async (id) => {
    const propertyPhoto = await propertyService.getFeaturedPhotos(id);
    if (propertyPhoto.length > 0) setPropertyPhotos(propertyPhoto)
    else setPropertyPhotos([defaultPhoto])
  }

  useEffect(() => {
    if (data) {
      fetchPropertiesPhoto(data.id);
      fetchLandlordData(data.user_profile_landlord_id);
    }
  }, []);

  useEffect(() => {
    if (propertyPhotos.length > 0 && landlord) {
      setIsLoading(false)
    }
  }, [propertyPhotos, landlord]);

  const fetchLikedProperties = async (user_id) => {
    const likedPropertiesData = await propertyLikedService.getOneFromUserId(
      user_id
    )
    setLikedProperties(likedPropertiesData)
  }

  const createPropertyLike = async (prop_id, user_id) => {
    const liked = await propertyLikedService.createOne(prop_id, user_id)
    if (liked.property_id === data.id) {
      setLiked(true)
    }
  }

  const deletePropertyLike = async (id) => {
    await propertyLikedService.deleteOne(id)
    setLiked(false)
  }

  const getLocationData = async() => {
    let postal_code = data.postal_code
    postal_code = postal_code.slice(0,2)

    const location = await axios.get(`${apiBaseURL}/location_postal_codes/postal_code/${postal_code}`)

    setLocationData(location.data[0])
  }

  useEffect(() => {
    fetchLikedProperties(localStorage.getItem(authService._userIdKey))
  }, [])

  useEffect(() => {
    // eslint-disable-next-line array-callback-return
    likedProperties.map((prop) => {
      if (prop.property_id === data.id) {
        setLiked(true)
        setLikeID(prop.id)
      }
    })
  })

  useEffect(() => {
    getLocationData()
  }, [])

  useEffect(() => {
    fetchPropertiesPhoto(data.id)
  }, [])

  useEffect(() => {
    fetchLandlordData(data.user_profile_landlord_id)
  }, [])

  const handleLike = () => {
    try {
      console.log('handle Like')
      createPropertyLike(data.id, localStorage.getItem(authService._userIdKey))
    } catch (error) { }
  }

  const handleUnLike = () => {
    try {
      console.log('hendlae UnLike')
      deletePropertyLike(likeID)
    } catch (error) { }
  }

  // console.log('flag', liked)

  return landlord === undefined ? (
    <SkeletonCardLoad height={400} />
  ) : (
    <div className="card-property__card">
      <div className="card__image">
        <div className="card__image-badge">
          <FeaturedIcon />
          <p>Featured</p>
        </div>

        <div className="card__image-slide">
          {
            propertyPhotos.length > 0 ? <Splide
              options={{
                perPage: 1,
                noDrag: true,
                drag: type === 'browse' ? true : false,
                pagination: false,
                arrows: type === 'newleaseShortlist' ? true : false,
              }}
            >
              {
                propertyPhotos.length > 0 ?
                  propertyPhotos.map((photo) => {
                    return (
                      <SplideSlide>
                        <img src={photo.asset_location} />
                      </SplideSlide>
                    )
                  }) : (
                    <SplideSlide>
                      <img src='https://placehold.co/600x400' />
                    </SplideSlide>
                  )
              }
            </Splide> : <>Loading....</>
          }
        </div>

        <div className="card__image-info">
          <div className="card__image-info-wrapper">
            <div>
              <ImageIcon />
              <p>{propertyPhotos.length}</p>
            </div>
            {type === 'browse' && (
              <div>
                <SwipeIcon />
                <p>Swipe</p>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* TODO: Make Dynamic */}
      <div className="card-property__detail">
        <div className="detail__button-flag">
          <div className="flag__train">
            <div className='flag-label flag-label--blue'>
              <p>DTL</p>
            </div>

            <p className="p1">Rochor</p>
          </div>

          <div className="detail__button-container">
            <button>
              <CommentIcon />
            </button>
            {!liked ? (
              <button onClick={handleLike}>
                <LoveIcon />
              </button>
            ) : (
              <button onClick={handleUnLike}>
                <p>Liked</p>
              </button>
            )}
          </div>
        </div>

        <div className="detail__property-name-price">
          <div className="property-name">
            <h1>
              {propertyRentalTypeOptions.map((type) => {
                if (data.rental_type === type.id) {
                  return type.label
                }
              })}{' '}
              at {locationData?.name}
            </h1>
            <p>{data.address}</p>
          </div>

          <div className="property-price">
            <h1>
              <span>S$</span> {data.monthly_rental} / mth
            </h1>
          </div>
        </div>

        <div className="detail__property-feature">
          <div className="detail__feature">
            <p>{data.number_of_beds === 0 ? "Studio" : data.number_of_beds === 5 ? "5+" : data.number_of_beds}</p>

            <div className="feature__icon">
              <BedIcon />
            </div>
          </div>

          <div className="detail__feature">
            <p>{data.number_of_baths === 3 ? "3+" : data.number_of_baths}</p>

            <div className="feature__icon">
              <BathIcon />
            </div>
          </div>

          <p className="p1">
            {data.floor_area} sqft / {(data.floor_area / 10.764).toFixed(2)} sqm
          </p>
        </div>

        <div className="detail__property-bottom">
          <div className="bottom-interact">
            <div className="interact__icon">
              <EyeIcon />
            </div>

            <p>{data.property_view_count}</p>
          </div>

          {/* TODO: Make Dynamic */}
          <div className="bottom-interact">
            <div className="interact__icon">
              <LoveIcon />
            </div>

            <p>5</p>
          </div>

          <div className="bottom-interact">
            <div className="interact__icon interact__icon--plus">
              <PlusIcon />
            </div>

            <p>
              {moment(data.created_date).fromNow()} by {landlord.name}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CardProperty
