import React from 'react'
import ReactDOM from 'react-dom'
import '../../../../assets/css/components/ui/modal.css'
import { ReactComponent as Verify } from '../../../../assets/illustration/services/Quality Check_Monochromatic 1.svg'
import { ReactComponent as Close } from '../../../../assets/icon/general/Vector-9-close.svg'
import { ReactComponent as Stats } from '../../../../assets/icon/general/Vector-45-stats.svg'
import { ReactComponent as CheckIcon } from '../../../../assets/icon/general/Vector-42-check.svg'

function ModalServicing({ show, hide, onDismiss }) {
  return ReactDOM.createPortal(
    <div className="modal">
      <div className="modal__overlay" onClick={hide}></div>

      <div className="modal__content">
        <div className="content__illus">
          <Verify />
        </div>

        <div className="content__text">
          <h1>Opt in for P.Lease-certified partner services</h1>
          <p>
            We are working with the best-in-class service providers to help you
            keep your property in tip-top condition. Say yes to:
          </p>
          <div className="services-advantage">
            <div className="services-advantage_item">
              <CheckIcon />
              <p>Damage-free guarantee</p>
            </div>
            <div className="services-advantage_item">
              <CheckIcon />
              <p>Fair, transparent, itemised flat pricing</p>
            </div>
            <div className="services-advantage_item">
              <CheckIcon />
              <p>Integrated invoicing and one-tap payment</p>
            </div>
          </div>
        </div>

        <div className="content__handler">
          <button className="button__style-4--1">
            <Stats />
            Learn more
          </button>
          <button className="button__style-4--2" onClick={onDismiss}>
            <Close />
            Dismiss message
          </button>
        </div>
      </div>
    </div>,
    document.getElementById('portal')
  )
}

export default ModalServicing
