import React, { useEffect, useState } from 'react'
import './ScheduleViewing.css'
import HeadBack from '../../components/ui/HeadBack'
import InfoBox from '../../components/ui/InfoBox'
import Modal from '../../components/ui/Modal'
import TenantViewingService from '../../services/TenantViewingService'
import { useParams, useLocation } from 'react-router-dom'
import { monthOptions, dayOptions } from '../../utils'

import AuthService from '../../services/AuthService'
import profileService from '../../services/profileService'
import userServices from '../../services/userService'
import SnackBar from '../../components/ui/SnackBar'

const ScheduleViewing = () => {
  const [isFilled, setIsFilled] = useState(false)
  const [isShow, setIsShow] = useState(false)
  const params = useParams()
  const { state } = useLocation()

  // fetch data
  const [propertyViewingDate, setPropertyViewingDate] = useState([])

  // forms state
  const [userTenantId, setUserTenantId] = useState()
  const [selectTimeSlot, setSelectTimeSlot] = useState()
  const [paxNumber, setPaxNumber] = useState()
  const [additionalOccupier, setAdditionalOccupier] = useState()
  const [termsMonth, setTermsMonth] = useState()
  const [name, setName] = useState()
  const [citizenship, setCitizenship] = useState()
  const [relationship, setRelationship] = useState(1)
  const [pet, setPet] = useState()
  const [expectedLeaseStart, setExpectedLeaseStart] = useState()

  // Snackbar
  const [snackbarMessage, setSnackbarMessage] = useState("")
  const [snackbarStatus, setSnackbarStatus] = useState(false)

  useEffect(() => {
    let timeout
    if (snackbarStatus) {
      timeout = setTimeout(() => setSnackbarStatus(false), 2000);
    }
    return () => clearTimeout(timeout);
  }, [snackbarStatus]);

  const userId = localStorage.getItem(AuthService._userIdKey)

  const changeTime = (string_date) => {
    const data_time = string_date.split(':')
    const suffix = Number(data_time[0]) > 12 ? 'PM' : 'AM'
    return `${data_time[0]}:${data_time[1]} ${suffix}`
  }

  const fetchPropertyViewingDate = async (id) => {
    const propertyViewingDateResponse =
      await TenantViewingService.getAllViewingDateBasedOnId(id)
    const propertyViewingDateData = propertyViewingDateResponse.map((data) => {
      const viewing_date = new Date(data.viewing_date.toString())
      const time_start = changeTime(data.time_start)
      const time_end = changeTime(data.time_end)
      return {
        ...data,
        viewing_date: viewing_date,
        time_start: time_start,
        time_end: time_end,
      }
    })
    setPropertyViewingDate(propertyViewingDateData)
  }

  const fetchUserDetail = async () => {
    const userDetailResponse = await userServices.getOneFromId(userId)
    const userProfileResponse = await profileService.getOneFromUser(userId)
    console.log(userProfileResponse)
    setName(userDetailResponse.name)
    setUserTenantId(userProfileResponse.id)
  }

  useEffect(() => {
    fetchPropertyViewingDate(params.propertyId)
    fetchUserDetail()
  }, [])

  useEffect(() => {
    if (selectTimeSlot) {
      setIsFilled(true)
    }
  }, [selectTimeSlot])

  const _validateForm = () => {
    if(!citizenship) {
      setSnackbarMessage("Citizenship can't be empty")
      setSnackbarStatus(true)
      return false
    }

    if(!additionalOccupier) {
      setSnackbarMessage("Number of additional occupiers should be chosen")
      setSnackbarStatus(true)
      return false;
    }

    if(!name) {
      setSnackbarMessage("Name can't be empty")
      setSnackbarStatus(true)
      return false
    }
    
    if(!pet) {
      setSnackbarMessage("Pets options should be selected")
      setSnackbarStatus(true)
      return false
    }

    return true;
  }

  const requestViewingHandler = async () => {
    // setIsShow(true)

    if(!_validateForm()){
      return false;
    }

    const {
      user_profile_agent_id,
      user_profile_landlord_id,
      id: property_id,
    } = state.property.detailData
    const property_viewing_date = propertyViewingDate[selectTimeSlot - 1]
    const property_viewing_date_id = property_viewing_date.id
    const total_viewing_request = property_viewing_date.total_viewing_request

    const expected_lease_date =
      expectedLeaseStart === undefined
        ? ''
        : new Date(expectedLeaseStart)
            .toISOString()
            .slice(0, 19)
            .replace('T', ' ')

    const form = {
      user_profile_landlord_id,
      user_profile_agent_id,
      user_profile_tenant_id: userTenantId,
      property_id,
      property_viewing_date_id,
      name,
      citizenship,
      number_of_additional_occupiers: additionalOccupier,
      relationship: Number(relationship),
      pets: Number(pet),
      expected_lease_date,
      expected_terms_of_lease_month: termsMonth,
      status: 1,
      number_of_pax_viewing: paxNumber,
    }

    if (expected_lease_date === '') {
      delete form.expected_lease_date
    }

    if (user_profile_landlord_id === null) {
      delete form.user_profile_landlord_id
    }

    const response = await TenantViewingService.requestViewing(form)
    const updateViewingRequeset = await TenantViewingService.addViewingRequest(
      property_viewing_date_id,
      total_viewing_request + 1
    )

    setIsShow(true);
  }

  return (
    <>
      <div style={{ position: 'relative' }}>
        <SnackBar status={snackbarStatus} message={snackbarMessage} />

        {isShow && (
          <Modal
            show={isShow}
            type="scheduleViewing"
            hide={() => {
              window.location.reload(true)
              setIsShow(false)
            }}
          />
        )}
        <HeadBack
          title="Schedule Viewing"
          backLink={`/browse/tenant-detail/${params.propertyId}`}
        />

        <div className="viewing-page">
          <InfoBox info="Once your scheduled viewing timeslot has been accepted by the landlord, you will find the full address for the property in the Manage > New Lease page." />

          <div className="requirements-container">
            <p className="text-lg bold">Viewing Requirements</p>
            <div className="timeslot-container">
              <p className="text-md bold">Select a Timeslot</p>
              <div className="timeslot-card-container">
                {propertyViewingDate.map((property, index) => {
                  const chooser = index + 1
                  const request_left =
                    Number(property.viewing_quota) -
                    Number(property.total_viewing_request)
                  const flag = request_left <= 0 ? true : false
                  return (
                    <div
                      class={`timeslot-card-item ${
                        selectTimeSlot === chooser ? 'timeslot-selected' : null
                        // TODO: change to request_left - 1
                      } ${flag && 'disabled-filter'} `}
                      onClick={() => setSelectTimeSlot(chooser)}
                    >
                      <div className="timeslot-date">
                        <p className="text-lg normal">
                          {property.viewing_date.getDate()}
                        </p>
                        <p className="text-lg normal">
                          {monthOptions[property.viewing_date.getMonth()]}
                        </p>
                        <div className="timeslot-day">
                          <p className="text-lg normal">
                            {dayOptions[property.viewing_date.getDay()]}
                          </p>
                        </div>
                      </div>
                      <div className="timeslot-time">
                        {/* TODO: change to request_left <= 0 */}
                        {request_left <= 0 ? (
                          <div className="timeslot-occupied-full">
                            <div className="slot-indicator-full"></div>
                            <div>
                              <p className="text-xs normal">Full</p>
                            </div>
                          </div>
                        ) : (
                          <div className="timeslot-occupied-available">
                            <div className="slot-indicator-available"></div>
                            <div>
                              <p className="text-xs normal">
                                {request_left} pax left
                              </p>
                            </div>
                          </div>
                        )}

                        <p className="text-sm normal timeslot-time">
                          {property.time_start} - {property.time_end}
                        </p>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>

            <div className="pax-container">
              <p className="text-md bold">Number of Pax Viewing</p>
              <div className="type-option_item-container">
                <div
                  className={`${
                    paxNumber === 1
                      ? 'type-option_item option-selected'
                      : 'type-option_item'
                  }`}
                  onClick={() => setPaxNumber(1)}
                >
                  <p>1</p>
                </div>

                <div
                  className={`${
                    paxNumber === 2
                      ? 'type-option_item option-selected'
                      : 'type-option_item'
                  }`}
                  onClick={() => setPaxNumber(2)}
                >
                  <p>2</p>
                </div>

                <div
                  className={`${
                    paxNumber === 3
                      ? 'type-option_item option-selected'
                      : 'type-option_item'
                  }`}
                  onClick={() => setPaxNumber(3)}
                >
                  <p>3</p>
                </div>

                <div
                  className={`${
                    paxNumber === 4
                      ? 'type-option_item option-selected'
                      : 'type-option_item'
                  }`}
                  onClick={() => setPaxNumber(4)}
                >
                  <p>4</p>
                </div>

                <div
                  className={`${
                    paxNumber === 5
                      ? 'type-option_item option-selected'
                      : 'type-option_item'
                  }`}
                  onClick={() => setPaxNumber(5)}
                >
                  <p>5</p>
                </div>
              </div>
            </div>
          </div>

          <div className="tenant-profile-container">
            <p className="text-lg bold">Tenant Profile</p>

            <div className="form-input tenant-profile-form">
              <label className="text-md bold">
                Name of Tenant
                <input
                  className="input__text"
                  type="text"
                  placeholder="Type here..."
                  value={`${name}`}
                  onChange={(e) => setName(e.target.value)}
                ></input>
              </label>
            </div>

            <div className="form-input tenant-profile-form">
              <label className="text-md bold">
                Citizenship
                <input
                  className="input__text"
                  type="text"
                  placeholder="Type here..."
                  onChange={(e) => setCitizenship(e.target.value)}
                ></input>
              </label>
            </div>

            <div className="tenant-profile-form">
              <p className="text-md bold">Number of Additional Occupier(s)</p>
              <div className="type-option_item-container">
                <div
                  className={`${
                    additionalOccupier === 0
                      ? 'type-option_item option-selected'
                      : 'type-option_item'
                  }`}
                  onClick={() => setAdditionalOccupier(0)}
                >
                  <p>0</p>
                </div>

                <div
                  className={`${
                    additionalOccupier === 1
                      ? 'type-option_item option-selected'
                      : 'type-option_item'
                  }`}
                  onClick={() => setAdditionalOccupier(1)}
                >
                  <p>1</p>
                </div>

                <div
                  className={`${
                    additionalOccupier === 2
                      ? 'type-option_item option-selected'
                      : 'type-option_item'
                  }`}
                  onClick={() => setAdditionalOccupier(2)}
                >
                  <p>2</p>
                </div>

                <div
                  className={`${
                    additionalOccupier === 3
                      ? 'type-option_item option-selected'
                      : 'type-option_item'
                  }`}
                  onClick={() => setAdditionalOccupier(3)}
                >
                  <p>3</p>
                </div>

                <div
                  className={`${
                    additionalOccupier === 4
                      ? 'type-option_item option-selected'
                      : 'type-option_item'
                  }`}
                  onClick={() => setAdditionalOccupier(4)}
                >
                  <p>4</p>
                </div>

                <div
                  className={`${
                    additionalOccupier === 5
                      ? 'type-option_item option-selected'
                      : 'type-option_item'
                  }`}
                  onClick={() => setAdditionalOccupier(5)}
                >
                  <p>5</p>
                </div>
              </div>
            </div>

            <div className="tenant-profile-form">
              <div className="type-option_item-container">
                <div>
                  <label
                    className="text-md bold"
                    style={{ marginBottom: '1rem' }}
                  >
                    Relationship with Additional Occupier(s)
                  </label>

                  <select
                    name="relationship"
                    id="relationship"
                    className="date-picker-container"
                    onChange={(e) => setRelationship(e.target.value)}
                  >
                    <option value="1" selected>
                      Family
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <div class="permissions-radio-container">
              <p class="text-md bold">Pets</p>
              <div class="permissions-radio-option">
                <label class="radio-option-container">
                  <input
                    type="radio"
                    name="pets-radio"
                    id="pets-0"
                    value={1}
                    onChange={(e) => setPet(e.target.value)}
                  />
                  <label class="radio-custom" for="pets-0">
                    Yes
                  </label>
                  <span class="checkmark"></span>
                </label>

                <label class="radio-option-container">
                  <input
                    type="radio"
                    name="pets-radio"
                    id="pets-1"
                    value={2}
                    onChange={(e) => setPet(e.target.value)}
                  />
                  <label class="radio-custom" for="pets-1">
                    No
                  </label>
                  <span class="checkmark"></span>
                </label>
              </div>
            </div>

            <div className="tenant-profile-form">
              <p className="text-md bold" style={{ marginBottom: '1rem' }}>
                Expected Lease Start{' '}
                <span style={{ color: 'var(--c-white-3)' }}>(optional)</span>
              </p>
              <input
                type="date"
                id="birthday"
                name="birthday"
                className="date-picker-container"
                onChange={(e) => setExpectedLeaseStart(e.target.value)}
              ></input>
            </div>

            <div className="tenant-profile-form">
              <p className="text-md bold">
                Expected Terms of Lease (months){' '}
                <span style={{ color: 'var(--c-white-3)' }}>(optional)</span>
              </p>
              <div className="type-option_item-container">
                <div
                  className={`${
                    termsMonth === 3
                      ? 'type-option_item option-selected'
                      : 'type-option_item'
                  }`}
                  onClick={() => setTermsMonth(3)}
                >
                  <p>3</p>
                </div>

                <div
                  className={`${
                    termsMonth === 6
                      ? 'type-option_item option-selected'
                      : 'type-option_item'
                  }`}
                  onClick={() => setTermsMonth(6)}
                >
                  <p>6</p>
                </div>

                <div
                  className={`${
                    termsMonth === 9
                      ? 'type-option_item option-selected'
                      : 'type-option_item'
                  }`}
                  onClick={() => setTermsMonth(9)}
                >
                  <p>9</p>
                </div>

                <div
                  className={`${
                    termsMonth === 12
                      ? 'type-option_item option-selected'
                      : 'type-option_item'
                  }`}
                  onClick={() => setTermsMonth(12)}
                >
                  <p>12</p>
                </div>

                <div
                  className={`${
                    termsMonth === 18
                      ? 'type-option_item option-selected'
                      : 'type-option_item'
                  }`}
                  onClick={() => setTermsMonth(18)}
                >
                  <p>18</p>
                </div>

                <div
                  className={`${
                    termsMonth === 24
                      ? 'type-option_item option-selected'
                      : 'type-option_item'
                  }`}
                  onClick={() => setTermsMonth(24)}
                >
                  <p>24</p>
                </div>

                <div
                  className={`${
                    termsMonth === 36
                      ? 'type-option_item option-selected'
                      : 'type-option_item'
                  }`}
                  onClick={() => setTermsMonth(36)}
                >
                  <p>36</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="schedule-button">
          <div
            className={`${
              isFilled && paxNumber
                ? 'button__style-3 button__style-3--active'
                : 'button__style-3'
            }`}
            onClick={requestViewingHandler}
          >
            <p className="text-md" style={{ textAlign: 'center' }}>
              Request Viewing
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default ScheduleViewing
