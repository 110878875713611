import React from 'react'
import '../../../assets/css/components/LeasePage/Tenant/leaseMonthlyChart.css'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
	ChartArea
} from 'chart.js';
import { Bar, Chart } from 'react-chartjs-2';
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function LeaseMonthlyChart() {
	const labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

	const data = {
		labels,
		datasets: [
			{
				label: 'Dataset 1',
				data: [10,5,3,6,2,5,7,1,2,4,5,3],
				barPercentage: .5,
				borderRadius: 20,
				backgroundColor: '#FFC700',
			},
		],
	};

	const options = {
		responsive: true,
		plugins: {
			legend: {
				position: 'top',
				display: false,
				labels: {
					font: {
						size: 5
					}
				}
			},
			datalabels: {
				display: false
			},
			title: {
				display: false,
				text: 'Chart.js Bar Chart',
			},
		},
	};

	return (
		<div className='monthlychart'>
			<p className='monthlychart__title'>Payment Breakdown (to date)</p>

			<div className="monthlychart__overview">
				<div className="overview__wrapper">
					<span className='overview__title'>FEB 2022</span>

					<div className="overview__detail">
						<div className="detail__row">
							<p>Security Deposit</p>
							<p><span>SGD</span> $2,600</p>
						</div>

						<div className="detail__row">
							<p>Advance Rental</p>
							<p><span>SGD</span> $2,600</p>
						</div>

						<div className="detail__row">
							<p>Total Amount Paid</p>
							<p><span>SGD</span> $5,2000000</p>
						</div>
					</div>
				</div>
			</div>

			<div className="monthlychart__container">
				<Bar options={options} data={data} />
			</div>
		</div>
	)
}

export default LeaseMonthlyChart