import React, { useState } from 'react'
import { ReactComponent as ChevronDown } from '../../../assets/icon/general/Vector-14-chevron-down.svg'
import { ReactComponent as ChevronUp } from '../../../assets/icon/general/Vector-13-chevron-up.svg'

const AmenitiesItem = ({ icon, title, content }) => {
  const [isActive, setIsActive] = useState(false)

  console.log(content);
  return (
    <div
      class={isActive ? 'amenities__list__active' : 'amenities__list'}
      onClick={() => setIsActive(!isActive)}
    >
      <div
        class={
          isActive ? 'amenities__list-title__active' : 'amenities__list-title'
        }
      >
        {icon}
        <p class="text-lg">{title}</p>
        <div class="amenities__list-chevron">
          {isActive ? <ChevronUp /> : <ChevronDown />}
        </div>
      </div>
      {isActive && <div class="amenities__list-point">{content}</div>}
    </div>
  )
}

export default AmenitiesItem
