import React from 'react'
import { NavLink } from 'react-router-dom'
import { ReactComponent as BrowseLogo } from '../../assets/icon/navigation/Vector.svg'
import { ReactComponent as LeaseLogo } from '../../assets/icon/navigation/Vector-1.svg'
import { ReactComponent as ChatLogo } from '../../assets/icon/navigation/Vector-3.svg'
import { ReactComponent as AccountLogo } from '../../assets/icon/navigation/Vector-2.svg'
import '../../assets/css/components/ui/menuNavigation.css'

function MenuNavigation() {
  return (
    <footer className="footer">
      <nav className="menu">
        <NavLink to="/browse" className="menu__link">
          <div className="menu__item">
            <BrowseLogo className="menu__item-svg" />
            <p>Browse</p>
          </div>
        </NavLink>

        <NavLink to="/lease" className="menu__link">
          <div className="menu__item">
            <LeaseLogo className="menu__item-svg" />
            <p>Lease</p>
          </div>
        </NavLink>

        <NavLink to="/chat" className="menu__link">
          <div className="menu__item">
            <ChatLogo className="menu__item-svg" />
            <p>Chat</p>
          </div>
        </NavLink>

        <NavLink to="/account" className="menu__link">
          <div className="menu__item">
            <AccountLogo className="menu__item-svg" />
            <p>Account</p>
          </div>
        </NavLink>
      </nav>
    </footer>
  )
}

export default MenuNavigation
