import React from 'react'
import '../../../assets/css/components/LeasePage/Tenant/leaseBreakdownChart.css'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
ChartJS.register(ArcElement, Tooltip, Legend);

function LeaseBreakdownChart() {
	const data = {
		// labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
		datasets: [
			{
				label: 'Payment Breakdown',
				data: [20, 3, 3, 2, 1],
				backgroundColor: [
					'#FFC700',
					'#FFFFFF',
					'#9D70FF',
					'#1FA2FF',
					'#0BE5CB',
				],
				borderWidth: 0,
			},
		],
	};

	var options = {        
    // cutout: 100,
		plugins: {
			legend: {
				display: false
			}
		},
		responsive: true,
		maintainAspectRatio: true
};

	return (
		<div className='breakdownchart'>
			<p className='breakdownchart__title'>Payment Breakdown (to date)</p>

			<div className="breakdownchart__container">
				<div className="chart">
					<Doughnut data={data} options={options} />
				</div>

				<div className="chart__detail">
					<div className="detail__label">
						<div className="label label-stat-1"></div>

						<div className="label__info">
							<span className='info__title info__title-stat-1'>MONTHLY RENT</span>
							<p className='info__money'><span>SGD</span>$28,600</p>
						</div>
					</div>
					<div className="detail__label">
						<div className="label label-stat-2"></div>

						<div className="label__info">
							<span className='info__title info__title-stat-2'>SECURITY DEPOSIT</span>
							<p className='info__money'><span>SGD</span>$2,600</p>
						</div>
					</div>
					<div className="detail__label">
						<div className="label label-stat-3"></div>

						<div className="label__info">
							<span className='info__title info__title-stat-3'>ADVANCE RENTAL</span>
							<p className='info__money'><span>SGD</span>$2,600</p>
						</div>
					</div>
					<div className="detail__label">
						<div className="label label-stat-4"></div>

						<div className="label__info">
							<span className='info__title info__title-stat-4'>REPAIRS</span>
							<p className='info__money'><span>SGD</span>$1,295</p>
						</div>
					</div>
					<div className="detail__label">
						<div className="label label-stat-5"></div>

						<div className="label__info">
							<span className='info__title info__title-stat-5'>SERVICING</span>
							<p className='info__money'><span>SGD</span>$505</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default LeaseBreakdownChart