import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { ReactComponent as BackIcon } from '../../assets/icon/general/Vector-1-back.svg'
import OnboardingOutlet1 from '../../components/FirstTimePage/OnboardingOutlet1'
import OnboardingOutlet2 from '../../components/FirstTimePage/OnboardingOutlet2'
import OnboardingOutlet3 from '../../components/FirstTimePage/OnboardingOutlet3'
import '../../assets/css/pages/FirstTimePage/onboarding.css'

function Onboarding() {
  const [outletActive, setOutletActive] = useState(0)
  const tabsTitle = ['Enter Details', 'Submit Docs', 'Review']
  const [onBoard1, setOnBoard1] = useState([])
  const [onBoard2, setOnBoard2] = useState([])

  const directEditOB1 = (data) => {
    setOnBoard1(data)
  }
  const directEditOB2 = (data) => {
    setOnBoard2(data)
  }

  const directEditPersonal = (outletNumber) => {
    setOutletActive(outletNumber)
  }

  return (
    <section className="onboarding">
      <div className="onboarding__nav">
        <div className="onboarding__head">
          <div className="signup__back">
            <NavLink to="/account">
              <BackIcon />
            </NavLink>
          </div>

          <h1>Profile</h1>
        </div>

        <div className="onboarding__tabs">
          {tabsTitle.map((tab, index) => (
            <button
              disabled={index == 2 && (onBoard1.length <= 0 || onBoard2.length <= 0)}
              className={`button__tab ${outletActive === index ? 'button__tab--active' : ''
                } ${outletActive > index ? 'button__tab--done' : ''}`}
              onClick={() => setOutletActive(index)}
            >
              <p>{tab}</p>
              <div className="button__tab-line"></div>
            </button>
          ))}
        </div>
      </div>

      <div className="onboarding__outlet-container">
        {outletActive === 0 && (
          <OnboardingOutlet1
            directEditPersonal={directEditPersonal}
            directEditOB1={directEditOB1}
            onBoard1Data={onBoard1}
          />
        )}
        {outletActive === 1 && <OnboardingOutlet2 directEditPersonal={directEditPersonal} directEditOB2={directEditOB2} />}
        {outletActive === 2 && (
          <OnboardingOutlet3 directEditPersonal={directEditPersonal} onBoard1={onBoard1} onBoard2={onBoard2} />
        )}
      </div>
    </section>
  )
}

export default Onboarding
