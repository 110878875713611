import Review from '../../components/reviewTA/Review'
import ReviewTAHEad from '../../components/reviewTA/ReviewTAHead'

const ReviewTA = () => {
  return (
    <div className="review-ta__container">
      <ReviewTAHEad />
      <Review />
    </div>
  )
}

export default ReviewTA
