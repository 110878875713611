import FloatButton from '../../components/offer/FloatButton'
import FormDummy from '../../assets/images/image 12.png'
import { useNavigate } from 'react-router-dom'
import Modal from '../ui/Modal'
import { useEffect, useState } from 'react'
import tenantLeasePropService from '../../services/tenantLeasePropService'
import authService from '../../services/AuthService'
import LOI from '../documents/LOI/LOI'

const ReviewOffer = ({ setSnackbarMessage, setSnackbarStatus, formData }) => {
  const navigate = useNavigate()
  const [isShow, setIsShow] = useState(false)
  const [isShow2, setIsShow2] = useState(false)
  const [isSign, setIsSign] = useState(false)
  const [submitOfferType, setSubmitOfferType] = useState('offerSubmitCC')
  const [placeHolder, setPlaceHolder] = useState(false)
  const [isSubmittedSuccessfully, setIsSubmittedSuccessfully] = useState(false)

  const [signedPhoto, setSignedPhoto] = useState(null)

  // const modalHandler = () => {
  // 	setIsShow(true)
  // }

  const signOfferHandler = () => {
    setIsSign(true)
    setIsShow(false)
  }

  const signProceedHandler = () => {
    setIsShow(true)
  }

  const submitOfferHandler = async (type) => {
    const handleSubmit = await handleCreateOfferSubmission();
    if(!handleSubmit) return;
    setIsShow2(true)
    setSubmitOfferType(type)
    setIsSubmittedSuccessfully(true)
  }

  useEffect(() => {
    console.log("Form Data: ", formData);
  }, [])

  const handleCreateOfferSubmission = async () => {
    try {
      if (!signedPhoto) {
        setSnackbarMessage("Please sign the document")
        setSnackbarStatus(true)
        return false
      }
  
      if(!formData) {
        setSnackbarMessage("Please fill the required form")
        setSnackbarStatus(true)
        return false
      }

      const userProfileData = authService.getLoggedUserData();

      const toBeSavedData = {
        user_profile_tenant_id: userProfileData?.user_profile_id, // TODO: Update fill user profile data
        user_profile_landlord_id: formData?.user_profile_landlord_id,
        property_id: formData.property_id,
        number_additional_occupier: parseInt(formData?.number_of_additional_occupier),
        start_date_of_lease: formData?.start_date_of_lease,
        monthly_rental: formData?.monthly_rental_offer,
        security_deposit: formData?.monthly_rental_offer, // TODO: determine should this be set here or after the user sign TA
        sign_photo_base64: signedPhoto,
        offer_status: 1,
        deposit_status: 1
      }

      console.log("To be saved data", toBeSavedData);
  
      const createResponse = await tenantLeasePropService.createOne(toBeSavedData)
      console.log("createResponse", createResponse);

      if(createResponse.data !== null) {
        return true
      }

      return false;

    } catch (error) {
      console.error(error);
      return false
    }
  }

  return (
    <>

      {isShow && (
        <Modal
          show={isShow}
          type="signOffer"
          signOffer={() => signOfferHandler()}
          hide={() => setIsShow(false)}
          setSignedPhoto={setSignedPhoto}
        />
      )}
      {isShow2 && (
        <Modal
          show={isShow2}
          propertyId={formData?.property_id}
          type={submitOfferType}
          signOffer={() => signOfferHandler()}
          hide={() => setIsShow2(false)}
        />
      )}
      <div className="offer-body offer-body-ro">
        <div className="offer-body__section-container">
          <div className="offer-body__form-container">
            <div className="offer-body__head">
              <h1 className="offer-body__title">Review Offer Detail</h1>
              {isSign && <span>Signed</span>}
            </div>
            <div className="review-offer__form-container">
              <div
                className="iframe-overlay"
                onClick={() => navigate('/loi-preview')}
              ></div>

              {placeHolder ? (
                <img
                  alt="Dummy Form"
                  className="review-offer__item"
                  src={FormDummy}
                />
              ) : (
                <iframe
                  src="/iframe-loi"
                  width={'100%'}
                  height={'100%'}
                  frameborder="0"
                ></iframe>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* <FloatButton text={'Proceed to Sign'} /> */}

      <div className="search-apply">
        <button
          className="search-apply__btn apply-btn"
          onClick={() =>
            !isSign
              ? signProceedHandler()
              : submitOfferHandler('offerSubmitOther')
          }
        >
          <span>{!signedPhoto ? 'Proceed to Sign' : 'Submit Offer'}</span>
        </button>
      </div>
    </>
  )
}

export default ReviewOffer
