import React, { useState, useEffect } from 'react'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { ReactComponent as BackIcon } from '../../assets/icon/general/Vector-1-back.svg'
import { ReactComponent as Eye } from '../../assets/icon/general/Vector-30-eye.svg'
import { ReactComponent as Info } from '../../assets/icon/general/Vector-18-info.svg'

import { ReactComponent as GoogleIcon } from '../../assets/logo/Google.svg'
import { ReactComponent as AppleIcon } from '../../assets/logo/Apple.svg'
import { ReactComponent as SingpassIcon } from '../../assets/logo/Singpass.svg'
import BackButton from '../../components/ui/BackButton'

import SignUpIllus from '../../components/FirstTimePage/SignUpIllus'
import SnackBar from '../../components/ui/SnackBar'

import '../../assets/css/pages/FirstTimePage/signUp.css'
import { useForm } from 'react-hook-form'
import axios from 'axios'
import { apiBaseURL } from '../../utils'
import authService from '../../services/AuthService'

function SignUp() {
  const { userType } = useParams()
  const navigate = useNavigate();
  const [type, setType] = useState('password')
  const [typeConfirm, setTypeConfirm] = useState('password')
  const [statusSignUp, setStatusSignUp] = useState(false)

  const [errorMessage, setErrorMessage] = useState("")

  useEffect(() => {
    if (authService.isAuthenticated()) {
      navigate('/browse')
    }
  }, [])
  

  const togglePassword = () => {
    if (type === 'password') {
      setType('text')
      return
    }
    setType('password')
  }

  const togglePasswordConfirm = () => {
    if (typeConfirm === 'password') {
      setTypeConfirm('text')
      return
    }
    setTypeConfirm('password')
  }

  const [password, setPassword] = useState('')
  const [passwordConfirmed, setpasswordConfirmed] = useState(false)
  const [lengthValidated, setLengthValidated] = useState(false)
  const [numberSpecialValidated, setNumberSpecialValidated] = useState(false)
  const [upperValidated, setUpperValidated] = useState(false)
  const [lowerValidated, setLowerValidated] = useState(false)

  // Form Hook
  const { register, handleSubmit, watch, formState: { errors } } = useForm();

  const handleChange = (value) => {
    const length = RegExp('(?=.{8,})')
    const number = RegExp('(?=.*[0-9])')
    const special = RegExp('(?=.*[!@#$%^&*])')
    const upper = RegExp('(?=.*[A-Z])')
    const lower = RegExp('(?=.*[a-z])')
    if (length.test(value)) {
      setLengthValidated(true)
    } else {
      setLengthValidated(false)
    }

    if (number.test(value) || special.test(value)) {
      setNumberSpecialValidated(true)
    } else {
      setNumberSpecialValidated(false)
    }

    if (upper.test(value)) {
      setUpperValidated(true)
    } else {
      setUpperValidated(false)
    }

    if (lower.test(value)) {
      setLowerValidated(true)
    } else {
      setLowerValidated(false)
    }
    setPassword(value)
  }

  const handlePasswordConfirm = (value) => {
    if (value === password) {
      setpasswordConfirmed(true)
    } else {
      setpasswordConfirmed(false)
    }
  }

  const _handleSubmit = async (reqData) => {

    // Check sign up
    if (!passwordConfirmed) {
      setErrorMessage("Password does not match")
      setStatusSignUp(true)
      return;
    };

    if (!lengthValidated) {
      setErrorMessage("Password must be 8 characters long")
      setStatusSignUp(true)
      return;
    };

    if (!numberSpecialValidated) {
      setErrorMessage("Password must contains at numbers and symbols")
      setStatusSignUp(true)
      return;
    };

    if (!upperValidated) {
      setErrorMessage("Password must contains upper case characters")
      setStatusSignUp(true)
      return;
    };

    if (!lowerValidated) {
      setErrorMessage("Password must contains lower case character")
      setStatusSignUp(true)
      return;
    };

    try {
      const {data, message} = await authService.register(reqData, userType);

      if (data === null) alert(message)
      if (data !== null) {
        alert("Registered! please login")
        navigate(`/login/${userType}`)
      }
    } catch (error) {
      console.log(error);
      alert(error.response.data.message)
    }
  }

  useEffect(() => {
    let timeout
    if (statusSignUp) {
      timeout = setTimeout(() => setStatusSignUp(false), 2000);
    }
    return () => clearTimeout(timeout);
  }, [statusSignUp]);

  return (
    <section className="signup">
      <SnackBar status={statusSignUp} message={errorMessage} />

      {/* <div className="signup__back">
        <NavLink to="/getstarted">
          <BackIcon />
        </NavLink>
      </div> */}
      <BackButton directURL="/getstarted" />

      <div className="signup__head">
        <div className="signup__head-title">
          {/* <h1 onClick={() => setStatusSignUp(!statusSignUp)}>Create your account</h1> */}
          <h1>Create your account</h1>
          {userType !== 'user4' && <p>and unlock these features</p>}
        </div>

        <SignUpIllus userType={userType} />
      </div>

      <div className="signup__form-wrapper">
        {/* FORM */}
        <form className="signup__form" onSubmit={handleSubmit(_handleSubmit)}>
          <div className="form-input">
            <label>
              FULL NAME
              <input
                className="input__text"
                type="text"
                placeholder="Type here..."
                {...register("name", { required: true })}
              ></input>
            </label>
            {errors.name && <p>This field is required</p>}
          </div>

          <div className="form-input">
            <label>
              MOBILE NUMBER
              <div className="form-input__number">
                <select>
                  <option value="+62">+62</option>
                  <option value="+66">+66</option>
                </select>
                <input
                  className="input__text"
                  type="text"
                  placeholder="Type here..."
                  {...register("mobile_number", { required: true })}
                ></input>
              </div>
            </label>
            {errors.mobile_number && <p>This field is required</p>}
          </div>

          <div className="form-input">
            <label>
              EMAIL ADDRESS
              <input
                className="input__text"
                type="text"
                placeholder="Type here..."
                {...register("email", { required: true })}
              ></input>
            </label>
            {errors.email && <p>This field is required</p>}
          </div>

          <div className="form-input">
            <label>
              PASSWORD
              <div class="input__password-box">
                <input
                  className="input__text"
                  type={type}
                  placeholder="Type here..."
                  {...register("password", { required: true, onChange: (e) => handleChange(e.target.value) })}
                ></input>
                <div class="input__eye-icon" onClick={togglePassword}>
                  <Eye />
                </div>
              </div>
            </label>
            {errors.password && <p>This field is required</p>}
          </div>

          <div className="form-input">
            <label>
              CONFIRM PASSWORD
              <div class="input__password-box">
                <input
                  className="input__text"
                  type={typeConfirm}
                  placeholder="Type here..."
                  {...register("password_confirm", { required: true, onChange: (e) => handlePasswordConfirm(e.target.value) })}
                ></input>
                <div class="input__eye-icon" onClick={togglePasswordConfirm}>
                  <Eye />
                </div>
              </div>
            </label>
            {errors.password_confirm && <p>This field is required</p>}
          </div>

          <div className="requirement__wrapper">
            <div className="password__requirement">
              <p
                className={
                  passwordConfirmed ? 'text-sm validated' : 'text-sm not-validated'
                }
              >
                Password Match
              </p>
            </div>
            <div className="password__requirement">
              <p
                className={
                  lengthValidated ? 'text-sm validated' : 'text-sm not-validated'
                }
              >
                At least 8 characters long
              </p>
            </div>
            <div className="password__requirement">
              <p
                className={
                  numberSpecialValidated
                    ? 'text-sm validated'
                    : 'text-sm not-validated'
                }
              >
                Contains a number or symbol
              </p>
            </div>
            <div className="password__requirement">
              <p
                className={
                  upperValidated ? 'text-sm validated' : 'text-sm not-validated'
                }
              >
                Contains an upper case character
              </p>
            </div>
            <div className="password__requirement">
              <p
                className={
                  lowerValidated ? 'text-sm validated' : 'text-sm not-validated'
                }
              >
                Contains a lower case character
              </p>
            </div>
          </div>

          <input
            className="button__style-2 button__style-2--active"
            type="submit"
            value="Continue"
          />
        </form>
      </div>

      <div className="signup__alternative">
        <p>or log in with</p>
        <div className="signup__alternative-options">
          <button>
            <GoogleIcon />
          </button>
          <button>
            <AppleIcon />
          </button>
          {/* <button>
            <SingpassIcon />
          </button> */}
        </div>
      </div>

      <div className="signup__policy">
        <p>
          By continuing, you acknowledge and agree to P.Lease’s{' '}
          <a href="#">Terms of Service</a> and <a href="#">Privacy Policy</a>.
        </p>
        <p>
          Already have an account?{' '}
          <NavLink to={`${'/login/' + userType}`}>Log in</NavLink>
        </p>
      </div>

      <NavLink className="signup__skip" to="/browse">
        Skip for now
      </NavLink>
    </section>
  )
}

export default SignUp
