import React, { useState } from 'react'
import HeadBack from '../../../components/ui/HeadBack'
import '../../../assets/css/pages/LeasePage/Tenant/addRequest.css'
import { ReactComponent as CloseIcon } from '../../../assets/icon/general/Vector-9-close.svg'
import { ReactComponent as CameraIcon } from '../../../assets/icon/general/Vector-57-camera.svg'
import { ReactComponent as Upload } from '../../../assets/icon/general/Vector-71-upload.svg'

function AddRequest() {
  const [files, setFiles] = useState([])
  const [documentFile, setDocumentFile] = useState([])
  const [isFilled, setIsFilled] = useState()

	const handleUpload = (event) => {
    const selectedFiles = event.target.files
    setFiles([...files, ...selectedFiles])
  }

  const handleUploadDocs = (event) => {
    const selectedFiles = event.target.files
    setDocumentFile([...documentFile, ...selectedFiles])
  }

  const handleRemove = (index) => {
    const newFiles = [...files]
    newFiles.splice(index, 1)
    setFiles(newFiles)
  }

	return (
		<div className='addrequest'>
			<HeadBack backLink="/lease/tenant" title="Add Request" />
		
			<div className='addrequest__body'>
				<form className='req__form'>
					<div className='req__field'>
						<p>Topic</p>

						<div className='req__field-category'>
							<button>Defects</button>
							<button>Repairs</button>
							<button>Servicing</button>
							<button>Contract</button>
							<button>Viewing</button>
						</div>
					</div>

					<div className="form-input form-input__select addrequest-input">
            <label>
              Type

							<select name="cars" id="cars">
								<option value="volvo">Volvo</option>
								<option value="saab">Saab</option>
								<option value="mercedes">Mercedes</option>
								<option value="audi">Audi</option>
							</select>
            </label>
          </div>

          <div className="form-input addrequest-input">
            <label>
              Title
              <input
                className="input__text"
                type="text"
                placeholder="Type here..."
              ></input>
            </label>
          </div>

					<div className="form-input form-input__textarea addrequest-input">
            <label>
              Message
							<textarea id="w3review" name="w3review" rows="4" cols="50" placeholder='Type here...' />
            </label>
          </div>


					<div className="details-form-container details-form-container--addrequest">
						<div className="onboarding__outlet-2-identity">
							<div className="onboarding__outlet-2-head">
								<h1>Supporting Document(s)</h1>
								<p className="p1">You may upload multiple files. Max size per file is 10 MB. Supported file types: .jpg, .png, .pdf</p>
							</div>

							<div className="onboarding__outlet-2-photo">
								<div className="onboarding__outlet-2-file">
									<input
										type="file"
										id="identity"
										accept="image/*"
										multiple
										onChange={handleUpload}
									/>
									<label htmlFor="identity">
										<CameraIcon /> <span>Take a photo</span>
									</label>
								</div>

								<div className="docs-type-separator">
									<p className="text-lg bold">or</p>
								</div>

								<div className="onboarding__outlet-2-file">
									<input
										type="file"
										id="identity"
										accept="application/pdf"
										multiple
										onChange={handleUploadDocs}
									/>
									<label htmlFor="identity">
										<Upload /> <span>Upload File</span>
									</label>
								</div>

								<div
									className={`onboarding__outlet-2-file-preview ${
										files.length === 0 &&
										'onboarding__outlet-2-file-preview--hide'
									}`}
								>
									{files.map((file, index) => (
										<div className="image__preview" key={index}>
											<div
												className="image__preview-delete"
												onClick={() => handleRemove(index)}
											>
												<CloseIcon />
											</div>
											<img src={URL.createObjectURL(file)} alt="file" />
										</div>
									))}
								</div>
							</div>
						</div>
        	</div>

					<div className="form-input form-input__radio">
						<p>Action Required</p>

						<div className='radio__wrapper'>
							<label className='label__input'>
								<input
									className="input__radio"
									type="radio"
									name="fav_language" 
									value="Yes"
								></input>
								<span className='label__text'>Yes</span>
								<span className="checkmark"></span>
							</label>

							<label className='label__input'>
								<input
									className="input__radio"
									type="radio"
									name="fav_language" 
									value="No"
								></input>
								<span className='label__text'>No</span>
								<span className="checkmark"></span>
							</label>
						</div>
          </div>

					<div className="form-input form-input form-input__radio-date">
						<p>Add to Schedule</p>

						<div className='radio__wrapper'>
							<label className='label__input'>
								<input
									className="input__radio"
									type="radio"
									name="fav_language" 
									value="Yes"
								></input>
								<span className='label__text'>Yes</span>
								<span className="checkmark"></span>
							</label>

							<div>
								<input
									type="date"
									id="birthday"
									name="birthday"
									className="date-picker-container"
								></input>
							</div>

							<label className='label__input'>
								<input
									className="input__radio"
									type="radio"
									name="fav_language" 
									value="Yes"
								></input>
								<span className='label__text'>No</span>
								<span className="checkmark"></span>
							</label>
						</div>
          </div>

          <div className="onboarding__button">
            <input
              className={`${
                isFilled
                  ? 'button__style-3 button__style-3--active'
                  : 'button__style-3'
              }`}
              type="submit"
              value="Continue"
            />
          </div>
				</form>
			</div>
		</div>
	)
}

export default AddRequest