import { useForm } from "react-hook-form";
import { identificationTypeOptions } from "../../../services/valuesService";
import { useEffect } from "react";

const LOIform = ({ userData, register, propertyData }) => {

  return userData ? (
    <div className="offer-body__section-container">
      <div className="offer-body__form-container">
        <h1 className="offer-body__title">
          Letter of Intent (Residential) Form
        </h1>
        <div className="offer-body__form">
          <label htmlFor="name" className="offer-body__input-label">
            Name of Tenant
          </label>
          <input
            {...register('name', { value: userData.name })}
            className="offer-body__form-input"
            type="text"
            id="name"
            placeholder="Type here..."
            disabled="true"
          />
        </div>
        <div className="offer-body__form">
          <label htmlFor="citizenship" className="offer-body__input-label">
            Citizenship
          </label>
          <input
            {...register('citizenship', { value: userData.citizenship })}
            className="offer-body__form-input"
            type="text"
            id="citizenship"
            placeholder="Type here..."
          />
        </div>
        <div className="offer-body__form">
          <label htmlFor="idType" className="offer-body__input-label">
            Identification Type
          </label>
          <input
            {...register('identification_type', { value: identificationTypeOptions.filter((option) => option.id === userData.identification_type)[0].label })}
            className="offer-body__form-input"
            type="text"
            id="idType"
            placeholder="Type here..."
            disabled="true"
          />
        </div>
        <div className="offer-body__form">
          <label htmlFor="idNumber" className="offer-body__input-label">
            Identification Number
          </label>
          <input
            {...register('identification_number', { value: userData.identification_number })}
            className="offer-body__form-input"
            type="text"
            id="idNumber"
            placeholder="Type here..."
            disabled="true"
          />
        </div>
        <div className="offer-body__form">
          <label htmlFor="address" className="offer-body__input-label">
            Current Address
          </label>
          <input
            {...register('address', { value: userData?.address })}
            className="offer-body__form-input"
            type="text"
            id="address"
            placeholder="Type here..."
          />
        </div>
        <div className="offer-body__form">
          <h1 className="offer-body__input-label">
            Number of Additional Occupier(s)
          </h1>
          <div className="offer-body__flex-row-container">
            <input
              {...register('number_of_additional_occupier')}
              className="offer-body__radio-btn"
              type="radio"
              id="0"
              placeholder="Type here..."
              value={0}
            />
            <label className="offer-radio__label" htmlFor="0">
              0
            </label>
            <input
              {...register('number_of_additional_occupier')}
              className="offer-body__radio-btn"
              type="radio"
              id="1"
              value={1}
            />
            <label className="offer-radio__label" htmlFor="1">
              1
            </label>
            <input
              {...register('number_of_additional_occupier')}
              className="offer-body__radio-btn"
              type="radio"
              id="2"
              value={2}
            />
            <label className="offer-radio__label" htmlFor="2">
              2
            </label>
            <input
              {...register('number_of_additional_occupier')}
              className="offer-body__radio-btn"
              type="radio"
              id="3"
              value={3}
            />
            <label className="offer-radio__label" htmlFor="3">
              3
            </label>
            <input
              {...register('number_of_additional_occupier')}
              className="offer-body__radio-btn"
              type="radio"
              id="4"
              value={4}
            />
            <label className="offer-radio__label" htmlFor="4">
              4
            </label>
            <input
              {...register('number_of_additional_occupier')}
              className="offer-body__radio-btn"
              type="radio"
              id="5"
              value={5}
            />
            <label className="offer-radio__label" htmlFor="5">
              5
            </label>
          </div>
        </div>
        <div className="offer-body__form">
          <label className="offer-body__input-label" htmlFor="date">
            Start Date of Lease
          </label>
          <input
            {...register('start_date_of_lease')}
            type="date" className="offer-body__form-input" />
        </div>
        <div className="offer-body__form">
          <label className="offer-body__input-label" htmlFor="date">
            Monthly Rental (nett SGD)
          </label>
          <input
            {...register('monthly_rental_offer', { value: propertyData?.monthly_rental })}
            type="text"
            placeholder="Type here..."
            className="offer-body__form-input"
          />
        </div>
      </div>
    </div>
  ) : <>Loading</>
}

export default LOIform
