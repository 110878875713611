import React from 'react'
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide'
import { ReactComponent as PleaseLogo1 } from '../../assets/logo/Frame 1.svg'
import { ReactComponent as PleaseLogo2 } from '../../assets/logo/Logo-c 2.svg'
import { ReactComponent as SplashIllus1 } from '../../assets/illustration/splash/Group 1.svg'
import { ReactComponent as SplashIllus2 } from '../../assets/illustration/splash/Information carousel_Monochromatic 1.svg'
import { ReactComponent as SplashIllus3 } from '../../assets/illustration/splash/Profiling_Monochromatic 1.svg'
import '../../assets/css/pages/FirstTimePage/splash.css'

import Button from '../../components/ui/Button'
import { NavLink } from 'react-router-dom'

function Splash() {
  return (
    <section className="splash">
      <div className="splash__overlay">
        <PleaseLogo1 />
      </div>

      <div className="splash__logo">
        <PleaseLogo2 />
      </div>

      <div className="splash__slide">
        <Splide
          hasTrack={false}
          aria-label="..."
          options={{
            perPage: 1,
            arrows: false,
          }}
        >
          <SplideTrack>
            <SplideSlide>
              <div className="splash__slide-card">
                <div className="card__illus card__illus-1">
                  <div className="card__illus-wrapper">
                    <SplashIllus1 />
                  </div>
                </div>

                <div className="card__description">
                  <h1>End-to-end lease management</h1>
                  <p>
                    P.Lease is an all-in-one ecosystem to manage your leasing
                    requirements.
                  </p>
                </div>
              </div>
            </SplideSlide>

            <SplideSlide>
              <div className="splash__slide-card card__illus-2">
                <div className="card__illus">
                  <div className="card__illus-wrapper">
                    <SplashIllus2 />
                  </div>
                </div>

                <div className="card__description">
                  <h1>Agent-free, lower fees, automation for the win</h1>
                  <p>
                    Whether you are a landlord or tenant, P.Lease takes the
                    agent, and the fuss, out of the equation.
                  </p>
                </div>
              </div>
            </SplideSlide>

            <SplideSlide>
              <div className="splash__slide-card card__illus-3">
                <div className="card__illus">
                  <div className="card__illus-wrapper">
                    <SplashIllus3 />
                  </div>
                </div>

                <div className="card__description">
                  <h1>
                    Verified profiles means you transact risk-free with a smile
                  </h1>
                  <p>
                    All onboarded users are screened and verified while all
                    monies are held in escrow. Lease with a peace of mind with
                    P.Lease.
                  </p>
                </div>
              </div>
            </SplideSlide>
          </SplideTrack>
        </Splide>
      </div>

      <div className="splash__button">
        <Button buttonName="Get Started" allow={true} directURL="/getstarted" />
        <div className="signup__policy">
          <p>
            Already have an account?{' '}
            <NavLink to="/login/tenant">Log in</NavLink>
          </p>
        </div>
      </div>
    </section>
  )
}

export default Splash
