import React, { useState } from 'react'
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import '../../../assets/css/pages/LeasePage/Tenant/leaseTenant.css'
import LeaseTenantNewOutlet from '../../../components/LeasePage/Tenant/LeaseTenantNewOutlet'
import LeaseTenantNewOutlet1 from '../../../components/LeasePage/Tenant/LeaseTenantNewOutlet1'
import LeaseTenantNewOutlet2 from '../../../components/LeasePage/Tenant/LeaseTenantNewOutlet2'
import LeaseTenantNewOutlet3 from '../../../components/LeasePage/Tenant/LeaseTenantNewOutlet3'
import LeaseTenantCurrentOutlet from '../../../components/LeasePage/Tenant/LeaseTenantCurrentOutlet';
import LeaseTenantCurrentOutlet1 from '../../../components/LeasePage/Tenant/LeaseTenantCurrentOutlet1';
import LeaseTenantCurrentOutlet2 from '../../../components/LeasePage/Tenant/LeaseTenantCurrentOutlet2';
import LeaseTenantCurrentOutlet3 from '../../../components/LeasePage/Tenant/LeaseTenantCurrentOutlet3';
import { ReactComponent as BackIcon } from '../../../assets/icon/general/Vector-1-back.svg'


function LeaseTenant() {
	const [leaseType, setLeaseType] = useState(0)
	const [newLeaseType, setNewLeaseType] = useState(0)
	const [currentLeaseType, setCurrentLeaseType] = useState(0)

	const newLeaseTabHandler = () => {
		if(newLeaseType === 0) {
			return <LeaseTenantNewOutlet />
		} else if (newLeaseType === 1) {
			return <LeaseTenantNewOutlet1 />
		} else if (newLeaseType === 2) {
			return <LeaseTenantNewOutlet2 />
		} else {
			return <LeaseTenantNewOutlet3 />
		}
	}

	const currentLeaseTabHandler = () => {
		if(currentLeaseType === 0) {
			return <LeaseTenantCurrentOutlet />
		} else if (currentLeaseType === 1) {
			return <LeaseTenantCurrentOutlet1 />
		} else if (currentLeaseType === 2) {
			return <LeaseTenantCurrentOutlet2 />
		} else {
			return <LeaseTenantCurrentOutlet3 />
		}
	}

	return (
		<div className='lease__t'>
			<div className='lease__t-head'>
				<div className='head__main-tab'>
					<button className={leaseType === 0 ? 'button__main-tab button__main-tab--active' : 'button__main-tab'} onClick={() => setLeaseType(0)}>Current Lease</button>
					<button className={leaseType === 1 ? 'button__main-tab button__main-tab--active' : 'button__main-tab'} onClick={() => setLeaseType(1)}>New Lease</button>
				</div>

				<div className='head__sub-tab'>
					{leaseType === 0 && (
					<div className='head__sub-tab-outlet head__sub-tab-outlet--2'>
						<button className={currentLeaseType === 0 ? 'button__sub-tab button__sub-tab--active' : 'button__sub-tab'} onClick={() => setCurrentLeaseType(0)}>Lease Summary</button>
						<button className={currentLeaseType === 1 ? 'button__sub-tab button__sub-tab--active' : 'button__sub-tab'} onClick={() => setCurrentLeaseType(1)}>Lease Details</button>
						<button className={currentLeaseType === 2 ? 'button__sub-tab button__sub-tab--active' : 'button__sub-tab'} onClick={() => setCurrentLeaseType(2)}>Manage Schedule</button>
						<button className={currentLeaseType === 3 ? 'button__sub-tab button__sub-tab--active' : 'button__sub-tab'} onClick={() => setCurrentLeaseType(3)}>Manage Request</button>
					</div>)}

					{leaseType === 1 && (
					<div className='head__sub-tab-outlet head__sub-tab-outlet--1'>
						<Splide 
							hasTrack={false}
							options={{ 
								perPage: 2,
								pagination:false, 
								arrows: false
							}}
						>
							<SplideTrack>
								<SplideSlide>
									<button className={newLeaseType === 0 ? 'button__sub-tab button__sub-tab--active' : 'button__sub-tab'} onClick={() => setNewLeaseType(0)}>Shortlist</button>
								</SplideSlide>
								<SplideSlide>
									<button className={newLeaseType === 1 ? 'button__sub-tab button__sub-tab--active' : 'button__sub-tab'} onClick={() => setNewLeaseType(1)}>Scheduled Viewing</button>
								</SplideSlide>
								<SplideSlide>
									<button className={newLeaseType === 2 ? 'button__sub-tab button__sub-tab--active' : 'button__sub-tab'} onClick={() => setNewLeaseType(2)}>Offer</button>
								</SplideSlide>
								<SplideSlide>
									<button className={newLeaseType === 3 ? 'button__sub-tab button__sub-tab--active' : 'button__sub-tab'} onClick={() => setNewLeaseType(3)}>Tenancy Agreement</button>
								</SplideSlide>
							</SplideTrack>
						</Splide>
					</div>)}
				</div>
			</div>

			<div className={leaseType === 0 ? 'lease__t-body lease__t-body--current' : 'lease__t-body lease__t-body--new'}>
				{leaseType === 0 && (
					currentLeaseTabHandler()
				)}
				{leaseType === 1 && (
					newLeaseTabHandler()
				)}
			</div>
		</div>
	)
}

export default LeaseTenant