import { useState } from 'react'
import ManageRequestHead from '../../components/manageRequest/manageRequestHead'
import ManageRequestViewing from '../../components/manageRequest/manageRequestViewing'
import ManageRequestOffer from '../../components/manageRequest/manageRequestOffer'
import ModalViewing from '../../components/manageRequest/Modals/modalViewing'

const ManageRequest = () => {
  const [tab, setTab] = useState(1)
  const [view, setView] = useState(false)
  const state = (data) => {
    setTab(data)
  }
  return (
    <div className="modal-request__main">
      <ManageRequestHead tabSelect={state} />
      {tab === 1 ? <ManageRequestViewing /> : <ManageRequestOffer />}
    </div>
  )
}

export default ManageRequest
