import React, { useState, useEffect } from 'react'
import { ReactComponent as Info } from '../../assets/icon/general/Vector-18-info.svg'
import { ReactComponent as Eye } from '../../assets/icon/general/Vector-30-eye.svg'
import '../../assets/css/pages/AccountPage/password.css'
import HeadBack from '../../components/ui/HeadBack'
import { NavLink } from 'react-router-dom'
import ModalSystemNotif from '../../components/ui/ModalSystemNotif'
import PopUpSystemNotif from '../../components/ui/PopUpSystemNotif'

function Password() {
  const [type, setType] = useState('password')
  const [isShow, setIsShow] = useState(true)
  const [isShow2, setIsShow2] = useState(true)

  useEffect(() => {
    let timeout
    if (isShow2) {
      timeout = setTimeout(() => setIsShow2(false), 3000);
    }
    return () => clearTimeout(timeout);
  }, [isShow2]);

  const togglePassword = () => {
    if (type === 'password') {
      setType('text')
      return
    }
    setType('password')
  }

  const [lengthValidated, setLengthValidated] = useState(false)
  const [numberSpecialValidated, setNumberSpecialValidated] = useState(false)
  const [upperValidated, setUpperValidated] = useState(false)
  const [lowerValidated, setLowerValidated] = useState(false)

  const handleChange = (value) => {
    const length = RegExp('(?=.{8,})')
    const number = RegExp('(?=.*[0-9])')
    const special = RegExp('(?=.*[!@#$%^&*])')
    const upper = RegExp('(?=.*[A-Z])')
    const lower = RegExp('(?=.*[a-z])')
    if (length.test(value)) {
      setLengthValidated(true)
    } else {
      setLengthValidated(false)
    }

    if (number.test(value) || special.test(value)) {
      setNumberSpecialValidated(true)
    } else {
      setNumberSpecialValidated(false)
    }

    if (upper.test(value)) {
      setUpperValidated(true)
    } else {
      setUpperValidated(false)
    }

    if (lower.test(value)) {
      setLowerValidated(true)
    } else {
      setLowerValidated(false)
    }
  }

  return (
    <section class="password__main">
      {/* modal system notification / error messages - detailed */}
      {isShow && (<ModalSystemNotif hide={() => setIsShow(false)} />)}
      {/* modal system notification / error messages - simple */}
      <PopUpSystemNotif active={isShow2} />

      <HeadBack title="Password / 2FA" backLink="/account" />
      <div class="password__body">
        <form class="password__body-password">
          <p class="text-lg">Password</p>
          <div className="form-input">
            <label>
              CREATE PASSWORD
              <div class="input__password-box">
                <input
                  className="input__text"
                  type={type}
                  placeholder="Type here..."
                  onChange={(e) => handleChange(e.target.value)}
                ></input>
                <div class="input__eye-icon" onClick={togglePassword}>
                  <Eye />
                </div>
              </div>
            </label>
            <div class="password__requirement">
              <p
                class={
                  lengthValidated
                    ? 'text-sm validated'
                    : 'text-sm not-validated'
                }
              >
                At least 8 characters long
              </p>
            </div>
            <div class="password__requirement">
              <p
                class={
                  numberSpecialValidated
                    ? 'text-sm validated'
                    : 'text-sm not-validated'
                }
              >
                Contains a number or symbol
              </p>
            </div>
            <div class="password__requirement">
              <p
                class={
                  upperValidated ? 'text-sm validated' : 'text-sm not-validated'
                }
              >
                Contains an upper case character
              </p>
            </div>
            <div class="password__requirement">
              <p
                class={
                  lowerValidated ? 'text-sm validated' : 'text-sm not-validated'
                }
              >
                Contains a lower case character
              </p>
            </div>
          </div>
        </form>
        <div class="password__body-auth">
          <p class="text-lg">Authentication</p>
          <div class="password__body-auth-opt">
            <div class="auth-opt-subtitle">
              <p class="text-md">Two-Factor (2FA)</p>
              <div class="auth-opt-switch">
                <input
                  type="radio"
                  id="radio-SMS"
                  name="switch-one"
                  value="SMS"
                />
                <label for="radio-SMS">SMS</label>
                <input
                  type="radio"
                  id="radio-Email"
                  name="switch-one"
                  value="Email"
                />
                <label for="radio-Email">Email</label>
              </div>
            </div>
            <div class="password__body-auth-container">
              <div class="password__body-auth-info">
                <Info />
                <p>
                  Receive a unique authentication code via your chosen method.
                </p>
              </div>
            </div>
          </div>
          <div class="password__body-auth-opt">
            <div class="auth-opt-subtitle">
              <p class="text-md">Biometric</p>
              <div class="auth-opt-switch">
                <input
                  type="radio"
                  id="radio-Disabled"
                  name="switch-two"
                  value="Disabled"
                  checked
                />
                <label for="radio-Disabled">Disabled</label>
                <input
                  type="radio"
                  id="radio-Enabled"
                  name="switch-two"
                  value="Enabled"
                />
                <label for="radio-Enabled">Enabled</label>
              </div>
            </div>
            <div class="password__body-auth-container">
              <div class="password__body-auth-info">
                <Info />
                <p>Sign in using your biometrics in place of your password.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="password__button">
        <NavLink to="/account">
          <button
            className="button__style-4--1"
            type="button"
          >
            Update
          </button>
        </NavLink>
      </div>
    </section>
  )
}

export default Password
