import BrowseSearchHead from '../../components/browse/BrowseSearchHead'
import BrowseSearchBody from '../../components/browse/BrowseSearchBody'
import BrowseSearchDistrict from '../../components/browse/BrowseSearchDistrict'
import BrowseSearchPostal from '../../components/browse/BrowseSearchPostal'
import { useState } from 'react'
import SearchApply from '../../components/browse/SearchApply'
const BrowseSearchLocation = () => {
  const [tab, setTab] = useState(0)
  const [isTrue, setIsTrue] = useState(false)
  const setPage = (data) => {
    setTab(data)
  }
  const value = (val) => {
    setIsTrue(val)
  }
  return (
    <div className="browse-location">
      <BrowseSearchHead func={setPage} />
      {tab === 0 ? (
        <BrowseSearchBody value={value} />
      ) : tab === 1 ? (
        <BrowseSearchDistrict value={value} />
      ) : tab === 2 ? (
        <BrowseSearchPostal value={value} />
      ) : (
        ''
      )}
      {/* {isTrue === true ? <SearchApply /> : ' '} */}
    </div>
  )
}

export default BrowseSearchLocation
