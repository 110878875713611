import React from 'react'
import { useNavigate } from 'react-router-dom'
import ReactDOM from 'react-dom'
import '../../../../assets/css/components/ui/modal.css'
import { ReactComponent as RocketIllus } from '../../../../assets/illustration/onboarding/Rocket Launch _Monochromatic 1.svg'
import { ReactComponent as Back } from '../../../../assets/icon/general/Vector-1-back.svg'
import { ReactComponent as Home } from '../../../../assets/icon/general/Vector-17-house.svg'

function ModalVerification({ show, hide }) {
  const navigate = useNavigate()

  return ReactDOM.createPortal(
    <div className="modal">
      <div className="modal__overlay" onClick={hide}></div>

      <div className="modal__content">
        <div className="content__illus">
          <RocketIllus />
        </div>

        <div className="content__text">
          <h1>We have received your listing submission...</h1>
          <p>
            We will verify your property details and proceed to list it on the
            marketplace. We will keep you posted on your listing. <br /> <br />{' '}
            In the meantime, you may configure additional items for your
            listing, such as your preferred viewing timeslots and etc.
          </p>
        </div>

        <div className="content__handler">
          <button className="button__style-4--1">
            <Home />
            Configure additional details
          </button>
          <button
            className="button__style-4--2"
            onClick={() => navigate('/lease')}
          >
            <Back />
            Back to listing summary
          </button>
        </div>
      </div>
    </div>,
    document.getElementById('portal')
  )
}

export default ModalVerification
