import axios from 'axios'
import { apiBaseURL } from '../utils'

const createOne = async (data) => {
  var resData = { data: null, message: 'Failed to create' }

  if (!data) return resData

  const response = await axios.post(`${apiBaseURL}/tenant_lease_props`, data)

  if (response.status !== 200) return resData

  resData = response.data

  return resData
}

const updateOne = async (data, id) => {
  const response = await axios.patch(
    `${apiBaseURL}/tenant_lease_props/${id}`,
    data
  )
  return response.data
}

const getTenantLeasePropBasedOnProfileId = async (id) => {
  const response = await axios.get(
    `${apiBaseURL}/user_profiles/${id}/tenant_lease_props`
  )
  return response.data
}

const getTenantLeasePropBasedOnPropertyId = async (propertyId) => {
  const response = await axios.get(
    `${apiBaseURL}/properties/${propertyId}/tenant_lease_props`
  )
  return response.data[0]
}

const tenantLeasePropService = {
  createOne,
  getTenantLeasePropBasedOnProfileId,
  getTenantLeasePropBasedOnPropertyId,
  updateOne,
}

export default tenantLeasePropService
