import React from 'react'
import Button from '../../ui/Button'
import '../../../assets/css/components/LeasePage/Tenant/leaseCurrentBox.css'
import { ReactComponent as ScheduleIllus } from '../../../assets/illustration/lease/Reminder Note _Monochromatic 1.svg'
import { ReactComponent as PartyIllus } from '../../../assets/illustration/lease/Party_Monochromatic 2.svg'

function LeaseCurrentBox(props) {
  return (
    <div className="current__manage">
      <p className="p1">{props.title}</p>

      <div className="manage__box">
        {props.outlet === 'request' && (
          <div className="box__row">
            <div>
              <p>TOTAL</p>
              <p>11</p>
            </div>
            <div>
              <p>ACTION REQUIRED</p>
              <p>1</p>
            </div>
            <div>
              <p>OPEN</p>
              <p>2</p>
            </div>
            <div>
              <p>RESOLVED</p>
              <p>9</p>
            </div>
          </div>
        )}

        {props.outlet === 'schedule' && (
          <div className="box__row-2">
            <div className="row-2__illus">
              <ScheduleIllus />
            </div>

            <div className="row-2__desc">
              <span>A gentle reminder...</span>
              <p>You have 2 outstanding items that require your attention.</p>
            </div>
          </div>
        )}

        {props.outlet === 'summaryHandoverBefore' && (
          <div className="box__row-2">
            <div className="row-2__illus">
              <ScheduleIllus />
            </div>

            <div className="row-2__desc">
              <span>A gentle reminder...</span>
              <p>
                Handover day is coming soon - <strong>15 Mar 2022 (Tue)</strong>
                . Get prepared by reading the Handover Checklist.
              </p>
            </div>
          </div>
        )}

        {props.outlet === 'summaryHandoverAfter' && (
          <div className="box__row-2">
            <div className="row-2__illus">
              <PartyIllus />
            </div>

            <div className="row-2__desc">
              <span>It’s Handover Day!</span>
              <p>
                View the checklist to access the inventory list and submit the
                pre-condition photos.
              </p>
            </div>
          </div>
        )}

        {props.outlet === 'details' && (
          <div className="box__row">
            <div>
              <p>LEASE DURATION</p>
              <p>24 mths</p>
            </div>
            <div>
              <p>LEASE START</p>
              <p>15 Mar 2022</p>
            </div>
            <div>
              <p>LEASE END</p>
              <p>15 Mar 2024</p>
            </div>
            <div>
              <p>MONTHLY RENT</p>
              <p>
                <span className="unit">SGD</span> $2,600
              </p>
            </div>
            <div>
              <p>PAYMENT DATE</p>
              <p>15th of the month</p>
            </div>
          </div>
        )}

        {props.outlet === 'summary' && (
          <div className="box__row">
            <div>
              <p>TOTAL SPENT</p>
              <p>
                <span className="unit">SGD</span> $30,400
              </p>
            </div>
            <div>
              <p>NEXT PAYMENT DUE</p>
              <p>15 Dec 2022</p>
            </div>
            <div>
              <p>NEXT SERVICING</p>
              <p>Feb 2023</p>
            </div>
          </div>
        )}

        {props.outlet === 'summaryLeased' && (
          <div className="box__row">
            <div>
              <p>LIFETIME RENT COLLECTED</p>
              <p>
                <span className="unit">SGD</span> $30,400
              </p>
            </div>
            <div>
              <p>LIFETIME LEASED DURATION</p>
              <p>24 mths</p>
            </div>
          </div>
        )}

        {props.outlet === 'request' && (
          <div className="box__row">
            <Button
              directURL="/"
              buttonName="Get Services"
              type={2}
              allow={true}
            />
            <Button
              directURL="/"
              buttonName="Add Request"
              color="orange"
              type={2}
              allow={true}
            />
          </div>
        )}

        {props.outlet === 'schedule' && (
          <div className="box__row">
            <Button
              directURL="/"
              buttonName="Sync Calendar"
              type={2}
              allow={true}
            />
            <Button
              directURL="/"
              buttonName="Add Request"
              color="orange"
              type={2}
              allow={true}
            />
          </div>
        )}

        {props.outlet === 'details' && (
          <>
            <div className="box__row">
              <Button
                directURL="/"
                buttonName="View Agreement"
                type={2}
                allow={true}
              />
              <Button
                directURL="/"
                buttonName="Add Request"
                color="orange"
                type={2}
                allow={true}
              />
            </div>
            {props.handover && (
              <div className="box__row box__row--btn-handover-1">
                <Button
                  directURL="/"
                  buttonName="View Inventory List"
                  // iconShow={true}
                  type={2}
                  allow={true}
                />
                <Button
                  directURL="/"
                  buttonName="View Pre-condition Photos"
                  // iconShow={true}
                  type={2}
                  allow={true}
                />
              </div>
            )}
          </>
        )}

        {props.outlet === 'summary' && (
          <div className="box__row">
            <Button
              directURL="/"
              buttonName="Manage Schedule"
              type={2}
              allow={true}
            />
            <Button
              directURL="/"
              buttonName="Add Request"
              color="orange"
              type={2}
              allow={true}
            />
          </div>
        )}

        {props.outlet === 'summaryHandoverBefore' && (
          <div className="box__row box__row--btn-handover">
            <Button
              directURL="/handover-check"
              buttonName="View Checklist"
              color="orange"
              type={2}
              allow={true}
            />
          </div>
        )}

        {props.outlet === 'summaryHandoverAfter' && (
          <div className="box__row box__row--btn-handover">
            <Button
              directURL="/handover-check"
              buttonName="View Checklist"
              color="orange"
              type={2}
              allow={true}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default LeaseCurrentBox
