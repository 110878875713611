import React from 'react'

import { ReactComponent as Coin } from '../../../assets/icon/general/Vector-21-coin.svg'
import Button from '../../../pages/LeasePage/components/Button'

const ServiceCardItem = (props) => {
  return (
    <div className="service-card-item">
      <div className="service-card-title">
        <div className="service-card-title_icon">{props.icon}</div>
        <p className="text-md bold">{props.service}</p>
      </div>
      <div>
        <Button
          buttonName="View Pricing"
          icon={
            <div className="lease-button-icon-colored">
              <Coin />
            </div>
          }
          directURL={props.url}
        />
      </div>
    </div>
  )
}

export default ServiceCardItem
