import React, { useState } from 'react'
import '../../assets/css/pages/LeasePage/lease.css'

import Listed from './Menu/Listed'
import Leased from './Menu/Leased'
import MarketData from './Menu/MarketData'

function Lease() {
  // state for render menu
  const [activeMenu, setActiveMenu] = useState(0)
  return (
    <div className='lease'>
      {/* Lease menu */}
      <div className="lease-top-menu">
        <div
          className={`lease-top-menu-item ${
            activeMenu === 0 && 'lease-menu-active'
          }`}
          onClick={() => setActiveMenu(0)}
        >
          <p>Listed</p>
        </div>

        <div
          className={`lease-top-menu-item ${
            activeMenu === 1 && 'lease-menu-active'
          }`}
          onClick={() => setActiveMenu(1)}
        >
          <p>Leased</p>
        </div>

        <div
          className={`lease-top-menu-item ${
            activeMenu === 2 && 'lease-menu-active'
          }`}
          onClick={() => setActiveMenu(2)}
        >
          <p>Market Data</p>
        </div>

        <div
          className={`lease-top-menu-item ${
            activeMenu === 3 && 'lease-menu-active'
          }`}
          onClick={() => setActiveMenu(3)}
        >
          <p>Schedule</p>
        </div>
      </div>

      {activeMenu === 0 && <Listed />}
      {activeMenu === 1 && <Leased />}
      {activeMenu === 2 && <MarketData />}
    </div>
  )
}

export default Lease
