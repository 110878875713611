import React, { useEffect, useState } from 'react'

import { ReactComponent as Back } from '../../assets/icon/general/Vector-1-back.svg'
import { useNavigate } from 'react-router-dom'

import '../../assets/css/pages/LeasePage/listNewProperty.css'
import SelectPackage from '../../components/LeasePage/Landlord/SelectPackage'
import DetailsAndDocs from '../../components/LeasePage/Landlord/DetailsAndDocs'
import PreviewAndPayment from '../../components/LeasePage/Landlord/PreviewAndPayment'

const ListNewProperty = () => {
  const navigate = useNavigate()

  const [outletActive, setOutletActive] = useState(0)
  const [formData, setFormData] = useState(null)
  const [packageSelected, setPackageSelected] = useState(1)

  const tabsTitle = [
    'Select Package',
    'Input Details & Submit Doc',
    'Preview & Make Payment',
  ]

  const directEditPersonal = (outletNumber) => {
    setOutletActive(outletNumber)
  }

  useEffect(() => {
    console.log("formdata: ", formData);
    if(formData != null) setOutletActive(2)
  }, [formData])


  return (
    <div style={{ position: 'relative' }}>
      <div className="onboarding__nav new-property-nav">
        <div className="onboarding__head">
          <div className="signup__back" onClick={() => navigate(-1)}>
            <Back />
          </div>

          <h1>List New Property</h1>
        </div>

        <div className="onboarding__tabs">
          {tabsTitle.map((tab, index) => (
            <button
              disabled={index === 2 && formData === null}
              key={index}
              className={`button__tab ${outletActive === index ? 'button__tab--active' : ''
                } ${outletActive > index ? 'button__tab--done' : ''}`}
              onClick={() => setOutletActive(index)}
            >
              <p className="text-md bold">{tab}</p>
              <div className="button__tab-line"></div>
            </button>
          ))}
        </div>
      </div>

      <div className="onboarding__outlet-container">
        {outletActive === 0 && <SelectPackage setOutletActive={setOutletActive} setPackageSelected={setPackageSelected} />}
        {outletActive === 1 && <DetailsAndDocs setFormData={setFormData} packageSelected={packageSelected} />}
        {outletActive === 2 && <PreviewAndPayment formData={formData} packageSelected={packageSelected} />}
      </div>
    </div>
  )
}

export default ListNewProperty
