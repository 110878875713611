import React, { useState } from 'react'
import HeadBack from '../../components/ui/HeadBack'
import './TenancyAgreement.css'
import InfoBox from '../../components/ui/InfoBox'
import {
  AGGREEMENT_POINT,
  RENT_AGREEMENT,
  SECURITY_DEPOSIT_AGREEMENT,
} from './schema'
import Modal from '../../components/ui/Modal'

import { ReactComponent as Check } from '../../assets/icon/general/Vector-42-check.svg'
import { ReactComponent as Alert } from '../../assets/icon/general/Vector-Info-Box.svg'
import Rent from './Item/Rent'
import SecurityDeposit from './Item/SecurityDeposit'
import GoverningLaw from './Item/GoverningLaw'

const TenancyAgreement = () => {
  const [currentStep, setCurrentStep] = useState(1)
  const suggestedEdit = false
  const lastPoint = AGGREEMENT_POINT[AGGREEMENT_POINT.length - 1]
  const isSuggestEdit = false
  const [isShow, setIsShow] = useState(false)

  const modalHandler = () => {
    setIsShow(true)
  }

  return (
    <div className="agreement-page">
      {isShow && (
        <Modal show={isShow} type="reject-suggest" hide={() => setIsShow(false)} />
      )}
      <HeadBack title="Review Tenancy Agreement" />
      <div className="agreement-point-container">
        {AGGREEMENT_POINT.map((item, index) => (
          <div
            className={`agreement-point_item-container ${
              currentStep === item.poin && 'point-active'
            } `}
            onClick={() => setCurrentStep(item.poin)}
            key={index}
          >
            <div>
              <p className="text-sm normal">{item.poin}</p>
            </div>

            {isSuggestEdit ? (
              <div className="point-indicator accept"></div>
            ) : (
              <div className="point-indicator edit"></div>
            )}
          </div>
        ))}
      </div>

      {/* Page content */}

      {currentStep === 2.1 && (
        <Rent suggestedEdit={true} onClick={() => setCurrentStep(2.2)} onShowModal={modalHandler}/>
      )}

      {currentStep === 2.2 && <SecurityDeposit />}

      {currentStep === lastPoint.poin && <GoverningLaw />}
    </div>
  )
}

export default TenancyAgreement
