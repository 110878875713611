import { useState } from 'react'
import '../../assets/css/pages/OfferPage/offer.css'

import { ReactComponent as BackIcon } from '../../assets/icon/general/Vector-1-back.svg'
import HeadBack from '../ui/HeadBack'
import BackButton from '../ui/BackButton'

const OfferHead = ({ tab, setTab }) => {
  return (
    <div className="offer-head">
      <div className="offer-head__back-button">
        <div className="offer-head__back-icon">
          {/* <BackIcon /> */}
          <BackButton directURL="/browse/tenant-detail" />
        </div>
        <h1 className="offer-head__back-text">Make an Offer</h1>
        {/* <HeadBack title="Make an Offer" backLink="/browse/tenant-detail" /> */}
      </div>


      <div className="offer-head__tab-switch-container">
        <div className="offer-head__tab-switch" onClick={() => setTab(0)}>
          <h1 className={tab === 0 ? 'tab-text__active' : 'tab-text'}>
            Prepare Letter of Intent (LOI)
          </h1>
        </div>
        {/* <div className="offer-head__tab-switch" onClick={() => setTab(1)}>
          <h1 className={tab === 1 ? 'tab-text__active' : 'tab-text'}>
            Pay Booking Deposit
          </h1>
        </div> */}
        <div className="offer-head__tab-switch" onClick={() => setTab(1)}>
          <h1 className={tab === 1 ? 'tab-text__active' : 'tab-text'}>
            Review Offer & Sign Off
          </h1>
        </div>
      </div>
    </div>
  )
}

export default OfferHead
