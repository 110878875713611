import { React, useEffect, useState } from 'react'
import { ReactComponent as Star } from '../../assets/icon/general/Vector-44-star.svg'
import { ReactComponent as Bell } from '../../assets/icon/general/Vector-52-bell.svg'
import { ReactComponent as SwitchIcon } from '../../assets/icon/general/Vector-48-switch.svg'
import { ReactComponent as Info } from '../../assets/icon/general/Vector-18-info.svg'
import { ReactComponent as Profile } from '../../assets/icon/general/Vector-23-profile.svg'
import { ReactComponent as RightArrow } from '../../assets/icon/general/Vector-2-rightArrow.svg'
import { ReactComponent as PasswordIcon } from '../../assets/icon/general/Vector-51-password.svg'
import { ReactComponent as Card } from '../../assets/icon/general/Vector-54-card.svg'
import { ReactComponent as Bank } from '../../assets/icon/general/Vector-56-bank.svg'
import { ReactComponent as Language } from '../../assets/icon/general/Vector-55-language.svg'
import { ReactComponent as Question } from '../../assets/icon/general/Vector-16-question.svg'
import { ReactComponent as VerifiedIcon } from '../../assets/icon/general/Vector-43-verified.svg'
import '../../assets/css/pages/AccountPage/account.css'
import { NavLink, useNavigate } from 'react-router-dom'
import authService from '../../services/AuthService'
import userServices from '../../services/userService'
import Modal from '../../components/ui/Modal'

function Account() {
  const [isLogged, setIsLogged] = useState(true)
  const [isOnboarded, setIsOnboarded] = useState(false)
  const [isVerificated, setIsVerificated] = useState(false)
  const [accountType, setAccountType] = useState('tenant')
  const [ account, setAccount ] = useState([]);

  const navigate = useNavigate();

  const fetchAccountData = async (id) => {
    const accountData = await userServices.getOneFromId(id);
    setAccount(accountData);
  }

  useEffect(() => {
    fetchAccountData(localStorage.getItem(authService._userIdKey));
  }, [])

  useEffect(() => {
    if(account.user_status === 2){
      setIsOnboarded(true);
      setIsVerificated(true);
    }
  })

  const handleSwitchChange = (event) => {
    setAccountType(event.target.value)
  }

  const handleButtonClick = () => {
    if (accountType === 'tenant') {
      setAccountType('landlord')
    } else {
      setAccountType('tenant')
    }
  }

  /**
   * @Author Rainer Regan 6 May 2023
   */
  const _handleLogOut = () => {
    modalHandler()
    authService.logout();
    if(!authService.isAuthenticated()) {
      navigate('/');
    }
  }

  const [isShow, setIsShow] = useState(false)

  const modalHandler = () => {
    setIsShow(true)
  }

  return (
    <section className="account_main">
      {isShow && (
        <Modal show={isShow} type="logout" hide={() => setIsShow(false)} />
      )}
      <div className="account_header">
        <div className="account_header-upper">
          <div className="account_header-upper-user">
            <div className="account_header-upper-user-username">
              <div className="account_header-upper-user-username-initial">
                <h4>{isLogged ? 'WZ' : 'UP'}</h4>
              </div>
              <h2>{isLogged ? account.name : 'Userp30190iwq0'} </h2>
              {isVerificated && <VerifiedIcon />}
            </div>
            <div className="account_header-upper-user-rating">
              <Star />
              <p>No Rating Yet</p>
            </div>
          </div>
          <div className="account_header-upper-notification">
            <NavLink to="/account/notifications" className="account_link">
              <button>
                <Bell />
              </button>
            </NavLink>
          </div>
        </div>
        <div className="account_header-switch">
          <input
            type="radio"
            id="radio-one"
            name="switch-one"
            value="tenant"
            checked={accountType === 'tenant'}
            onChange={handleSwitchChange}
          />
          <label for="radio-one">Tenant</label>
          <SwitchIcon className="icon-switch" onClick={handleButtonClick} />
          <input
            type="radio"
            id="radio-two"
            name="switch-one"
            value="landlord"
            checked={accountType === 'landlord'}
            onChange={handleSwitchChange}
          />
          <label for="radio-two">Landlord</label>
        </div>
        {isOnboarded && (
          <div>
            {accountType === 'tenant' ? (
              <div className="account_header-assets">
                <div className="account_header-assets-value">
                  <h2>4 mths</h2>
                  <h4>TILL END OF LEASE</h4>
                </div>
                <div className="account_header-assets-value">
                  <h2>15 Dec 2022</h2>
                  <h4>NEXT PAYMENT DUE</h4>
                </div>
              </div>
            ) : (
              <div className="account_header-assets">
                <div className="account_header-assets-value">
                  <h2>2</h2>
                  <h4>Listed</h4>
                </div>
                <div className="account_header-assets-value">
                  <h2>1</h2>
                  <h4>Leased</h4>
                </div>
                <div className="account_header-assets-value">
                  <h2>SGD $46,000</h2>
                  <h4>Lifetime Rented Collected</h4>
                </div>
              </div>
            )}
          </div>
        )}
      </div>

      <div className="account_body">
        {!isOnboarded && (
          <div className="account_body-container">
            <div className="account_body-container-warn">
              <Info />
              {accountType === 'tenant' && (
                <div>
                  {isVerificated ? (
                    <p>We are currently verifying your account.</p>
                  ) : (
                    <p>
                      Complete your profile to make enquiries, book viewings and
                      make offers.
                    </p>
                  )}
                </div>
                //
              )}
              {accountType === 'landlord' && (
                <p>
                  Complete your profile to list properties, schedule viewings
                  and accept offers.
                </p>
              )}
            </div>
          </div>
        )}
        <div className="account_body-container">
          
          <NavLink to={isOnboarded ? "/account/profile" : "/onboarding"} className="account_link">
            <div className="account_body-container-list">
              <div className="account_body-container-list-title">
                <Profile />
                <p>Profile</p>
                {!isOnboarded && (
                  <div className="account-body-container-uncompleted">
                    {accountType === 'tenant' ? (
                      <div>
                        <p>Pending Verification</p>
                      </div>
                    ) : (
                      <p>Not Completed</p>
                    )}
                  </div>
                )}
              </div>
              <div className="account_body-container-list-detail">
                <RightArrow />
              </div>
            </div>
          </NavLink>
        </div>

        <div className="account_body-container">
          <NavLink to="/account/password" className="account_link">
            <div className="account_body-container-list">
              <div className="account_body-container-list-title">
                <PasswordIcon />
                <p>Password / 2FA</p>
              </div>
              <div className="account_body-container-list-detail">
                <RightArrow />
              </div>
            </div>
          </NavLink>
        </div>

        <div className="account_body-container">
          <NavLink to="/payment" className="account_link">
            <div className="account_body-container-list">
              <div className="account_body-container-list-title">
                <Card />
                <p>Payment Method</p>
              </div>
              <div className="account_body-container-list-detail">
                <RightArrow />
              </div>
            </div>
          </NavLink>
        </div>

        <div className="account_body-container">
          <div className="account_body-container-list">
            <div className="account_body-container-list-title">
              <Bank />
              <p>Bank Account</p>
            </div>
            <div className="account_body-container-list-detail">
              <RightArrow />
            </div>
          </div>
        </div>

        <div className="account_body-container">
          <NavLink to="/account/notifications" className="account_link">
            <div className="account_body-container-list">
              <div className="account_body-container-list-title">
                <Bell />
                <p>Notification</p>
              </div>
              <div className="account_body-container-list-detail">
                <RightArrow />
              </div>
            </div>
          </NavLink>
        </div>

        <div className="account_body-container">
          <div className="account_body-container-list">
            <div className="account_body-container-list-title">
              <Language />
              <p>Language</p>
            </div>
            <div className="account_body-container-list-detail">
              <RightArrow />
            </div>
          </div>
        </div>

        <div className="account_body-container">
          <div className="account_body-container-list">
            <div className="account_body-container-list-title">
              <Question />
              <p>Support</p>
            </div>
            <div className="account_body-container-list-detail">
              <RightArrow />
            </div>
          </div>
        </div>

        {isLogged && (
          <div className="account_body-container" onClick={_handleLogOut}>
            <div className="account_body-container-list">
              <div className="account_body-container-list-title">
                <Question />
                <p>Logout</p>
              </div>
              <div className="account_body-container-list-detail">
                <RightArrow />
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  )
}

export default Account
