import React, { useState } from 'react'
import './Chat.css'
import ImgPlaceholder from '../../assets/images/image 5.png'
import { ReactComponent as Info } from '../../assets/icon/general/Vector-18-info.svg'
import { ReactComponent as Home } from '../../assets/icon/general/Vector-17-house.svg'
import { ReactComponent as Flag } from '../../assets/icon/general/Vector-46-flag.svg'
import { ReactComponent as Trash } from '../../assets/icon/general/Vector-81-trash.svg'
import { ReactComponent as Send } from '../../assets/icon/general/Vector-26-send.svg'
import { ReactComponent as Emoji } from '../../assets/icon/general/Vector-80-emoji.svg'
import Modal from '../../components/ui/Modal'
import ChatHead from '../../components/ui/ChatHead'

function Chat() {
  const [isShow, setIsShow] = useState(false)
  const [modalType, setModalType] = useState(null)

  const modalHandler = (type) => {
    setModalType(type)
    setIsShow(true)
  }
  return (
    <div className="chat">
      {isShow && (
        <Modal show={isShow} type={modalType} hide={() => setIsShow(false)} />
      )}

      <div className="chat-container">
        <ChatHead />

        <div className="chat-menu-container">
          <div
            className="chat-menu-item"
            onClick={() => modalHandler('chatUserProfile')}
          >
            <Info />
          </div>

          <div
            className="chat-menu-item"
            onClick={() => modalHandler('chatPropertySummary')}
          >
            <Home />
          </div>

          <div
            className="chat-menu-item"
            onClick={() => modalHandler('chatReportUser')}
          >
            <Flag />
          </div>

          <div
            className="chat-menu-item"
            onClick={() => modalHandler('chatDelete')}
          >
            <Trash />
          </div>
        </div>

        <div className="chat-body">
          <div>
            <div className="chat-information-container">
              <div className="chat-information">
                <div>
                  <Info className="chat-information_icon" />
                </div>
                <div className="chat-information_value">
                  <p>
                    You are chatting with{' '}
                    <span className="chat-information_user">Wong Zhenyi</span>.
                  </p>
                </div>
              </div>
            </div>

            <div className="chat-messages">
              <div className="chat-messages__info">
                <div className="chat-year-container">
                  <div className="chat-year">
                    <p>2022</p>
                  </div>
                </div>

                <div className="chat-time-container">
                  <div className="chat-time">
                    <p>28 NOV</p>
                    <div className="chat-time_dot"></div>
                    <p>09:15 AM</p>
                  </div>
                </div>
              </div>

              <div className="chat-messages__text">
                <div className="chat-bubble-container">
                  <div className="chat-bubble_receiver">
                    <div className="chat-user-item ">
                      <div className="chat-user-item_name">
                        <p>WZ</p>
                      </div>
                    </div>

                    <div className="buble-wrapper">
                      <div className="chat-bubble_message">
                        <p>
                          hi, can i just quickly check if there are already other
                          tenants staying in the house?
                        </p>
                      </div>

                      <div className="chat-bubble_message">
                        <p>
                          hi, can i just quickly check if there are already other
                          tenants staying in the house?
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="chat-bubble-container">
                  <div className="chat-bubble_sender">
                    <div className="chat-bubble_message chat-sender">
                      <p>
                        yes. there are 3 tenants occupying the other 2 common
                        rooms
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              
              <div className="chat-messages__info">
                <div className="chat-unread-container">
                  <div className="chat-unread_label">
                    <p>Unread</p>
                  </div>
                </div>
                <div className="chat-time-container">
                  <div className="chat-time">
                    <p>28 NOV</p>
                    <div className="chat-time_dot"></div>
                    <p>09:15 AM</p>
                  </div>
                </div>
              </div>

              <div className="chat-messages__text">
                <div className="chat-bubble-container">
                  <div className="chat-bubble_receiver">
                    <div className="chat-user-item ">
                      <div className="chat-user-item_name">
                        <p>WZ</p>
                      </div>
                    </div>

                    <div className="buble-wrapper">
                      <div className="chat-bubble_message">
                        <p>
                          ok thanks. then just want to check, cos this is my first
                          time renting, how are the tenants supposed to settle
                          things like cleaning of shared areas like living room,
                          toilet all these?
                        </p>
                      </div>

                      <div className="chat-bubble_message">
                        <p>
                          like is it got roster or landlord will settle the
                          cleaning of common areas?
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="chat-footer-container">
        <div className="chat-footer-input_container">
          <div className="chat-footer-icon_emoji">
            <Emoji />
          </div>
          <input
            type="text"
            className="chat-footer-input"
            placeholder="Type here..."
          />
          <div className="chat-footer-icon_send">
            <Send />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Chat
