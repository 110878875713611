import React from 'react'
import '../../../assets/css/components/browse/Tenant/tenantDetailsOutlet3.css'
import InfoBoxColored from '../../ui/InfoBoxColored'

import { ReactComponent as IconLove } from '../../../assets/icon/general/Vector-15-love.svg'
import { ReactComponent as IconCalendar } from '../../../assets/icon/general/Vector-12-calendardoted.svg'
import { ReactComponent as IconCoin } from '../../../assets/icon/general/Vector-21-coin.svg'

function TenantDetailsOutlet3() {
  return (
    <div className="details-listing">
      <InfoBoxColored
        title="VIEW-TO-SHORLIST RATIO"
        info="This listing as a higher-than-average view-to-shortlist ratio of 12:1. This means that for every 12 people viewing this listing, 1 of them has shortlisted it."
        icon={<IconLove className="info-box-icon_colored" />}
      />

      <InfoBoxColored
        title="VIEWINGS BOOKED"
        info="More than 10 people have booked viewings since this property was listed 2h ago."
        icon={<IconCalendar className="info-box-icon_colored" />}
      />

      <InfoBoxColored
        title="RENTAL PRICE"
        info="This property costs 4% more than the average 3-bedroom HDB for rent in District 12."
        icon={<IconCoin className="info-box-icon_colored" />}
      />
    </div>
  )
}

export default TenantDetailsOutlet3
