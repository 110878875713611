import React from 'react'

import { ReactComponent as IconInfo } from '../../assets/icon/general/Vector-18-info.svg'
function InfoBox(props) {
	return (
		<div className="info-box">
			<IconInfo className="info-box__icon" />
			<p>
				{props.info}
			</p>
		</div>
	)
}

export default InfoBox