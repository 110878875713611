import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
// import layout
import MenuLayout from '../components/layout/MenuLayout'

// import pages
import Splash from '../pages/FirstTimePage/Splash'
import GetStarted from '../pages/FirstTimePage/GetStarted'
import SignUp from '../pages/FirstTimePage/SignUp'
import Login from '../pages/FirstTimePage/Login'
import Verify from '../pages/FirstTimePage/Verify'
import Onboarding from '../pages/FirstTimePage/Onboarding'
import Browse from '../pages/BrowsePage/Browse'
import Lease from '../pages/LeasePage/Lease'
import Chat from '../pages/ChatPage/Chat'
import Account from '../pages/AccountPage/Account'
import ViewGuide from '../pages/LeasePage/ViewGuide'
import ReviewTenancyAgreement from '../pages/LeasePage/Tenant/ReviewTenancyAgreement'
import Profile from '../pages/AccountPage/Profile'
import Password from '../pages/AccountPage/Password'
import Notifications from '../pages/AccountPage/Notifications'
import ManageListing from '../pages/LeasePage/ManageListing'
import TenantDetails from '../pages/BrowsePage/Tenant/TenantDetails'

// Lease Tenant
import LeaseTenant from '../pages/LeasePage/Tenant/LeaseTenant'
import BrowseSearchLocation from '../pages/BrowsePage/BrowseSearchLocation'
import Payment from '../pages/LeasePage/Tenant/Payment'
import ListNewProperty from '../pages/LeasePage/ListNewProperty'
import Services from '../pages/LeasePage/Services/Services'
import AirconServicing from '../pages/LeasePage/Services/AirconServicing'
import AddRequest from '../pages/LeasePage/Tenant/AddRequest'
import OfferPage from '../pages/OfferPage/OfferPage'
import BrowseFilter from '../pages/BrowsePage/BrowseFilter/BrowseFilter'
import ScheduleViewing from '../pages/ScheduleViewing/ScheduleViewing'
import Protected from '../components/utils/Protected'
import TenancyAgreement from '../pages/TenancyAgreement/TenancyAgreement'
import ManageRequest from '../pages/ManageRequest/ManageRequest'
import AddCard from '../components/offer/Payment Choices/AddCard'
import PreCondition from '../pages/LeasePage/PreCondition'
import HandOverCheck from '../pages/LeasePage/HandOverCheck'
import InventoryList from '../pages/LeasePage/InventoryList'
// import ReviewTenancyAgreement from '../pages/LeasePage/Tenant/ReviewTenancyAgreement'
import ReviewTA from '../pages/ReviewTA/ReviewTA'
import userConstant from '../utils/constants/user.constant'
import LOI from '../components/documents/LOI/LOI'
import LOIPreview from '../pages/DocumentPreview/LOIPreview/LOIPreview'

const RouterView = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to='/browse' />} />
      <Route path="/start" element={<Splash />} />
      <Route path="/getstarted" element={<GetStarted />} />
      <Route path="/signup/:userType" element={<SignUp />} />
      <Route path="/login/:userType" element={<Login />} />
      <Route
        path="/verify"
        element={
          <Protected checkVerification={false}>
            <Verify />
          </Protected>
        }
      />
      <Route
        path="/onboarding"
        element={
          <Protected>
            <Onboarding />
          </Protected>
        }
      />
      <Route
        path="/lease/guide"
        element={
          <Protected>
            <ViewGuide />
          </Protected>
        }
      />
      <Route
        path="/lease/tenant/review"
        element={
          <Protected role={userConstant.userRoleConstants.TENANT} roleRedirection='/lease'>
            <ReviewTenancyAgreement />
          </Protected>
        }
      />
      <Route
        path="/browse/search"
        element={
          <Protected>
            <BrowseSearchLocation />
          </Protected>
        }
      />
      <Route
        path="/payment"
        element={
          <Protected>
            <Payment />
          </Protected>
        }
      />
      <Route
        path="/lease/new-property"
        element={
          <Protected role={userConstant.userRoleConstants.LANDLORD} roleRedirection='/lease/tenant'>
            <ListNewProperty />
          </Protected>
        }
      />
      <Route
        path="/lease/services"
        element={
          <Protected>
            <Services />
          </Protected>
        }
      />
      <Route
        path="/lease/services/aircon"
        element={
          <Protected>
            <AirconServicing />
          </Protected>
        }
      />
      <Route
        path="/lease/manage-listing/:propertyId"
        element={
        <Protected role={userConstant.userRoleConstants.LANDLORD} roleRedirection='/lease/tenant'>
            <ManageListing />
          </Protected>
        }
      />
      <Route
        path="/account/profile"
        element={
          <Protected checkVerification={false}>
            <Profile />
          </Protected>
        }
      />
      <Route
        path="/account/password"
        element={
          <Password />
        }
      />
      <Route
        path="/account/notifications"
        element={
          <Protected>
            <Notifications />
          </Protected>
        }
      />
      <Route
        path="/addrequest"
        element={
          <Protected>
            <AddRequest />
          </Protected>
        }
      />
      <Route
        path="/makeoffer/:propertyId"
        element={
          <Protected role={userConstant.userRoleConstants.TENANT} roleRedirection='/browse'>
            <OfferPage />
          </Protected>
        }
      />
      <Route
        path="/browse/filter"
        element={
          <Protected>
            <BrowseFilter />
          </Protected>
        }
      />
      <Route
        path="/details/schedule/:propertyId"
        element={
          <Protected role={userConstant.userRoleConstants.TENANT} roleRedirection='/browse'>
            <ScheduleViewing />
          </Protected>
        }
      />
      <Route
        path="/browse/tenant-detail/:propertyId"
        element={<TenantDetails />}
      />
      <Route
        path="/tenancy-agreement"
        element={
          <Protected>
            <TenancyAgreement />
          </Protected>
        }
      />
      <Route
        path="/manage-request"
        element={
          <Protected>
            <ManageRequest />
          </Protected>
        }
      />
      <Route path="/add-card" element={<AddCard />} />
      <Route path="/review-ta" element={<ReviewTA />} />
      <Route path="/pre-condition" element={<PreCondition />} />
      <Route path="/handover-check" element={<HandOverCheck />} />
      <Route path="/inventory-list" element={<InventoryList />} />
      <Route path="/iframe-loi" element={<LOI />} />
      <Route path="/loi-preview" element={<LOIPreview />} />

      <Route element={<MenuLayout />}>
        <Route path="/browse" element={<Browse />} />
        <Route
          path="/lease"
          element={
            <Protected role={userConstant.userRoleConstants.LANDLORD} roleRedirection='/lease/tenant'>
              <Lease />
            </Protected>
          }
        />
        <Route
          path="/lease/tenant"
          element={
            <Protected role={userConstant.userRoleConstants.TENANT} roleRedirection='/lease'>
              <LeaseTenant />
            </Protected>
          }
        />
        <Route
          path="/chat"
          element={
            <Protected>
              <Chat />
            </Protected>
          }
        />
        <Route
          path="/account"
          element={
            <Protected checkVerification={false}>
              <Account />
            </Protected>
          }
        />
      </Route>
    </Routes>
  )
}

export default RouterView

// "/"
// "/getstarted"
// "/signup/:userType"
// "/verify"
// "/onboarding"
// "/lease/guide"
// "/lease/tenant/review"
// "/browse/search"
// "/payment"
// "/lease/new-property"
// "/lease/services"
// "/lease/services/aircon"
// "/lease/manage-listing"
// "/account/profile"
// "/account/password"
// "/account/notifications"
// "/addrequest"

// "/browse"
// "/lease"
// "/lease/tenant"
// "/chat"
// "/account"
