import { useEffect, useState } from 'react'
import { ReactComponent as InfoIcon } from '../../assets/icon/general/Vector-18-info.svg'
import InfoBox from '../ui/InfoBox'
import FloatButton from './FloatButton'
import BillingForm from './Forms/BillingForm'
import LOIform from './Forms/LOIform'
import OptionalForm from './Forms/OptionalForm'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import OtherTOU from './Forms/OtherToU'

const OfferLOI = ({ userData, setSnackbarStatus, setSnackbarMessage, setFormData, propertyData }) => {
  const navigate = useNavigate();

  const { register, handleSubmit, watch, formState: { errors } } = useForm();
  const [additionalRequirements, setAdditionalRequirements] = useState(null)

  const onSubmit = async (data) => {
    if(!data) {
      setSnackbarStatus(true)
      setSnackbarMessage("Form must be filled")
      return
    }

    if(data.citizenship === ""){
      setSnackbarStatus(true)
      setSnackbarMessage("Citizenship should be filled")
      return
    }

    if(data.address === ""){
      setSnackbarStatus(true)
      setSnackbarMessage("Address should be filled")
      return
    }

    if(!data.number_of_additional_occupier){
      setSnackbarStatus(true)
      setSnackbarMessage("Number of additional occupiers should be filled")
      return
    }

    if(!data.start_date_of_lease){
      setSnackbarStatus(true)
      setSnackbarMessage("Start of lease date should be filled")
      return
    }

    if(data.monthly_rental_offer === ""){
      setSnackbarStatus(true)
      setSnackbarMessage("Monthly rental offer should be filled")
      return
    }

    const {id, ...restPropertyData} = propertyData;

    const dataCombined = {
      ...data,
      additional_requirements: additionalRequirements,
      property_id: id,
      ...restPropertyData
    }

    setFormData(dataCombined)
  }

  useEffect(() => {
    if (userData) {
      if (userData.user_status !== 2) {
        alert("Please fulfill all data before requesting an offer")
        navigate('/onboarding')
      }
      
      console.log("Property_data", propertyData);
    }
  }, [])

  return userData ? (
    <>
      <div className="offer-loi">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="offer-body">
            <div className="offer-body__content">
              <InfoBox
                info={
                  'All fields are mandatory unless otherwise stated. Some fields have been pre-filled based on existing/verified information and cannot be changed.'
                }
              />
              <LOIform userData={userData} register={register} propertyData={propertyData} />
              <BillingForm />
              <OtherTOU />
              <OptionalForm setAdditionalRequirements={setAdditionalRequirements} />
            </div>
          </div>
          <div className="search-apply offer-button">
            <button className="search-apply__btn apply-btn" type='submit' >
              <span>Continue</span>
            </button>
          </div>
        </form>
      </div>
    </>
  ) : <>Something wrong happened</>
}

export default OfferLOI
