const BillingForm = () => {
  return (
    <div className="offer-body__section-container">
      <div className="offer-body__form-container">
        <h1 className=" offer-body__title">Billing, Services & Fees</h1>
        <div class="billing-switches-services">
          <p class="text-md">Utilities</p>
          <div class="services-option">
            <input
              type="radio"
              id="tenant-util"
              name="switch-util"
              value="tenant-util"
            />
            <label for="tenant-util">Tenant</label>
            <input
              type="radio"
              id="rent-util"
              name="switch-util"
              value="rent-util"
            />
            <label for="rent-util">Included in Rent</label>
          </div>
        </div>
        <div class="billing-switches-services">
          <p class="text-md">Wifi</p>
          <div class="services-option">
            <input
              type="radio"
              id="tenant-wifi"
              name="switch-wifi"
              value="tenant-wifi"
            />
            <label for="tenant-wifi">Tenant</label>
            <input
              type="radio"
              id="rent-wifi"
              name="switch-wifi"
              value="rent-wifi"
            />
            <label for="rent-wifi">Included in Rent</label>
          </div>
        </div>
        <div class="billing-switches-services">
          <p class="text-md">Stamp Duty</p>
          <div class="services-option">
            <input
              type="radio"
              id="tenant-stamp"
              name="switch-stamp"
              value="tenant-stamp"
              selected
            />
            <label for="tenant-stamp">Tenant</label>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BillingForm
