const userRoleConstants = {
  TENANT: 1,
  LANDLORD: 2,
  AGENT: 3
}

const userConstant = {
  userRoleConstants
}

export default userConstant;