import { ReactComponent as WarningIcon } from '../../../assets/icon/general/Vector-18-info.svg'

function ModalExtend(props) {
  return (
    <div className="modal-manage-request">
      <div className="modal-content__container">
        <div className="modal-content__icon-container">
          <WarningIcon />
        </div>
        <div className="modal-content__title">
          <h1 className="modal-content__title-text">Offer period extension </h1>
        </div>
        <div className="modal-content__rejection-input">
          <h1 className="modal-content__rejection-title">
            Select Extension Period
          </h1>
          <div className="modal-content__extend">
            <div className="modal-content__extend-item">
              <h1 className="modal-content__extend-time">24h</h1>
              <p className="modal-content__extend-placeholder">EXTENSION</p>
            </div>
            <div className="modal-content__extend-item">
              <h1 className="modal-content__extend-time">48h</h1>
              <p className="modal-content__extend-placeholder">EXTENSION</p>
            </div>
          </div>
        </div>
        <button className="manage-request__reject-btn" onClick={props.hide}>
          Extend Offer Period
        </button>
        <button className="manage-request__reject-btn" onClick={props.hide}>
          Back to offer list
        </button>
      </div>
    </div>
  )
}

export default ModalExtend
