import { useNavigate } from 'react-router-dom'
import InfoBox from '../../ui/InfoBox'

const Card = () => {
  const navigate = useNavigate()
  return (
    <div>
      <InfoBox
        info={
          'Your card will only be charged after you sign the LOI and submit your offer.'
        }
      />
      <div onClick={() => navigate()} className="button-add-card">
        <h1 className="button-add-card__text">Add a new debit / credit card</h1>
      </div>
    </div>
  )
}

export default Card
