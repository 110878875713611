import { useState } from 'react'
import MapDummy from '../../assets/images/maps dummy.png'
import SearchApply from './SearchApply'
const BrowseSearchPostal = (props) => {
  const [value, setValue] = useState(false)
  props.value(value)
  const handleChange = () => {
    setValue(true)
  }
  return (
    <div className="search__postal">
      <form action="" className="search__postal-form">
        <div className="search-body">
          <div className="search-body__search-postal">
            <h1>Within</h1>
            <div className="search-body__search-postal-select-container">
              <select className="search-body__search-postal-select">
                <option value={500}>500 m</option>
              </select>
            </div>
            <h1>of</h1>
            <input
              type="number"
              className="search-body__search-postal-input"
              placeholder="Postal Code"
              onChange={() => handleChange()}
            />
          </div>
        </div>
        <div className="search-postal__map-container">
          <img src={MapDummy} alt="dummy" />
        </div>

        <SearchApply />
      </form>
    </div>
  )
}
export default BrowseSearchPostal
