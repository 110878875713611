import React, { useState } from 'react'
import { ReactComponent as CloseIcon } from '../../../assets/icon/general/Vector-9-close.svg'
import { ReactComponent as Upload } from '../../../assets/icon/general/Vector-71-upload.svg'
import '../../../assets/css/components/LeasePage/accordionItem.css'

const AccordionUpload = (props) => {
  const [isActive, setIsActive] = useState(props.active)
  const [files, setFiles] = useState([])
  const [files2, setFiles2] = useState([])
  const [files3, setFiles3] = useState([])
  const [files4, setFiles4] = useState([])
  const [files5, setFiles5] = useState([])

  const handleUpload = (event) => {
    const selectedFiles = event.target.files
    setFiles([...files, ...selectedFiles])
  }
  const handleUpload2 = (event) => {
    const selectedFiles2 = event.target.files
    setFiles2([...files2, ...selectedFiles2])
  }
  const handleUpload3 = (event) => {
    const selectedFiles3 = event.target.files
    setFiles3([...files3, ...selectedFiles3])
  }
  const handleUpload4 = (event) => {
    const selectedFiles4 = event.target.files
    setFiles4([...files4, ...selectedFiles4])
  }
  const handleUpload5 = (event) => {
    const selectedFiles5 = event.target.files
    setFiles5([...files5, ...selectedFiles5])
  }

  const handleRemove = (index) => {
    const newFiles = [...files]
    newFiles.splice(index, 1)
    setFiles(newFiles)
  }
  const handleRemove2 = (index) => {
    const newFiles2 = [...files2]
    newFiles2.splice(index, 1)
    setFiles2(newFiles2)
  }
  const handleRemove3 = (index) => {
    const newFiles3 = [...files3]
    newFiles3.splice(index, 1)
    setFiles3(newFiles3)
  }
  const handleRemove4 = (index) => {
    const newFiles4 = [...files4]
    newFiles4.splice(index, 1)
    setFiles4(newFiles4)
  }
  const handleRemove5 = (index) => {
    const newFiles5 = [...files5]
    newFiles5.splice(index, 1)
    setFiles5(newFiles5)
  }

  return (
    <div
      className={
        isActive
          ? `accordion-item accordion-item--handover ${
              props.type === 'invlist'
                ? 'accordion-item--active'
                : 'accordion-item--active1'
            }`
          : 'accordion-item accordion-item--handover'
      }
    >
      <div className="accordion-title" onClick={() => setIsActive(!isActive)}>
        <div className="accordion-title--wrap">
          <p className="text-lg bold">{props.title}</p>
        </div>
        <div>
          {isActive ? (
            <svg
              className="svg__up"
              width="10"
              height="6"
              viewBox="0 0 10 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.933333 5.86667L0 4.93333L5.00004 0L10 4.93333L9.06667 5.86667L5.00004 1.86667L0.933333 5.86667Z"
                fill="white"
              />
            </svg>
          ) : (
            <svg
              className="svg__down"
              width="10"
              height="6"
              viewBox="0 0 10 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.06667 0.133334L10 1.06667L4.99996 6L4.31286e-07 1.06667L0.933334 0.133333L4.99996 4.13333L9.06667 0.133334Z"
                fill="white"
              />
            </svg>
          )}
        </div>
      </div>

      {isActive && (
        <div
          className={`accordion-content ${
            props.type === 'handoc' ||
            (props.type === 'addInventory' && 'accordion-content-nogap')
          }`}
        >
          <div className="content__wrapper">
            {props.id === 1 && (
              <>
                <p className="title-up-precon">
                  You may upload multiple files. Max size per file is 10 MB.
                  Supported file types: .jpg, .png, .pdf
                </p>
                <div className="onboarding__outlet-2-photo">
                  <div className="onboarding__outlet-2-file">
                    <input
                      type="file"
                      id="addLivingRoom"
                      accept="image/*,.pdf"
                      multiple
                      onChange={handleUpload}
                    />
                    <label htmlFor="addLivingRoom">
                      <Upload /> <span>Upload File</span>
                    </label>
                  </div>
                  <div
                    className={`onboarding__outlet-2-file-preview ${
                      files.length === 0 &&
                      'onboarding__outlet-2-file-preview--hide'
                    }`}
                  >
                    {files.map((file, index) => (
                      <div className="image__preview" key={index}>
                        <div
                          className="image__preview-delete"
                          onClick={() => handleRemove(index)}
                        >
                          <CloseIcon />
                        </div>
                        <img src={URL.createObjectURL(file)} alt="file" />
                      </div>
                    ))}
                  </div>
                </div>
              </>
            )}
            {props.id === 2 && (
              <>
                <p className="title-up-precon">
                  You may upload multiple files. Max size per file is 10 MB.
                  Supported file types: .jpg, .png, .pdf
                </p>
                <div className="onboarding__outlet-2-photo">
                  <div className="onboarding__outlet-2-file">
                    <input
                      type="file"
                      id="addBedroom"
                      accept="image/*,.pdf"
                      multiple
                      onChange={handleUpload2}
                    />
                    <label htmlFor="addBedroom">
                      <Upload /> <span>Upload File</span>
                    </label>
                  </div>
                  <div
                    className={`onboarding__outlet-2-file-preview ${
                      files2.length === 0 &&
                      'onboarding__outlet-2-file-preview--hide'
                    }`}
                  >
                    {files2.map((file, index) => (
                      <div className="image__preview" key={index}>
                        <div
                          className="image__preview-delete"
                          onClick={() => handleRemove2(index)}
                        >
                          <CloseIcon />
                        </div>
                        <img src={URL.createObjectURL(file)} alt="file" />
                      </div>
                    ))}
                  </div>
                </div>
              </>
            )}
            {props.id === 3 && (
              <>
                <p className="title-up-precon">
                  You may upload multiple files. Max size per file is 10 MB.
                  Supported file types: .jpg, .png, .pdf
                </p>
                <div className="onboarding__outlet-2-photo">
                  <div className="onboarding__outlet-2-file">
                    <input
                      type="file"
                      id="addKitchen"
                      accept="image/*,.pdf"
                      multiple
                      onChange={handleUpload3}
                    />
                    <label htmlFor="addKitchen">
                      <Upload /> <span>Upload File</span>
                    </label>
                  </div>
                  <div
                    className={`onboarding__outlet-2-file-preview ${
                      files3.length === 0 &&
                      'onboarding__outlet-2-file-preview--hide'
                    }`}
                  >
                    {files3.map((file, index) => (
                      <div className="image__preview" key={index}>
                        <div
                          className="image__preview-delete"
                          onClick={() => handleRemove3(index)}
                        >
                          <CloseIcon />
                        </div>
                        <img src={URL.createObjectURL(file)} alt="file" />
                      </div>
                    ))}
                  </div>
                </div>
              </>
            )}
            {props.id === 4 && (
              <>
                <p className="title-up-precon">
                  You may upload multiple files. Max size per file is 10 MB.
                  Supported file types: .jpg, .png, .pdf
                </p>
                <div className="onboarding__outlet-2-photo">
                  <div className="onboarding__outlet-2-file">
                    <input
                      type="file"
                      id="addStudy"
                      accept="image/*,.pdf"
                      multiple
                      onChange={handleUpload4}
                    />
                    <label htmlFor="addStudy">
                      <Upload /> <span>Upload File</span>
                    </label>
                  </div>
                  <div
                    className={`onboarding__outlet-2-file-preview ${
                      files4.length === 0 &&
                      'onboarding__outlet-2-file-preview--hide'
                    }`}
                  >
                    {files4.map((file, index) => (
                      <div className="image__preview" key={index}>
                        <div
                          className="image__preview-delete"
                          onClick={() => handleRemove4(index)}
                        >
                          <CloseIcon />
                        </div>
                        <img src={URL.createObjectURL(file)} alt="file" />
                      </div>
                    ))}
                  </div>
                </div>
              </>
            )}
            {props.id === 5 && (
              <>
                <p className="title-up-precon">
                  You may upload multiple files. Max size per file is 10 MB.
                  Supported file types: .jpg, .png, .pdf
                </p>
                <div className="onboarding__outlet-2-photo">
                  <div className="onboarding__outlet-2-file">
                    <input
                      type="file"
                      id="addOther"
                      accept="image/*,.pdf"
                      multiple
                      onChange={handleUpload5}
                    />
                    <label htmlFor="addOther">
                      <Upload /> <span>Upload File</span>
                    </label>
                  </div>
                  <div
                    className={`onboarding__outlet-2-file-preview ${
                      files5.length === 0 &&
                      'onboarding__outlet-2-file-preview--hide'
                    }`}
                  >
                    {files5.map((file, index) => (
                      <div className="image__preview" key={index}>
                        <div
                          className="image__preview-delete"
                          onClick={() => handleRemove5(index)}
                        >
                          <CloseIcon />
                        </div>
                        <img src={URL.createObjectURL(file)} alt="file" />
                      </div>
                    ))}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default AccordionUpload
