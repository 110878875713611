import React from 'react'
import BackButton from '../../components/ui/BackButton'
import HeadBack from '../../components/ui/HeadBack'
import AccordionActions from './components/AccordionActions'
import '../../assets/css/pages/LeasePage/handOverCheck.css'

function HandOverCheck() {
  return (
    <div className="handoc">
      <HeadBack title="Handover Checklist" backLink="/lease/tenant" />

      <div className="handoc-body">
        <p className='handoc-title'>Best practices for a successful, dispute-free handover</p>

        <div className="handoc-accordion">
          <AccordionActions
            title="What happens on handover day, who does what"
            type="handoc"
            btnType=""
          />
					<AccordionActions
            title="Reviewing the inventory list"
            type="handoc"
            btnType="updateInventory"
          />
					<AccordionActions
            title="Taking pre-condition photos"
            type="handoc"
            btnType="takePhoto"
          />
					<AccordionActions
            title="What happens after handover day"
            type="handoc"
            btnType=""
          />
        </div>
      </div>
    </div>
  )
}

export default HandOverCheck
