// TODO: Change this before build
const apiBaseURL = 'https://please-api.dnpproject.id/api/v1'
// const apiBaseURL = 'http://localhost:3001/api/v1'

const propertyStatusOptions = { 1: 'Not Verified', 2: 'Verified' }

const propertyPackageOptions = { 1: 'DIY', 2: 'Premium', 3: 'Premium+' }

const floorOptions = { 1: 'Low', 2: 'Mid', 3: 'High' }

const propertyRentalTypeOptions = {
  1: 'Entire Unit',
  2: 'Room with En-suite',
  3: 'Room',
}

const tenantViewingStatusOptions = {
  1: 'Pending',
  2: 'Accepted',
  3: 'Rejected',
}

const monthOptions = {
  1: 'Jan',
  2: 'Feb',
  3: 'Mar',
  4: 'Apr',
  5: 'May',
  6: 'Jun',
  7: 'Jul',
  8: 'Aug',
  9: 'Sep',
  10: 'Oct',
  11: 'Nov',
  12: 'Dec',
}

const dayOptions = {
  0: 'Sun',
  1: 'Mon',
  2: 'Tue',
  3: 'Wed',
  4: 'Thu',
  5: 'Fri',
  6: 'Sat',
}

const furnishingLoadOptions = {
  1: 'Full Furnished',
  2: 'Partial Furnished',
  3: 'Unfurnished',
}

const tenantLeaseStatusOption = {
  1: 'On Offer',
  2: 'Accepted',
  3: 'Rejected',
  4: 'On Lease',
}

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

const stringUtils = {
  capitalizeFirstLetter,
}

const timeCounterFromNow = (targetTime) => {
  const currentDate = new Date()
  const targetDate = new Date(targetTime)
  if (targetDate - currentDate < 0) return 'already expired'
  else if (targetDate - currentDate === 0) return 'now'
  var seconds = Math.floor(targetDate - currentDate / 1000)
  var minutes = Math.floor(seconds / 60)
  var hours = Math.floor(minutes / 60)
  var days = Math.floor(hours / 24)

  hours = (hours - days * 24).toString()
  minutes = (minutes - days * 24 * 60 - hours * 60).toString()

  return days.toString() + 'd ' + hours + 'h ' + minutes + 'm '
}

export {
  apiBaseURL,
  propertyStatusOptions,
  propertyPackageOptions,
  propertyRentalTypeOptions,
  furnishingLoadOptions,
  floorOptions,
  stringUtils,
  monthOptions,
  dayOptions,
  tenantViewingStatusOptions,
  tenantLeaseStatusOption,
  timeCounterFromNow,
}
