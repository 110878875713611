import React, { useEffect, useState } from 'react'
import { ReactComponent as Info } from '../../../assets/icon/general/Vector-18-info.svg'
import ModalVerification from './Modal/ModalVerification'
import CardPropertyOnCreateProperty from '../../ui/CardPropertyOnCreateProperty'
import propertyService from '../../../services/PropertyService'
import SnackBar from '../../ui/SnackBar'
import propertyPhotoServices from '../../../services/propertyPhotoService'
import propertyFurnishingServices from '../../../services/propertyFurnishingService'

const PreviewAndPayment = ({ formData, packageSelected }) => {
  const [isShow, setIsShow] = useState(false)
  const [isFilled, setIsFilled] = useState(true)
  // Snackbar
  const [snackbarMessage, setSnackbarMessage] = useState("")
  const [snackbarStatus, setSnackbarStatus] = useState(false)

  useEffect(() => {
    if (formData == null) {
      setIsFilled(false)
      return alert("Data is empty")
    }
    setIsFilled(true)
    // console.log(packageSelected, "packageSelected");
  }, [])

  useEffect(() => {
    let timeout
    if (snackbarStatus) {
      timeout = setTimeout(() => setSnackbarStatus(false), 2000);
    }
    return () => clearTimeout(timeout);
  }, [snackbarStatus]);

  const handleUpload = async (id, photo, type) => {
    photo.map(async (file) => {
      const res = await propertyPhotoServices.uploadPhoto(file)
      await propertyPhotoServices.createPropertyPhoto(id, res.data.url, type)
    })
  }

  const handleFurnishing = async (id, furnishing) => {
    furnishing.map(async (furnish) => {
      await propertyFurnishingServices.createOne(id, furnish)
    })
  }

  const _handleSubmit = async () => {
    const response = await propertyService.createNewProperty(formData);
    await handleUpload(response.data.data.id, formData.property_photo_files, 1);
    await handleUpload(response.data.data.id, formData.floor_plan_photo_files, 2);
    await handleUpload(response.data.data.id, formData.proof_photo_files, 3);
    await handleFurnishing(response.data.data.id, formData.furnishings);

    if (response.data !== null) {
      setIsShow(true)
      setIsFilled(false)
    }

    console.log("response", response);
  }

  return (
    <div>
      <SnackBar message={snackbarMessage} status={snackbarStatus} />

      {isShow && (
        <ModalVerification show={isShow} hide={() => setIsShow(false)} />
      )}

      <div className="new-property-details-title">
        <p>Preview Listing</p>
      </div>

      <div style={{ marginBottom: '1.2rem' }}>
        <CardPropertyOnCreateProperty data={formData} />
      </div>

      <div className="details-form-container">
        <p className="text-lg bold" style={{ marginBottom: '.6rem' }}>
          Listing Fee
        </p>
        <div className="guide-information-box">
          <div className="guide-information-box_icon">
            <Info />
          </div>
          <p className="text-sm guide-information-box_desc">
            This fee is an initial estimate based on your asking price. You will
            only need to pay this fee based on your accepted price if and when
            your property is successfully leased.
          </p>
        </div>
        <div className="preview-total-amount_card">
          <p className="preview-total-amount_type">DIY Basic</p>

          <p className="preview-total-amount_value">
            SGD <span className="text-lg">${formData?.monthly_rental}</span>
          </p>
        </div>
      </div>

      <div className="onboarding__button">
        <div
          className={`${isFilled
            ? 'button__style-3 button__style-3--active'
            : 'button__style-3'
            }`}
          onClick={_handleSubmit}
        >
          <p className="text-md" style={{ textAlign: 'center' }}>
            Submit for Verification
          </p>
        </div>
      </div>
    </div>
  )
}

export default PreviewAndPayment
