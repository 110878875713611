import { useState } from 'react'
import '../../assets/css/pages/ManageRequest/managerequest.css'

import { ReactComponent as BackIcon } from '../../assets/icon/general/Vector-1-back.svg'

const ManageRequestHead = (props) => {
  const [tab, setTab] = useState(1)
  props.tabSelect(tab)
  return (
    <div className="manage-request">
      <div className="manage-request__back-button">
        <div className="manage-request__back-icon">
          <BackIcon />
        </div>
        <h1 className="manage-request__back-text">Manage Request</h1>
      </div>
      <div className="manage-request__tab-switch-container">
        <div
          onClick={() => setTab(1)}
          className={
            tab === 1
              ? 'manage-request__tab-switch-item-active'
              : 'manage-request__tab-switch-item'
          }
        >
          <h1 className="manage-request__tab-switch-text">Viewing</h1>
        </div>
        <div
          onClick={() => setTab(2)}
          className={
            tab === 2
              ? 'manage-request__tab-switch-item-active'
              : 'manage-request__tab-switch-item'
          }
        >
          <h1 className="manage-request__tab-switch-text">Offer</h1>
        </div>
      </div>
    </div>
  )
}

export default ManageRequestHead
