import React from 'react'
import Button from '../../ui/Button'
import '../../../assets/css/components/LeasePage/Tenant/leaseTenantCurrentOutlet3.css'
import LeaseCurrentBox from './LeaseCurrentBox'
function LeaseTenantCurrentOutlet3() {
	return (
		<div className='current3'>
			<LeaseCurrentBox title="Manage Request" outlet="request" />

			<div className='current3__open'>
				<p className='head__title'>Open</p>

				<div className='open__items'>
					<div className="open__container">
						<div className='open__year'>2022</div>

						<div className='open__wrapper'>
							<div className='open__card'>
								<div className='card__left'>
									<div className='profile'>
										WZ
									</div>
								</div>

								<div className='card__right'>
									<div className='date'>
										<p>28 NOV 2022</p>
										<span></span>
										<p>04:37 AM</p>
									</div>

									<div className='title'>
										<p className='p1'>Need to shorten lease</p>

										<div className='title__info'>
											<div className='label__wrapper label--contract'>
												<div className='label'></div>
												<p>Contract</p>
											</div>

											<div className='label__wrapper label--defects'>
												<div className='label'></div>
												<p>Defects</p>
											</div>
										</div>
									</div>

									<div className='message'>
										<p className='message__title'>LATEST MESSAGE</p>

										<div className='message__card'>
											<div className='left'>
												<div className='left__profile'>
													WZ
												</div>
											</div>

											<div className='right'>
												<div className='right__date'>
													<p>28 NOV 2022</p>
													<span></span>
													<p>04:37 AM</p>
												</div>
												<p className='right__text'>hi, i will be moving overseas to work. can a new tenant take over my existing lease? please ad...</p>
											</div>
										</div>
									</div>

									<div className='actions'>
										<Button directURL="/" buttonName="View Thread" type={2} allow={true} />
										<Button directURL="/" buttonName="Resolve" color="orange" type={2} allow={true} />
									</div>
								</div>
							</div>

							<div className='open__card'>
								<div className='card__left'>
									<div className='profile'>
										WZ
									</div>
								</div>

								<div className='card__right'>
									<div className='date'>
										<p>28 NOV 2022</p>
										<span></span>
										<p>04:37 AM</p>
									</div>

									<div className='title'>
										<p className='p1'>Need to shorten lease</p>

										<div className='title__info'>
											<div className='label__wrapper label--contract'>
												<div className='label'></div>
												<p>Contract</p>
											</div>

											<div className='label__wrapper label--defects'>
												<div className='label'></div>
												<p>Defects</p>
											</div>
										</div>
									</div>

									<div className='message'>
										<p className='message__title'>LATEST MESSAGE</p>

										<div className='message__card'>
											<div className='left'>
												<div className='left__profile'>
													WZ
												</div>
											</div>

											<div className='right'>
												<div className='right__date'>
													<p>28 NOV 2022</p>
													<span></span>
													<p>04:37 AM</p>
												</div>
												<p className='right__text'>hi, i will be moving overseas to work. can a new tenant take over my existing lease? please ad...</p>
											</div>
										</div>
									</div>

									<div className='actions'>
										<Button directURL="/" buttonName="View Thread" type={2} allow={true} />
										<Button directURL="/" buttonName="Resolve" color="orange" type={2} allow={true} />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default LeaseTenantCurrentOutlet3