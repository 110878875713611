import axios from "axios"
import { apiBaseURL } from "../utils"

const getAll = async () => {
  const response = await axios.get(`${apiBaseURL}/furnishings`)
  return response.status == 200 ? response.data : []
}

const furnishingService = {
  getAll
}

export default furnishingService