import '../../assets/css/components/browse/browseSearchHead.css'
import { ReactComponent as CloseIcon } from '../../assets/icon/general/Vector-9-close.svg'
import { ReactComponent as LensLogo } from '../../assets/icon/general/Vector-lens.svg'
import { ReactComponent as BackIcon } from '../../assets/icon/general/Vector-1-back.svg'
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide'

import { useState } from 'react'
import BackButton from '../ui/BackButton'

const BrowseSearchHead = (props) => {
  const [filtered, setFiltered] = useState(true)
  const [tab, setTab] = useState(0)

  props.func(tab)

  return (
    <div className="search-browse">
      <div className="search-head__back-button">
        <div className="search-head__back-icon">
          <BackButton directURL="/browse" />
        </div>
        <h1 className="search-head__back-text">Search By Location</h1>
      </div>
      {/* Search Browse */}
      <div className="search-browse__tab-container">
        <div
          onClick={() => setTab(0)}
          className={
            tab === 0
              ? 'search-head__tab-btn-active'
              : 'search-head__tab-button'
          }
        >
          <h1>MRT</h1>
        </div>
        <div
          onClick={() => setTab(1)}
          className={
            tab === 1
              ? 'search-head__tab-btn-active'
              : 'search-head__tab-button'
          }
        >
          <h1>District</h1>
        </div>
        <div
          onClick={() => setTab(2)}
          className={
            tab === 2
              ? 'search-head__tab-btn-active'
              : 'search-head__tab-button'
          }
        >
          <h1>Postal Code</h1>
        </div>
      </div>
      {/* Search Input Container */}
      {tab === 0 ? (
        <div className='search-slide-browse'>
          <div className="search-browse__input-container">
            <div className="search-head__search-input">
              <div className="search-input__lens">
                <LensLogo />
              </div>

              <input
                className="search-input__input"
                type="text"
                placeholder="Search station name"
              />
            </div>
          </div>
          
          {filtered && (
          <div className="search-browse__icons-container">
            <Splide
              hasTrack={false}
              options={{
                perPage: 2,
                pagination: false,
                arrows: false,
              }}
            >
              <SplideTrack>
                <SplideSlide onClick={() => console.log('hallo')}>
                  <button
                    className={
                      filtered
                        ? 'button__sub-tab--browse button__sub-tab--active'
                        : 'button__sub-tab--browse'
                    }
                  >
                    {filtered && (
                      <div className="sub__wrapp">
                        <span>Hallo</span>
                        <CloseIcon />
                      </div>
                    )}
                  </button>
                </SplideSlide>
                <SplideSlide>
                  <button
                    className={
                      filtered
                        ? 'button__sub-tab--browse button__sub-tab--active'
                        : 'button__sub-tab--browse'
                    }
                  >
                    {filtered && (
                      <div className="sub__wrapp">
                        <span>Hallo</span>
                        <CloseIcon />
                      </div>
                    )}
                  </button>
                </SplideSlide>
                <SplideSlide>
                  <button
                    className={
                      filtered
                        ? 'button__sub-tab--browse button__sub-tab--active'
                        : 'button__sub-tab--browse'
                    }
                  >
                    {filtered && (
                      <div className="sub__wrapp">
                        <span>Hallo</span>
                        <CloseIcon />
                      </div>
                    )}
                  </button>
                </SplideSlide>
                <SplideSlide>
                  <button
                    className={
                      filtered
                        ? 'button__sub-tab--browse button__sub-tab--active'
                        : 'button__sub-tab--browse'
                    }
                  >
                    {filtered && (
                      <div className="sub__wrapp">
                        <span>Hallo</span>
                        <CloseIcon />
                      </div>
                    )}
                  </button>
                </SplideSlide>
                <SplideSlide>
                  <button
                    className={
                      filtered
                        ? 'button__sub-tab--browse button__sub-tab--active'
                        : 'button__sub-tab--browse'
                    }
                  >
                    {filtered && (
                      <div className="sub__wrapp">
                        <span>Hallo</span>
                        <CloseIcon />
                      </div>
                    )}
                  </button>
                </SplideSlide>
                <SplideSlide>
                  <button
                    className={
                      filtered
                        ? 'button__sub-tab--browse button__sub-tab--active'
                        : 'button__sub-tab--browse'
                    }
                  >
                    {filtered && (
                      <div className="sub__wrapp">
                        <span>Hallo</span>
                        <CloseIcon />
                      </div>
                    )}
                  </button>
                </SplideSlide>
                <SplideSlide>
                  <button
                    className={
                      filtered
                        ? 'button__sub-tab--browse button__sub-tab--active'
                        : 'button__sub-tab--browse'
                    }
                  >
                    {filtered && (
                      <div className="sub__wrapp">
                        <span>Hallo</span>
                        <CloseIcon />
                      </div>
                    )}
                  </button>
                </SplideSlide>
              </SplideTrack>
            </Splide>
          </div>)}
        </div>
      ) : (
        ' '
      )}
    </div>
  )
}

export default BrowseSearchHead
