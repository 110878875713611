import React from 'react'
import { ReactComponent as NewLeaseIllus1 } from '../../../assets/illustration/lease/Null-State Shortlist 2.svg';
import { ReactComponent as NewLeaseIllus2 } from '../../../assets/illustration/lease/Null-State Viewing 2.svg';
import { ReactComponent as NewLeaseIllus3 } from '../../../assets/illustration/lease/Null-State Offer 2.svg';
import '../../../assets/css/components/LeasePage/Tenant/leaseTenantNewBoxInfo.css'

function LeaseTenantNewBoxInfo(props) {
	return (
		<div className='box__info'>
			<div className='info__desc'>
				{props.newLease === "shortlist" ? <NewLeaseIllus1 /> : props.newLease === "schedule" ? <NewLeaseIllus2 /> : <NewLeaseIllus3 />}

				<h1>{props.newLease === "shortlist" ? 'No properties shortlisted yet!' : props.newLease === "schedule" ? 'No viewings scheduled yet...' : 'No offers made yet...'}</h1>
				<p>{props.newLease === "shortlist" ? 'To shortlist any listing, simply tap on the “heart” icon. Your shortlisted properties will appear here for easier reference.' : props.newLease === "schedule" ? 'To book a viewing, tap on the “Book Viewing” button. You will be able to select your preferred timeslot from a list of available timings.' : 'To make an offer, tap on the “Make an Offer” button. Then, follow the guided steps to prepare the Letter-of-Intent (LOI) and make a deposit.'}</p>
			</div>
		</div>
	)
}

export default LeaseTenantNewBoxInfo