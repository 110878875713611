import { useState } from 'react'
import { ReactComponent as CardIcon } from '../../../assets/icon/general/Vector-54-card.svg'
import { ReactComponent as BankIcon } from '../../../assets/icon/general/Vector-56-bank.svg'
import Card from './Card'
import BankTransfer from './BankTransfer'
import Paynow from './Paynow'
import PaynowLogo from '../../../assets/images/paynow logo.png'
import PaynowGray from '../../../assets/images/paynow-logo-vector-01 2.png'

const PaymentChoices = () => {
  const [tab, setTab] = useState(0)
  return (
    <div className="offer-body__section-container">
      <h1 className="offer-body__title">Payment Method</h1>
      <div className="offer-body__tab-container">
        <div
          onClick={() => setTab(0)}
          className={
            tab === 0
              ? 'offer-body__tab-selector-active'
              : 'offer-body__tab-selector'
          }
        >
          <CardIcon className="tab-selector__icon" />
          <p>Card</p>
        </div>
        <div
          onClick={() => setTab(1)}
          className={
            tab === 1
              ? 'offer-body__tab-selector-active'
              : 'offer-body__tab-selector'
          }
        >
          <BankIcon className="tab-selector__icon" />
          <p>Bank Transfer</p>
        </div>
        <div
          onClick={() => setTab(2)}
          className={
            tab === 2
              ? 'offer-body__tab-selector-active'
              : 'offer-body__tab-selector'
          }
        >
          {tab === 2 ? (
            <img src={PaynowLogo} alt="Paynow Logo" />
          ) : (
            <img src={PaynowGray} alt="Paynow Logo" />
          )}
        </div>
      </div>
      <div style={{ marginTop: '2rem' }}>
        {tab === 0 ? (
          <Card />
        ) : tab === 1 ? (
          <BankTransfer />
        ) : tab === 2 ? (
          <Paynow />
        ) : (
          'false'
        )}
      </div>
    </div>
  )
}

export default PaymentChoices
