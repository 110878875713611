export const amenities_data_living = [
  {
    id: 'AI' + Math.floor(Math.random() * 10000),
    name: 'Sofa',
  },
  {
    id: 'AI' + Math.floor(Math.random() * 10000),
    name: 'Coffee Table',
  },
  {
    id: 'AI' + Math.floor(Math.random() * 10000),
    name: 'Dining Table Set',
  },
  {
    id: 'AI' + Math.floor(Math.random() * 10000),
    name: 'Wall Fan',
  },
  {
    id: 'AI' + Math.floor(Math.random() * 10000),
    name: 'Standing Fan',
  },
  {
    id: 'AI' + Math.floor(Math.random() * 10000),
    name: 'Ceiling Fan',
  },
  {
    id: 'AI' + Math.floor(Math.random() * 10000),
    name: 'Aircon',
  },
]

export const amenities_data_kitchen = [
  {
    id: 'AI' + Math.floor(Math.random() * 10000),
    name: 'Stove',
  },
  {
    id: 'AI' + Math.floor(Math.random() * 10000),
    name: 'Oven',
  },
  {
    id: 'AI' + Math.floor(Math.random() * 10000),
    name: 'Microwave',
  },
  {
    id: 'AI' + Math.floor(Math.random() * 10000),
    name: 'Dishwasher',
  },
  {
    id: 'AI' + Math.floor(Math.random() * 10000),
    name: 'Washing Machine',
  },
  {
    id: 'AI' + Math.floor(Math.random() * 10000),
    name: 'Dryer',
  },
  {
    id: 'AI' + Math.floor(Math.random() * 10000),
    name: 'Clothes Rack',
  },
]

export const amenities_data_bedroom = [
  {
    id: 'AI' + Math.floor(Math.random() * 10000),
    name: 'Bed',
  },
  {
    id: 'AI' + Math.floor(Math.random() * 10000),
    name: 'Vanity',
  },
  {
    id: 'AI' + Math.floor(Math.random() * 10000),
    name: 'Bedside Table',
  },
  {
    id: 'AI' + Math.floor(Math.random() * 10000),
    name: 'Wall Fan',
  },
  {
    id: 'AI' + Math.floor(Math.random() * 10000),
    name: 'Ceiling Fan',
  },
  {
    id: 'AI' + Math.floor(Math.random() * 10000),
    name: 'Standing Fan',
  },
  {
    id: 'AI' + Math.floor(Math.random() * 10000),
    name: 'Aircon',
  },
  {
    id: 'AI' + Math.floor(Math.random() * 10000),
    name: 'Wardrobe',
  },
]

export const amenities_data_study = [
  {
    id: 'AI' + Math.floor(Math.random() * 10000),
    name: 'Table',
  },
  {
    id: 'AI' + Math.floor(Math.random() * 10000),
    name: 'Chair',
  },
  {
    id: 'AI' + Math.floor(Math.random() * 10000),
    name: 'Cupboard',
  },
  {
    id: 'AI' + Math.floor(Math.random() * 10000),
    name: 'Wall Fan',
  },
  {
    id: 'AI' + Math.floor(Math.random() * 10000),
    name: 'Ceiling Fan',
  },
  {
    id: 'AI' + Math.floor(Math.random() * 10000),
    name: 'Standing Fan',
  },
  {
    id: 'AI' + Math.floor(Math.random() * 10000),
    name: 'Aircon',
  },
]
