import React from 'react'
import { NavLink } from 'react-router-dom'
import '../../assets/css/components/ui/button.css'
import { ReactComponent as ViewIcon } from '../../assets/icon/general/Vector-30-view.svg'

function Button(props) {
  return (
    <>    
      {props.type === 1 ? 
        <NavLink
          to={props.directURL}
          className={`'button button__link' ${
            props.allow ? '' : 'button__link--disabled'
          }`}
        >
          <div className="button__box">
            <p>{props.buttonName}</p>
          </div>
        </NavLink>
        :
        <NavLink
          to={props.directURL}
          className={`'button button__link' ${
            props.allow ? '' : 'button__link--disabled'
          }`}
        >
          <div className={props.color === 'orange' ? "button__box button__box--type2 orange" : "button__box button__box--type2"}>
            <p>
            {props.iconShow && (<span><ViewIcon /></span>)}
            {props.buttonName}
            </p>
          </div>
        </NavLink>
      }
    </>
  )
}

export default Button
