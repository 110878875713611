import React from 'react'
import { ReactComponent as CheckIcon } from '../../assets/icon/general/Vector-42-check.svg';
import { ReactComponent as StartedIllus1 } from '../../assets/illustration/get started/1.svg';
import { ReactComponent as StartedIllus2 } from '../../assets/illustration/get started/2.svg';
import { ReactComponent as StartedIllus3 } from '../../assets/illustration/get started/3.svg';
import '../../assets/css/components/FirstTimePage/signUpIllus.css'

function SignUpIllus({ userType }) {
	if (userType === "user4") return null
	return (
		<div className='signup__features'>
			<div className='signup__features-illus'>
				{userType === "tenant" && (
					<StartedIllus1 />
				)}
				{userType === "landlord" && (
					<StartedIllus2 />
				)}
				{userType === "agent" && (
					<StartedIllus3 />
				)}
			</div>

			{userType === "tenant" && (
				<div className='signup__features-list'>
					<div className='features-list__item'>
						<CheckIcon />
						<p>Unlimited browsing</p>
					</div>

					<div className='features-list__item'>
						<CheckIcon />
						<p>Shortlists</p>
					</div>

					<div className='features-list__item'>
						<CheckIcon />
						<p>Direct chat with landlord</p>
					</div>
				</div>
			)}

			{userType === "landlord" && (
				<div className='signup__features-list'>
					<div className='features-list__item'>
						<CheckIcon />
						<p>Unlimited browsing</p>
					</div>

					<div className='features-list__item'>
						<CheckIcon />
						<p>Transaction insights</p>
					</div>

					<div className='features-list__item'>
						<CheckIcon />
						<p>Direct chat with prospective tenants</p>
					</div>
				</div>
			)}

			{userType === "agent" && (
				<div className='signup__features-list'>
					<div className='features-list__item'>
						<CheckIcon />
						<p>Management of multiple properties</p>
					</div>

					<div className='features-list__item'>
						<CheckIcon />
						<p>Access to P.Lease’s certified services</p>
					</div>
				</div>
			)}
		</div>
	)
}

export default SignUpIllus