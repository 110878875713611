import FloatButton from '../../components/offer/FloatButton'
import FormDummy from '../../assets/images/image 12.png'
import { useNavigate } from 'react-router-dom'
import Modal from '../ui/Modal'
import { useState } from 'react'

const Review = () => {
  const navigate = useNavigate()
  const [isShow, setIsShow] = useState(false)
  const [isShow2, setIsShow2] = useState(false)
  const [isSign, setIsSign] = useState(false)
  const [submitOfferType, setSubmitOfferType] = useState('offerSubmitCC')
  // const modalHandler = () => {
  // 	setIsShow(true)
  // }

  const signOfferHandler = () => {
    setIsSign(true)
    setIsShow(false)
  }

  const signProceedHandler = () => {
    setIsShow(true)
  }

  const submitOfferHandler = (type) => {
    console.log('submit')
    setIsShow2(true)

    setSubmitOfferType(type)
  }
  return (
    <>
      {isShow && (
        <Modal
          show={isShow}
          type="signOffer"
          signOffer={() => signOfferHandler()}
          hide={() => setIsShow(false)}
        />
      )}
      {isShow2 && (
        <Modal
          show={isShow2}
          type={submitOfferType}
          signOffer={() => signOfferHandler()}
          hide={() => setIsShow2(false)}
        />
      )}
      <div className="offer-body">
        <div className="offer-body__content">
          <div className="offer-body__section-container">
            <div className="offer-body__form-container">
              <div className="offer-body__head">
                <h1 className="offer-body__title">Review Tenancy Agreement</h1>
                {isSign && <span>Signed</span>}
              </div>
              <div className="review-offer__form-container">
                <img
                  alt="Dummy Form"
                  className="review-offer__item"
                  src={FormDummy}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <FloatButton text={'Proceed to Sign'} /> */}

      <div className="search-apply">
        <div
          className="search-apply__btn apply-btn"
          onClick={() =>
            !isSign ? signProceedHandler() : submitOfferHandler('TASigned')
          }
        >
          <span>{!isSign ? 'Proceed to Sign' : 'Submit Offer'}</span>
        </div>
      </div>
    </>
  )
}

export default Review
