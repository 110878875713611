import React, { useEffect, useState } from 'react'
import Button from '../../pages/LeasePage/components/Button'
import '../../assets/css/components/LeasePage/leaseCardProperty.css'
import DummyImage from '../../assets/images/image 5.png'
import { ReactComponent as Plus } from '../../assets/icon/general/Vector-plus.svg'
import { ReactComponent as SquareCheckIcon } from '../../assets/icon/general/Vector-7-square-check.svg'
import { ReactComponent as Bed } from '../../assets/icon/general/Vector-4-bed.svg'
import { ReactComponent as PlusIconSquare } from '../../assets/icon/general/Vector-65-plus.svg'
import { ReactComponent as ViewIcon } from '../../assets/icon/general/Vector-30-view.svg'
import { ReactComponent as WormsIcon } from '../../assets/icon/general/Vector-83-worms.svg'
import { ReactComponent as CoinIcon } from '../../assets/icon/general/Vector-21-coin.svg'
import { ReactComponent as BathtubIcon } from '../../assets/icon/general/Vector-85-bathtub.svg'
import { useNavigate } from 'react-router-dom'
import Modal from '../ui/Modal'
import { propertyPackageOptions, propertyStatusOptions, tenantLeaseStatusOption } from '../../utils'
import tenantLeasePropService from '../../services/tenantLeasePropService'

function LeaseCardProperty(props) {
  const navigate = useNavigate()

  const [isShow, setIsShow] = useState(false)
  const [typeModal, setTypeModal] = useState('')
  const [isReview1, setIsReview1] = useState(false)
  const [isReview2, setIsReview2] = useState(false)
  const [isPay, setIsPay] = useState(false)
  const [isHandover, setIsHandover] = useState(true)

  const [leaseData, setLeaseData] = useState(null)

  const [cardStatusClass, setCardStatusClass] = useState('payment')
  const [cardStatusText, setCardStatusText] = useState(propertyStatusOptions[props.data?.status])

  const modalHandler = (type) => {
    console.log(type)
    setTypeModal(type)
    setIsShow(true)
  }

  const payHandler = () => {
    setIsPay(true)
    // setIsShow(false)
  }

  const hide = () => {
    setIsShow(false)
  }

  const hideReview1 = () => {
    setIsShow(false)
    setIsReview1(true)
    console.log('hallo')
  }

  const hideReview2 = () => {
    setIsShow(false)
    console.log('hallo')
    setIsReview2(true)
  }

  const setReview2 = () => {
    setIsReview2(true)
  }

  const handleClickDirect = (url) => {
    navigate(url)
  }

  const fetchLeaseData = async (propertyId) => {
    const response = await tenantLeasePropService.getTenantLeasePropBasedOnPropertyId(propertyId);
    setLeaseData(response)
  }

  useEffect(() => {
    if(props.data) {
      fetchLeaseData(props.data?.id)
    }
  }, [])
  

  useEffect(() => {
    if(leaseData) {
      console.log(`${props.data.id} Lease Data: `, leaseData);

      const leaseOfferStatus = leaseData?.offer_status

      if(leaseOfferStatus !== 3) {
        setCardStatusText(`Offer ${tenantLeaseStatusOption[leaseOfferStatus]}`)
  
        switch(leaseOfferStatus) {
          case 1:
            setCardStatusClass('acceptance')
            break;
          case 2:
            setCardStatusClass('accept')
        }
      }
    }
  }, [leaseData])
  

  return (
    <div className="lease-unit-card">
      {isShow && (
        <Modal
          type={typeModal}
          show={isShow}
          pay={payHandler}
          hide={hide}
          hideReview1={hideReview1}
          hideReview2={hideReview2}
          setReview2={setReview2}
        />
      )}

      <div className="lease-unit-card_information">
        <div className="lease-unit-card_information_image">
          <img
            src={props.photo?.asset_location ?? 'https://placehold.co/500'}
            alt="unit"
          />
        </div>

        <div className="lease-unit-card_information_details">
          <div className="information_details_wrapper">
            {props.cardLeaseType !== 'summaryLeased' && (
              // status : payment, signature, acceptance, accept
              <div className={`lease-unit-card_status lease-unit-card_status--${cardStatusClass}`}>
                <div className="status-bar"></div>
                {/* TODO: Status Color */}
                <p>{cardStatusText}</p>
              </div>
            )}

            {props.cardLeaseType !== 'tenancyAgreement' && (
              <p className="p1">
                {propertyPackageOptions[props.data?.property_package]}
              </p>
            )}

            <p className="p2">{props.data?.address}</p>
            <p className="p3">
              <span className="">S$</span>{' '}
              <span className="">{props.data?.monthly_rental}</span>
            </p>
          </div>

          <div className="information-details-specs">
            <div className="details-specs-facilities">
              <div className="facilities-item">
                <p>{props.data?.number_of_beds}</p>
                <Bed />
              </div>
              <div className="facilities-item">
                <p>{props.data?.number_of_baths}</p>

                <div className="item__bathtub">
                  <BathtubIcon />
                </div>
              </div>
            </div>

            <div className="details-specs__sqft">
              <p className="p1">
                {props.data?.floor_area} sqft/{' '}
                {Number(props.data?.floor_area / 10.764).toFixed(2)} sqm
              </p>
            </div>
          </div>
        </div>
      </div>

      {leaseData?.offer_status === 2 && (
        <div>
          <div className="lease-unit-card__offer-summary">
            <div className="offer-summary__head">
              <PlusIconSquare />
              <p>OFFER SUMMARY</p>
            </div>

            <div className="offer-summary__accepted">
              <div>
                <p>OFFER BY</p>
                <p>{leaseData?.user_profile_tenant_id}</p>
              </div>
              <div>
                <p>OFFER ACCEPTED ON</p>
                <p>{leaseData?.created_date}</p>
              </div>
            </div>

            <div className="offer-summary__date">
              <div>
                <p>LEASE DURATION</p>
                <p>-</p>
              </div>
              <div>
                <p>LEASE START</p>
                <p>{leaseData?.start_date_of_lease}</p>
              </div>
              <div>
                <p>LEASE END</p>
                <p>-</p>
              </div>
            </div>
          </div>

          <div className="lease-unit-card__button">
            {!isReview1 ? (
              <button
                onClick={() => modalHandler('review1')}
                className="button__style-5 button__style-5--active"
              >
                <SquareCheckIcon />
                <p>Review Tenancy Agreement</p>
              </button>
            ) : (
              <button
                onClick={() => modalHandler('review2')}
                className={
                  isReview2
                    ? 'button__style-5 button__style-5--success'
                    : 'button__style-5 button__style-5--active'
                }
              >
                <ViewIcon />
                <p>Review Tenancy Agreement</p>
              </button>
            )}

            <button
              onClick={() => modalHandler('sign')}
              className={
                isReview2
                  ? `button__style-5 ${isPay
                    ? ' button__style-5--success'
                    : 'button__style-5--active'
                  }`
                  : 'button__style-5'
              }
            >
              <WormsIcon />
              <p>Sign Tenancy Agreement</p>
            </button>
            <button
              onClick={() => handleClickDirect('/payment')}
              className={
                isPay
                  ? 'button__style-5 button__style-5--active'
                  : 'button__style-5'
              }
            >
              <CoinIcon />
              <p>Pay Security Deposit & Stamp Duty</p>
            </button>
          </div>
        </div>
      )}

      {props.cardLeaseType === 'summaryLeased' && (
        <div>
          <div className="lease-unit-card__offer-summary">
            <div className="offer-summary__head">
              <PlusIconSquare />
              <p>LEASE STATS</p>
            </div>

            <div className="offer-summary__accepted">
              <div>
                <p>LEASE DURATION</p>
                <p>12 mths</p>
              </div>
              <div>
                <p>LEASE START</p>
                <p>6 Dec 2022</p>
              </div>
              <div>
                <p>LEASE END</p>
                <p>12 Dec 2023</p>
              </div>
            </div>

            <div className="offer-summary__date">
              <div>
                <p>MONTHLY RENT</p>
                <p>
                  <span className="unit-currency">SGD</span> $3,100
                </p>
              </div>
              <div>
                <p>MINOR REPAIR</p>
                <p>
                  <span className="unit-currency">SGD</span> $150
                </p>
              </div>
              <div>
                <p>SECURITY DEPOSIT</p>
                <p>
                  <span className="unit-currency">SGD</span> $3,100
                </p>
              </div>
            </div>

            <div className="offer-summary__date">
              <div>
                <p>PAYMENT DATE</p>
                <p>14th of the month</p>
              </div>
              <div>
                <p>TOTAL RENT COLLECTED</p>
                <p>
                  <span className="unit-currency">SGD</span> $0
                </p>
              </div>
            </div>
          </div>

          {isHandover && (
            <>
              <div className="lease-unit-card-button lease-unit-card-button--sumleas">
                <Button
                  buttonName="Manage listing"
                  directURL="/lease/manage-listing"
                  type="secondary"
                />
                <Button
                  buttonName="Get Services"
                  directURL="/lease/services"
                  type="secondary"
                />
              </div>

              <div className="lease-unit-card__button">
                <button onClick={() => handleClickDirect('/pre-condition')} className="button__style-5 button__style-5--active">
                  <p>Accept pre-condition photos</p>
                </button>

                <button className="button__style-5 button__style-5--normal">
                  <p>View updated inventory list</p>
                </button>
              </div>
            </>
          )}
        </div>
      )}

      {!leaseData && (
        <div className="lease-unit-card-button">
          <Button
            buttonName="Manage listing"
            directURL={`/lease/manage-listing/${props.data?.id}`}
            type="secondary"
          />
          <Button
            buttonName="Get Services"
            directURL="/lease/services"
            type="secondary"
          />
        </div>
      )}
    </div>
  )
}

export default LeaseCardProperty
