import { useState } from 'react'
import '../../assets/css/pages/OfferPage/offer.css'

import { ReactComponent as BackIcon } from '../../assets/icon/general/Vector-1-back.svg'
import HeadBack from '../ui/HeadBack'
import BackButton from '../ui/BackButton'

const ReviewTAHEad = (props) => {
  return (
    <div className="offer-head">
      <div className="offer-head__back-button">
        <div className="offer-head__back-icon">
          {/* <BackIcon /> */}
          <BackButton directURL="/browse/tenant-detail" />
        </div>
        <h1 className="offer-head__back-text">Manage Offer</h1>
        {/* <HeadBack title="Make an Offer" backLink="/browse/tenant-detail" /> */}
      </div>
    </div>
  )
}

export default ReviewTAHEad
