export const AGGREEMENT_POINT = [
  {
    id: 1,
    poin: 1,
  },
  {
    id: 2.1,
    poin: 2.1,
  },
  {
    id: 2.2,
    poin: 2.2,
  },
  {
    id: 2.3,
    poin: 2.3,
  },
  {
    id: 3.1,
    poin: 3.1,
  },
  {
    id: 3.2,
    poin: 3.2,
  },
  {
    id: 3.3,
    poin: 3.3,
  },
  {
    id: 4.1,
    poin: 4.1,
  },
  {
    id: 4.2,
    poin: 4.2,
  },
  {
    id: 4.3,
    poin: 4.3,
  },
]

export const RENT_AGREEMENT = [
  {
    name: 'Monthly Rent',
    value: 'S$3,600',
  },
  {
    name: 'Payment Schedule',
    value: 'Every 22nd',
  },
  {
    name: 'Payment Period',
    value: '24 months',
  },
  {
    name: 'First Payment',
    value: '22 Jan 2023',
  },
]

export const SECURITY_DEPOSIT_AGREEMENT = [
  {
    name: 'Deposit',
    value: 'S$7,200',
  },
  {
    name: 'Equivalent To',
    value: '2 months',
  },
  {
    name: 'Refund Period',
    value: 'Within 14 days',
  },
]
