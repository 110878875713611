import React, { useState } from 'react'
import { ReactComponent as Up } from '../../../assets/icon/general/Vector-13-chevron-up.svg'
import { ReactComponent as Down } from '../../../assets/icon/general/Vector-14-chevron-down.svg'
import DummyImage from '../../../assets/images/image 5.png'
import '../../../assets/css/components/LeasePage/accordionItem.css'
const AccordionItem = (props) => {
  const [isActive, setIsActive] = useState(props.active)

  return (
    <div className={isActive ? "accordion-item accordion-item--active" : "accordion-item"}>
      <div className="accordion-title" onClick={() => setIsActive(!isActive)}>
        <div>
          <p className="text-lg bold">{props.title}</p>
        </div>
        <div>
          {isActive ? 
            <svg className='svg__up' width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.933333 5.86667L0 4.93333L5.00004 0L10 4.93333L9.06667 5.86667L5.00004 1.86667L0.933333 5.86667Z" fill="white"/>
            </svg>
            : 
            <svg className='svg__down' width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9.06667 0.133334L10 1.06667L4.99996 6L4.31286e-07 1.06667L0.933334 0.133333L4.99996 4.13333L9.06667 0.133334Z" fill="white"/>
            </svg>
          }
        </div>
      </div>

      {/* {isActive && (
        <div className="accordion-content">
				  {props.content.map(({ item }) => (
            <div className="content__wrapper">
              {props.type === "currentLeaseDetails" && (
                <span className='content__title'>{item.title}</span>
              )}
              {props.type != "currentLeaseDetails" && (
                <p className="text-sm normal content__text">{props.content}</p>
              )}
              {props.type === "currentLeaseDetails" && (
                <ul className='content__list'>
                  {item.list.map(({ itemList }) => (
                    <li>{itemList}</li>
                  ))}
                </ul>
              )}
            </div>
          ))}
        </div>
      )} */}

      {isActive && (
        <div className={`accordion-content ${props.type === "precon" && "accordion-content-nogap"}`}>
          <div className="content__wrapper">
            {props.type === "currentLeaseDetails" && (
              <span className='content__title'>PAYMENT</span>
            )}
            {props.type != "currentLeaseDetails" && (
              <p className="text-sm normal content__text">{props.content}</p>
            )}
            {props.type === "currentLeaseDetails" && (
              <ul className='content__list'>
                  <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptate impedit exercitationem aliquid cumque iure eum esse sed. Consectetur, deleniti possimus!</li>
                  <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptate impedit exercitationem aliquid cumque iure eum esse sed. Consectetur, deleniti possimus!</li>
              </ul>
            )}
          </div>

          {props.type === "precon" && (
            <div className="content__wrapper content__wrapper-precon">
              <div className="precon-images">
                <div className="precon-image">
                  <img src={'/'} alt="" />
                </div>

                <div className="precon-image">
                  <img src={DummyImage} alt="" />
                </div>
                
                <div className="precon-image">
                  <img src={DummyImage} alt="" />
                </div>

                <div className="precon-image">
                  <img src={DummyImage} alt="" />
                </div>
              </div>
            </div>  
          )}

          <div className="content__wrapper">
            {props.type === "currentLeaseDetails" && (
              <span className='content__title'>PAYMENT</span>
            )}
            {props.type != "currentLeaseDetails" && (
              <p className="text-sm normal content__text">{props.content}</p>
            )}
            {props.type === "currentLeaseDetails" && (
              <ul className='content__list'>
                  <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptate impedit exercitationem aliquid cumque iure eum esse sed. Consectetur, deleniti possimus!</li>
                  <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptate impedit exercitationem aliquid cumque iure eum esse sed. Consectetur, deleniti possimus!</li>
              </ul>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default AccordionItem
