import axios from 'axios'
import { apiBaseURL } from '../utils'

const uploadPhoto = async (photo) => {
  let formData = new FormData()
  formData.append('file', photo)
  const response = axios.post(`${apiBaseURL}/property_photos/media`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })

  return response
}

const createPropertyPhoto = async (id, url, type) => {
  let data = JSON.stringify({
    type: `${type}`,
    asset_location: `${url}`,
    property_id: `${id}`,
  })

  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: `${apiBaseURL}/property_photos`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: data,
  }

  axios
    .request(config)
    .then((response) => {
      console.log('Property Photo', JSON.stringify(response.data))
    })
    .catch((error) => {
      console.log(error)
    })
}

const propertyPhotoServices = {
  uploadPhoto,
  createPropertyPhoto,
}

export default propertyPhotoServices
