import axios from 'axios'
import { apiBaseURL } from '../utils'
import authService from './AuthService'

const getOneFromUser = async (id) => {
  const response = await axios.get(`${apiBaseURL}/users/${id}/user_profiles`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem(authService._tokenKey)}`,
      'x-access-token': `${localStorage.getItem(authService._tokenKey)}`,
    },
  })
  return response.data[0]
}

const profileService = {
  getOneFromUser,
}

export default profileService
