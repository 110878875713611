import React, { useState } from 'react'
import { ReactComponent as DropIcon } from '../../assets/icon/general/Vector-14-chevron-down.svg'
import { ReactComponent as UpIcon } from '../../assets/icon/general/Vector-13-chevron-up.svg'

import { ReactComponent as CheckIcon } from '../../assets/icon/general/Vector-42-check.svg'

function AccordionBrowse(props) {
  const [accordion, setAccordion] = useState(false)
  const [value, setValue] = useState(false)
  props.value(value)

  const toggle = (i) => {
    // console.log(i)
    // if (select === i) {
    //   return setSelect(null)
    // }

    // setSelect(i)

    setAccordion(!accordion)
  }
  const autoBg = (color) => ({
    backgroundColor: color,
  })
  return (
    <div
      // className={
      //   select === i
      //     ? 'search-body__accordion-yellow'
      //     : 'search-body__accordion'
      // }

      className={
        accordion ? 'search-body__accordion-yellow' : 'search-body__accordion'
      }
      key={props.accData.id}
    >
      <div className="search-body__accordion-title" onClick={() => toggle()}>
        <div className="search_accordion__cover">
          <div
            className="search-accordion__code"
            style={props.accData.clr !== null ? autoBg(props.accData.clr) : ''}
          >
            <h1 className="search-accordion__line-code">{props.accData.lne}</h1>
          </div>
          <h1
            // className={
            //   select === i
            //     ? 'search-accordion__line-name-yellow'
            //     : 'search-accordion__line-name'
            // }

            className={
              accordion
                ? 'search-accordion__line-name-yellow'
                : 'search-accordion__line-name'
            }
          >
            {props.accData.line}
          </h1>
        </div>
        {/* {select === i ? (
                <UpIcon className="drop-icon__yellow" />
              ) : (
                <DropIcon style={style(props.accData.id)} className="drop-icon" />
              )} */}

        {accordion ? (
          <UpIcon className="drop-icon__yellow" />
        ) : (
          // <DropIcon style={style(props.accData.id)} className="drop-icon" />
          <DropIcon className="drop-icon" />
        )}
      </div>
      <div
        // className={
        //   select === i ? 'search-accordion__station-checkboxes' : 'hide'
        // }

        className={accordion ? 'search-accordion__station-checkboxes' : 'hide'}
      >
        {props.accData.station.map((station) => (
          /* Station list per accordions */
          <div className="search-accordion__checkboxes-container browse-input--checkbox browse-input--checkbox_normal">
            <label
              htmlFor={station}
              className="station__label"
              onClick={() => setValue(true)}
            >
              <input type="checkbox" name="save" id={station} value={station} />
              <div className="amenities-label station__name">{station}</div>
              <span className="checkbox-mark">
                <CheckIcon />
              </span>
              <div className="station__line-container">
                <span
                  className="station__line-color"
                  /*colors for station line */
                  style={{
                    backgroundColor: '#F99D25',
                  }}
                ></span>
                <span
                  className="station__line-color"
                  /*colors for station line */
                  style={{
                    backgroundColor: '#E22726',
                  }}
                ></span>
                <span
                  className="station__line-color"
                  /*colors for station line */
                  style={{
                    backgroundColor: '#8F4199',
                  }}
                ></span>
              </div>
            </label>
            {/* checkboxes for station lists */}
          </div>
        ))}
      </div>
    </div>
  )
}

export default AccordionBrowse
