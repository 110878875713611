import axios from 'axios'
import { apiBaseURL } from '../utils'
import authService from './AuthService'

const getOneFromProfile = async (id) => {
  const res = await axios.get(`${apiBaseURL}/user_profiles/${id}`)
  const response = await axios.get(`${apiBaseURL}/users/${res.data.user_id}`)
  return response.data
}

/**
 * Create User Profile
 * @param object data -> {user_id, user_type}
 */
const createUserProfile = async (data) => {
  const response = await axios.post(`${apiBaseURL}/user_profiles`, data)

  if (response.status !== 200)
    return { data: null, message: response.data.message }
  if (response.status === 200) {
    return {
      data: response.data,
      message: response.data.message,
    }
  }
}

const getOneFromId = async (id) => {
  const response = await axios.get(`${apiBaseURL}/users/${id}`)
  return response.data
}

const updateOneUser = async (id, data) => {
  let config = {
    method: 'patch',
    maxBodyLength: Infinity,
    url: `${apiBaseURL}/users/${id}`,
    headers: {
      'x-access-token': `${localStorage.getItem(authService._tokenKey)}`,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem(authService._tokenKey)}`,
    },
    data: JSON.stringify(data),
  }

  console.log('config', config)
  axios
    .request(config)
    .then((response) => {
      console.log(JSON.stringify(response.data))
    })
    .catch((error) => {
      console.log(error)
    })
}

const createUserPhotos = async (id, asset, type) => {
  let data = JSON.stringify({
    asset_location: `${asset}`,
    user_id: `${id}`,
    document_type: type,
  })

  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: `${apiBaseURL}/user_photos`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: data,
  }

  axios
    .request(config)
    .then((response) => {
      console.log(JSON.stringify(response.data))
    })
    .catch((error) => {
      console.log(error)
    })
}

const getUserPhoto = async (id) => {
  const response = await axios.get(`${apiBaseURL}/users/${id}/user_photos`)
  return response.data
}

const userServices = {
  getOneFromProfile,
  createUserProfile,
  getOneFromId,
  updateOneUser,
  createUserPhotos,
  getUserPhoto,
}

export default userServices
