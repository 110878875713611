import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as Up } from '../../../assets/icon/general/Vector-13-chevron-up.svg'
import { ReactComponent as Down } from '../../../assets/icon/general/Vector-14-chevron-down.svg'
import { ReactComponent as PencilIcon } from '../../../assets/icon/general/Vector-49-pencil.svg'
import { ReactComponent as CameraIcon } from '../../../assets/icon/general/Vector-57-camera.svg'
import { ReactComponent as CheckIcon } from '../../../assets/icon/general/Vector-42-check.svg'
import { ReactComponent as PlusIcon } from '../../../assets/icon/general/Vector-plus.svg'
import { ReactComponent as TrashIcon } from '../../../assets/icon/general/Vector-81-trash.svg'
import { ReactComponent as CloseIcon } from '../../../assets/icon/general/Vector-9-close.svg'
import { ReactComponent as Upload } from '../../../assets/icon/general/Vector-71-upload.svg'

import DummyImage from '../../../assets/images/image 5.png'
import '../../../assets/css/components/LeasePage/accordionItem.css'
import Modal from '../../../components/ui/Modal'

const checkData = [
  { htmlFor: 'selectALl', label: 'Select All' },
  { htmlFor: 'television', label: 'Television' },
  { htmlFor: 'diningTableSet', label: 'Dining Table Set' },
  { htmlFor: 'ceilingFan', label: 'Ceiling Fan' },
]

const AccordionActions = (props) => {
  const [isActive, setIsActive] = useState(props.active)
  const [isDisabled, setIsDisabled] = useState(false)
  const [isAddToInv, setIsAddToInv] = useState(false)
  const [files, setFiles] = useState([])
  const navigate = useNavigate()

  const handleClickDirect = (url) => {
    navigate(url)
  }

  const addInvenHandler = () => {
    setIsAddToInv(true)
  }

  const handleUpload = (event) => {
    const selectedFiles = event.target.files
    setFiles([...files, ...selectedFiles])
  }

  const handleRemove = (index) => {
    const newFiles = [...files]
    newFiles.splice(index, 1)
    setFiles(newFiles)
  }

  const buttonTypeHandler = () => {
    if (props.btnType === 'updateInventory') {
      return (
        <button
          onClick={() => handleClickDirect('/inventory-list')}
          className={`button__style-5 button__style-5-disabled ${
            !isDisabled && 'button__style-5--active'
          }`}
        >
          <PencilIcon />
          <p>View updated inventory list</p>
        </button>
      )
    } else if (props.btnType === 'takePhoto') {
      return (
        <button
          onClick={() => handleClickDirect('/pre-condition')}
          className={`button__style-5 button__style-5-disabled ${
            !isDisabled && 'button__style-5--active'
          }`}
        >
          <CameraIcon />
          <p>Take pre-condition photo(s)</p>
        </button>
      )
    } else if (props.btnType === 'addInventory') {
      return (
        <div className="addInv">
          <div className="addInv-check">
            {checkData.map((item, index) => (
              <div
                className="form-input form-input--checkbox form-input--checkbox_normal"
                key={index}
              >
                <label htmlFor={item.htmlFor}>
                  <input
                    type="checkbox"
                    name="save"
                    id={item.htmlFor}
                    value={item.label}
                  />

                  <div className="amenities-label">{item?.label}</div>
                  <span className="checkbox-mark checkbox-mark-addInv">
                    <CheckIcon />
                  </span>
                </label>
              </div>
            ))}
          </div>

          <div className="addInv-actions">
            <button
              onClick={() => handleClickDirect('/pre-condition')}
              className={`button__style-5 button__style-5-disabled ${
                isDisabled ? 'button__style-5--normal' : ''
              }`}
            >
              <TrashIcon />
              <p>Delete</p>
            </button>

            <button
              onClick={addInvenHandler}
              className={`button__style-5 button__style-5--normal`}
            >
              <PlusIcon />
              <p>Add</p>
            </button>
          </div>

          <div className="addInv-verif">
            <button
              onClick={() => handleClickDirect('/pre-condition')}
              className={`button__style-5 button__style-5-disabled ${
                isDisabled ? 'button__style-5--normal' : ''
              }`}
            >
              <CheckIcon />
              <p>Verify to be in acceptable condition</p>
            </button>
          </div>
        </div>
      )
    } else if (props.btnType === 'addPrecon') {
      return (
        <>
          <p className="title-up-precon">
            You may upload multiple files. Max size per file is 10 MB. Supported
            file types: .jpg, .png, .pdf
          </p>
          <div className="onboarding__outlet-2-photo">
            <div className="onboarding__outlet-2-file">
              <input
                type="file"
                id="propertyPhoto"
                accept="image/*,.pdf"
                multiple
                onChange={handleUpload}
              />
              <label htmlFor="propertyPhoto">
                <Upload /> <span>Upload File</span>
              </label>
            </div>
            <div
              className={`onboarding__outlet-2-file-preview ${
                files.length === 0 && 'onboarding__outlet-2-file-preview--hide'
              }`}
            >
              {files.map((file, index) => (
                <div className="image__preview" key={index}>
                  <div
                    className="image__preview-delete"
                    onClick={() => handleRemove(index)}
                  >
                    <CloseIcon />
                  </div>
                  <img src={URL.createObjectURL(file)} alt="file" />
                </div>
              ))}
            </div>
          </div>
        </>
      )
    } else {
      return ''
    }
  }

  return (
    <div
      className={
        isActive
          ? `accordion-item accordion-item--handover ${
              props.type === 'invlist'
                ? 'accordion-item--active'
                : 'accordion-item--active1'
            }`
          : 'accordion-item accordion-item--handover'
      }
    >
      {isAddToInv && (
        <Modal
          show={isAddToInv}
          type="addInventory"
          hide={() => setIsAddToInv(false)}
        />
      )}

      <div className="accordion-title" onClick={() => setIsActive(!isActive)}>
        <div className="accordion-title--wrap">
          <p className="text-lg bold">{props.title}</p>
        </div>
        <div>
          {isActive ? (
            <svg
              className="svg__up"
              width="10"
              height="6"
              viewBox="0 0 10 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.933333 5.86667L0 4.93333L5.00004 0L10 4.93333L9.06667 5.86667L5.00004 1.86667L0.933333 5.86667Z"
                fill="white"
              />
            </svg>
          ) : (
            <svg
              className="svg__down"
              width="10"
              height="6"
              viewBox="0 0 10 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.06667 0.133334L10 1.06667L4.99996 6L4.31286e-07 1.06667L0.933334 0.133333L4.99996 4.13333L9.06667 0.133334Z"
                fill="white"
              />
            </svg>
          )}
        </div>
      </div>

      {isActive && (
        <div
          className={`accordion-content ${
            props.type === 'handoc' ||
            (props.type === 'addInventory' && 'accordion-content-nogap')
          }`}
        >
          <div className="content__wrapper">{buttonTypeHandler()}</div>
        </div>
      )}
    </div>
  )
}

export default AccordionActions
