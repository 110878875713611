import React, { useState } from 'react'
import '../../../assets/css/pages/LeasePage/listNewProperty.css'

import { ReactComponent as Illustration } from '../../../assets/illustration/package/User interface_Monochromatic 1.svg'
import { ReactComponent as Illustration2 } from '../../../assets/illustration/package/Team Work_Monochromatic 1.svg'
import { ReactComponent as Illustration3 } from '../../../assets/illustration/package/Analytics team_Monochromatic 1.svg'
import { ReactComponent as Check } from '../../../assets/icon/general/Vector-42-check.svg'
// import Button from '../../ui/Button'
import Button from '../../../pages/LeasePage/components/Button'
import PackageCard from './Package/PackageCard'

const SelectPackage = ({setOutletActive, setPackageSelected}) => {
  const [userSelect, setUserSelect] = useState('tenant')

  const featuresItem = [
    'Photo and video uploads',
    'Listing performance insights',
    `P.Lease's certified services`,
  ]

  const _handleSelectPackageButtonOnClick = () => {
    if(userSelect) setOutletActive(1)
  }

  const premiumItem = [
    'Professional photo-taking of property',
    'Uploading of property details',
    'Onsite / Remote viewing',
    '1st week of featured listing free',
  ]

  const premiumPlusItem = [
    'Everything in the premium plan',
    '1-time 4-hour deep cleaning free',
    'Professional virtual tour recording',
    '1 additional week of featured listing free',
  ]
  return (
    <div>
      <div className="package-options-card">
        {/* Card */}
        <div
          className={`${
            userSelect === 'tenant'
              ? 'package-card-container pick__active'
              : 'package-card-container'
          }`}
          onClick={() => {
            setUserSelect('tenant')
            setPackageSelected(1)
          }}
        >
          <div className="package-card-illustration">
            <Illustration />
          </div>

          <div className="package-card-details">
            <div>
              <div
                className={`${
                  userSelect === 'tenant'
                    ? 'package-card-details__title card-title-selected'
                    : 'package-card-details__title'
                }`}
              >
                <p>DIY BASIC</p>
              </div>
              <p className="package-card-details_desc">
                For savvy landlords who enjoy dealing with prospective tenants
              </p>
            </div>
            <div className="package-card-details_advantage">
              <p>50%</p>
              <p className="text-md">of 1 month's rent</p>
            </div>
          </div>

          <div className="package-card-features-container">
            {featuresItem.map((item, index) => (
              <div className="package-card-features_item" key={index}>
                <div>
                  <Check />
                </div>
                <p>{item}</p>
              </div>
            ))}
          </div>

          <div className="package-card-button">
            <Button
              buttonName="View full terms & conditions"
              type="secondary"
            />
          </div>
        </div>
        <div
          className={`${
            userSelect === 'landlord'
              ? 'package-card-container pick__active'
              : 'package-card-container'
          }`}
          onClick={() => {
            setUserSelect('landlord')
            setPackageSelected(2)
          }}
        >
          <div className="package-card-illustration">
            <Illustration2 />
          </div>

          <div className="package-card-details">
            <div>
              <div
                className={`${
                  userSelect === 'landlord'
                    ? 'package-card-details__title card-title-selected'
                    : 'package-card-details__title'
                }`}
              >
                <p>PREMIUM</p>
              </div>
              <p className="package-card-details_desc">
                For landlords who want a fuss-free leasing experience
              </p>
            </div>
            <div className="package-card-details_advantage">
              <p>100%</p>
              <p className="text-md">of 1 month's rent</p>
            </div>
          </div>

          <div className="package-card-features-container">
            <div className="package-card-features-container_title">
              <p>HANDLED BY P.LEASE </p>
            </div>

            {premiumItem.map((item, index) => (
              <div className="package-card-features_item" key={index}>
                <div>
                  <Check />
                </div>
                <p>{item}</p>
              </div>
            ))}
          </div>

          <div className="package-card-button">
            <Button
              buttonName="View full terms & conditions"
              type="secondary"
            />
          </div>

          <div className="packagae-card-label">
            <p>Most Popular</p>
          </div>
        </div>

        <div
          className={`${
            userSelect === 'agent'
              ? 'package-card-container pick__active'
              : 'package-card-container'
          }`}
          onClick={() => {
            setUserSelect('agent')
            setPackageSelected(3)
          }}
        >
          <div className="package-card-illustration">
            <Illustration3 />
          </div>

          <div className="package-card-details">
            <div>
              <div
                className={`${
                  userSelect === 'agent'
                    ? 'package-card-details__title card-title-selected'
                    : 'package-card-details__title'
                }`}
              >
                <p>PREMIUM+</p>
              </div>
              <p className="package-card-details_desc">
                For time-sensitive landlords who want to seal the deal quickly
              </p>
            </div>
            <div className="package-card-details_advantage">
              <p>120%</p>
              <p className="text-md">of 1 month's rent</p>
            </div>
          </div>

          <div className="package-card-features-container">
            <div className="package-card-features-container_title">
              <p>HANDLED BY P.LEASE </p>
            </div>

            {premiumPlusItem.map((item, index) => (
              <div className="package-card-features_item" key={index}>
                <div>
                  <Check />
                </div>
                <p>{item}</p>
              </div>
            ))}
          </div>

          <div className="package-card-button">
            <Button
              buttonName="View full terms & conditions"
              type="secondary"
            />
          </div>
        </div>
      </div>
      <div className="onboarding__button" onClick={() => _handleSelectPackageButtonOnClick()}>
        <div
          className={`${
            userSelect
              ? 'button__style-3 button__style-3--active'
              : 'button__style-3'
          }`}
        >
          <p className="text-md" style={{ textAlign: 'center' }}>
            Select Package
          </p>
        </div>
      </div>
    </div>
  )
}

export default SelectPackage
