import React, { useEffect, useState } from 'react'
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide'
import '../../../assets/css/components/browse/Tenant/tenantDetailsOutlet0.css'
import { ReactComponent as Share } from '../../../assets/icon/general/Vector-60-share.svg'
import { ReactComponent as Love } from '../../../assets/icon/general/Vector-15-love.svg'
import { ReactComponent as Pin } from '../../../assets/icon/general/Vector-20-pin.svg'
import { ReactComponent as Bed } from '../../../assets/icon/general/Vector-4-bed.svg'
import { ReactComponent as BathIcon } from '../../../assets/icon/general/Vector-85-bathtub.svg'
import { ReactComponent as Area } from '../../../assets/icon/general/Vector-3-clipboard.svg'
import { ReactComponent as Eye } from '../../../assets/icon/general/Vector-30-eye.svg'
import { ReactComponent as Plus } from '../../../assets/icon/general/Vector-plus.svg'
import { ReactComponent as House } from '../../../assets/icon/general/Vector-17-house.svg'
import { ReactComponent as Piechart } from '../../../assets/icon/general/Vector-84-piechart.svg'
import { ReactComponent as Layer } from '../../../assets/icon/general/Vector-19-layer.svg'
import { ReactComponent as Furniture } from '../../../assets/icon/general/Vector-11-furniture.svg'
import { ReactComponent as CalendarDot } from '../../../assets/icon/general/Vector-12-calendardoted.svg'
import { ReactComponent as Calendar } from '../../../assets/icon/general/Vector-5-calendar.svg'
import { ReactComponent as Star } from '../../../assets/icon/general/Vector-47-star-highlight.svg'
import { ReactComponent as ChevronDown } from '../../../assets/icon/general/Vector-14-chevron-down.svg'
import AccordionItem from '../../../pages/LeasePage/components/AccordionItem'
import AmenitiesItem from './AmenitiesItem'
import {
  apiBaseURL,
  floorOptions,
  furnishingLoadOptions,
  propertyRentalTypeOptions,
} from '../../../utils'
import moment from 'moment'
import axios from 'axios'
import userServices from '../../../services/userService'
import authService from '../../../services/AuthService'
import propertyLikedService from '../../../services/propertyLikedService'

function TenantDetailsOutlet0(props) {
  const [propertyCategory, setPropertyCategory] = useState({})
  const [landlordData, setLandlordData] = useState({})
  const [liked, setLiked] = useState(false)
  const [likedID, setLikedID] = useState('')
  const [likedProperties, setLikedProperties] = useState([])

  const getProp = async () => {
    const response = await propertyLikedService.getOneFromUserId(localStorage.getItem(authService._userIdKey))
    setLikedProperties(response)
  }

  const createPropertyLike = async (prop_id, user_id) => {
    const liked = await propertyLikedService.createOne(prop_id, user_id)
    if (liked.data.property_id === props.detailData.id) {
      setLikedID(liked.id)
      setLiked(true)
      getProp()
    }
  }

  const deletePropertyLike = async (id) => {
    const response = await propertyLikedService.deleteOne(id)
    await getProp()
    console.log(response.status)
    if(response.status === 200){
      console.log("inide if")
      setLikedID('')
      setLiked(false)
      // console.log("flag", liked)
    }
  }

  const getData = async () => {
    const propertyCategoryResponse = await axios.get(
      `${apiBaseURL}/property_categories/${props.detailData.property_category_id}`
    )
    const landlordResponse = await userServices.getOneFromProfile(
      props.detailData.user_profile_landlord_id
    )

    setPropertyCategory(propertyCategoryResponse.data)
    setLandlordData(landlordResponse)
  }

  const handleLike = () => {
    try {
      console.log("handle Like")
      createPropertyLike(props.detailData.id, localStorage.getItem(authService._userIdKey))
    } catch (error) {
      console.log("error", error)
    }
  }

  const handleUnLike = () => {
    try {
      console.log("Handle UnLike")
      deletePropertyLike(likedID)
    } catch (error) {
      
    }
  }
  useEffect(() => {
    getProp()
  }, [])

  useEffect(() => {
    likedProperties.map((prop) => {
      if(prop.property_id === props.detailData.id){
        console.log("Set True Effect")
        setLiked(true)
        setLikedID(prop.id)
      }else{
        console.log("Set False Effect")
        setLiked(false)
        setLikedID('')
      }
    })
  })

  useEffect(() => {
    getData()
  }, [])

  return (
    <div className="property-details">
      <div class="property-details__main-info">
        {/* Flag */}
        <div class="property-details__flag">
          <div class="property-details__flag-container">
            <div class="property-details__flag-train">
              <div>
                <p>DTL</p>
              </div>
              <p class="text-xs">Rochor</p>
            </div>
            <div class="property-details__flag-train">
              <p class="text-xs">D12</p>
            </div>
          </div>
          <div class="property-details__flag-button">
            <button>
              <Share />
            </button>
            {!liked ? (
              <button onClick={handleLike}>
                <Love />
              </button>
            ) : (
              <button onClick={handleUnLike}>
                <p>Liked</p>
              </button>
            )}
          </div>
        </div>

        {/* Name & Price */}
        <div class="property-details__name-price">
          <div class="property-details__property-name">
            <p class="text-lg">
              {propertyRentalTypeOptions[props.detailData.rental_type]} at{' '}
              {props.detailData.district}
            </p>
            <div>
              <p class="text-sm">{props.detailData.address}</p>
              <p class="text-sm">
                <Pin /> <span>Map</span>
              </p>
            </div>
          </div>
          <div class="property-details__property-price">
            <p class="text-md">
              <span>S$</span> {props.detailData.monthly_rental} / mth
            </p>
          </div>
        </div>

        {/* Feature */}
        <div class="property-details__feature">
          <div class="property-details__feature-icon">
            <Bed />
            <p class="text-sm">{props.detailData.number_of_beds}</p>
          </div>
          <div class="property-details__feature-icon">
            <BathIcon />
            <p class="text-sm">{props.detailData.number_of_baths}</p>
          </div>
          <div class="property-details__feature-icon">
            <Area />
            <p class="text-sm">
              {props.detailData.floor_area} sqft /{' '}
              {Number(props.detailData.floor_area / 10.764).toFixed(2)} sqm
            </p>
          </div>
        </div>

        {/* Insight */}
        <div class="property-details__insight">
          <div class="property-details__insight-icon">
            <Eye />
            <p class="text-sm">{props.detailData.property_view_count}</p>
          </div>
          <div class="property-details__insight-icon">
            <Love />
            <p class="text-sm">{props.countLikeData.count ?? '0'}</p>
          </div>
          <div class="property-details__insight-icon">
            <Plus />
            <p class="text-sm">
              {/* TODO: Check BUG */}
              Posted {moment(props.detailData.created_date).fromNow()} by{' '}
              {landlordData.name ?? ''}
            </p>
          </div>
        </div>
      </div>

      {/* Info */}
      {/* <div class="property-details__info">
        <div class="property-details__info-point">
          <House />
          <p class="text-sm">HDB</p>
        </div>
        <div class="property-details__info-point">
          <Piechart />
          <p class="text-sm">Entire Unit</p>
        </div>
        <div class="property-details__info-point">
          <CalendarDot />
          <p class="text-sm">Available Now</p>
        </div>
        <div class="property-details__info-point">
          <Layer />
          <p class="text-sm">Mid Floor</p>
        </div>
        <div class="property-details__info-point">
          <Furniture />
          <p class="text-sm">Full Furnished</p>
        </div>
        <div class="property-details__info-point">
          <Calendar />
          <p class="text-sm">Built Year 2012</p>
        </div>
      </div> */}

      <div className="search-browse__icons-container detail-browse__icons-container">
        <Splide
          hasTrack={false}
          options={{
            perPage: 2,
            pagination: false,
            arrows: false,
          }}
        >
          <SplideTrack>
            <SplideSlide onClick={() => console.log('hallo')}>
              <button className="button__sub-tab--browse">
                <House className="search-browse__svg" />
                {/* TODO: Check BUG */}
                <span>{propertyCategory.name ?? ''}</span>
              </button>
            </SplideSlide>
            <SplideSlide onClick={() => console.log('hallo')}>
              <button className="button__sub-tab--browse">
                <Piechart className="search-browse__svg" />
                <span>
                  {propertyRentalTypeOptions[props.detailData.rental_type]}
                </span>
              </button>
            </SplideSlide>
            <SplideSlide onClick={() => console.log('hallo')}>
              <button className="button__sub-tab--browse">
                <CalendarDot className="search-browse__svg" />
                {/* TODO: Waiting for property_viewing_date */}
                <span>Available Now</span>
              </button>
            </SplideSlide>
            <SplideSlide onClick={() => console.log('hallo')}>
              <button className="button__sub-tab--browse">
                <Layer className="search-browse__svg" />
                <span>{floorOptions[props.detailData.floor]} Floor</span>
              </button>
            </SplideSlide>
            <SplideSlide onClick={() => console.log('hallo')}>
              <button className="button__sub-tab--browse">
                <Furniture className="search-browse__svg" />
                <span>
                  {furnishingLoadOptions[props.detailData.furnishing_load]}
                </span>
              </button>
            </SplideSlide>
            <SplideSlide onClick={() => console.log('hallo')}>
              <button className="button__sub-tab--browse">
                <Calendar className="search-browse__svg" />
                <span>Built Year {props.detailData.build_year}</span>
              </button>
            </SplideSlide>
          </SplideTrack>
        </Splide>
      </div>

      {/* Highlight */}
      <div class="property-details__highlight">
        <div class="property-details__highlight-subtitle">
          <Star />
          <p class="text-xs">HIGHLIGHTS</p>
        </div>
        <p class="text-sm">{props.detailData.highlight}</p>
      </div>

      {/* FAQ */}
      <div class="property-details__FAQ">
        <p class="text-xl">Property FAQ</p>

        <div className="faq__wrapper">
          {props.faqData?.map((q) => {
            return <AmenitiesItem title={q.question} content={q.answer} />
          })}
        </div>
        {/* <div class="property-details__FAQ-container">
          <p class="text-lg">Is cooking allowed?</p>
          <ChevronDown />
        </div>
        <div class="property-details__FAQ-container">
          <p class="text-lg">Are pets allowed?</p>
          <ChevronDown />
        </div>
        <div class="property-details__FAQ-container">
          <p class="text-lg">Who pays for utilities and wifi?</p>
          <ChevronDown />
        </div> */}
      </div>
    </div>
  )
}

export default TenantDetailsOutlet0
