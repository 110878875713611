import React from 'react'

import { ReactComponent as IconInfo } from '../../assets/icon/general/Vector-18-info.svg'
function InfoBoxColored(props) {
  return (
    <div className="info-box-colored">
      <div className="info-box-colored_heading">
        <div className="info-box-icon_colored">
          {props.icon ? props.icon : <IconInfo className="info-box__icon" />}
        </div>

        <p>{props.title}</p>
      </div>
      <div>
        <p className="text-sm normal">{props.info}</p>
      </div>
    </div>
  )
}

export default InfoBoxColored
