import { useNavigate } from 'react-router-dom'
import { ReactComponent as PinIcon } from '../../assets/icon/general/Vector-20-pin.svg'
import { ReactComponent as CloseIcon } from '../../assets/icon/general/Vector-9-close.svg'
import { ReactComponent as Filter } from '../../assets/icon/general/Vector-29-filter.svg'

const SearchApply = (props) => {
  const navigate = useNavigate()
  const check = true
  const passProps = () => {
    navigate('/browse', { state: { var: check } })
  }

  return (
    <div className="search-apply">
      <div className='search__reset'>
        <input type="reset" value="Clear all"></input>
        <CloseIcon className="search-apply__icon-clear" />
      </div>

      <button
        type="submit"
        className="search-apply__btn apply-btn"
        onClick={() => passProps()}
      >
        {props.type ==="filter" ? 
        <Filter className="search-apply__icon-apply" />
          :
        <PinIcon className="search-apply__icon-apply" />}
        <span>Apply location filter</span>
      </button>
    </div>
  )
}

export default SearchApply
