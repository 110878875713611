import React from 'react'
import { RENT_AGREEMENT } from '../schema'
import InfoBox from '../../../components/ui/InfoBox'

import { ReactComponent as Check } from '../../../assets/icon/general/Vector-42-check.svg'
import { ReactComponent as Alert } from '../../../assets/icon/general/Vector-Info-Box.svg'

const Rent = ({ suggestedEdit, onClick, onShowModal }) => {
  return (
    <div>
      <div className="agreement-content">
        <InfoBox info="This Tenancy Agreement (TA) between “Tenant” Wong Zhenyi (*****567A) and “Landlord” Wilson Chan Yi De (*****890B) will be legally binding once both parties have signed the TA and once the necessary stamp duty has been paid." />
        <div className="agreement-item_box">
          <div className="agreement-item_heading">
            <p>2.1 / Rent</p>
          </div>
          {RENT_AGREEMENT.map((item, index) => (
            <div className="agreement-item_content" key={index}>
              <div>
                <p>{item.name}</p>
              </div>
              <div>
                <p>{item.value}</p>
              </div>
            </div>
          ))}
        </div>

        {suggestedEdit ? (
          <div className="agreement-item-edits_box">
            <div className="agreement-item-edits_heading">
              <p>Suggested Edits</p>
            </div>

            <div className="agreement-item_content">
              <div>
                <p>Payment Schedule</p>
              </div>
              <div>
                <p>Every 25th</p>
              </div>
            </div>

            <div className="agreement-item_content">
              <div>
                <p>First Payment</p>
              </div>
              <div>
                <p>25 Jan 2023</p>
              </div>
            </div>
          </div>
        ) : null}

        <div className="agreement-item_box">
          <div className="text-lg bold" style={{ marginBottom: '1rem' }}>
            <p>Tenant agrees to...</p>
          </div>
          <div className="agreement-item_desc">
            <p>
              Pay the monthly rental of{' '}
              <span className="agreement-item_highlight">S$3,600</span> to the
              landlord via P.Lease on the{' '}
              <span className="agreement-item_highlight">22nd </span> day of
              each calendar month for a period of{' '}
              <span className="agreement-item_highlight">24 months</span>{' '}
              commencing on{' '}
              <span className="agreement-item_highlight">22 Jan 2023</span>{' '}
              without any deduction whatsoever.
            </p>
          </div>
        </div>
      </div>

      <div className="agreement-button_container">
        <div style={{ flex: 2 }}>
          <div className="tenant-details-button" onClick={onShowModal}>
            <Alert />
            <p className="text-md bold">Reject & Suggest Edit</p>
          </div>
        </div>

        <div style={{ flex: 1 }}>
          <div className="tenant-details-offerBtn" onClick={onClick}>
            <Check />
            <p className="text-md bold">Accept</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Rent
