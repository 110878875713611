import React from 'react'
import ReactDOM from 'react-dom/client'
import './assets/css/base/index.css'
import './assets/css/base/global.css'
import './assets/css/vendor/splide.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'

// Vendor
// Default theme
import '@splidejs/react-splide/css'

// or other themes
import '@splidejs/react-splide/css/skyblue'
import '@splidejs/react-splide/css/sea-green'

// or only core styles
import '@splidejs/react-splide/css/core'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
