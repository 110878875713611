import React, { useState } from 'react'
import HeadBack from '../../components/ui/HeadBack'
import InfoBox from '../../components/ui/InfoBox'
import AccordionItem from './components/AccordionItem'
import '../../assets/css/pages/LeasePage/preCondition.css'
import AccordionUpload from './components/AccordionUpload'

function PreCondition() {
  const [userType, isUserType] = useState('tenant')
	const infoText = () => {
		if(userType === 'tenant') {
			return 'Your landlord will be notified of your photo submissions by 11:59 PM on handover day. Your photos will then be subjected to the landlord’s acceptance.'
		} else {
			return 'Press and hold to select multiple photos.'
		}
	}

  return (
    <div className="precon">
      <HeadBack backLink="/lease" title="Pre-condition Photo" />

      <div className="precon-body">
        <InfoBox info={infoText()} />

        <div className="precon-accordion">
          {userType === 'tenant' ? (
            <>
              <AccordionUpload
                title="Living Room"
								id={1}
                type="invlist"
                btnType="addPrecon"
              />
							<AccordionUpload
                title="Bedroom"
								id={2}
                type="invlist"
                btnType="addPrecon"
              />
							<AccordionUpload
                title="Kitchen / Service Yard"
								id={3}
                type="invlist"
                btnType="addPrecon"
              />
							<AccordionUpload
                title="Study"
								id={4}
                type="invlist"
                btnType="addPrecon"
              />
							<AccordionUpload
                title="Other Area(s)"
								id={5}
                type="invlist"
                btnType="addPrecon"
              />
            </>
          ) : (
            <>
              <AccordionItem title="Living Room" type="precon" />
              <AccordionItem title="Bedroom" type="precon" />
              <AccordionItem title="Kitchen / Service Yard" type="precon" />
              <AccordionItem title="Study" type="precon" />
              <AccordionItem title="Other Area(s)" type="precon" />
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default PreCondition
