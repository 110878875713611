import React from 'react'
import { NavLink } from 'react-router-dom'
import LogoB from '../../../../assets/logo/Logo-b 1.svg'
import './navbar.css'

function Navbar() {
	return (
		<div className='navbar-web'>
			<img src={LogoB} alt="please-logo" />

			<div className="navbar-web__links">
				<NavLink to={'/browse'}>Browse</NavLink>
				<NavLink to={'/browse'}>Lease</NavLink>
				<NavLink to={'/browse'}>Chat</NavLink>
				<NavLink to={'/browse'}>Account</NavLink>
			</div>
		</div>
	)
}

export default Navbar