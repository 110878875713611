import axios from 'axios'
import { apiBaseURL } from '../utils'
import authService from './AuthService'

const getOneFromUserId = async (id) => {
  const response = await axios.get(`${apiBaseURL}/users/${id}/property_likes`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem(authService._tokenKey)}`,
      'x-access-token': `${localStorage.getItem(authService._tokenKey)}`,
    },
  })
  return response.data
}

const createOne = async (prop_id, user_id) => {
  let data = JSON.stringify({
    property_id: `${prop_id}`,
    user_id: `${user_id}`,
  })

  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: `${apiBaseURL}/property_likes`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: data,
  }

  const response = await axios.request(config)
  
  return response.data
}

const deleteOne = async (id) => {
  const response = await axios.delete(`${apiBaseURL}/property_likes/${id}`)
  
  return response
}

const propertyLikedService = {
  getOneFromUserId,
  createOne,
  deleteOne,
}

export default propertyLikedService
