import React, { useState, useEffect } from 'react'
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide'
import BackButton from '../../../components/ui/BackButton'
import '../../../assets/css/pages/LeasePage/reviewTenancyAgreement.css'
import { ReactComponent as Check } from '../../../assets/icon/general/Vector-42-check.svg'
import { ReactComponent as Alert } from '../../../assets/icon/general/Vector-Info-Box.svg'
import InfoBox from '../../../components/ui/InfoBox'

const data = [
  {
    tab: 'Introduction',
    complete: false,
    subTab: [
      {
        title: '1',
        content: [
          {
            title: 'introduction',
            desc: 'lorem ipsum 1',
          },
        ],
        complete: false,
      },
    ],
  },
  {
    tab: 'Schedule',
    complete: false,
    subTab: [
      {
        title: '1-5',
        content: [
          {
            title: 'Schedule',
            desc: 'lorem ipsum 1',
          },
        ],
        complete: false,
      },
      {
        title: '5-10',
        content: [
          {
            title: 'Schedule',
            desc: 'lorem ipsum 2',
          },
        ],
        complete: false,
      },
      {
        title: '10-15',
        content: [
          {
            title: 'Schedule',
            desc: 'lorem ipsum 3',
          },
        ],
        complete: false,
      },
      {
        title: '15-20',
        content: [
          {
            title: 'Schedule',
            desc: 'lorem ipsum 4',
          },
        ],
        complete: false,
      },
    ],
  },
  {
    tab: 'Operative Part',
    complete: false,
    subTab: [
      {
        title: '1-5',
        content: [
          {
            title: 'Operative Part',
            desc: 'lorem ipsum 1',
          },
        ],
        complete: false,
      },
      {
        title: '5-10',
        content: [
          {
            title: 'Operative Part',
            desc: 'lorem ipsum 2',
          },
        ],
        complete: false,
      },
      {
        title: '10-15',
        content: [
          {
            title: 'Operative Part',
            desc: 'lorem ipsum 3',
          },
        ],
        complete: false,
      },
      {
        title: '15-21',
        content: [
          {
            title: 'Operative Part',
            desc: 'lorem ipsum 4',
          },
        ],
        complete: false,
      },
    ],
  },
  {
    tab: 'Annexure A',
    complete: false,
    subTab: [
      {
        title: '1',
        content: [
          {
            title: 'Annexure A',
            desc: 'lorem ipsum 1',
          },
        ],
        complete: false,
      },
    ],
  },
  {
    tab: 'Annexure B',
    complete: false,
    subTab: [
      {
        title: '1',
        content: [
          {
            title: 'Annexure B',
            desc: 'lorem ipsum 1',
          },
        ],
        complete: false,
      },
    ],
  },
  {
    tab: 'Annexure C',
    complete: false,
    subTab: [
      {
        title: '1',
        content: [
          {
            title: 'Annexure C',
            desc: 'lorem ipsum 1',
          },
        ],
        complete: false,
      },
    ],
  },
]

function ReviewTenancyAgreement() {
  const [tabNum, setTabNum] = useState(0)
  const [subTabNum, setSubTabNum] = useState(0)
  const activeTab = data[tabNum]
  // const activeSubTab = data[tabNum].subTab[subTabNum]
  const contentSubTab = data[tabNum].subTab[subTabNum].content
	
	const nextTab = (i) => {
		setTabNum(i)
		setSubTabNum(0)
	}

  return (
    <div className="lease__t-ag">
      <div className="lease__t-head lease__t-head-ag">
        <div className="head__main-tab head__main-tab--2">
          <BackButton directURL="/lease/tenant" size="small" />
          <h1>
            Review Tenancy Agreement
          </h1>
        </div>

        <div className="head__sub-tab">
          <div className="head__sub-tab-outlet head__sub-tab-outlet--1">
            <Splide
              hasTrack={false}
              options={{
                perPage: 2,
                pagination: false,
                arrows: false,
              }}
            >
              <SplideTrack>
                {data.map((data, i) => (
                  <SplideSlide>
                    <button
                      className={
                        tabNum === i
                          ? 'button__sub-tab button__sub-tab--2 button__sub-tab--active'
                          : 'button__sub-tab button__sub-tab--2'
                      }
                      onClick={() => nextTab(i)}
                    >
                      {data.tab}
                    </button>
                  </SplideSlide>
                ))}
              </SplideTrack>
            </Splide>
          </div>
        </div>

        <div className="head__sub-tab">
          <div className="head__sub-tab-outlet head__sub-tab-outlet--1">
            <Splide
              hasTrack={false}
              options={{
                perPage: 2,
                pagination: false,
                arrows: false,
              }}
            >
              <SplideTrack>
                {activeTab.subTab.map((data, i) => (
                  <SplideSlide>
                    <button
                      className={
                        subTabNum === i
                          ? 'button__sub-tab button__sub-tab--2 button__sub-tab--active'
                          : 'button__sub-tab button__sub-tab--2'
                      }
                      onClick={() => setSubTabNum(i)}
                    >
                      {data.title}
                    </button>
                  </SplideSlide>
                ))}

                {/* {showSubTabHandler()} */}
              </SplideTrack>
            </Splide>
          </div>
        </div>
      </div>

      <div className="lease__t-body lease__t-body-ag">
        <InfoBox info="This Tenancy Agreement (TA) between “Tenant” Wong Zhenyi (*****567A) and “Landlord” Wilson Chan Yi De (*****890B) will be legally binding once both parties have signed the TA and once the necessary stamp duty has been paid." />

        {contentSubTab.map((data, i) => (
          <div className="agreement-item_box">
            <div className="text-lg bold" style={{ marginBottom: '1rem' }}>
              <p>{data.title}</p>
            </div>
            <div className="agreement-item_desc">
              <p>{data.desc}</p>
            </div>
          </div>
        ))}
      </div>

      <div className="agreement-button_container agreeement-btn-ag">
        <button className="tenant-details-offerBtn accept__btn">
          <Check />
          <p className="text-md bold">Accept</p>
        </button>
      </div>
    </div>
  )
}

export default ReviewTenancyAgreement
