import React from 'react'
import ReactDOM from 'react-dom'

import { ReactComponent as WarningIcon } from '../../assets/icon/general/Vector-31-warning.svg'
import '../../assets/css/components/ui/snackBar.css'

function SnackBar(props) {
  return ReactDOM.createPortal(
    <div className={props.status ? "snackbar snackbar--active" : "snackbar"}>
			<div className="snackbar__icon">
				<WarningIcon />
			</div>

			<p className='snackbar__text'>{props.message}</p>
		</div>,
    document.getElementById('portal')
  )
}

export default SnackBar
