import axios from 'axios'
import { apiBaseURL } from '../utils'
import setAuthToken from './generalApi'
import userServices from './userService'
import userConstant from '../utils/constants/user.constant'

const _tokenKey = 'token'
const _userIdKey = 'userId'
const _userDataKey = 'userData'

const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split('.')[1]))
  } catch (e) {
    return null
  }
}

const sendEmailOtp = async (user_id, email) => {
  const data = {
    user_id: user_id,
    email: email
  }
  const response = await axios.post(`${apiBaseURL}/auth/send_email_otp`, data);
  console.log(response);
}

const verifyOtp = async (user_id, code) => {
  try {
    const response = await axios.post(`${apiBaseURL}/auth/verify_email_otp`, {
      user_id,
      code
    })

    if (response.status !== 200) {
      return response
    }

    return response.data
  } catch (error) {
    return { message: error.response.data.message }
  }
}

const getUserProfileBasedOnUserType = async (userId, userType) => {
  const response = await axios.get(`${apiBaseURL}/users/${userId}/user_profiles`)

  if (response.status !== 200) return { data: null, message: response.data.message }
  else {
    const filteredUserProfile = response.data.filter((profile) => profile.user_type === userType);
    return { data: filteredUserProfile, message: "Success" }
  }

}

const checkUserTypeFromAuthPage = (userTypeString) => {
  switch (userTypeString) {
    case 'tenant': return userConstant.userRoleConstants.TENANT
    case 'landlord': return userConstant.userRoleConstants.LANDLORD
    case 'agent': return userConstant.userRoleConstants.AGENT
    default: return userConstant.userRoleConstants.TENANT
  }
}

const getUserTypeNameFromId = (userType) => {
  switch (userType) {
    case 1: return "tenant"
    case 2: return "landlord"
    case 3: return "agent"
    default: return "tenant"
  }
}

const login = async (data, userType) => {
  const response = await axios.post(`${apiBaseURL}/auth/login`, data)

  if (response.status !== 200)
    return { data: null, message: response.data.message }
  if (response.status === 200) {
    const _token = response.data.data?.token ?? null
    const _userId = response.data.data.data?.id ?? null
    if (!_token) return { data: null, message: "No token provided" }
    setAuthToken(_token)
    const _userProfileResponse = await getUserProfileBasedOnUserType(_userId, checkUserTypeFromAuthPage(userType));


    if (_userProfileResponse.data.length <= 0) {
      return { data: null, message: `You are not registered as a ${userType}` }
    }

    const loggedUserProfileData = _userProfileResponse.data[0]

    const _userData = response.data.data.data != null ? {
      id: response.data.data.data?.id,
      email: response.data.data.data?.email,
      name: response.data.data.data?.name,
      role: loggedUserProfileData.user_type, // Changing the role setting
      user_profile_id: loggedUserProfileData?.id,
      is_email_verified: response.data.data.data?.is_email_verified,
    } : null;

    if (_userData) localStorage.setItem(_userDataKey, JSON.stringify(_userData))
    if (_token) localStorage.setItem(_tokenKey, _token)
    if (_userId) localStorage.setItem(_userIdKey, _userId)

    return { data: response.data, message: response.data.message }
  }
}

const register = async (data, userType) => {
  const response = await axios.post(`${apiBaseURL}/auth/register`, data);

  const userTypeID = userType === null ? 1 : checkUserTypeFromAuthPage(userType);

  if (response.status !== 200) return { data: null, message: response.data.message }
  if (response.status === 200) {
    const userProfileDataRequest = { user_id: response.data.data.id, user_type: userTypeID };
    const userProfileResponse = await userServices.createUserProfile(userProfileDataRequest);

    console.log(userProfileResponse);

    return {
      data: { user_data: response.data, user_profile: userProfileResponse.data.data },
      message: response.data.message
    }
  }
}

const logout = () => {
  localStorage.removeItem(_tokenKey)
  localStorage.removeItem(_userIdKey)
  localStorage.removeItem(_userDataKey)
}

const isAuthenticated = () => {
  const token = localStorage.getItem(_tokenKey)

  // Check if token exists and is not expired
  if (token) {
    const decoded = parseJwt(token)
    return decoded.exp > Date.now() / 1000
  }

  return false
}

const getUserProfileData = async () => {
  if (isAuthenticated()) {
    const user_id = localStorage.getItem(_userIdKey);

    setAuthToken(localStorage.getItem(_tokenKey))

    const response = await axios.get(`${apiBaseURL}/users/${user_id}/user_profiles`)
    return response.data
  }

  return null
}

const getLoggedUserData = () => {
  const userData = localStorage.getItem(_userDataKey);
  if (userData) return JSON.parse(userData);
  return null;
}

const updateLoggedUserEmailVerified = (email_verified_status) => {
  const userData = getLoggedUserData()
  userData.is_email_verified = email_verified_status;

  localStorage.setItem(_userDataKey, JSON.stringify(userData));
}

const authService = {
  parseJwt,
  login,
  logout,
  isAuthenticated,
  _tokenKey,
  _userIdKey,
  getUserProfileData,
  register,
  _userDataKey,
  getLoggedUserData,
  sendEmailOtp,
  verifyOtp,
  updateLoggedUserEmailVerified
}

export default authService
