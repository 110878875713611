import React, { useEffect, useState } from 'react'
import '../../../assets/css/components/browse/Tenant/tenantDetailsOutlet1.css'

import { amenitiesData } from './amenitiesData'
import AmenitiesItem from './AmenitiesItem'
import { apiBaseURL, stringUtils } from '../../../utils'
import { ReactComponent as Chair } from '../../../assets/icon/general/Vector-58-chair.svg'
import { ReactComponent as Bed } from '../../../assets/icon/general/Vector-4-bed.svg'
import { ReactComponent as Desk } from '../../../assets/icon/general/Vector-62-desk.svg'
import { ReactComponent as Fridge } from '../../../assets/icon/general/Vector-59-fridge.svg'
import axios from 'axios'

function TenantDetailsOutlet1(props) {
  const [propertyFurnishingData, setPropertyFurnishingData] = useState([])

  /**
   * @Author Arga Christian 6 May 2023
   * @Edited by Rainer at 6 May 2023 11:38
   */
  const fetchPropertiesFurnishings = async (id) => {
    var response = []

    var livingRoom = { icon: <Chair />, title: 'Living Room', content: <></> }
    var bedRoom = { icon: <Bed />, title: 'Bedroom', content: <></> }
    var studyRoom = { icon: <Desk />, title: 'Study Room', content: <></> }
    var kitchenServiceYard = { icon: <Fridge />, title: 'Kitchen & Service Yard', content: <></> }

    const propertyFurnishingResponse = await axios.get(
      `${apiBaseURL}/properties/${id}/property_furnishings`
    )

    const currLivingRoom = []
    const currBedRoom = []
    const currStudyRoom = []
    const currKitchenServiceYard = []

    for (let i = 0; i < propertyFurnishingResponse.data.length; i++) {
      const data = propertyFurnishingResponse.data[i]

      const furnishingResponse = await axios.get(
        `${apiBaseURL}/furnishings/${data.furnishing_id}`
      )

      switch (furnishingResponse.data.furnishing_type) {
        case 1:
          currLivingRoom.push(furnishingResponse.data.furnishing_item);
          break;
        case 2:
          currKitchenServiceYard.push(furnishingResponse.data.furnishing_item)
          break;
        case 3:
          currBedRoom.push(furnishingResponse.data.furnishing_item)
          break;
        case 4:
          currStudyRoom.push(furnishingResponse.data.furnishing_item)
          break;
      }
    }

    Object.assign(livingRoom, {
      content: currLivingRoom.length > 0 ? (
        <>
          {currLivingRoom.map((data) => (
            <li>{stringUtils.capitalizeFirstLetter(data)}</li>
          ))}
        </>
      ) : (<>No facilities available</>),
    })

    Object.assign(kitchenServiceYard, {
      content: currKitchenServiceYard.length > 0 ?  (
        <>
          {currKitchenServiceYard.map((data) => (
            <li>{stringUtils.capitalizeFirstLetter(data)}</li>
          ))}
        </>
      ) : (<>No facilities available</>),
    })

    Object.assign(bedRoom, {
      content: currBedRoom.length > 0 ? (
        <>
          {currBedRoom.map((data) => (
            <li>{stringUtils.capitalizeFirstLetter(data)}</li>
          ))}
        </>
      ) : (<>No facilities available</>),
    })

    Object.assign(studyRoom, {
      content: currStudyRoom.length > 0 ? (
        <>
          {currStudyRoom.map((data) => (
            <li>{stringUtils.capitalizeFirstLetter(data)}</li>
          ))}
        </>
      ) : (<>No facilities available</>),
    })

    response.push(livingRoom)
    response.push(bedRoom)
    response.push(studyRoom)
    response.push(kitchenServiceYard)
    
    setPropertyFurnishingData(response)
  }

  useEffect(() => {
    fetchPropertiesFurnishings(props.propertyId)
  }, [])

  return (
    <div class="amenities">
      {propertyFurnishingData.map(({ icon, title, content, index }) => (
        <AmenitiesItem
          icon={icon}
          title={title}
          content={content}
          key={index}
        />
      ))}
    </div>
  )
}

export default TenantDetailsOutlet1
