import React, { useState } from "react"
import { ReactComponent as CloseIcon } from '../../assets/icon/general/Vector-9-close.svg';
import { ReactComponent as CameraIcon } from '../../assets/icon/general/Vector-57-camera.svg';
import '../../assets/css/components/FirstTimePage/onboardingOutlite2.css'
import { useForm } from "react-hook-form";

function OnboardingOutlet2({ directEditPersonal, directEditOB2 }) {
  const [files, setFiles] = useState([]);
  const [filesSelfie, setFilesSelfie] = useState([]);

  const { register, handleSubmit, watch, formState: { errors } } = useForm();

  const handleUpload = (event) => {
    const selectedFiles = event.target.files;
    setFiles([...files, ...selectedFiles]);
  }

	const handleUploadSelfie = (event) => {
    const selectedFilesSelfie = event.target.files;
    setFilesSelfie([...filesSelfie, ...selectedFilesSelfie]);
  }

  const handleRemove = (index) => {
    const newFiles = [...files];
    newFiles.splice(index, 1);
    setFiles(newFiles);
  }

	const handleRemoveSelfie = (index) => {
    const newFilesSelfie = [...filesSelfie];
    newFilesSelfie.splice(index, 1);
    setFilesSelfie(newFilesSelfie);
  }

  const _handleSubmit = async () => {
	try {
		directEditOB2([files, filesSelfie]);
		directEditPersonal(2);
	} catch (error) {
		console.log(error);
	}
  }

	return (
		<div className='onboarding__outlet-2'>
			<form className="onboarding__2-form" onSubmit={handleSubmit(_handleSubmit)}>
				<div className='onboarding__outlet-2-identity'>
					<div className='onboarding__outlet-2-head'>
						<h1>Identity Document</h1>
						<p className="p1">Take a photo of your identity document (e.g. NRIC/Passport/Work Permit) in a brightly-lit environment.</p>
					</div>

					<div className='onboarding__outlet-2-photo'>
						<div className="onboarding__outlet-2-file">
							<input type="file" id="identity" accept="image/*" multiple onChange={handleUpload} />
							<label htmlFor="identity"><CameraIcon /> <span>Take a photo</span></label>
						</div>

						<div className={`onboarding__outlet-2-file-preview ${files.length === 0 && 'onboarding__outlet-2-file-preview--hide'}`}>
							{files.map((file, index) => (
								<div className="image__preview" key={index}>
									<div className="image__preview-delete" onClick={() => handleRemove(index)}><CloseIcon /></div>
									<img src={URL.createObjectURL(file)} />
								</div>
							))}
						</div>
					</div>
				</div>

				<div className='onboarding__outlet-2-selfie'>
					<div className='onboarding__outlet-2-head'>
						<h1>Selfie Verification</h1>
						<p className="p1">Take a selfie of yourself holding your identity document (e.g. NRIC/Passport/Work Permit) in a brightly-lit environment.<br /><br />Please ensure that your face is not blocked and that your identity document can be seen clearly.</p>
					</div>

					<div className='onboarding__outlet-2-photo'>
						<div className="onboarding__outlet-2-file">
							<input type="file" id="selfie" accept="image/*" multiple onChange={handleUploadSelfie} />
							<label htmlFor="selfie"><CameraIcon /> <span>Take a photo</span></label>
						</div>

						<div className={`onboarding__outlet-2-file-preview ${filesSelfie.length === 0 && 'onboarding__outlet-2-file-preview--hide'}`}>
							{filesSelfie.map((file, index) => (
								<div className="image__preview" key={index}>
									<div className="image__preview-delete" onClick={() => handleRemoveSelfie(index)}><CloseIcon /></div>
									<img src={URL.createObjectURL(file)} />
								</div>
							))}
						</div>
					</div>
				</div>

				<div className='onboarding__button'>
					<input className={`${files.length !== 0 && filesSelfie.length !== 0 ? 'button__style-3 button__style-3--active' : 'button__style-3'}`} type="submit" value="Continue" />
				</div>
			</form>
		</div>
	)
}

export default OnboardingOutlet2