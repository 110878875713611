import axios from 'axios'
import { apiBaseURL } from '../utils'

const getAllViewingDateBasedOnId = async (id) => {
  const response = await axios.get(
    `${apiBaseURL}/properties/${id}/property_viewing_dates`
  )
  return response.data
}

const getListViewingDateBasedOnId = async (array) => {
  const res = []
  array = array.filter((value, index, self) => self.indexOf(value) == index)
  for (let i = 0; i < array.length; i++) {
    const response = await axios.get(
      `${apiBaseURL}/property_viewing_dates/${array[i]}`
    )
    res.push(response.data)
  }
  return res
}

const requestViewing = async (data) => {
  const request = await axios.post(`${apiBaseURL}/tenant_viewing_props`, data)
  return request.data
}

const updateViewing = async (data, id) => {
  const request = await axios.patch(
    `${apiBaseURL}/tenant_viewing_props/${id}`,
    data
  )
  return request.data
}

const addViewingRequest = async (id, total) => {
  const request = await axios.patch(
    `${apiBaseURL}/property_viewing_dates/${id}`,
    { total_viewing_request: total }
  )
  return request.data
}

const getRequestViewingBasedOnUserProfile = async (id) => {
  const response = await axios.get(
    `${apiBaseURL}/user_profiles/${id}/tenant_viewing_props/`
  )
  return response.data
}

const TenantViewingService = {
  getAllViewingDateBasedOnId,
  getListViewingDateBasedOnId,
  requestViewing,
  addViewingRequest,
  getRequestViewingBasedOnUserProfile,
  updateViewing,
}

export default TenantViewingService
