import React from 'react'
import '../../../assets/css/components/LeasePage/Tenant/leaseTenantNewOutlet.css'
import LeaseTenantNewBoxInfo from './LeaseTenantNewBoxInfo'

function LeaseTenantNewOutlet2() {
	return (
		<div className='lease__t-body-outlet'>
			<div className='outlet__head'>
				<h1>Offer</h1>

				<div className='head__count'>
					<p>0</p>
				</div>
			</div>

			<div className='outlet__body'>
				<LeaseTenantNewBoxInfo newLease="offer" />
			</div>
		</div> 
	)
}

export default LeaseTenantNewOutlet2