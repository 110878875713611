const OtherTOU = () => {
  return (
    <div className="offer-body__section-container">
      <div className="offer-body__form-container">
        <h1 className=" offer-body__title">Other Terms of Use</h1>
        <div class="billing-switches-services">
          <p class="text-md">Security Deposit (months)</p>
          <div class="services-option">
            <input
              type="radio"
              id="0.5secdepo"
              name="switch-secdepo"
              value="0.5secdepo"
            />
            <label for="0.5secdepo">0.5</label>
            <input
              type="radio"
              id="1secdepo"
              name="switch-secdepo"
              value="1secdepo"
            />
            <label for="1secdepo">1</label>
            <input
              type="radio"
              id="1.5secdepo"
              name="switch-secdepo"
              value="1.5secdepo"
            />
            <label for="1.5secdepo">1.5</label>
            <input
              type="radio"
              id="2secdepo"
              name="switch-secdepo"
              value="2secdepo"
            />
            <label for="2secdepo">2</label>
            <input
              type="radio"
              id="2.5secdepo"
              name="switch-secdepo"
              value="2.5secdepo"
            />
            <label for="2.5secdepo">2.5</label>
            <input
              type="radio"
              id="3secdepo"
              name="switch-secdepo"
              value="3secdepo"
            />
            <label for="3secdepo">3</label>
          </div>
        </div>
        <div class="billing-switches-services">
          <p class="text-md">Defect-free Period (weeks)</p>
          <div class="services-option">
            <input
              type="radio"
              id="defect-free-0"
              name="switch-defect"
              value="defect-free-0"
            />
            <label for="defect-free-0">0</label>
            <input
              type="radio"
              id="defect-free-2"
              name="switch-defect"
              value="defect-free-2"
            />
            <label for="defect-free-2">2</label>
            <input
              type="radio"
              id="defect-free-4"
              name="switch-defect"
              value="defect-free-4"
            />
            <label for="defect-free-4">4</label>
          </div>
        </div>
        <div class="billing-switches-services">
          <p class="text-md">Minor Repair Cost</p>
          <div class="services-option">
            <input
              type="radio"
              id="150cost"
              name="switch-minor"
              value="150cost"
              selected
            />
            <label for="150cost">$150</label>
            <input
              type="radio"
              id="200cost"
              name="switch-minor"
              value="200cost"
              selected
            />
            <label for="200cost">$200</label>
            <input
              type="radio"
              id="250cost"
              name="switch-minor"
              value="250cost"
              selected
            />
            <label for="250cost">$250</label>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OtherTOU
