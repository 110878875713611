import React from 'react'
import '../../assets/css/pages/AccountPage/notifications.css'
import Room from '../../assets/images/image 5.png'
import HeadBack from '../../components/ui/HeadBack'

function Notifications() {
  return (
    <section class="notifications__main">
      <HeadBack title="Notifications" backLink="/account" />
      {/* Tenant */}
      <div class="notifications__body">
        <div class="notifications__body-card">
          <div class="notifications__card-date-time">
            <p class="text-xs">6 DEC</p>
            <span></span>
            <p class="text-xs">01:28 PM</p>
          </div>
          <div class="notifications__card-title">
            <p class="text-lg">Offer Accepted</p>
          </div>
          <div class="notifications__card-desc">
            <p class="text-sm">
              Congratulations! <span>Wilson Chan Yi De</span> has accepted your
              offer of <span>S$3,600</span> for{' '}
              <span>Entire Unit at Toa Payoh</span>. To formalise your offer,
              please review and sign off on the Tenancy Agreement.
            </p>
          </div>
          <div class="notifications__card-button">
            <button>View Details</button>
          </div>
        </div>
      </div>

      {/* Landlord */}
      {/* <div class="notifications__assets">
        <div class="notifications__assets-image-selected">
          <img src={Room} alt="room" />
          <div class="assets-image-selected-text">
            <span></span>
            <p class="text-xs">
              Current<br></br>Rental
            </p>
          </div>
        </div>
        <div class="notifications__assets-image">
          <img src={Room} alt="" />
        </div>
      </div>

      <div class="notifications__body">
        <div class="notifications__body-card">
          <div class="notifications__card-date-time">
            <p class="text-xs">28 NOV</p>
            <span></span>
            <p class="text-xs">09:15 AM</p>
          </div>
          <div class="notifications__card-title">
            <p class="text-lg">Viewing Request Accepted</p>
          </div>
          <div class="notifications__card-desc">
            <p class="text-sm">
              <span>Chen Ziqi</span> has accepted your viewing request for <span>Entire Unit at Woods Square</span> on 9 Dec 2022 at 7pm.
            </p>
          </div>
          <div class="notifications__card-button">
            <button>View Details</button>
          </div>
        </div>
      </div> */}
    </section>
  )
}

export default Notifications
