import React, { useState } from 'react'
import { ReactComponent as Back } from '../../../assets/icon/general/Vector-1-back.svg'

import '../../../assets/css/pages/LeasePage/AirconServicing.css'
import { useNavigate } from 'react-router-dom'
import Button from '../components/Button'

const AirconServicing = () => {
  const navigate = useNavigate()
  const [totalAircon, setTotalAircon] = useState()
  const [propertyType, setPropertyType] = useState()
  return (
    <div style={{ position: 'relative' }}>
      <div className="onboarding__nav">
        <div className="onboarding__head">
          <div className="signup__back" onClick={() => navigate(-1)}>
            <Back />
          </div>

          <h1>Aircon Servicing</h1>
        </div>
      </div>

      <div className="service-content">
        <div className="service-box">
          <div className="service-box_item">
            <p className="text-md bold">Number of Aircon</p>
            <div className="type-option_item-container">
              <div
                className={`${
                  totalAircon === '1'
                    ? 'type-option_item option-selected'
                    : 'type-option_item'
                }`}
                onClick={() => setTotalAircon('1')}
              >
                <p>1</p>
              </div>

              <div
                className={`${
                  totalAircon === '2'
                    ? 'type-option_item option-selected'
                    : 'type-option_item'
                }`}
                onClick={() => setTotalAircon('2')}
              >
                <p>2</p>
              </div>

              <div
                className={`${
                  totalAircon === '3'
                    ? 'type-option_item option-selected'
                    : 'type-option_item'
                }`}
                onClick={() => setTotalAircon('3')}
              >
                <p>3</p>
              </div>

              <div
                className={`${
                  totalAircon === '4'
                    ? 'type-option_item option-selected'
                    : 'type-option_item'
                }`}
                onClick={() => setTotalAircon('4')}
              >
                <p>4</p>
              </div>

              <div
                className={`${
                  totalAircon === '5+'
                    ? 'type-option_item option-selected'
                    : 'type-option_item'
                }`}
                onClick={() => setTotalAircon('5+')}
              >
                <p>5+</p>
              </div>
            </div>

            <div className="quotation-information">
              <p className="text-sm bold">
                Quotation (all prices stated in SGD)
              </p>
              <div className="quotation-container">
                <p className="quotation-title">Service</p>
                <p className="quotation-title">Per Unit</p>
                <p className="quotation-title">Per Year (no contract)</p>
                <p className="quotation-title">Per Year (contract)</p>
                <p className="quotation-title">Savings</p>

                <p className="text-sm normal">Aircon Servicing</p>
                <p className="text-sm normal">$35</p>
                <p className="text-sm normal">$140</p>
                <p className="text-sm normal">$130</p>
                <p className="text-sm normal">
                  $10 <span>7%</span>
                </p>

                <p className="text-sm normal">Chemical Wash</p>
                <p className="text-sm normal">$90</p>
                <p className="text-sm normal"></p>
                <p className="text-sm normal"></p>
                <p className="text-sm normal"></p>

                <p className="text-sm normal">Chemical Overhaul</p>
                <p className="text-sm normal">$150</p>
                <p className="text-sm normal"></p>
                <p className="text-sm normal"></p>
                <p className="text-sm normal"></p>

                <p className="text-sm normal">Troubleshoot</p>
                <p className="text-sm normal">$50</p>
                <p className="text-sm normal"></p>
                <p className="text-sm normal"></p>
                <p className="text-sm normal"></p>
              </div>
            </div>
          </div>
        </div>

        <div className="service-box">
          <div className="service-box_item">
            <p className="text-md bold">Recommended Service Schedule</p>
            <div className="type-option_item-container">
              <div>
                <p className="text-xs normal">START</p>
                <p className="text-sm normal">Within 1-2 weeks of lease</p>
              </div>

              <div>
                <p className="text-xs normal">Frequency</p>
                <p className="text-sm normal">Every 3 mths</p>
              </div>
            </div>
          </div>
        </div>

        <div className="service-box">
          <div className="service-box_item">
            <p className="text-md bold">Damage-free Guarantee</p>
            <div className="type-option_item-container">
              <ul className="guarantee_item-container">
                <li className="text-sm ">
                  If aircon is found to be leaking within 7 days after last
                  serviced date, tenant and/or landlord can raise a request to
                  seek advice and schedule a fix within 7 days of request date
                  at no cost.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="service-bottom_button">
        <div className="service-bottom_button-item">
          <div style={{ flex: 1 }}>
            <Button buttonName="Pay per Servicing" type="secondary" />
          </div>
          <div style={{ flex: 1 }}>
            <Button buttonName="Get Annual Contract" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default AirconServicing
