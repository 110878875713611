import React from 'react'
import LOI from '../../../components/documents/LOI/LOI'
import HeadBack from '../../../components/ui/HeadBack'
import {useRef ,useEffect, useState} from 'react'
import './loiPreview.css'
import { useReactToPrint } from 'react-to-print'

function LOIPreview() {
	const loiDocRef = useRef()

	const handlePrint = useReactToPrint({
		content: () => loiDocRef.current,
		documentTitle: 'LOI Document',
		// onAfterPrint: () => alert('Print Success')
	})
	
	useEffect(() => {
		console.log(loiDocRef)
	}, [])

	return (
		<div className='loi-prev'>
			<HeadBack backLink="/makeoffer" title="LOI Document Preview" />

			<div className="loi-prev__body">
				<LOI loiDocRef={loiDocRef} />
			</div>

			<div className="loi-prev__box">
				<button onClick={handlePrint}>Print / Download</button>
			</div>
		</div>	
	)
}

export default LOIPreview