import InfoBox from '../ui/InfoBox'
import ManageRequestPic from '../../assets/images/vector__manage-request.png'
import { ReactComponent as ChatLogo } from '../../assets/icon/navigation/Vector-3.svg'
import { ReactComponent as WarningIcon } from '../../assets/icon/general/Vector-31-warning.svg'
import { useState, useEffect } from 'react'
import ModalViewing from './Modals/modalViewing'
import ModalManageOffer from './Modals/modalManageOffer'
import ModalReject from './Modals/modalReject'
import TenantViewingService from '../../services/TenantViewingService'
import authService from '../../services/AuthService'
import {
  dayOptions,
  monthOptions,
  tenantViewingStatusOptions,
  timeCounterFromNow,
} from '../../utils'

const ManageRequestViewing = (props) => {
  const [modal, setModal] = useState(false)
  const [actionModal, setActionModal] = useState('')
  const [typeModal, setTypeModal] = useState('')
  const [modalReject, setModalReject] = useState(false)

  const [tenantRequestData, setTenantRequestData] = useState(null)
  const [arrayOfCurrentlyRequestedViewingDateIds, setArrayOfCurrentlyRequestedViewingDateIds] = useState(null)

  const [viewingData, setViewingData] = useState([])
  const [viewingSentData, setViewingSentData] = useState()

  const changeTime = (string_date) => {
    const data_time = string_date.split(':')
    const hours =
      Number(data_time[0]) > 12
        ? '0' + Number(data_time[0] - 12).toString()
        : data_time[0]
    const suffix = Number(data_time[0]) > 12 ? 'PM' : 'AM'
    return `${hours}:${data_time[1]} ${suffix}`
  }

  async function fetchTenantViewingProp() {
    const user_profile_id = authService.getLoggedUserData().user_profile_id // Use service

    const tenantResponse =
      await TenantViewingService.getRequestViewingBasedOnUserProfile(
        user_profile_id
      )

    setTenantRequestData(tenantResponse)
  }

  async function fetchPropertyViewingDates(array) {
    const viewingResponse = await TenantViewingService.getListViewingDateBasedOnId(array)
    console.log("Viewing Dates Response", viewingResponse);
    setViewingData(viewingResponse)
  }

  // Update 20 May 2023 @Rainer
  useEffect(() => {
    fetchTenantViewingProp() // On Page load, fetch tenant viewing prop data
  }, [])

  useEffect(() => {
    // When tenant request data is loaded, check if not null
    if(tenantRequestData){
      const arrayOfViewingDateId = tenantRequestData.map(
        (data) => data.property_viewing_date_id
      )

      if (arrayOfViewingDateId.length > 0) setArrayOfCurrentlyRequestedViewingDateIds(arrayOfViewingDateId) // Save the viewing date id to the state
    }

  }, [tenantRequestData])

  useEffect(() => {
    if(arrayOfCurrentlyRequestedViewingDateIds) {
      fetchPropertyViewingDates(arrayOfCurrentlyRequestedViewingDateIds);
    }
  }, [arrayOfCurrentlyRequestedViewingDateIds])
  
  

  //TODO: check nested await function
  // useEffect(() => {
  //   // fetchTenantViewingProp()
  //   const arrayOfViewingDateId = tenantRequestData.map(
  //     (data) => data.property_viewing_date_id
  //   )
  //   fetchPropertyViewingDate(arrayOfViewingDateId)
  // }, [tenantRequestData])

  const modalViewingAcceptHandler = (index) => {
    setViewingSentData(JSON.parse(index))
    setActionModal('accept')
    setTypeModal('viewing')
    setModal(!modal)
  }

  const modalViewingRejectHandler = (index) => {
    setViewingSentData(JSON.parse(index))
    setActionModal('reject')
    setTypeModal('viewing')
    setModal(!modal)
  }

  return (
    <div className="manage-request__body">
      {modal && (
        <ModalManageOffer
          data_viewing={viewingSentData}
          action={actionModal}
          type={typeModal}
          hide={() => setModal(false)}
        />
      )}
      <InfoBox
        info={
          'Viewing requests will be rejected automatically if there is no response within 48 hours of the time of request.'
        }
      />
      <div className="manage-request__section-container">
        <div className="manage-request__flex-container">
          <div className="manage-request__reminder-image">
            <img src={ManageRequestPic} alt="illust1" />
          </div>
          <div className="manage-request__reminder-container">
            <h1 className="manage-request__reminder-text-upper">
              A gentle reminder...
            </h1>
            <p className="manage-request__reminder-text-down">
              You have 1 outstanding request that requires your attention.
            </p>
          </div>
        </div>
        {tenantRequestData && viewingData ? (
          viewingData.map((data, index) => {
            const viewing_today = tenantRequestData.filter(
              (viewing_data) =>
                viewing_data.property_viewing_date_id === data.id
            )
            const viewing_date = new Date(data.viewing_date)
            return (
              <>
                <div className="manage-request__section-container">
                  <div className="manage-request__flex-container gap8">
                    <div className="manage-request__date-container">
                      <h1 className="manage-request__date-text">
                        {viewing_date.getDate()}
                      </h1>
                      <h1 className="manage-request__date-text">
                        {monthOptions[viewing_date.getMonth()]}
                      </h1>
                      <div className="manage-request__day-container">
                        <h1 className="manage-request__day-text">
                          {dayOptions[viewing_date.getDay()]}
                        </h1>
                      </div>
                    </div>
                    <div className="manage-request__request-container">
                      <div className="manage-request__request-time-container">
                        <h1 className="manage-request__request-time-text">
                          {changeTime(viewingData[index].time_start)} -{' '}
                          {changeTime(viewingData[index].time_end)}
                        </h1>
                      </div>
                      {viewing_today.map((viewing_data, viewing_index) => {
                        const created_date = new Date(viewing_data.created_date)
                        created_date.setDate(created_date.getDate() + 2)
                        const between_time = timeCounterFromNow(created_date)
                        return (
                          <>
                            {' '}
                            {viewing_data.status === 1 ? (
                              <div className="manage-request__section-container">
                                <div className="manage-request__request-item">
                                  <div className="manage-request__warning-container">
                                    <div style={{ width: '1.6rem' }}>
                                      <WarningIcon className="manage-request__warning-icon" />
                                    </div>
                                    <p className="manage-request__warning-text">
                                      Request expiring in {between_time}
                                    </p>
                                  </div>
                                  <div className="manage-request__status-container">
                                    <div className="manage-request__status-dot pending"></div>
                                    <h1 className="manage-request__status-text pending-text">
                                      Pending Action
                                    </h1>
                                  </div>
                                  <div className="manage-request__name-container">
                                    <h1 className="manage-request__name-text">
                                      {viewing_data.name}
                                    </h1>
                                    <div className="manage-request__comment-container">
                                      <div className="manage-request__comment-counter">
                                        <h1>3</h1>
                                      </div>
                                      <ChatLogo className="manage-request__comment-icon" />
                                    </div>
                                  </div>
                                  <div className="manage-request__btn-container">
                                    <button
                                      value={JSON.stringify(viewing_data)}
                                      className="manage-request__reject-btn"
                                      // onClick={modalHandler({'accept', 'viewing'})}
                                      onClick={(e) => {
                                        modalViewingRejectHandler(
                                          e.target.value
                                        )
                                      }}
                                    >
                                      Reject
                                    </button>
                                    <button
                                      value={JSON.stringify(viewing_data)}
                                      className="manage-request__accept-btn"
                                      onClick={(e) => {
                                        console.log(e.target.value)
                                        modalViewingAcceptHandler(
                                          e.target.value
                                        )
                                      }}
                                    >
                                      Accept
                                    </button>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div className="manage-request__section-container">
                                <div className="manage-request__request-item">
                                  <div className="manage-request__status-container">
                                    <div
                                      className={
                                        'manage-request__status-dot ' +
                                        tenantViewingStatusOptions[
                                          viewing_data.status
                                        ].toLowerCase()
                                      }
                                    ></div>
                                    <h1
                                      className={
                                        'manage-request__status-text ' +
                                        tenantViewingStatusOptions[
                                          viewing_data.status
                                        ].toLowerCase() +
                                        '-text'
                                      }
                                    >
                                      {
                                        tenantViewingStatusOptions[
                                          viewing_data.status
                                        ]
                                      }
                                    </h1>
                                  </div>
                                  <div className="manage-request__name-container">
                                    <h1 className="manage-request__name-text">
                                      {viewing_data.name}
                                    </h1>
                                    <div className="manage-request__comment-container">
                                      <div className="manage-request__comment-counter">
                                        <h1>5</h1>
                                      </div>
                                      <ChatLogo className="manage-request__comment-icon" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </>
                        )
                      })}
                    </div>
                  </div>
                </div>
              </>
            )
          })
        ) : (
          <div>Loading...</div>
        )}
      </div>
    </div>
  )
}

export default ManageRequestViewing
