import React from 'react'
import ReactDOM from 'react-dom'
import { ReactComponent as CheckIcon } from '../../assets/icon/general/Vector-42-check.svg'
import '../../assets/css/components/ui/popUpSystemNotif.css'

function PopUpSystemNotif({active}) {
	return ReactDOM.createPortal (
		<div className={active ? 'popupsys popupsys--active' : 'popupsys'}>
			<div className="popupsys__box">
				<CheckIcon />
				<p>2FA Method changed to SMS successfully</p>
			</div>
		</div>,
		document.getElementById('portal')
	)
}

export default PopUpSystemNotif