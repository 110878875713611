import React from 'react'
import HeadBack from '../../components/ui/HeadBack'
import AccordionActions from './components/AccordionActions'
import InfoBox from '../../components/ui/InfoBox'
import '../../assets/css/pages/LeasePage/inventoryList.css'

function InventoryList() {
  return (
    <div className="invlist">
      <HeadBack title="Inventory List" backLink="/lease/tenant" />

      <div className="invlist-body">
				<InfoBox info="The edits made here are in real-time. To see the latest updates, pull down the screen to refresh." />

        <div className="invlist-accordion">
          <AccordionActions
            title="Living Room"
            type="invlist"
            btnType="addInventory"
          />
					<AccordionActions
            title="Bedroom"
            type="invlist"
            btnType="addInventory"
          />
					<AccordionActions
            title="Kitchen / Service Yard"
            type="invlist"
            btnType="addInventory"
          />
					<AccordionActions
            title="Study"
            type="invlist"
            btnType="addInventory"
          />
					<AccordionActions
            title="Other Area(s)"
            type="invlist"
            btnType="addInventory"
          />
        </div>
      </div>
    </div>
  )
}

export default InventoryList
