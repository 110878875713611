import React, { useEffect, useState } from 'react'
// import BrowseItemList from '../../components/browse/BrowseItemList'
import CardProperty from '../../components/ui/CardProperty'
// Import components
import SearchBrowse from '../../components/browse/SearchBrowse'
import '../../assets/css/pages/BrowsePage/browse.css'
import { NavLink, useLocation, useSearchParams } from 'react-router-dom'
import propertyService from '../../services/PropertyService'
import propertyLikedService from '../../services/propertyLikedService'
import authService from '../../services/AuthService'
import Blurred from '../../assets/images/image blur card.png'
import Button from '../../components/ui/Button'
import Navbar from '../../components/ui/web/Navbar/Navbar'
import Maps from '../../components/ui/web/Maps/Maps'
import { ReactComponent as InfoIcon } from '../../assets/icon/general/Vector-18-info.svg'
import { ReactComponent as FilterList } from '../../assets/icon/general/Vector-28-filterlist.svg'

function Browse() {
  const [searchParams, setSearchParams] = useSearchParams()
  const [propertyResults, setPropertyResults] = useState(null)
  const [likedProperties, setLikedProperties] = useState([])
  const location = useLocation()
  console.log(location.state)
  const [checker, setChecker] = useState()
  const [liked, setLiked] = useState(false)

  const directEditLiked = (bool) => {
    setLiked(bool)
  }

  // NOTE: Untuk fetch tolong pisahkan spt ini
  const fetchPropertiesData = async () => {
    const propertyList = await propertyService.getAll(searchParams.get('_start'), searchParams.get('end'))
    setPropertyResults(propertyList)
  }

  useEffect(() => {
    if (location.state) {
      setChecker(location.state.var)
    }
  }, [location.state])

  useEffect(() => {
    fetchPropertiesData()
  }, [])

  return propertyResults === null ? (
    <>Loading...</>
  ) : (
    <div class="browse-page">
      <Navbar />
      <SearchBrowse check={checker} />

      <div className="browse-page__con">
        <div className="con-body">
          <div className="con-body__list-info-container">
            <div className="search-browse__list-card">
              <InfoIcon />
              <h1>4,961 listings found</h1>
            </div>

            <div className="search-browse__list-filter">
              <FilterList className="search-browse__list-filter-icon" />
              <h1>Newest</h1>
            </div>
          </div>

          <div className="browse-page__body">
            {propertyResults.map((property) => {
              return (
                <NavLink
                  className="card-property__link"
                  to={`/browse/tenant-detail/${property.id}`}
                >
                  <CardProperty
                    type="browse"
                    data={property}
                    liked={liked}
                    setLiked={directEditLiked}
                  />
                </NavLink>
              )
            })}

            <div className="card-property__relative-container">
              <img src={Blurred} />

              <div className="card-property__blurred-text-container">
                <h1 className="card-property__blurred-text-title">
                  Want to see more?
                </h1>
                <p className="card-property__blurred-text">
                  Login or sign up now to unlock unlimited browsing.
                </p>

                <div className="card-property__blurred-button-container">
                  <div className="card-property__blurred-button">
                    <Button
                      type="2"
                      buttonName="Login"
                      color="orange"
                      allow="true"
                      directURL="/login/:userType"
                    />
                  </div>
                  <div className="card-property__blurred-button">
                    <Button
                      type="2"
                      buttonName="Sign up"
                      color="orange"
                      allow="true"
                      directURL="/signup/:userType"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Maps />
      </div>
    </div>
  )
}

export default Browse
