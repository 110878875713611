import { ReactComponent as Chair } from '../../../assets/icon/general/Vector-58-chair.svg'
import { ReactComponent as Bed } from '../../../assets/icon/general/Vector-4-bed.svg'
import { ReactComponent as Desk } from '../../../assets/icon/general/Vector-62-desk.svg'
import { ReactComponent as Fridge } from '../../../assets/icon/general/Vector-59-fridge.svg'


export const amenitiesData = [
    {
      icon: <Chair />,
      title: 'Living Room',
      content: (
        <>
          <li>Bed</li>
          <li>Ceiling Fan</li>
          <li>Aircon</li>
          <li>Wardrobe</li>
          <li>Vanity</li>
        </>
      ),
    },
    {
      icon: <Bed />,
      title: 'Bedroom',
      content: (
        <>
          <li>Bed</li>
          <li>Ceiling Fan</li>
          <li>Aircon</li>
          <li>Wardrobe</li>
          <li>Vanity</li>
        </>
      ),
    },
    {
      icon: <Desk />,
      title: 'Study Room',
      content: (
        <>
          <li>Bed</li>
          <li>Ceiling Fan</li>
          <li>Aircon</li>
          <li>Wardrobe</li>
          <li>Vanity</li>
        </>
      ),
    },
    {
      icon: <Fridge />,
      title: 'Kitchen & Service Yard',
      content: (
        <>
          <li>Bed</li>
          <li>Ceiling Fan</li>
          <li>Aircon</li>
          <li>Wardrobe</li>
          <li>Vanity</li>
        </>
      ),
    },
  ]