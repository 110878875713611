import React from 'react'
import { ReactComponent as PlusIcon } from '../../../assets/icon/general/Vector-plus.svg';
import { ReactComponent as MasterCardIcon } from '../../../assets/icon/general/Vector-master-card.svg';
import { ReactComponent as VisaIcon } from '../../../assets/icon/general/Vector-visa.svg';

function PaymentOutlet() {
	const isFilled = false
	return (
		<div className='payment__outlet-1'>
			<div className='number__cards'>
				<button className='card__payment card__payment--active'>
					<div>
						<p>**** **** **** 4567</p>
						<p>01 / 23</p>
					</div>

					<div>
						<MasterCardIcon />
					</div>
				</button>

				<button className='card__payment'>
					<div>
						<p>**** **** **** 4567</p>
						<p>01 / 23</p>
					</div>

					<div>
						<VisaIcon />
					</div>
				</button>
			</div>

			<button className='button__style-5 button__style-5--active'>
				<PlusIcon />
				<p>Add a new debit / credit card</p>
			</button>

			<div className='onboarding__button'>
					<button className={`${isFilled ? 'button__style-3 button__style-3--active' : 'button__style-3'}`} type="button">Continue</button>
				</div>
		</div>
	)
}

export default PaymentOutlet