import React from 'react'
import LeaseCardProperty from '../LeaseCardProperty'

function LeaseTenantNewOutlet3() {
	return (
		<div className='lease__t-body-outlet'>
			<LeaseCardProperty cardLeaseType="tenancyAgreement" />
		</div>
	)
}

export default LeaseTenantNewOutlet3