import axios from 'axios'
import authService from './AuthService'
import { apiBaseURL } from '../utils'


const getAllFromPropID = async (id) => {
    const response = await axios.get(`${apiBaseURL}/properties/${id}/property_viewing_dates`)

    return response
}

const getOne = async (id) => {
    const response = await axios.get(`${apiBaseURL}/property_viewing_dates/${id}`)

    return response
}

const createOne = async (data) => {
    const response = await axios.post(`${apiBaseURL}/property_viewing_dates`, data)

    return response
}

const updateOne = async (id, data) => {
    const response = await axios.patch(`${apiBaseURL}/property_viewing_dates/${id}`, data)

    return response
}

const deleteOne = async (id) => {
    const response = await axios.delete(`${apiBaseURL}/property_viewing_dates/${id}`)

    return response
}

const propertyViewingDateService = {
    getAllFromPropID,
    createOne,
    deleteOne,
    getOne,
    updateOne,
}

export default propertyViewingDateService