import React, { useEffect, useState } from 'react'
import { ReactComponent as Back } from '../../../assets/icon/general/Vector-1-back.svg'
import { ReactComponent as Info } from '../../../assets/icon/general/Vector-18-info.svg'

import { ReactComponent as Snow } from '../../../assets/icon/general/Vector-77-snow.svg'
import { ReactComponent as WaterDrop } from '../../../assets/icon/general/Vector-72-water-drop.svg'
import { ReactComponent as Cleaning } from '../../../assets/icon/general/Vector-73-cleaning.svg'
import { ReactComponent as Electrical } from '../../../assets/icon/general/Vector-78-electrical.svg'

import '../../../assets/css/pages/LeasePage/services.css'

import { useNavigate } from 'react-router-dom'
import ServiceCardItem from '../../../components/LeasePage/Services/ServiceCardItem'
import ModalServicing from '../../../components/LeasePage/Landlord/Modal/ModalServicing'

const Services = () => {
  const [isShow, setIsShow] = useState(false)
  const [isDismiss, setIsDismiss] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    if (!isDismiss) {
      setTimeout(() => {
        setIsShow(true)
      }, 500)
    }
  }, [isDismiss])

  return (
    <div>
      {isShow && (
        <ModalServicing
          show={isShow}
          hide={() => setIsShow(false)}
          onDismiss={() => {
            setIsDismiss(true)
            setIsShow(false)
          }}
        />
      )}
      <div className="onboarding__nav">
        <div className="onboarding__head">
          <div className="signup__back" onClick={() => navigate(-1)}>
            <Back />
          </div>

          <h1>Services</h1>
        </div>
      </div>

      <div className="service-content">
        <div className="guide-information-box">
          <div className="guide-information-box_icon">
            <Info />
          </div>
          <p className="text-sm guide-information-box_desc">
            If you opt in for P.Lease-certified partner services, you will be
            quoted a flat pricing which will either be billed to you or your
            tenant depending on your setting when your property lease starts.
            Learn more. <br></br>{' '}
            <span className="information-box-direct">Learn More</span>
          </p>
        </div>

        <div>
          <ServiceCardItem
            icon={<Snow />}
            service="Aircon Servicing"
            url="/lease/services/aircon"
          />
          <ServiceCardItem icon={<Cleaning />} service="General Cleaning" />
          <ServiceCardItem icon={<WaterDrop />} service="Plumbing & Sanitary" />
          <ServiceCardItem icon={<Electrical />} service="Electrical Works" />
        </div>
      </div>
    </div>
  )
}

export default Services
