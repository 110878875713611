import axios from 'axios';

const setAuthToken = (token) => {
  axios.defaults.headers.common['X-Access-token'] = '';
  delete axios.defaults.headers.common['X-Access-token'];

  if (token) {
    axios.defaults.headers.common['X-Access-token'] = `${token}`;
  }
}

export default setAuthToken;