import React from 'react'
import '../../../assets/css/components/LeasePage/Tenant/leaseTenantCurrentOutlet1.css'
import LeaseCurrentBox from './LeaseCurrentBox'
import {dataCurrentLeaseDetails} from '../../../assets/dummyData/dataCurrentLeaseDetails.js'
import AccordionItem from '../../../pages/LeasePage/components/AccordionItem'

function LeaseTenantCurrentOutlet1() {
	return (
		<div className="current1">
			<LeaseCurrentBox title="Lease Details" outlet="details" handover={true} />

			<div className="current1__important">
				<span className="important__title">Important Terms of Lease</span>

				{/* {dataCurrentLeaseDetails.map(({ title, content, index }) => (
					<AccordionItem title={title} content={content} type='currentLeaseDetails' key={index} />
				))} */}

				<AccordionItem title="Lease Period" active={true} type='currentLeaseDetails' />
				<AccordionItem title="Lease Period" active={false} type='currentLeaseDetails' />
			</div>
		</div>
	)
}

export default LeaseTenantCurrentOutlet1