import React, { useState } from 'react'
import { Splide, SplideSlide } from '@splidejs/react-splide'

import '../../../assets/css/pages/BrowsePage/Tenant/tenant-details.css'
import ImgPlaceholder from '../../../assets/images/image 5.png'
import { ReactComponent as Back } from '../../../assets/icon/general/Vector-1-back.svg'
import { ReactComponent as Chat } from '../../../assets/icon/general/Vector-6-chatbubble.svg'
import { ReactComponent as Calender } from '../../../assets/icon/general/Vector-12-calendardoted.svg'
import { ReactComponent as Dollar } from '../../../assets/icon/general/Vector-66-dollar.svg'
import { ReactComponent as ImageIcon } from '../../../assets/icon/general/Vector-34-image.svg'
import { ReactComponent as SwipeIcon } from '../../../assets/icon/general/Vector-35-swipe.svg'
import TenantDetailsOutlet0 from '../../../components/browse/Tenant/TenantDetailsOutlet0'
import TenantDetailsOutlet1 from '../../../components/browse/Tenant/TenantDetailsOutlet1'
import TenantDetailsOutlet2 from '../../../components/browse/Tenant/TenantDetailsOutlet2'
import TenantDetailsOutlet3 from '../../../components/browse/Tenant/TenantDetailsOutlet3'
import { useNavigate, useParams } from 'react-router-dom'
import BackButton from '../../../components/ui/BackButton'
import { useEffect } from 'react'
import { apiBaseURL } from '../../../utils'
import axios from 'axios'

function TenantDetails() {
  const navigate = useNavigate()

  const [tenantDetails, setTenantDetails] = useState(0)
  const [detailData, setDetailData] = useState(null)
  const [faqData, setFaqData] = useState(null)
  const [countLikeData, setCountLikeData] = useState(null)
  const [propertyFeaturedPhotoData, setPropertyFeaturedPhotoData] = useState([])
  const params = useParams()


  const fetchPropertiesDetail = async (id) => {
    const detailResponse = await axios.get(`${apiBaseURL}/properties/${id}`)
    const faqResponse = await axios.get(
      `${apiBaseURL}/properties/${id}/property_faqs`
    )
    const countLikeResponse = await axios.get(
      `${apiBaseURL}/properties/${id}/property_likes/counts`
    )
    const featuredPhotoResponse = await axios.get(
      `${apiBaseURL}/properties/${id}/featured_photos`
    )
    setDetailData(detailResponse.data)
    setFaqData(faqResponse.data)
    setCountLikeData(countLikeResponse.data)
    setPropertyFeaturedPhotoData(featuredPhotoResponse.data)
  }

  useEffect(() => {
    fetchPropertiesDetail(params.propertyId)
  }, [])

  return detailData === null ? (
    <>Loading...</>
  ) : (
    <section class="tenant-details">
      {/* Header */}
      <div className="tenant-details__header">
        <div className="tenant-details__header-back">
          <BackButton directURL="/browse" />
        </div>

        <div className="tenant-detail__header-image-slider">
          {propertyFeaturedPhotoData !== null ? (
            <Splide
              options={{
                perPage: 1,
                pagination: false,
                arrows: false,
              }}
            >
              {propertyFeaturedPhotoData.length > 0 ? (
                propertyFeaturedPhotoData.map((fp) => {
                  return (
                    <SplideSlide>
                      <img
                        className="detail-slider__image"
                        src={fp.asset_location}
                      />
                    </SplideSlide>
                  )
                })
              ) : (
                <SplideSlide>
                  <img
                    className="detail-slider__image"
                    src="https://placehold.co/600x400"
                  />
                </SplideSlide>
              )}
            </Splide>
          ) : (
            <>Loading...</>
          )}
        </div>

        <div className="tenant-detail__header-image-info">
          <div className="tenant-detail__header-image-info-wrapper">
            <div>
              <ImageIcon />
              <p>{propertyFeaturedPhotoData.length}</p>
            </div>

            <div>
              <SwipeIcon />
              <p>Swipe</p>
            </div>
          </div>
        </div>
      </div>

      <div className="details__wrapper">
        {/* Tab */}
        <div class="lease__t-head details__head">
          <div class="head__sub-tab">
            <div className="head__sub-tab-outlet head__sub-tab-outlet--2">
              <button
                className={
                  tenantDetails === 0
                    ? 'button__sub-tab button__sub-tab--active'
                    : 'button__sub-tab'
                }
                onClick={() => setTenantDetails(0)}
              >
                Property Details
              </button>
              <button
                className={
                  tenantDetails === 1
                    ? 'button__sub-tab button__sub-tab--active'
                    : 'button__sub-tab'
                }
                onClick={() => setTenantDetails(1)}
              >
                Amenities / Facilities
              </button>
              <button
                className={
                  tenantDetails === 2
                    ? 'button__sub-tab button__sub-tab--active'
                    : 'button__sub-tab'
                }
                onClick={() => setTenantDetails(2)}
              >
                What's Nearby
              </button>
              <button
                className={
                  tenantDetails === 3
                    ? 'button__sub-tab button__sub-tab--active'
                    : 'button__sub-tab'
                }
                onClick={() => setTenantDetails(3)}
              >
                Listing Insights
              </button>
            </div>
          </div>
        </div>

        {/* Body */}
        <div class="tenant-details__body">
          {tenantDetails === 0 && (
            <TenantDetailsOutlet0
              detailData={detailData}
              faqData={faqData}
              countLikeData={countLikeData}
            />
          )}
          {tenantDetails === 1 && (
            <TenantDetailsOutlet1 propertyId={params.propertyId} />
          )}
          {tenantDetails === 2 && <TenantDetailsOutlet2 />}
          {tenantDetails === 3 && <TenantDetailsOutlet3 />}
        </div>
      </div>

      {/* Footer */}
      <div class="tenant-details__footer">
        <button className="tenant-details-button">
          <Chat />
          Enquire
        </button>
        <button
          className="tenant-details-button"
          onClick={() =>
            navigate(`/details/schedule/${params.propertyId}`, {
              state: {
                property: { detailData },
              },
            })
          }
        >
          <Calender />
          Viewing
        </button>
        <button
          className="tenant-details-offerBtn"
          onClick={() => navigate(`/makeoffer/${params.propertyId}`)}
        >
          <Dollar />
          Offer
        </button>
      </div>
    </section>
  )
}

export default TenantDetails
