import logo from './logo.svg'
// import './App.css'
import RouterView from './router/router'

function App() {
  return (
    <main className="main">
      <RouterView />
    </main>
  )
}

export default App
