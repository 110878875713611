
const type = {
  PROPERTY_PHOTO: 1,
  FLOOR_PLAN: 2,
  PROOF_OF_PROPERTY_OWNERSHIP: 3
}

const propertyPhotoConstants = {
  type
}

export default propertyPhotoConstants