import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import { ReactComponent as InfoIcon } from '../../assets/icon/general/Vector-24-hexa-exclamation.svg'
import '../../assets/css/components/ui/modalSystemNotif.css'

function ModalSystemNotif({ hide, title, message, isProtectedRouteMessage = false }) {
	return ReactDOM.createPortal(
		<div className='modsys'>
			<div className="modsys__overlay" onClick={hide}></div>

			<div className="modsys__box">
				<div className="modsys__icon">
					<InfoIcon />
				</div>

				<div className="modsys__text">
					<p>{title}</p>
					<p>{message}</p>
				</div>

				<div className="modsys__actions">
					{
						!isProtectedRouteMessage && (
							<button className='modsys__btn modsys__btn--active'>
								Report Issue
							</button>
						)
					}

					<button className={`modsys__btn ${isProtectedRouteMessage && 'modsys__btn--active'}`} onClick={hide}>
						{ isProtectedRouteMessage ? "Sign In" : "Dismiss"}
					</button>
				</div>
			</div>
		</div>,
		document.getElementById('portal')
	)
}

export default ModalSystemNotif