import React, { useState } from 'react'
// Import assets
import '../../assets/css/components/browse/searchBrowse.css'
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide'
// Import icons
import { ReactComponent as LensLogo } from '../../assets/icon/general/Vector-lens.svg'
import { ReactComponent as NavigationLogo } from '../../assets/icon/general/Vector-82-navigation.svg'
import { ReactComponent as FilterLogo } from '../../assets/icon/general/Vector-29-filter.svg'
import { ReactComponent as LocationIcon } from '../../assets/icon/general/Vector-20-pin.svg'
import { ReactComponent as HouseIcon } from '../../assets/icon/general/Vector-17-house.svg'
import { ReactComponent as TypeIcon } from '../../assets/icon/general/Vector-84-piechart.svg'
import { ReactComponent as BedIcon } from '../../assets/icon/general/Vector-4-bed.svg'
import { ReactComponent as CoinIcon } from '../../assets/icon/general/Vector-21-coin.svg'
import { ReactComponent as DotedCalIcon } from '../../assets/icon/general/Vector-12-calendardoted.svg'
import { ReactComponent as ClipboardIcon } from '../../assets/icon/general/Vector-3-clipboard.svg'
import { ReactComponent as InfoIcon } from '../../assets/icon/general/Vector-18-info.svg'
import { ReactComponent as CloseIcon } from '../../assets/icon/general/Vector-9-close.svg'
import { ReactComponent as FilterList } from '../../assets/icon/general/Vector-28-filterlist.svg'

import { ReactComponent as CalendarIcon } from '../../assets/icon/general/Vector-5-calendar.svg'
import { ReactComponent as StackIcon } from '../../assets/icon/general/Vector-19-layer.svg'
import { ReactComponent as TableIcon } from '../../assets/icon/general/Vector-11-furniture.svg'


import { NavLink, useNavigate } from 'react-router-dom'

const SearchBrowse = (check) => {
  const [filtered, setFiltered] = useState(false)
  const navigate = useNavigate()
  return (
    <div className="search-browse">
      {/* Search Input Container */}
      <div className="search-browse__input-container">
        <div
          className="search-browse__search-input"
          onClick={() => navigate('/browse/search')}
        >
          <div className="search-input__lens">
            <LensLogo />
          </div>
          <input
            className="search-input__input"
            type="text"
            placeholder="Search by location"
          />
          <div className="search-input__navigation">
            <NavigationLogo />
          </div>
        </div>

        <div
          className="search-browse__filter"
          onClick={() => navigate('/browse/filter')}
        >
          <div className="search-browse__filter-icon">
            <FilterLogo />
          </div>
          <h1>Filter</h1>
        </div>
      </div>

      {/* Search Browse Icon Container */}
      <div className="search-browse__icons-container">
        <Splide
          hasTrack={false}
          options={{
            perPage: 2,
            pagination: false,
            arrows: false,
          }}
        >
          <SplideTrack>
            <SplideSlide onClick={() => console.log('hallo')}>
              <button
                className={
                  filtered
                    ? 'button__sub-tab--browse button__sub-tab--active'
                    : 'button__sub-tab--browse'
                }
              >
                <LocationIcon className="search-browse__svg" />
                {filtered && (
                  <div className="sub__wrapp">
                    <span>Hallo</span>
                    <CloseIcon />
                  </div>
                )}
              </button>
            </SplideSlide>
            <SplideSlide>
              <button
                className={
                  filtered
                    ? 'button__sub-tab--browse button__sub-tab--active'
                    : 'button__sub-tab--browse'
                }
              >
                <HouseIcon className="search-browse__svg" />
                {filtered && (
                  <div className="sub__wrapp">
                    <span>Hallo</span>
                    <CloseIcon />
                  </div>
                )}
              </button>
            </SplideSlide>
            <SplideSlide>
              <button
                className={
                  filtered
                    ? 'button__sub-tab--browse button__sub-tab--active'
                    : 'button__sub-tab--browse'
                }
              >
                <TypeIcon className="search-browse__svg" />
                {filtered && (
                  <div className="sub__wrapp">
                    <span>Hallo</span>
                    <CloseIcon />
                  </div>
                )}
              </button>
            </SplideSlide>
            <SplideSlide>
              <button
                className={
                  filtered
                    ? 'button__sub-tab--browse button__sub-tab--active'
                    : 'button__sub-tab--browse'
                }
              >
                <BedIcon className="search-browse__svg" />
                {filtered && (
                  <div className="sub__wrapp">
                    <span>Hallo</span>
                    <CloseIcon />
                  </div>
                )}
              </button>
            </SplideSlide>
            <SplideSlide>
              <button
                className={
                  filtered
                    ? 'button__sub-tab--browse button__sub-tab--active'
                    : 'button__sub-tab--browse'
                }
              >
                <CoinIcon className="search-browse__svg" />
                {filtered && (
                  <div className="sub__wrapp">
                    <span>Hallo</span>
                    <CloseIcon />
                  </div>
                )}
              </button>
            </SplideSlide>
            <SplideSlide>
              <button
                className={
                  filtered
                    ? 'button__sub-tab--browse button__sub-tab--active'
                    : 'button__sub-tab--browse'
                }
              >
                <DotedCalIcon className="search-browse__svg" />
                {filtered && (
                  <div className="sub__wrapp">
                    <span>Hallo</span>
                    <CloseIcon />
                  </div>
                )}
              </button>
            </SplideSlide>
            <SplideSlide>
              <button
                className={
                  filtered
                    ? 'button__sub-tab--browse button__sub-tab--active'
                    : 'button__sub-tab--browse'
                }
              >
                <ClipboardIcon className="search-browse__svg" />
                {filtered && (
                  <div className="sub__wrapp">
                    <span>Hallo</span>
                    <CloseIcon />
                  </div>
                )}
              </button>
            </SplideSlide>

            <SplideSlide>
              <button
                className={
                  filtered
                    ? 'button__sub-tab--browse button__sub-tab--active'
                    : 'button__sub-tab--browse'
                }
              >
                <TableIcon className="search-browse__svg" />
                {filtered && (
                  <div className="sub__wrapp">
                    <span>Hallo</span>
                    <CloseIcon />
                  </div>
                )}
              </button>
            </SplideSlide>

            <SplideSlide>
              <button
                className={
                  filtered
                    ? 'button__sub-tab--browse button__sub-tab--active'
                    : 'button__sub-tab--browse'
                }
              >
                <StackIcon className="search-browse__svg" />
                {filtered && (
                  <div className="sub__wrapp">
                    <span>Hallo</span>
                    <CloseIcon />
                  </div>
                )}
              </button>
            </SplideSlide>

            <SplideSlide>
              <button
                className={
                  filtered
                    ? 'button__sub-tab--browse button__sub-tab--active'
                    : 'button__sub-tab--browse'
                }
              >
                <CalendarIcon className="search-browse__svg" />
                {filtered && (
                  <div className="sub__wrapp">
                    <span>Hallo</span>
                    <CloseIcon />
                  </div>
                )}
              </button>
            </SplideSlide>
          </SplideTrack>
        </Splide>
      </div>

      {/* Search Browse */}
      <div className="search-browse__list-info-container">
        <div className="search-browse__list-card">
          <InfoIcon />
          <h1>4,961 listings found</h1>
        </div>

        <div className="search-browse__list-filter">
          <FilterList className="search-browse__list-filter-icon" />
          <h1>Newest</h1>
        </div>
      </div>
    </div>
  )
}

export default SearchBrowse
