import './loi.css'
import logo from '../../../assets/logo/propnex.png'

function LOI({loiDocRef}) {
  return (
    <div className="loi" ref={loiDocRef}>
      <div className="loi-1">
        <div className="loi-head">
          <img src={logo} alt="logo-propnex" />
          <p>
            480 Lorong 6 Toa Payoh, #10/11-01 HDB Hub East Wing, Singapore
            310480 • Tel: (65) 6820 8000, Fax: (65) 6829 6600 •{' '}
            <a href="https://www.propnex.com/" target="_blank">
              www.PropNex.com
            </a>
          </p>

          <div className="loi-title">
            <p>LETTER OF INTENT (RESIDENTIAL)</p>
          </div>
        </div>

        <div className="loi-content">
          <div className="content content-desktop">
            <div className="content__grid">
              <p className='title1'>Date</p>
              <p className='title2'></p>
              <p className="dot">:</p>
              <p className="value"></p>
            </div>
            <div className="content__grid">
              <p>To the Landlord:</p>
              <p>Name</p>
              <p className="dot">:</p>
              <p className="value"></p>
            </div>
            <div className="content__grid">
              <p></p>
              <p>NRIC No.</p>
              <p className="dot">:</p>
              <p className="value"></p>
            </div>
            <div className="content__grid">
              <p></p>
              <p>Address</p>
              <p className="dot">:</p>

              <div className="content__grid-sub">
                <p className="value">
                  {/* Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Voluptates architecto fuga aspernatur cum? Vero pariatur
                  consequatur voluptatum exercitationem asperiores quas animi
                  sint placeat deserunt dolor cum, nesciunt molestiae
                  consectetur non. */}
                </p>
                <div className="singapore">
                  <p>Singapore</p>
                  <p className="value"></p>
                </div>
              </div>
            </div>
            <div className="content__grid">
              <p>Dear Sirs</p>
            </div>
          </div>

					<div className="content content-mobile">
            <div className="content__grid">
              <p>Date :</p>
              <p className="value"></p>
            </div>
            <div className="content__grid">
              <p>To the Landlord : Name :</p>
              <p className="value"></p>
            </div>
            <div className="content__grid">
              <p>NRIC No. :</p>
              <p className="value"></p>
            </div>
            <div className="content__grid">
              <p>Address :</p>

              <div className="content__grid-sub">
                <p className="value">
                  {/* Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Voluptates architecto fuga aspernatur cum? Vero pariatur
                  consequatur voluptatum exercitationem asperiores quas animi
                  sint placeat deserunt dolor cum, nesciunt molestiae
                  consectetur non. */}
                </p>
                <div className="singapore">
                  <p>Singapore</p>
                  <p className="value"></p>
                </div>
              </div>
            </div>
            <div className="content__grid">
              <p>Dear Sirs</p>
            </div>
          </div>

          <div className="content content-desktop">
            <div className="content__grid col2">
              <p>RE: Property known as</p>

              <div className="content__grid-sub">
                <p className="value bold">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Voluptates architecto fuga aspernatur cum? Vero pariatur
                  consequatur voluptatum exercitationem asperiores quas animi
                  sint placeat deserunt dolor cum, nesciunt molestiae
                  consectetur non.
                </p>

                <div className="singapore">
                  <p>Singapore</p>
                  <p className="value bold"></p>
                </div>
              </div>
            </div>

            <div>
              <p>
                We are pleased to inform you that our prospect has confirmed
                with us of his/her intention to lease the above mentioned
                premises based on the following terms and conditions: -
              </p>
            </div>
          </div>

					<div className="content content-mobile">
            <div className="content__grid col2">
              <p>RE: Property known as</p>

              <div className="content__grid-sub">
                <p className="value bold">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Voluptates architecto fuga aspernatur cum? Vero pariatur
                  consequatur voluptatum exercitationem asperiores quas animi
                  sint placeat deserunt dolor cum, nesciunt molestiae
                  consectetur non.
                </p>

                <div className="singapore">
                  <p>Singapore</p>
                  <p className="value bold"></p>
                </div>
              </div>
            </div>

            <div>
              <p>
                We are pleased to inform you that our prospect has confirmed
                with us of his/her intention to lease the above mentioned
                premises based on the following terms and conditions: -
              </p>
            </div>
          </div>

          <div className="content content-desktop">
            <div className="content__grid col3">
              <p>Name of Tenant</p>
              <p className="dot">:</p>
              <p className="value"></p>
            </div>

            <div className="content__grid col3">
              <p>Name of Occupier</p>
              <p className="dot">:</p>
              <p className="value"></p>
            </div>
          </div>

					<div className="content content-mobile">
            <div className="content__grid col3">
              <p>Name of Tenant :</p>
              <p className="value"></p>
            </div>

            <div className="content__grid col3">
              <p>Name of Occupier :</p>
              <p className="value"></p>
            </div>
          </div>

          <div className="content content-desktop content-nomb">
            <div className="content__grid col4">
              <p className="num">1.</p>
              <p>Term of Lease</p>
              <p className="dot">:</p>

              <p className="value value-parent">
                <span className="value-sub-1 value-sub-1-nom"></span>
                <span className="value-sub-2">( )</span>
                <span>months of lease commencing on</span>
                <span className="value-sub-1"></span>
                <span>with an option to renew for a further period of</span>
                <span className="value-sub-1"></span>
                <span className="value-sub-2">( )</span>
                <span>
                  months. The Diplomatic clause shall be applicable after
                </span>
                <span className="value-sub-1"></span>
                <span className="value-sub-2">( )</span>
                <span>
                  months of occupation by the Tenant giving Two (2) calendar
                  months’ notice or Two (2) months rental in lieu of notice.
                </span>
              </p>
            </div>

            <div className="content__grid col4">
              <p className="num">2.</p>
              <p>Monthly Rental</p>
              <p className="dot">:</p>

              <p className="value value-parent">
                <span>S$</span>
                <span className="value-sub-1"></span>
                <span>plus GST</span>
                <span className="value-sub-1"></span>
                <span>total S$</span>
                <span className="value-sub-1"></span>
                <span>, (if applicable),</span>
                <span>
                  inclusive of the fixtures, fittings and maintenance fees
                  payable monthly in advance.
                </span>
              </p>
            </div>

            <div className="content__grid col4">
              <p className="num">3.</p>
              <p>Security Deposit</p>
              <p className="dot">:</p>

              <p className="value value-parent">
                <span className="value-sub-1 value-sub-1-nom"></span>
                <span className="value-sub-2">( )</span>
                <span>months deposit and One (1) month advance rental.</span>
              </p>
            </div>

            <div className="content__grid col4">
              <p className="num">4.</p>
              <p>Stamp Fee </p>
              <p className="dot">:</p>

              <p className="value value-nob">To be borne by the Tenant.</p>
            </div>
            <div className="content__grid col4">
              <p className="num">5.</p>
              <p>Utilities Supply</p>
              <p className="dot">:</p>

              <p className="value value-nob">
                Charges for the supply of water, electricity and gas shall be
                borne by the Tenant.
              </p>
            </div>
            <div className="content__grid col4">
              <p className="num">6.</p>
              <p>Telecommunications</p>
              <p className="dot">:</p>

              <p className="value value-nob">
                All charges for subscription, installation costs including GST
                in respect of the telephone(s), television, information
                technology (IT) equipment and services from such
                telecommunications companies, service providers, or any other
                similar company shall be borne by the Tenant.
              </p>
            </div>
            <div className="content__grid col4">
              <p className="num">7.</p>
              <p>Immigration Requirements</p>
              <p className="dot">:</p>

              <p className="value value-nob">
                The Tenant shall at all times ensure that all occupants of the
                Property comply with all applicable laws for entering and
                staying in Singapore.
              </p>
            </div>

            <div className="content__grid col2-bas">
              <p className="num">8.</p>
              <p>The following is a list of the Tenant’s requirements: - </p>
            </div>
          </div>

					<div className="content content-mobile content-nomb">
            <div className="content__grid col4">
              <p className="num">1. Term of Lease :</p>

              <p className="value value-parent">
                <span className="value-sub-1 value-sub-1-nom"></span>
                <span className="value-sub-2">( )</span>
                <span>months of lease commencing on</span>
                <span className="value-sub-1"></span>
                <span>with an option to renew for a further period of</span>
                <span className="value-sub-1"></span>
                <span className="value-sub-2">( )</span>
                <span>
                  months. The Diplomatic clause shall be applicable after
                </span>
                <span className="value-sub-1"></span>
                <span className="value-sub-2">( )</span>
                <span>
                  months of occupation by the Tenant giving Two (2) calendar
                  months’ notice or Two (2) months rental in lieu of notice.
                </span>
              </p>
            </div>

            <div className="content__grid col4">
              <p className="num">2. Monthly Rental :</p>

              <p className="value value-parent">
                <span>S$</span>
                <span className="value-sub-1"></span>
                <span>plus GST</span>
                <span className="value-sub-1"></span>
                <span>total S$</span>
                <span className="value-sub-1"></span>
                <span>, (if applicable),</span>
                <span>
                  inclusive of the fixtures, fittings and maintenance fees
                  payable monthly in advance.
                </span>
              </p>
            </div>

            <div className="content__grid col4">
              <p className="num">3. Security Deposit :</p>

              <p className="value value-parent">
                <span className="value-sub-1 value-sub-1-nom"></span>
                <span className="value-sub-2">( )</span>
                <span>months deposit and One (1) month advance rental.</span>
              </p>
            </div>

            <div className="content__grid col4">
              <p className="num">4. Stamp Fee :</p>

              <p className="value value-nob">To be borne by the Tenant.</p>
            </div>
            <div className="content__grid col4">
              <p className="num">5. Utilities Supply :</p>

              <p className="value value-nob">
                Charges for the supply of water, electricity and gas shall be
                borne by the Tenant.
              </p>
            </div>
            <div className="content__grid col4">
              <p className="num">6. Telecommunications :</p>

              <p className="value value-nob">
                All charges for subscription, installation costs including GST
                in respect of the telephone(s), television, information
                technology (IT) equipment and services from such
                telecommunications companies, service providers, or any other
                similar company shall be borne by the Tenant.
              </p>
            </div>
            <div className="content__grid col4">
              <p className="num">7. Immigration Requirements :</p>

              <p className="value value-nob">
                The Tenant shall at all times ensure that all occupants of the
                Property comply with all applicable laws for entering and
                staying in Singapore.
              </p>
            </div>

            <div className="content__grid col2-bas">
              <p className="num">8. The following is a list of the Tenant’s requirements: -</p>
            </div>
          </div>

          <div className="content content-sub">
            <div className="content__grid col2-bas">
              <p>A)</p>
              <p className="value"></p>
            </div>
            <div className="content__grid col2-bas">
              <p>B)</p>
              <p className="value"></p>
            </div>
            <div className="content__grid col2-bas">
              <p>C)</p>
              <p className="value"></p>
            </div>
            <div className="content__grid col2-bas">
              <p>D)</p>
              <p className="value"></p>
            </div>
            <div className="content__grid col2-bas">
              <p>E)</p>
              <p className="value"></p>
            </div>
            <div className="content__grid col2-bas">
              <p>F)</p>
              <p className="value"></p>
            </div>
          </div>
        </div>
      </div>

      <div className="loi-2">
        <div className="loi-content">
          <div className="content">
            <div className="content__grid no-col">
              <p className="value value-nob">
                <span>Enclosed please find Good Faith Deposit of $</span>
                <span className="value-sub-1"></span>
                <span>(Cheque no.</span>
                <span className="value-sub-1"></span>
                <span>) made payable to the Landlord </span>
                <span className="value-sub-1"></span>
              </p>

              <p className="value value-nob">
                The Landlord and the Tenant shall sign a Tenancy Agreement with
                terms and conditions to be agreed within{' '}
                <strong>seven (7) days</strong> from the date of this Letter of
                Intent. The Good Faith Deposit will eventually become part of
                the security deposit upon successful completion and execution of
                the Tenancy Agreement.
              </p>

              <p className="value value-nob">
                If the Landlord fails to sign the Tenancy Agreement after the
                terms and conditions are already agreed, he shall refund the
                Good Faith Deposit to the Tenant immediately and shall pay
                <strong> PROPNEX REALTY PTE LTD</strong> (hereinafter known as
                the “Agency”) the total commission plus GST stated below for
                services rendered. Thereafter this Letter of Intent shall become
                null and void and neither party shall have any claim against the
                other party.
              </p>

              <p className="value value-nob">
                If the Tenant fails to sign the Tenancy Agreement after the
                terms and conditions are already agreed, the Landlord shall be
                at liberty to rent the above premises to another tenant and the
                Good Faith Deposit tendered herewith shall be forfeited without
                further notice to the Tenant. In this event, 50% of the Good
                Faith Deposit shall be paid to the Agency provided such amount
                does not exceed the total service fee.
              </p>

              <p className="value value-nob">
                <span>
                  The Landlord shall pay the Agency a commission of S$
                </span>
                <span className="value-sub-1"></span>
                <span>plus GST S$</span>
                <span className="value-sub-1"></span>
                <span>total S$</span>
                <span className="value-sub-1"></span>
                <span>
                  {' '}
                  for securing the Tenant and where applicable, the Tenant shall
                  pay to the Agency a commission of S$
                </span>
                <span className="value-sub-1"></span>
                <span>plus GST S$</span>
                <span className="value-sub-1"></span>
                <span>total S$</span>
                <span className="value-sub-1"></span>
                <span> for procuring the lease for the above property.</span>
              </p>

              <p className="value value-nob">
                In the presence where the Landlord(s) and the Tenant(s)
                comprises two or more persons, all covenants and agreements
                given by the Landlord(s) and the Tenant(s) shall be deemed to
                have been given jointly and severally.
              </p>

              <p className="value value-nob">Yours faithfully,</p>
            </div>
          </div>

          <div className="content">
            <div className="content__grid no-col">
              <p className="value value-nob">
                <span className="value-sub-1 value-sub-1-nom"></span>
              </p>
            </div>
          </div>

          <div className="content content-desktop">
            <div className="content__grid col3">
              <p>Name</p>
              <p className="dot">:</p>
              <p className="value"></p>
            </div>
            <div className="content__grid col3">
              <p>CEA Reg. No.</p>
              <p className="dot">:</p>
              <p className="value"></p>
            </div>
            <div className="content__grid col3">
              <p>Designation</p>
              <p className="dot">:</p>
              <p className="value"></p>
            </div>
          </div>

					<div className="content content-mobile">
            <div className="content__grid col3">
              <p>Name :</p>
							<p className="value"></p>
            </div>
            <div className="content__grid col3">
              <p>CEA Reg. No. :</p>
              <p className="value"></p>
            </div>
            <div className="content__grid col3">
              <p>Designation :</p>
              <p className="value"></p>
            </div>
          </div>

          <div className="content">
            <div className="content__grid no-col">
              <p>
                <strong>
                  Please sign and return the duplicate of this letter as
                  acknowledgement of your receipt of this Letter of Intent and
                  the Good Faith Deposit and to indicate your agreement to the
                  above terms and conditions.
                </strong>
              </p>
            </div>
          </div>

          <div className="content-wrapper">
            <div className="content">
              <div className="content__grid no-col">
                <div className="signature">
									{/* <img src={logo} alt="logo-propnex" /> */}
                </div>
                <p>Signature of Tenant </p>
              </div>

              <div className="content__grid col-set-2">
                <p>Name</p>

                <div className="content__grid-sub content__grid-sub-no-gap">
                  <div className="content__grid col2-bas">
                    <p className="dot">:</p>
                    <p className="value"></p>
                  </div>

                  <div className="content__grid col2-bas">
                    <p className="dot">:</p>
                    <p className="value"></p>
                  </div>
                </div>
              </div>

              <div className="content__grid col-set-3">
                <p>NRIC No.</p>
                <p className="dot">:</p>
                <p className="value"></p>
              </div>

              <div className="content__grid col-set-3">
                <p>Date</p>
                <p className="dot">:</p>
                <p className="value"></p>
              </div>

              <div className="content__grid col-set-3">
                <p>Address</p>
                <p className="dot">:</p>
                <div className="content__grid-sub">
                  <p className="value"></p>
                  <div className="singapore">
                    <p>Singapore</p>
                    <p className="value"></p>
                  </div>
                </div>
              </div>

              <div className="content__grid col3-sig">
                <p>In the presence of </p>
                <p>hallo</p>
                <p>)</p>
              </div>

              <div className="content__grid col-set-2">
                <p>Name</p>

                <div className="content__grid-sub content__grid-sub-no-gap">
                  <div className="content__grid col2-bas">
                    <p className="dot">:</p>
                    <p className="value"></p>
                  </div>

                  <div className="content__grid col2-bas">
                    <p className="dot">:</p>
                    <p className="value"></p>
                  </div>
                </div>
              </div>

              <div className="content__grid col-set-3">
                <p>NRIC</p>
                <p className="dot">:</p>
                <p className="value"></p>
              </div>
            </div>

            <div className="content">
							<div className="content__grid no-col">
                <div className="signature">
									{/* <img src={logo} alt="logo-propnex" /> */}
                </div>
                <p>Signature of Landlord </p>
              </div>

              <div className="content__grid col-set-2">
                <p>Name</p>

                <div className="content__grid-sub content__grid-sub-no-gap">
                  <div className="content__grid col2-bas">
                    <p className="dot">:</p>
                    <p className="value"></p>
                  </div>

                  <div className="content__grid col2-bas">
                    <p className="dot">:</p>
                    <p className="value"></p>
                  </div>
                </div>
              </div>

              <div className="content__grid col-set-3">
                <p>NRIC No.</p>
                <p className="dot">:</p>
                <p className="value"></p>
              </div>

              <div className="content__grid col-set-3">
                <p>Date</p>
                <p className="dot">:</p>
                <p className="value"></p>
              </div>

              <div className="content__grid col-set-3">
                <p>Address</p>
                <p className="dot">:</p>
                <div className="content__grid-sub">
                  <p className="value"></p>
                  <div className="singapore">
                    <p>Singapore</p>
                    <p className="value"></p>
                  </div>
                </div>
              </div>

              <div className="content__grid col3-sig">
                <p>In the presence of </p>
                <p>hallo</p>
                <p>)</p>
              </div>

              <div className="content__grid col-set-2">
                <p>Name</p>

                <div className="content__grid-sub content__grid-sub-no-gap">
                  <div className="content__grid col2-bas">
                    <p className="dot">:</p>
                    <p className="value"></p>
                  </div>

                  <div className="content__grid col2-bas">
                    <p className="dot">:</p>
                    <p className="value"></p>
                  </div>
                </div>
              </div>

              <div className="content__grid col-set-3">
                <p>NRIC</p>
                <p className="dot">:</p>
                <p className="value"></p>
              </div>
            </div>
          </div>

          <div className="content content-footer">
						<div className="content-box__wrapper">
							<div className="content-box">
								<p>Disclaimer:</p>
								<p>
									This is PropNex Realty Pte Ltd’s prescribed document which may
									not be appropriate for use in all property transactions. If in
									doubt, you must seek independent legal advice and/or opinion.
									PropNex Realty Pte Ltd shall not be liable for all and/or any
									losses, damages and/or otherwise whatsoever arising from the use
									of this document (including but not limited to all and/or any
									amendments to this document).{' '}
								</p>
							</div>
						</div>

            <div className="content-box content-box-nb">
              <p>
                Salespersons are not allowed to collect a commission from the
                landlord and the tenant in the same transaction. The Tenant’s
                payment obligation would only apply where (a) the Landlord’s
                salesperson is not sharing the commission with the Tenant’s
                salesperson and the Tenant has agreed to pay a commission to
                their own representing salesperson OR (b) each salesperson is
                collecting a commission from their respective clients.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LOI
