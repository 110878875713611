
const floor = {
  LOW: 1,
  MID: 2,
  HIGH: 3
}

const furnishingLoad = {
  FULL: 1,
  PARTIAL: 2,
  UNFURNISHED: 3
}

const rentalType = {
  ENTIRE: 1,
  ENSUITE: 2,
  ROOM: 3,
}

const propertyTypeName = {
  HDB: 'HDB',
  CONDO: "Condo",
  LANDED: 'Landed'
}

const propertyConstant = {
  floor,
  furnishingLoad,
  rentalType,
  propertyTypeName
}

export default propertyConstant;