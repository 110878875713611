import QRDummy from '../../../assets/images/image 11.png'
import InfoBox from '../../ui/InfoBox'
import { ReactComponent as CopyIcon } from '../../../assets/icon/general/Vector-70-paste.svg'
import { ReactComponent as WarningIcon } from '../../../assets/icon/general/Vector-31-warning.svg'

const Paynow = () => {
  return (
    <>
      <div className="offer-body__flex-container">
        <img src={QRDummy} alt="QR Dummy" />
        <div style={{ height: '82px' }}>
          <InfoBox
            info={
              'Tap to download QR photo, then upload this photo onto your bank portal.'
            }
          />
        </div>
      </div>
      <div className="paynow__content-container">
        <h1>or PayNow using UEN</h1>
        <div style={{ width: '100%' }}>
          <h1 className="offer-body__input-label">
            Unique Entity Number (UEN)
          </h1>
          <div style={{ height: '.8rem' }} />
          <div className="offer-body__form-input">
            <h1>227000447WYYYY</h1>
            <CopyIcon />
          </div>
        </div>
        <div style={{ width: '100%' }}>
          <h1 className="offer-body__input-label">Entity</h1>
          <div style={{ height: '.8rem' }} />
          <div className="offer-body__form-input">
            <h1>P.LEASE PRIVATE LIMITED</h1>
            <CopyIcon />
          </div>
        </div>
        <div style={{ width: '100%' }}>
          <h1 className="offer-body__input-label">Unique Reference Code</h1>
          <div style={{ height: '.8rem' }} />
          <div className="offer-body__form-input">
            <h1>W03Z89</h1>
            <CopyIcon />
          </div>
        </div>
        <div style={{ width: '100%' }}>
          <div className="offer-body__warning-container">
            <div style={{ width: '1.6rem' }}>
              <WarningIcon className="offer-body__warning-icon" />
            </div>
            <p className="offer-body__warning-text">
              Please input your unique reference code during your transfer so
              that we can identify your funds swiftly.
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default Paynow
