import React, { useState } from 'react'
import LeaseCurrentBox from './LeaseCurrentBox'
import LeaseBreakdownChart from './LeaseBreakdownChart'
import LeaseMonthlyChart from './LeaseMonthlyChart'
import '../../../assets/css/components/LeasePage/Tenant/leaseTenantCurrentOutlet.css'

function LeaseTenantCurrentOutlet() {
	const [handover, setHandOver] = useState(true)

	return (
		<div className='current0'>
			<LeaseCurrentBox title="Lease Summary" outlet="summary" />
			{handover && ( <LeaseCurrentBox title="Handover Checklist" outlet="summaryHandoverAfter" />)}
			<LeaseBreakdownChart />
			<LeaseMonthlyChart />
		</div>
	)
}

export default LeaseTenantCurrentOutlet