import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import '../../../assets/css/components/ui/modalManageOffer.css'
import { ReactComponent as WarningIcon } from '../../../assets/icon/general/Vector-31-warning.svg'
import { ReactComponent as CheckIcon } from '../../../assets/icon/general/Vector-42-check.svg'
import { ReactComponent as ArrowIcon } from '../../../assets/icon/general/Vector-2-rightArrow.svg'
import { ReactComponent as CancelIcon } from '../../../assets/icon/general/Vector-9-close.svg'
import authService from '../../../services/AuthService'
import tenantLeasePropService from '../../../services/tenantLeasePropService'
import TenantViewingService from '../../../services/TenantViewingService'

function modalManageOffer(props) {
  async function onAccept() {
    if (props.type == 'viewing') {
      const data = { status: 2 }
      const response = await TenantViewingService.updateViewing(
        data,
        props.data_viewing.id
      )
      console.log(response)
      return response
    } else if (props.type == 'offer') {
      const data = { offer_status: 2 } // Change offer status on accept
      const response = await tenantLeasePropService.updateOne(
        data,
        props.data_lease.id
      )
      console.log(response)
      return response
    }
  }

  async function onReject() {
    if (props.type == 'viewing') {
      const data = { status: 3 }
      const response = await TenantViewingService.updateViewing(
        data,
        props.data_viewing.id
      )
      console.log(response)
      return response
    } else if (props.type == 'offer') {
      const data = { offer_status: 3 } // Update offer status on reject
      const response = await tenantLeasePropService.updateOne(
        data,
        props.data_lease.id
      )
      console.log(response)
      return response
    }
  }
  return ReactDOM.createPortal(
    <>
      {props.action == 'accept' && (
        <div className="modal-manage-request">
          <div className="modal-manage__overlay" onClick={onAccept}></div>

          <div className="modal-content__container">
            <div className="modal-content__icon-container">
              <WarningIcon />
            </div>
            <div className="modal-content__title">
              <h1 className="modal-content__title-text">
                Please confirm that you would like to accept this{' '}
                {`${props.type === 'viewing' ? 'viewing' : 'offer'}`}
              </h1>
            </div>
            <div className="manage-request__warning-container">
              <div style={{ width: '1.6rem' }}>
                <WarningIcon className="manage-request__warning-icon" />
              </div>
              <p className="manage-request__warning-text">
                This action is irreversible.
              </p>
            </div>
            {props.type !== 'viewing' && (
              <div className="modal-content__paragraph">
                <p className="modal-content__paragraph-text">
                  It is important to note that once you have accepted this offer
                  by
                  <span className="modal-content__paragraph-yellow">
                    {props.data_user.name}
                  </span>{' '}
                  for a monthly rental of{' '}
                  <span className="modal-content__paragraph-yellow">
                    SGD {props.data_lease.monthly_rental},
                  </span>{' '}
                  all other existing offers will be rejected automatically.
                </p>
              </div>
            )}
            <button className="manage-request__accept-btn" onClick={onAccept}>
              <CheckIcon />
              Accept {`${props.type === 'viewing' ? 'Viewing' : 'Offer'}`}
            </button>
            <button
              className="manage-request__reject-btn manage-request__reject-btn-2-arr"
              onClick={props.hide}
            >
              <ArrowIcon />
              Back to {`${props.type === 'viewing' ? 'viewing' : 'offer'}`} list
            </button>
          </div>
        </div>
      )}
      {props.action == 'reject' && (
        <div className="modal-manage-request">
          <div className="modal-manage__overlay" onClick={onReject}></div>

          <div className="modal-content__container">
            <div className="modal-content__icon-container">
              <WarningIcon />
            </div>
            <div className="modal-content__title">
              <h1 className="modal-content__title-text">
                Please confirm that you would like to reject this{' '}
                {`${props.type === 'viewing' ? 'viewing' : 'offer'}`}
              </h1>
            </div>
            <div className="manage-request__warning-container">
              <div style={{ width: '1.6rem' }}>
                <WarningIcon className="manage-request__warning-icon" />
              </div>
              <p className="manage-request__warning-text">
                This action is irreversible.
              </p>
            </div>
            {props.type !== 'viewing' && (
              <div className="modal-content__paragraph">
                <p className="modal-content__paragraph-text">
                  It is important to note that once you have rejected this offer
                  by
                  <span className="modal-content__paragraph-yellow">
                    {props.data_user.name}
                  </span>{' '}
                  for a monthly rental of{' '}
                  <span className="modal-content__paragraph-yellow">
                    SGD {props.data_lease.monthly_rental},
                  </span>{' '}
                  , it will be removed permanently from your offer list.
                </p>
              </div>
            )}
            <div className="modal-content__rejection-input">
              <h1 className="modal-content__rejection-title">
                Reason for Rejection{' '}
                <span className="optional">(optional)</span>
              </h1>

              <div className="form-input form-input-manage-offer">
                <label>
                  <div className="form-input__number">
                    <select>
                      <option value="" selected disabled hidden>
                        Please select...
                      </option>
                      <option value="+62">+62</option>
                      <option value="+66">+66</option>
                    </select>
                  </div>
                </label>
              </div>
            </div>
            <button
              className="manage-request__reject-btn btn-no-active manage-request__reject-btn-2"
              onClick={onReject}
            >
              <CancelIcon />
              Reject {`${props.type === 'viewing' ? 'Viewing' : 'Offer'}`}
            </button>
            <button
              className="manage-request__reject-btn manage-request__reject-btn-2-arr"
              onClick={props.hide}
            >
              <ArrowIcon />
              Back to {`${props.type === 'viewing' ? 'viewing' : 'offer'}`} list
            </button>
          </div>
        </div>
      )}
      {props.action === 'extend' && (
        <div className="modal-manage-request">
          <div className="modal-content__container">
            <div className="modal-content__icon-container">
              <WarningIcon />
            </div>
            <div className="modal-content__title">
              <h1 className="modal-content__title-text">
                Offer period extension{' '}
              </h1>
            </div>
            <div className="modal-content__rejection-input">
              <h1 className="modal-content__rejection-title">
                Select Extension Period
              </h1>
              <div className="modal-content__extend">
                <label htmlFor="24" className="extension__label">
                  <input type="radio" name="extension" id="24" />

                  <div className="modal-content__extend-item">
                    <h1 className="modal-content__extend-time">24h</h1>
                    <p className="modal-content__extend-placeholder">
                      EXTENSION
                    </p>
                  </div>
                </label>

                <label htmlFor="48" className="extension__label">
                  <input type="radio" name="extension" id="48" />

                  <div className="modal-content__extend-item">
                    <h1 className="modal-content__extend-time">48h</h1>
                    <p className="modal-content__extend-placeholder">
                      EXTENSION
                    </p>
                  </div>
                </label>
              </div>
            </div>
            <button
              className="manage-request__reject-btn btn-no-active manage-request__reject-btn-2"
              onClick={props.hide}
            >
              <CheckIcon />
              Extend Offer Period
            </button>
            <button
              className="manage-request__reject-btn manage-request__reject-btn-2-arr"
              onClick={props.hide}
            >
              <ArrowIcon />
              Back to offer list
            </button>
          </div>
        </div>
      )}
    </>,
    document.getElementById('portal')
  )
}

export default modalManageOffer
