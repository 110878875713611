import { useEffect, useState } from 'react'
import OfferHead from '../../components/offer/OfferHead'
import OfferLOI from '../../components/offer/OfferLOI'

import PayBooking from '../../components/offer/PayBooking'
import ReviewOffer from '../../components/offer/ReviewOffer'
import { useNavigate, useParams } from 'react-router-dom'
import authService from '../../services/AuthService'
import userServices from '../../services/userService'
import SnackBar from '../../components/ui/SnackBar'
import propertyService from '../../services/PropertyService'
import FloatButton from '../../components/offer/FloatButton'

const OfferPage = () => {
  const { propertyId } = useParams();
  const { id, role } = authService.getLoggedUserData();

  const navigate = useNavigate();

  const [userDataFull, setUserDataFull] = useState(null);
  const [propertyData, setPropertyData] = useState(null)

  const [isLoading, setIsLoading] = useState(true);
  const [tab, setTab] = useState(0)

  const [snackbarStatus, setSnackbarStatus] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("")

  const [formData, setFormData] = useState(null)

  const fetchData = async () => {
    const userDataResponse = await userServices.getOneFromId(id);
    const propertyDataResponse = await propertyService.getOne(propertyId);
    if (userDataResponse) setUserDataFull(userDataResponse);
    if (propertyDataResponse) setPropertyData(propertyDataResponse)
  }

  useEffect(() => {
    console.log(tab);
    if(formData) setTab(1)
  }, [formData])

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    if (userDataFull && propertyData) {
      if(userDataFull.user_status !== 2) {
        alert('Please finish your data verification first')
        navigate('/onboarding')
      }
      setIsLoading(false)
    }
  }, [userDataFull, propertyData])

  useEffect(() => {
    let timeout
    if (snackbarStatus) {
      timeout = setTimeout(() => setSnackbarStatus(false), 2000);
    }
    return () => clearTimeout(timeout);
  }, [snackbarStatus]);

  return isLoading ? <>Loading</> : (
    <>
      <SnackBar status={snackbarStatus} message={snackbarMessage} />
      <OfferHead setTab={setTab} tab={tab} />
      {
        tab === 0 ? 
        <OfferLOI setTab={setTab} userData={userDataFull} setSnackbarStatus={setSnackbarStatus} setSnackbarMessage={setSnackbarMessage} setFormData={setFormData} propertyData={propertyData} />
        :
        <ReviewOffer setSnackbarMessage={setSnackbarMessage} setSnackbarStatus={setSnackbarStatus} formData={formData} />

      }
    </>
  )
}

export default OfferPage
