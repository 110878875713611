import { ReactComponent as WarningIcon } from '../../../assets/icon/general/Vector-31-warning.svg'
import { ReactComponent as ChevDown } from '../../../assets/icon/general/Vector-14-chevron-down.svg'
function ModalReject(props) {
  return (
    <div className="modal-manage-request">
      <div className="modal-content__container">
        <div className="modal-content__icon-container">
          <WarningIcon />
        </div>
        <div className="modal-content__title">
          <h1 className="modal-content__title-text">
            Please confirm that you would like to reject this offer
          </h1>
        </div>
        <div className="manage-request__warning-container">
          <div style={{ width: '1.6rem' }}>
            <WarningIcon className="manage-request__warning-icon" />
          </div>
          <p className="manage-request__warning-text">
            This action is irreversible.
          </p>
        </div>
        <div className="modal-content__paragraph">
          <p className="modal-content__paragraph-text">
            It is important to note that once you have rejected this offer by
            <span className="modal-content__paragraph-yellow">
              Tay Chia Khoon
            </span>{' '}
            for a monthly rental of{' '}
            <span className="modal-content__paragraph-yellow">
              SGD 3,100.00
            </span>{' '}
            , it will be removed permanently from your offer list.
          </p>
        </div>
        <div className="modal-content__rejection-input">
          <h1 className="modal-content__rejection-title">
            Reason for Rejection <span className="optional">(optional)</span>
          </h1>
          <div className="modal-content__rejection-dropdown">
            Please Select...
            <ChevDown />
          </div>
        </div>
        <button className="manage-request__reject-btn" onClick={props.hide}>
          Reject Offer
        </button>
        <button className="manage-request__reject-btn" onClick={props.hide}>
          Back to offer list
        </button>
      </div>
    </div>
  )
}

export default ModalReject
