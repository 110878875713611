import React from 'react'
import map from '../../../../assets/images/map.png'
import './maps.css'

function Maps() {
	return (
		<div className='maps-web'>
			<img src={map} alt="map" />
		</div>
	)
}

export default Maps