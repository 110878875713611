import React, { useState } from 'react'
import '../../../assets/css/components/browse/Tenant/tenantDetailsOutlet2.css'
import { ReactComponent as Train } from '../../../assets/icon/general/Vector-10-train.svg'
import { ReactComponent as ChevronDown } from '../../../assets/icon/general/Vector-14-chevron-down.svg'
import { ReactComponent as ChevronUp } from '../../../assets/icon/general/Vector-13-chevron-up.svg'
import { ReactComponent as Person } from '../../../assets/icon/general/Vector-68-person-walk.svg'
import { ReactComponent as Cutlery } from '../../../assets/icon/general/Vector-67-cutlery.svg'
import { ReactComponent as Education } from '../../../assets/icon/general/Vector-63-education.svg'
import { ReactComponent as Medic } from '../../../assets/icon/general/Vector-65-plus.svg'
import { ReactComponent as Dollar } from '../../../assets/icon/general/Vector-66-dollar.svg'
import { ReactComponent as Postal } from '../../../assets/icon/general/Vector-64-postal.svg'

function TenantDetailsOutlet2() {
  const [isActive, setIsActive] = useState(false)

  return (
    <div class="whats-nearby">
      <div
        class={isActive ? 'whats-nearby__list__active' : 'whats-nearby__list'}
        onClick={() => setIsActive(!isActive)}
      >
        <div
          class={
            isActive
              ? 'whats-nearby__list-title__active'
              : 'whats-nearby__list-title'
          }
        >
          <Train />
          <p class="text-lg">Transportation</p>
          <div class="whats-nearby__list-title-chevron">
            {isActive ? <ChevronUp /> : <ChevronDown />}
          </div>
        </div>

        {isActive && (
          <div class="whats-nearby__list-content">
            {/* Train Station */}
            <p class="text-xs title">TRAIN STATION</p>
            <div class="whats-nearby__list-content-wrapper">
              <div class="list-content-station">
                <div class="list-content-station-flag">
                  <div>
                    <p>NSL</p>
                  </div>
                  <p class="text-xs">Toa Payoh</p>
                </div>
                <div class="list-content-station-estimation">
                  <div class="list-content-station-estimation-distance">
                    <p class="text-sm">331 m</p>
                  </div>
                  <div class="list-content-station-estimation-duration">
                    <Person />
                    <p class="text-sm">4 min</p>
                  </div>
                </div>
              </div>
              <div class="list-content-station">
                <div class="list-content-station-flag">
                  <div>
                    <p>NSL</p>
                  </div>
                  <p class="text-xs">Bradell</p>
                </div>
                <div class="list-content-station-estimation">
                  <div class="list-content-station-estimation-distance">
                    <p class="text-sm">574 m</p>
                  </div>
                  <div class="list-content-station-estimation-duration">
                    <Person />
                    <p class="text-sm">7 min</p>
                  </div>
                </div>
              </div>
              <div class="list-content-station">
                <div class="list-content-station-flag">
                  <div class="CCL">
                    <p>CCL</p>
                  </div>
                  <p class="text-xs">Caldecott</p>
                </div>
                <div class="list-content-station-estimation">
                  <div class="list-content-station-estimation-distance">
                    <p class="text-sm">799 m</p>
                  </div>
                  <div class="list-content-station-estimation-duration">
                    <Person />
                    <p class="text-sm">10 min</p>
                  </div>
                </div>
              </div>
            </div>

            {/* Bus Stop */}
            <p class="text-xs title">BUS STOP</p>
            <div class="whats-nearby__list-content-wrapper">
              {/* 1 */}
              <div class="list-content-bus">
                <div class="list-content-bus-stop">
                  <p class="text-xs">Blk 84B</p>
                  <div class="list-content-bus-stop-flag">
                    <div>
                      <p>232</p>
                    </div>
                  </div>
                </div>
                <div class="list-content-bus-estimation">
                  <div class="list-content-bus-estimation-distance">
                    <p class="text-sm">50 m</p>
                  </div>
                  <div class="list-content-bus-estimation-duration">
                    <Person />
                    <p class="text-sm">1 min</p>
                  </div>
                </div>
              </div>

              {/* 2 */}
              <div class="list-content-bus">
                <div class="list-content-bus-stop">
                  <p class="text-xs">Blk 140</p>
                  <div class="list-content-bus-stop-flag">
                    <div>
                      <p>232</p>
                    </div>
                  </div>
                </div>
                <div class="list-content-bus-estimation">
                  <div class="list-content-bus-estimation-distance">
                    <p class="text-sm">126 m</p>
                  </div>
                  <div class="list-content-bus-estimation-duration">
                    <Person />
                    <p class="text-sm">2 min</p>
                  </div>
                </div>
              </div>

              {/* 3 */}
              <div class="list-content-bus">
                <div class="list-content-bus-stop">
                  <p class="text-xs">Oposite Toa Payoh MRT</p>
                  <div class="list-content-bus-stop-flag">
                    <div>
                      <p>56</p>
                    </div>
                    <div>
                      <p>105</p>
                    </div>
                    <div>
                      <p>153</p>
                    </div>
                    <div class="flag-red">
                      <p>NR1</p>
                    </div>
                  </div>
                </div>
                <div class="list-content-bus-estimation">
                  <div class="list-content-bus-estimation-distance">
                    <p class="text-sm">244 m</p>
                  </div>
                  <div class="list-content-bus-estimation-duration">
                    <Person />
                    <p class="text-sm">3 min</p>
                  </div>
                </div>
              </div>

              {/* 4 */}
              <div class="list-content-bus">
                <div class="list-content-bus-stop">
                  <p class="text-xs">Toa Payoh MRT</p>
                  <div class="list-content-bus-stop-flag">
                    <div>
                      <p>56</p>
                    </div>
                    <div>
                      <p>105</p>
                    </div>
                    <div>
                      <p>153</p>
                    </div>
                    <div>
                      <p>232</p>
                    </div>
                    <div class="flag-red">
                      <p>NR1</p>
                    </div>
                  </div>
                </div>
                <div class="list-content-bus-estimation">
                  <div class="list-content-bus-estimation-distance">
                    <p class="text-sm">247 m</p>
                  </div>
                  <div class="list-content-bus-estimation-duration">
                    <Person />
                    <p class="text-sm">3 min</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <div class="whats-nearby__list">
        <div class="whats-nearby__list-title">
          <Cutlery />
          <p class="text-lg">Food</p>
          <div class="whats-nearby__list-title-chevron">
            <ChevronDown />
          </div>
        </div>
      </div>

      <div class="whats-nearby__list">
        <div class="whats-nearby__list-title">
          <Education />
          <p class="text-lg">School</p>
          <div class="whats-nearby__list-title-chevron">
            <ChevronDown />
          </div>
        </div>
      </div>

      <div class="whats-nearby__list">
        <div class="whats-nearby__list-title">
          <Medic />
          <p class="text-lg">Clinic / Hospital</p>
          <div class="whats-nearby__list-title-chevron">
            <ChevronDown />
          </div>
        </div>
      </div>

      <div class="whats-nearby__list">
        <div class="whats-nearby__list-title">
          <Dollar />
          <p class="text-lg">Bank / ATM</p>
          <div class="whats-nearby__list-title-chevron">
            <ChevronDown />
          </div>
        </div>
      </div>

      <div class="whats-nearby__list">
        <div class="whats-nearby__list-title">
          <Postal />
          <p class="text-lg">Postal</p>
          <div class="whats-nearby__list-title-chevron">
            <ChevronDown />
          </div>
        </div>
      </div>
    </div>
  )
}

export default TenantDetailsOutlet2
