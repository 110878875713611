import { useState } from 'react'
import '../../assets/css/components/browse/browseSearchBody.css'
import SearchApply from './SearchApply'
import { ReactComponent as CheckIcon } from '../../assets/icon/general/Vector-42-check.svg'

const BrowseSearchDistrict = (props) => {
  const [value, setValue] = useState(false)
  // props.value(value)
  return (
    <div className="search-body__height">
      <form action="">
        <div className="search-body__diswrapper">
          <div className="search-body__districts">
            <div className="search-body__districts-item browse-input--checkbox browse-input--checkbox_normal">
              <label
                htmlFor={'AllDistricts'}
                className="districts-name"
                onClick={() => setValue(true)}
              >
                <input
                  type="checkbox"
                  name="save"
                  id={'AllDistricts'}
                  value={'AllDistricts'}
                />
                <div className="districts-name amenities-label">
                  All Districts
                </div>
                <span className="checkbox-mark">
                  <CheckIcon />
                </span>
              </label>
            </div>
            {data.map((item, i) => (
              <div
                className="search-body__districts-item browse-input--checkbox browse-input--checkbox_normal"
                key={item.id}
              >
                <label
                  htmlFor={item.name}
                  className="districts-name"
                  onClick={() => setValue(true)}
                >
                  <input
                    type="checkbox"
                    name="save"
                    id={item.id}
                    value={item.name}
                  />
                  <span className="districts-code amenities-label">
                    {item.id}
                  </span>{' '}
                  <div className="districts-name amenities-label">
                    {item?.details}
                  </div>
                  <span className="checkbox-mark">
                    <CheckIcon />
                  </span>
                </label>
              </div>
            ))}
          </div>
        </div>

        <SearchApply />
      </form>
    </div>
  )
}

const data = [
  { id: 'D01', details: 'Boat Quay / Raffles Place / Marina' },
  { id: 'D02', details: 'Chinatown / Tanjong Pagar' },
  { id: 'D03', details: 'Alexandra / Commonwealth' },
  { id: 'D04', details: 'Harbourfront / Telok Blangah' },
  { id: 'D05', details: 'Buona Vista / West Coast / Clementi New Town' },
  { id: 'D06', details: 'City Hall / Clarke Quay' },
  { id: 'D07', details: 'Beach Road / Bugis / Rochor' },
  { id: 'D08', details: 'Farrer Park / Serangoon Rd' },
  { id: 'D09', details: 'Orchard / River Valley' },
  { id: 'D10', details: 'Tanglin / Holland / Bukit Timah' },
  { id: 'D11', details: 'Newton / Novena' },
  { id: 'D12', details: 'Balestier / Toa Payoh' },
  { id: 'D13', details: 'Macpherson / Potong Pasir' },
  { id: 'D14', details: 'Eunos / Geylang / Paya Lebar' },
  { id: 'D15', details: 'East Coast / Marine Parade' },
  { id: 'D16', details: 'Bedok / Upper East Coast' },
  { id: 'D17', details: 'Changi Airport / Changi Village' },
  { id: 'D18', details: 'Pasir Ris / Tampines' },
  { id: 'D19', details: 'Hougang / Punggol / Sengkang' },
  { id: 'D20', details: 'Ang Mo Kio / Bishan / Thomson' },
  { id: 'D21', details: 'Clementi Park / Upper Bukit Timah' },
  { id: 'D22', details: 'Boon Lay / Jurong / Tuas' },
  { id: 'D23', details: 'Bukit Batok / Bukit Panjang / Choa Chu Kang' },
  { id: 'D24', details: 'Lim Chu Kang / Tengah' },
  { id: 'D25', details: 'Admiralty / Woodlands' },
  { id: 'D26', details: 'Mandai / Upper Thomson' },
  { id: 'D27', details: 'Sembawang / Yishun' },
  { id: 'D28', details: 'Seletar / Yio Chu Kang' },
]

export default BrowseSearchDistrict
