import React from 'react'
import '../../../assets/css/components/LeasePage/marketData.css'
import InfoBox from '../../../components/ui/InfoBox'

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);


function MarketData() {
  
 const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'top',
      display: false
    },
    title: {
      display: false,
    },
  },
  // bezierCurve : true,
  scales: {
    x: {
        ticks: {
            color: 'rgba(255, 255, 255, 0.5)'
        },
        grid: {
          color: "rgba(255, 255, 255, 0.05)"
        }
    },
    y: {
      ticks: {
          color: 'rgba(255, 255, 255, 0.5)'
      },
      grid: {
        color: "rgba(255, 255, 255, 0.05)"
      }
    },
  }
};

const labels = ['Dec \'21', 'Feb \'22', 'Apr \'22', 'Jun \'22', 'Aug \'22', 'Oct \'22'];

 const data = {
  labels,
  datasets: [
    {
      label: '1/Studio',
      data: [1000, 2054,3550,2660,5232,4545],
      pointStyle: false,
      lineTension: 0.4,        
      borderWidth: 1,
      borderColor: '#ffc700',
    },
    {
      label: '2',
      data: [1600, 2654,3650,2660,5632,3645],
      pointStyle: false,
      lineTension: 0.4,        
      borderWidth: 1,
      borderColor: '#1FA2FF',
    },
    {
      label: '3',
      data: [2600, 2854,6850,2860,832,1845],
      pointStyle: false,
      lineTension: 0.4,        
      borderWidth: 1,
      borderColor: '#f99d25',
    },
    {
      label: '4',
      data: [3600, 2554,8550,2560,2532,8545],
      pointStyle: false,
      lineTension: 0.4,        
      borderWidth: 1,
      borderColor: '#0be5cb',
    },
    {
      label: '5+/Jumbo',
      data: [4600, 2054,9050,2060,4032,7045],
      pointStyle: false,
      lineTension: 0.4,        
      borderWidth: 1,
      borderColor: '#9D70FF',
    },
  ],
};

  return (
    <div className="marketdata">
      <div className="marketdata__search">
        <span className='search__title'>Search Transaction Records</span>
       
        <div className="search__info">
          <form action="" className='search__form'>
            <div className='form__wrapper'>
              <div className="form-input">
                <label>
                  POSTAL CODE
                  <input
                    className="input__text"
                    type="text"
                    placeholder="Code"
                  ></input>
                </label>
              </div>

              <div className="form-input">
                <label>
                  PROPERTY TYPE
                  <select className="input__select">
                    <option value="Condo">Condo</option>
                  </select>
                </label>
              </div>

              <div className="form-input">
                <label>
                BED(S)
                  <select className="input__select">
                    <option value="2">2</option>
                    <option value="3">1</option>
                  </select>
                </label>
              </div>
            </div>

            <input
              className="button__style-2 button__style-2--active"
              type="submit"
              value="Retrieve record summary"
            />
          </form>

          <div className="current__manage">
            <div className="manage__box">
              <p className="manage__title">Transaction Record Summary</p>
              <InfoBox info="Calculations are based on latest rental transaction data retrieved from URA." />
							<div className="box__row">
								<div>
									<p>TRANSACTED IN</p>
									<p>Oct 2022</p>
								</div>
								<div>
									<p>DISTRICT</p>
									<p>27</p>
								</div>
								<div>
									<p>TRANSACTIONS</p>
									<p>37</p>
								</div>
								<div>
									<p>AVG. SIZE</p>
									<p>600 - 900 sqft</p>
								</div>
								<div>
									<p>AVG. RENT</p>
                  <p>
										<span className="unit">SGD</span> $2,600
									</p>
								</div>
							</div>
            </div>
          </div>
        </div>
      </div>

      <div className="marketdata__chart">
        <div className="chart__display">
          <span className='chart__display-title'>Rental Transaction</span>

          <div className='chart__wrapper'>
            <div className="chart__line">
              <Line options={options} data={data} />
            </div>

            <div className='chart__label'>
              <div className='label'>
                <span className="label__badge label__badge-1"></span>
                <span className="label__info">1/Studio</span>
              </div>
              <div className='label'>
                <span className="label__badge label__badge-2"></span>
                <span className="label__info">2</span>
              </div>
              <div className='label'>
                <span className="label__badge label__badge-3"></span>
                <span className="label__info">3</span>
              </div>
              <div className='label'>
                <span className="label__badge label__badge-4"></span>
                <span className="label__info">4</span>
              </div>
              <div className='label'>
                <span className="label__badge label__badge-5"></span>
                <span className="label__info">5+/Jumbo</span>
              </div>
            </div>
          </div>
        </div>

        <div className="chart__display">
          <span className='chart__display-title'>Monthly Rent (on avg.)</span>

          <div className='chart__wrapper'>
            <div className="chart__line">
              <Line options={options} data={data} />
            </div>

            <div className='chart__label'>
              <div className='label'>
                <span className="label__badge label__badge-1"></span>
                <span className="label__info">1/Studio</span>
              </div>
              <div className='label'>
                <span className="label__badge label__badge-2"></span>
                <span className="label__info">2</span>
              </div>
              <div className='label'>
                <span className="label__badge label__badge-3"></span>
                <span className="label__info">3</span>
              </div>
              <div className='label'>
                <span className="label__badge label__badge-4"></span>
                <span className="label__info">4</span>
              </div>
              <div className='label'>
                <span className="label__badge label__badge-5"></span>
                <span className="label__info">5+/Jumbo</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MarketData
