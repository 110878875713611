import React from 'react'
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide'
import { useState, useEffect } from 'react'

import '../../assets/css/components/ui/cardProperty.css'
import ImgPlaceholder from '../../assets/images/image 5.png'
import { ReactComponent as CommentIcon } from '../../assets/icon/general/Vector-6-chatbubble.svg'
import { ReactComponent as LoveIcon } from '../../assets/icon/general/Vector-15-love.svg'
import { ReactComponent as BedIcon } from '../../assets/icon/general/Vector-4-bed.svg'
import { ReactComponent as BathIcon } from '../../assets/icon/general/Vector-85-bathtub.svg'
import { ReactComponent as EyeIcon } from '../../assets/icon/general/Vector-30-eye.svg'
import { ReactComponent as PlusIcon } from '../../assets/icon/general/Vector-plus.svg'
import { ReactComponent as FeaturedIcon } from '../../assets/icon/general/Vector-22-featured.svg'
import { ReactComponent as ImageIcon } from '../../assets/icon/general/Vector-34-image.svg'
import { ReactComponent as SwipeIcon } from '../../assets/icon/general/Vector-35-swipe.svg'
import moment from 'moment'
import { propertyRentalTypeOptions } from '../../services/valuesService'
import authService from '../../services/AuthService'
import userServices from '../../services/userService'
import axios from 'axios'
import { apiBaseURL } from '../../utils'

function CardPropertyOnCreateProperty({ type, data }) {
  const [user, setUser] = useState([])
  const [locationData, setLocationData] = useState()

  const getUserData = async (id) => {
    const useData = await userServices.getOneFromId(id)
    setUser(useData)
  }

  const getLocationData = async() => {
    let postal_code = data.postal_code
    postal_code = postal_code.slice(0,2)

    const location = await axios.get(`${apiBaseURL}/location_postal_codes/postal_code/${postal_code}`)

    setLocationData(location.data[0])
  }

  useEffect(() => {
    getUserData(localStorage.getItem(authService._userIdKey))
  }, [])

  useEffect(() => {
    getLocationData()
  }, [])

  return (
    <div className="card-property__card">
      <div className="card__image">
        <div className="card__image-badge">
          <FeaturedIcon />
          <p>Featured</p>
        </div>

        <div className="card__image-slide">
          <Splide
            options={{
              perPage: 1,
              noDrag: true,
              drag: true,
              pagination: false,
              arrows: type === 'newleaseShortlist' ? true : false,
            }}
          >
            {data?.property_photo_files.map((file) => {
              console.log(file)
              return (
                <SplideSlide>
                  <img src={URL.createObjectURL(file)} />
                </SplideSlide>
              )
            })}
          </Splide>
        </div>

        <div className="card__image-info">
          <div className="card__image-info-wrapper">
            <div>
              <ImageIcon />
              <p>{data?.property_photo_files.length ?? '0'}</p>
            </div>
            {true && (
              <div>
                <SwipeIcon />
                <p>Swipe</p>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* TODO: Make Dynamic */}
      <div className="card-property__detail">
        <div className="detail__button-flag">
          <div className="flag__train">
            <div>
              <p>DTL</p>
            </div>

            <p className="p1">Rochor</p>
          </div>

          <div className="detail__button-container">
            <button>
              <CommentIcon />
            </button>
            <button>
              <LoveIcon />
            </button>
          </div>
        </div>

        <div className="detail__property-name-price">
          {/* <div className="property-name">
						<h1>{
							propertyRentalTypeOptions.map((type) => {
								if (data.rental_type === type.id) {
									return type.label;
								}
							})
						} at {data.address}</h1>
						<p>{data.district}</p>
					</div> */}
          <div className="property-name">
            {/* TODO: Revert prop cata to rental type */}
            <h1>
              {propertyRentalTypeOptions.map((type) => {
                if (data.rental_type === type.id) {
                  return type.label
                }
              })}{' '}
              at {locationData?.name}
            </h1>
            {/* TODO Update this with disctrict */}
            <p>{data?.address}</p>
          </div>

          <div className="property-price">
            <h1>
              <span>S$</span> {data?.monthly_rental} / mth
            </h1>
          </div>
        </div>

        <div className="detail__property-feature">
          <div className="detail__feature">
            <p>{data.number_of_beds === 0 ? "Studio" : data.number_of_beds === 5 ? "5+" : data.number_of_beds}</p>

            <div className="feature__icon">
              <BedIcon />
            </div>
          </div>

          <div className="detail__feature">
            <p>{data.number_of_baths === 3 ? "3+" : data.number_of_baths}</p>

            <div className="feature__icon">
              <BathIcon />
            </div>
          </div>

          <p className="p1">
            {data.floor_area} sqft / {(data.floor_area / 10.764).toFixed(2)} sqm
          </p>
        </div>

        <div className="detail__property-bottom">
          <div className="bottom-interact">
            <div className="interact__icon">
              <EyeIcon />
            </div>

            <p>{data.property_view_count ?? 1234}</p>
          </div>

          {/* TODO: Make Dynamic */}
          <div className="bottom-interact">
            <div className="interact__icon">
              <LoveIcon />
            </div>

            <p>0</p>
          </div>

          <div className="bottom-interact">
            <div className="interact__icon interact__icon--plus">
              <PlusIcon />
            </div>

            <p>
              {moment(data.created_date).fromNow()} by {user.name}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CardPropertyOnCreateProperty
