import React from 'react'
import InfoBox from '../../../components/ui/InfoBox'

import { ReactComponent as Check } from '../../../assets/icon/general/Vector-42-check.svg'
import { ReactComponent as Alert } from '../../../assets/icon/general/Vector-Info-Box.svg'
import { SECURITY_DEPOSIT_AGREEMENT } from '../schema'

const GoverningLaw = ({ suggestedEdit, onClick }) => {
  return (
    <div>
      <div className="agreement-content">
        <InfoBox info="This Tenancy Agreement (TA) between “Tenant” Wong Zhenyi (*****567A) and “Landlord” Wilson Chan Yi De (*****890B) will be legally binding once both parties have signed the TA and once the necessary stamp duty has been paid." />
        <div className="agreement-item_box">
          <div className="agreement-item_heading">
            <p>5.13 / Governing Law</p>
          </div>
        </div>

        <div className="agreement-item_box">
          <div className="text-lg bold" style={{ marginBottom: '1rem' }}>
            <p>Tenant agrees that...</p>
          </div>
          <div className="agreement-item_desc">
            <p>
              The law applicable in any action arising out of this lease shall
              be that governing the Republic of Singapore and the parties hereto
              submit themselves to the jurisdiction of the law of Singapore.
            </p>
          </div>
        </div>
      </div>

      <div className="agreement-button_container">
        <div style={{ flex: 1 }}>
          <div className="tenant-details-offerBtn">
            <p className="text-md bold">Accept</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GoverningLaw
