import React, { useEffect, useState } from 'react'
import HeadBack from '../../../components/ui/HeadBack'
import './BrowseFilter.css'

import { ReactComponent as House } from '../../../assets/icon/general/Vector-17-house.svg'
import { ReactComponent as Pie } from '../../../assets/icon/general/Vector-84-piechart.svg'
import { ReactComponent as Bed } from '../../../assets/icon/general/Vector-4-bed.svg'
import { ReactComponent as Coin } from '../../../assets/icon/general/Vector-21-coin.svg'
import { ReactComponent as CalendarDot } from '../../../assets/icon/general/Vector-12-calendardoted.svg'
import { ReactComponent as Layer } from '../../../assets/icon/general/Vector-19-layer.svg'
import { ReactComponent as Furniture } from '../../../assets/icon/general/Vector-11-furniture.svg'

import { ReactComponent as Close } from '../../../assets/icon/general/Vector-9-close.svg'

import { ReactComponent as Filter } from '../../../assets/icon/general/Vector-29-filter.svg'
import SearchApply from '../../../components/browse/SearchApply'

const BrowseFilter = () => {
  const [isFilled, setIsFilled] = useState(false)
  const [propertyType, setPropertyType] = useState({
    property: [],
  })
  const [rentalType, setRentalType] = useState({
    rental: [],
  })

  const [bedNumber, setBedNumber] = useState('')
  const [furnishing, setFurnishing] = useState('')
  const [floor, setFloor] = useState('')

  const handlePropertyTypeChange = (e) => {
    // Destructuring
    const { value, checked } = e.target
    const { property } = propertyType

    // Case 1 : The user checks the box
    if (checked) {
      setPropertyType({
        property: [...property, value],
      })
    }

    // Case 2  : The user unchecks the box
    else {
      setPropertyType({
        property: property.filter((e) => e !== value),
      })
    }
  }

  const handleRentalTypeChange = (e) => {
    // Destructuring
    const { value, checked } = e.target
    const { rental } = rentalType

    // Case 1 : The user checks the box
    if (checked) {
      setRentalType({
        rental: [...rental, value],
      })
    }

    // Case 2  : The user unchecks the box
    else {
      setRentalType({
        rental: rental.filter((e) => e !== value),
      })
    }
  }

  useEffect(() => {
    if (propertyType?.property.length > 0) {
      setIsFilled(true)
    } else {
      setIsFilled(false)
    }
  }, [propertyType?.property])

  return (
    <div className="browse-filter-body">
      <HeadBack title="Filter" backLink={'/browse'} />

      <div className="browse-filter-page">
        <form className="form-filter">
          <div className="form-filter__wrapper">
            <div className="browse-option-container">
              <div className="type-option_heading">
                <div>
                  <House />
                </div>
                <p className="text-md bold">Property Type</p>
              </div>
              <div className="type-option_item-container">
                <div class="filter-checkbox-container">
                  <input
                    type="checkbox"
                    value="HDB"
                    name="property"
                    onChange={handlePropertyTypeChange}
                    id="hdb"
                  />
                  <label className="type-option_item" htmlFor="hdb">
                    HDB
                  </label>
                </div>

                <div class="filter-checkbox-container">
                  <input
                    type="checkbox"
                    value="Condo"
                    name="property"
                    onChange={handlePropertyTypeChange}
                    id="Condo"
                  />
                  <label className="type-option_item" htmlFor="Condo">
                    Condo
                  </label>
                </div>

                <div class="filter-checkbox-container">
                  <input
                    type="checkbox"
                    value="Landed"
                    name="property"
                    onChange={handlePropertyTypeChange}
                    id="Landed"
                  />
                  <label className="type-option_item" htmlFor="Landed">
                    Landed
                  </label>
                </div>
              </div>
            </div>

            <div className="browse-option-container">
              <div className="type-option_heading">
                <div>
                  <Pie />
                </div>
                <p className="text-md bold">Rental Type</p>
              </div>

              <div className="type-option_item-container">
                <div class="filter-checkbox-container">
                  <input
                    type="checkbox"
                    value="Entire Unit"
                    name="rental"
                    onChange={handleRentalTypeChange}
                    id="Entire Unit"
                  />
                  <label className="type-option_item" htmlFor="Entire Unit">
                    Entire Unit
                  </label>
                </div>

                <div class="filter-checkbox-container">
                  <input
                    type="checkbox"
                    value="Room with En-Suite"
                    name="rental"
                    onChange={handleRentalTypeChange}
                    id="Room with En-Suite"
                  />
                  <label
                    className="type-option_item"
                    htmlFor="Room with En-Suite"
                  >
                    Room with En-Suite
                  </label>
                </div>

                <div class="filter-checkbox-container">
                  <input
                    type="checkbox"
                    value="Room"
                    name="rental"
                    onChange={handleRentalTypeChange}
                    id="Room"
                  />
                  <label className="type-option_item" htmlFor="Room">
                    Room
                  </label>
                </div>
              </div>
            </div>

            <div className="browse-option-container">
              <div className="type-option_heading">
                <div>
                  <Bed />
                </div>
                <p className="text-md bold">Number of Beds</p>
              </div>

              <div className="type-option_item-container">
                <div class="filter-checkbox-container">
                  <input
                    type="checkbox"
                    value="Studio"
                    name="bed"
                    id="Studio"
                  />
                  <label className="type-option_item" htmlFor="Studio">
                    Studio
                  </label>
                </div>

                <div class="filter-checkbox-container">
                  <input type="checkbox" value="1" name="bed" id="1" />
                  <label className="type-option_item" htmlFor="1">
                    1
                  </label>
                </div>

                <div class="filter-checkbox-container">
                  <input type="checkbox" value="2" name="bed" id="2" />
                  <label className="type-option_item" htmlFor="2">
                    2
                  </label>
                </div>

                <div class="filter-checkbox-container">
                  <input type="checkbox" value="3" name="bed" id="3" />
                  <label className="type-option_item" htmlFor="3">
                    3
                  </label>
                </div>

                <div class="filter-checkbox-container">
                  <input type="checkbox" value="4" name="bed" id="4" />
                  <label className="type-option_item" htmlFor="4">
                    4
                  </label>
                </div>

                <div class="filter-checkbox-container">
                  <input type="checkbox" value="5+" name="bed" id="5+" />
                  <label className="type-option_item" htmlFor="5+">
                    5+
                  </label>
                </div>
              </div>
            </div>

            <div className="browse-option-container">
              <div className="type-option_heading">
                <div>
                  <Coin />
                </div>
                <p className="text-md bold">Rental Price</p>
              </div>

              <div className="type-option_item-container option-row">
                <div className="option-1">
                  <label htmlFor="rent price" className="text-xs normal">
                    MIN
                  </label>

                  <select
                    name="min rent"
                    id="min rent"
                    className="date-picker-container"
                    defaultValue={''}
                  >
                    <option value="">Select...</option>
                    <option value="$0">$0</option>
                  </select>
                </div>

                <div className="option-2">
                  <label htmlFor="max rent" className="text-xs normal">
                    MAX
                  </label>

                  <select
                    name="max rent"
                    id="max rent"
                    className="date-picker-container"
                    defaultValue={''}
                  >
                    <option value="" disabled>
                      Select...
                    </option>
                    <option value="$100">$100</option>
                  </select>
                </div>
              </div>
            </div>

            <div className="browse-option-container">
              <div className="type-option_heading">
                <div>
                  <CalendarDot />
                </div>
                <p className="text-md bold">Availability</p>
              </div>

              <div className="form-availability-container">
                <div class="form-input_radio-container">
                  <label class="radio-option-container radio-input_item">
                    <input
                      type="radio"
                      name="availability-radio"
                      id="now"
                      value="now"
                    />
                    <label class="radio-custom" for="now">
                      Now
                    </label>
                    <span class="checkmark"></span>
                  </label>

                  <label class="radio-option-container radio-input_item">
                    <input
                      type="radio"
                      name="availability-radio"
                      id="after"
                      value="after"
                    />
                    <label class="radio-custom" for="after">
                      After
                    </label>
                    <span class="checkmark"></span>
                  </label>
                </div>

                <div>
                  <input
                    type="date"
                    id="birthday"
                    name="birthday"
                    className="date-picker-container"
                  ></input>
                </div>
              </div>
            </div>

            <div className="browse-option-container">
              <div className="type-option_heading">
                <div>
                  <Layer />
                </div>
                <p className="text-md bold">Floor Area (sqft)</p>
              </div>

              <div className="type-option_item-container option-row">
                <div className="option-1">
                  <label htmlFor="min floor area" className="text-xs normal">
                    MIN
                  </label>

                  <select
                    name="min floor area"
                    id="min floor area"
                    className="date-picker-container"
                    defaultValue={''}
                  >
                    <option value="" disabled>
                      Select...
                    </option>
                    <option value="5">5 sqft</option>
                  </select>
                </div>

                <div className="option-2">
                  <label htmlFor="cars" className="text-xs normal">
                    MAX
                  </label>

                  <select
                    name="cars"
                    id="cars"
                    className="date-picker-container"
                    defaultValue={''}
                  >
                    <option value="" disabled>
                      Select...
                    </option>
                    <option value="5">100 sqft</option>
                  </select>
                </div>
              </div>
            </div>

            <div className="browse-option-container">
              <div className="type-option_heading">
                <div>
                  <Furniture />
                </div>
                <p className="text-md bold">Furnishing</p>
              </div>

              <div className="type-option_item-container">
                <div class="filter-checkbox-container">
                  <input
                    type="checkbox"
                    value="Full"
                    name="furnishing"
                    id="Full"
                  />
                  <label className="type-option_item" htmlFor="Full">
                    Full
                  </label>
                </div>

                <div class="filter-checkbox-container">
                  <input
                    type="checkbox"
                    value="Partial"
                    name="furnishing"
                    id="Partial"
                  />
                  <label className="type-option_item" htmlFor="Partial">
                    Partial
                  </label>
                </div>

                <div class="filter-checkbox-container">
                  <input
                    type="checkbox"
                    value="Unfurnished"
                    name="furnishing"
                    id="Unfurnished"
                  />
                  <label className="type-option_item" htmlFor="Unfurnished">
                    Unfurnished
                  </label>
                </div>
              </div>
            </div>

            <div className="browse-option-container">
              <div className="type-option_heading">
                <div>
                  <Layer />
                </div>
                <p className="text-md bold">Floor</p>
              </div>

              <div className="type-option_item-container">
                <div class="filter-checkbox-container">
                  <input type="checkbox" value="Low" name="floor" id="Low" />
                  <label className="type-option_item" htmlFor="Low">
                    Low
                  </label>
                </div>

                <div class="filter-checkbox-container">
                  <input type="checkbox" value="Mid" name="floor" id="Mid" />
                  <label className="type-option_item" htmlFor="Mid">
                    Mid
                  </label>
                </div>

                <div class="filter-checkbox-container">
                  <input type="checkbox" value="High" name="floor" id="High" />
                  <label className="type-option_item" htmlFor="High">
                    High
                  </label>
                </div>
              </div>
            </div>

            <div className="browse-option-container">
              <div className="type-option_heading">
                <div>
                  <CalendarDot />
                </div>
                <p className="text-md bold">Built Year</p>
              </div>

              <div className="form-availability-container">
                <div class="form-input_radio-container">
                  <label class="radio-option-container radio-input_item">
                    <input
                      type="radio"
                      name="availability-radio"
                      id="now"
                      value="now"
                    />
                    <label class="radio-custom" for="now">
                      {'<'} 5 Years Ago
                    </label>
                    <span class="checkmark"></span>
                  </label>

                  <label class="radio-option-container radio-input_item">
                    <input
                      type="radio"
                      name="availability-radio"
                      id="after"
                      value="after"
                    />
                    <label class="radio-custom" for="after">
                      On or after
                    </label>
                    <span class="checkmark"></span>
                  </label>
                </div>

                <div>
                  <select
                    name="year"
                    id="year"
                    className="date-picker-container"
                    defaultValue={''}
                  >
                    <option value="" disabled>
                      YYYY
                    </option>
                    <option value="2000">2000</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <SearchApply type="filter" />
          {/* <div className="schedule-button button-row">
            <div
              className={`${
                isFilled
                  ? 'button__style-3 button__style-3--active'
                  : 'button__style-3'
              }`}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '.5rem',
              }}
            >
              <div>
                <Close />
              </div>
              <p className="text-md" style={{ textAlign: 'center' }}>
                Clear
              </p>
            </div>

            <div
              className={`${
                isFilled
                  ? 'button__style-3 button__style-3--active'
                  : 'button__style-3'
              }`}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '.5rem',
              }}
            >
              <div>
                <Filter />
              </div>
              <p className="text-md" style={{ textAlign: 'center' }}>
                Apply filter(s)
              </p>
            </div>
          </div> */}
        </form>
      </div>
    </div>
  )
}

export default BrowseFilter
