import React, {useState} from 'react'
import HeadBack from '../../../components/ui/HeadBack'
import { ReactComponent as VerifiedIcon } from '../../../assets/icon/general/Vector-43-verified.svg';

import { ReactComponent as CreditCardIcon } from '../../../assets/icon/general/Vector-credit-card.svg';
import { ReactComponent as BankIcon } from '../../../assets/icon/general/Vector-56-bank.svg';
import { ReactComponent as PaynowIcon } from '../../../assets/icon/general/Vector-paynow.svg';
import '../../../assets/css/pages/LeasePage/Tenant/payment.css'

import PaymentOutlet1 from '../../../components/LeasePage/Tenant/PaymentOutlet1';
import PaymentOutlet from '../../../components/LeasePage/Tenant/PaymentOutlet';
import PaymentChoices from '../../../components/offer/Payment Choices/PaymentChoices';
function Payment() {
	const [paymentType, setPaymentType] = useState(0)
	const isFilled = false
	return (
		<div className='payment'>
			<HeadBack backLink="/lease/tenant" title="Make Payment" />

			<div className='payment__body'>
				<div className='payment__payable'>
					<span className='payable__title'>Payable(s)</span>

					<div className='payment__wrapper'>
						<div className='payable__pay'>
							<div>
								<span>Security Deposit</span>
								<span><span>SGD</span> $7,200.00</span>
							</div>
							<div>
								<span>Paying to Wilson Chan Yi De</span>
								<span className='payable__icon'>
									<VerifiedIcon />
								</span>
								<span>VIA P.LEASE</span>
							</div>
						</div>

						<div className='payable__pay'>
							<div>
								<span>Stamp Duty</span>
								<span><span>SGD</span> $345.60</span>
							</div>
							<div>
								<span>PAYING TO IRAS</span>
							</div>
						</div>

						<div className='payable__pay'>
							<div>
								<span>Total Payable</span>
								<span><span>SGD</span> $7,545.60</span>
							</div>
						</div>
					</div>
				</div>

				{/* <div className='payment__payable'>
					<span className='payable__title'>Payment Method</span>

					<div className='payment__tabs'>
						<button className={paymentType === 0 ? 'tab__payment tab__payment--active' : 'tab__payment'} onClick={() => setPaymentType(0)}>
							<CreditCardIcon /><p>Card</p>
						</button>

						<button className={paymentType === 1 ? 'tab__payment tab__payment--active' : 'tab__payment'} onClick={() => setPaymentType(1)}>
							<BankIcon /> 
							<p>Bank Transfer</p>
						</button>

						<button className='tab__payment'>
							<PaynowIcon />
						</button>
					</div>

					<div className='payment__outlet'>
						{paymentType === 0 && (<PaymentOutlet />)}
						{paymentType === 1 && (<PaymentOutlet1 />)}
					</div>
				</div> */}
				<PaymentChoices />

				<div className="onboarding__button">
          <button
            // onClick={modalHandler}
            className="button__style-3 button__style-3--active"
            type="button"
          >
            Continue
          </button>
        </div>
			</div>
		</div>
	)
}

export default Payment