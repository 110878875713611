import React, { useEffect, useState } from 'react'
import { ReactComponent as Back } from '../../assets/icon/general/Vector-1-back.svg'
import { ReactComponent as Pen } from '../../assets/icon/general/Vector-49-pen.svg'
import { ReactComponent as Delete } from '../../assets/icon/general/Vector-9-close.svg'
import '../../assets/css/pages/LeasePage/manageListing.css'
import { NavLink, useParams, useNavigate } from 'react-router-dom'
import Modal from '../../components/ui/Modal'
import propertyViewingDateService from '../../services/propertyViewingDateService'
import propertyService from '../../services/PropertyService'
import axios from 'axios'
import { apiBaseURL } from '../../utils'
import moment from 'moment'
import { useForm } from 'react-hook-form'

function ManageListing() {
  const { propertyId } = useParams()
  const navigate = useNavigate()
  const [timeslotsAdded, setTimeslotsAdded] = useState(false)
  const [timeslotsEdited, setTimeSlotsEdited] = useState(false)
  const [timeSlot, setTimeSlot] = useState([])
  const [editID, setEditID] = useState(null)
  const [property, setProperty] = useState([])
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm()

  const getTimeslot = async () => {
    const response = await propertyViewingDateService.getAllFromPropID(
      propertyId
    )
    setTimeSlot(response.data)
  }

  const deleteView = async (id) => {
    const response = await propertyViewingDateService.deleteOne(id)
    getTimeslot()

    console.log(response)
  }

  const deleteProp = async () => {
    const response = await propertyService.deleteOne(propertyId)
    console.log(response)
  }

  const handleDelete = (id) => {
    deleteView(id)
  }

  const handlePropDel = () => {
    deleteProp()
  }

  const getProp = async () => {
    const response = await axios.get(`${apiBaseURL}/properties/${propertyId}`)
    setValue('utilities', response.data.utilities?.toString())
    setValue('wifi', response.data.wifi?.toString())
    setValue('aircon_servicing', response.data.aircon_servicing?.toString())
    setValue('cooking', response.data.cooking?.toString())
    setValue('pets', response.data.pets?.toString())
    setValue('drilling', response.data.drilling?.toString())
    setValue('repainting', response.data.repainting?.toString())
    setValue('handover_cleaning', response.data.handover_cleaning?.toString())
  }

  const _handleSubmit = async (data) => {
    // Handle Form
    const formData = {
      utilities: parseInt(data.utilities),
      wifi: parseInt(data.wifi),
      aircon_servicing: parseInt(data.aircon_servicing),
      cooking: parseInt(data.cooking),
      pets: parseInt(data.pets),
      drilling: parseInt(data.drilling),
      repainting: parseInt(data.repainting),
      handover_cleaning: parseInt(data.handover_cleaning),
    }

    try {
      const response = await propertyService.updateOne(propertyId, formData)
      console.log(response)
      alert('Data Saved!')
      navigate('/lease')
    } catch (error) {
      alert(error)
    }
  }

  useEffect(() => {
    getProp()
  }, [])

  useEffect(() => {
    getTimeslot()
  }, [])

  const timeslotsToggler = () => {
    setTimeslotsAdded(true)
  }

  const editTimeToggler = () => {
    setTimeSlotsEdited(true)
  }

  return (
    <section class="manage-listing__main">
      {timeslotsAdded && (
        <Modal
          show={timeslotsAdded}
          type="addTimeSlot"
          hide={() => {
            getTimeslot()
            setTimeslotsAdded(false)
          }}
          Propid={propertyId}
        />
      )}
      {timeslotsEdited && (
        <Modal
          show={timeslotsEdited}
          type="editTimeSlot"
          hide={() => {
            getTimeslot()
            setTimeSlotsEdited(false)
          }}
          timeId={editID}
        />
      )}
      <div class="manage-listing__header">
        <div class="manage-listing__header-title">
          <NavLink to="/lease">
            <Back />
          </NavLink>
          <h2>Manage Listing</h2>
        </div>
      </div>
      <div class="manage-listing__body">
        <div class="manage-listing__body-container">
          <div class="body-container-timeslots">
            <p class="text-lg">Viewing Timeslots</p>
            {/* Schedule */}
            {timeSlot.map((time) => (
              <div class="body-container-timeslots-schedule">
                <div class="body-container-timeslots-schedule-details">
                  <div class="timeslots-schedule-details-date">
                    <div class="date__day">
                      <p class="text-md">
                        {moment(time.viewing_date).format('ddd')}
                      </p>
                    </div>
                    <div class="date__date-month">
                      <p class="text-lg">
                        {moment(time.viewing_date).format('DD')}
                      </p>
                      <p class="text-lg">
                        {moment(time.viewing_date).format('MMM')}
                      </p>
                    </div>
                  </div>
                  <div class="timeslots-schedule-details-time">
                    <p class="text-md">
                      {moment(time.time_start, 'HH:mm:ss').format('hh:mm A')} -{' '}
                      {moment(time.time_end, 'HH:mm:ss').format('hh:mm A')}
                    </p>
                    <p class="text-xs capacity">
                      MAX PAX{' '}
                      {time.viewing_quota === 9999
                        ? 'Unlimited'
                        : time.viewing_quota}
                    </p>
                  </div>
                </div>
                <div class="body-container-timeslots-schedule-action">
                  <Pen
                    onClick={() => {
                      setEditID(time.id)
                      editTimeToggler()
                      console.log('Pen')
                    }}
                  />
                  <Delete
                    onClick={() => {
                      handleDelete(time.id)
                      console.log('Delete')
                    }}
                  />
                </div>
              </div>
            ))}
            <button class="timeslotBtn" onClick={timeslotsToggler}>
              + Add new timeslot
            </button>
          </div>
        </div>
        <div class="manage-listing__body-container">
          <div class="body-container-billing">
            <p class="text-lg">Billing, Services & Fees</p>
            <div class="billing-switches">
              {/* Utilites */}
              <div class="billing-switches-services">
                <p class="text-md">Utilities</p>
                <div class="services-option">
                  <input
                    type="radio"
                    id="tenant-utilities"
                    name="switch-utilities"
                    value="1"
                    {...register('utilities', {
                      setValueAs: (value) => parseInt(value),
                    })}
                  />
                  <label for="tenant-utilities">Tenant</label>
                  <input
                    type="radio"
                    id="rent-utilities"
                    name="switch-utilities"
                    value="2"
                    {...register('utilities')}
                  />
                  <label for="rent-utilities">Included in Rent</label>
                </div>
              </div>

              {/* Wifi */}
              <div class="billing-switches-services">
                <p class="text-md">Wifi</p>
                <div class="services-option">
                  <input
                    type="radio"
                    id="tenant-wifi"
                    name="switch-wifi"
                    value="1"
                    {...register('wifi')}
                  />
                  <label for="tenant-wifi">Tenant</label>
                  <input
                    type="radio"
                    id="rent-wifi"
                    name="switch-wifi"
                    value="2"
                    {...register('wifi')}
                  />
                  <label for="rent-wifi">Included in Rent</label>
                </div>
              </div>

              {/* Aircon Servicing */}
              <div class="billing-switches-services">
                <p class="text-md">Aircon Servicing</p>
                <div class="services-option">
                  <input
                    type="radio"
                    id="tenant-aircon"
                    name="switch-aircon"
                    value="1"
                    {...register('aircon_servicing')}
                  />
                  <label for="tenant-aircon">Tenant</label>
                  <input
                    type="radio"
                    id="rent-aircon"
                    name="switch-aircon"
                    value="2"
                    {...register('aircon_servicing')}
                  />
                  <label for="rent-aircon">Included in Rent</label>
                </div>
              </div>

              {/* Handover Cleaning */}
              <div class="billing-switches-services">
                <p class="text-md">Handover Cleaning</p>
                <div class="services-option">
                  <input
                    type="radio"
                    id="tenant-cleaning"
                    name="switch-cleaning"
                    value="1"
                    {...register('handover_cleaning')}
                  />
                  <label for="tenant-cleaning">Tenant</label>
                  <input
                    type="radio"
                    id="rent-cleaning"
                    name="switch-cleaning"
                    value="2"
                    {...register('handover_cleaning')}
                  />
                  <label for="rent-cleaning">Included in Rent</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="manage-listing__body-container">
          <div class="body-container-permissions">
            <p class="text-lg">Additional Permissions</p>
            {/* Cooking */}
            <div class="permissions-radio-container">
              <p class="text-md">Cooking</p>
              <div class="permissions-radio-option">
                <label class="radio-option-container">
                  <input
                    type="radio"
                    name="cooking-radio"
                    id="cooking-0"
                    value="1"
                    {...register('cooking')}
                  />
                  <label class="radio-custom" for="cooking-0">
                    Heavy
                  </label>
                  <span class="checkmark"></span>
                </label>

                <label class="radio-option-container">
                  <input
                    type="radio"
                    name="cooking-radio"
                    id="cooking-1"
                    value="2"
                    {...register('cooking')}
                  />
                  <label class="radio-custom" for="cooking-1">
                    Moderate
                  </label>
                  <span class="checkmark"></span>
                </label>

                <label class="radio-option-container">
                  <input
                    type="radio"
                    name="cooking-radio"
                    id="cooking-1"
                    value="3"
                    {...register('cooking')}
                  />
                  <label class="radio-custom" for="cooking-1">
                    Light
                  </label>
                  <span class="checkmark"></span>
                </label>

                <label class="radio-option-container">
                  <input
                    type="radio"
                    name="cooking-radio"
                    id="cooking-1"
                    value="4"
                    {...register('cooking')}
                  />
                  <label class="radio-custom" for="cooking-1">
                    None
                  </label>
                  <span class="checkmark"></span>
                </label>
              </div>
            </div>

            {/* Pets */}
            <div class="permissions-radio-container">
              <p class="text-md">Pets</p>
              <div class="permissions-radio-option">
                <label class="radio-option-container">
                  <input
                    type="radio"
                    name="pets-radio"
                    id="pets-0"
                    value="1"
                    {...register('pets')}
                  />
                  <label class="radio-custom" for="pets-0">
                    Yes
                  </label>
                  <span class="checkmark"></span>
                </label>

                <label class="radio-option-container">
                  <input
                    type="radio"
                    name="pets-radio"
                    id="pets-1"
                    value="2"
                    {...register('pets')}
                  />
                  <label class="radio-custom" for="pets-1">
                    No
                  </label>
                  <span class="checkmark"></span>
                </label>
              </div>
            </div>

            {/* Drilling */}
            <div class="permissions-radio-container">
              <p class="text-md">Drilling</p>
              <div class="permissions-radio-option">
                <label class="radio-option-container">
                  <input
                    type="radio"
                    name="drilling-radio"
                    id="drilling-0"
                    value="1"
                    {...register('drilling')}
                  />
                  <label class="radio-custom" for="drilling-0">
                    Only if approved
                  </label>
                  <span class="checkmark"></span>
                </label>

                <label class="radio-option-container">
                  <input
                    type="radio"
                    name="drilling-radio"
                    id="drilling-1"
                    value="2"
                    {...register('drilling')}
                  />
                  <label class="radio-custom" for="drilling-1">
                    No
                  </label>
                  <span class="checkmark"></span>
                </label>
              </div>
            </div>

            {/* Repainting */}
            <div class="permissions-radio-container">
              <p class="text-md">Repainting</p>
              <div class="permissions-radio-option">
                <label class="radio-option-container">
                  <input
                    type="radio"
                    name="repainting-radio"
                    id="repainting-0"
                    value="1"
                    {...register('repainting')}
                  />
                  <label class="radio-custom" for="repainting-0">
                    Only if approved
                  </label>
                  <span class="checkmark"></span>
                </label>

                <label class="radio-option-container">
                  <input
                    type="radio"
                    name="repainting-radio"
                    id="repainting-1"
                    value="2"
                    {...register('repainting')}
                  />
                  <label class="radio-custom" for="repainting-1">
                    No
                  </label>
                  <span class="checkmark"></span>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="permissions__remove-button">
          <NavLink to={'/lease'}>
            <button
              type="reset"
              onClick={() => {
                handlePropDel()
              }}
            >
              Remove Listing
            </button>
          </NavLink>
        </div>
      </div>
      <div class="permissions__save-button">
          <button
            className="button__style-3 button__style-3--active"
            onClick={handleSubmit(_handleSubmit)}
          >
            Save Configuration
          </button>
      </div>
    </section>
  )
}

export default ManageListing
