import { useRef } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'

const OptionalForm = ({ setAdditionalRequirements }) => {
  const textareaRef = useRef(null)
  const [count, setCount] = useState(0)
  const [currentValue, setCurrentValue] = useState('')

  useEffect(() => {
    textareaRef.current.style.height = '0px'
    const scrollHeight = textareaRef.current.scrollHeight
    textareaRef.current.style.height = scrollHeight + 'px'

    if(currentValue !== "") setAdditionalRequirements(currentValue)
  }, [currentValue])

  return (
    <div className="offer-body__section-container">
      <div className="offer-body__form-container">
        <h1 className=" offer-body__title">
          Additional Requirements (optional)
        </h1>
        <div className="offer-body__form">
          <div className="offer-body__form-input">
            <textarea
              className="offer-body__text-area"
              maxLength={1000}
              ref={textareaRef}
              rows={'100%'}
              value={currentValue}
              onChange={(e) => [
                setCount(e.target.value.length),
                setCurrentValue(e.target.value),
              ]}
            />
            <div className="offer-body__text-area-counter">
              <p>{count}/1000</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OptionalForm
