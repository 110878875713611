import React, { useState } from 'react'
import DummyImage from '../../../assets/images/image 5.png'
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide'
import { ReactComponent as Bed } from '../../../assets/icon/general/Vector-4-bed.svg'
import { ReactComponent as PlusIcon } from '../../../assets/icon/general/Vector-plus.svg'
import { ReactComponent as Plus } from '../../../assets/icon/general/Vector-plus.svg'
import { ReactComponent as View } from '../../../assets/icon/general/Vector-30-view.svg'
import LeaseCurrentBox from '../../../components/LeasePage/Tenant/LeaseCurrentBox'
import LeaseCardProperty from '../../../components/LeasePage/LeaseCardProperty'
import '../../../assets/css/pages/LeasePage/leased.css'
import Button from '../components/Button'
import LeaseAddHead from '../../../components/ui/LeaseAddHead'
import { ReactComponent as Bathub } from '../../../assets/icon/general/Vector-85-bathtub.svg'
import { ReactComponent as Highlight } from '../../../assets/icon/general/Vector-47-star-highlight.svg'

const Leased = () => {
  const [handover, setHandover] = useState(true)

  return (
    <div className="leased">
      <LeaseAddHead type="leased" />

      <div className="leased-body">
        <div className="leased-wrapper">
          <LeaseCurrentBox title="Summary" outlet="summaryLeased" />

          {handover && (
            <LeaseCurrentBox
              title="Handover Checklist"
              outlet="summaryHandoverBefore"
            />
          )}
          <LeaseCardProperty cardLeaseType="summaryLeased" />
        </div>
      </div>
    </div>
  )
}

export default Leased
