import React, { useState, useRef, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import ReactDOM from 'react-dom'
import ReactDatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import '../../assets/css/components/ui/modal.css'
import { ReactComponent as VerifyIllus } from '../../assets/illustration/onboarding/verify.svg'
import { ReactComponent as RocketIllus } from '../../assets/illustration/onboarding/Rocket Launch _Monochromatic 1.svg'
import { ReactComponent as SignIllus } from '../../assets/illustration/modal/Checklist _Monochromatic 1.svg'
import { ReactComponent as PaymentIllus } from '../../assets/illustration/modal/Startup_Monochromatic 1.svg'
import { ReactComponent as MoneyTransferIllus } from '../../assets/illustration/modal/Money transfer _Monochromatic 1.svg'
import { ReactComponent as PartyIllus } from '../../assets/illustration/reviewTA/Party_Monochromatic 1.svg'
import { ReactComponent as ReviewTAIllus } from '../../assets/illustration/reviewTA/reviewTA-illus.svg'
import { ReactComponent as FingerIcon } from '../../assets/icon/general/Vector-53-finger.svg'
import { ReactComponent as LensIcon } from '../../assets/icon/general/Vector-lens.svg'
import { ReactComponent as CloseIcon } from '../../assets/icon/general/Vector-9-close.svg'
import { ReactComponent as WormsIcon } from '../../assets/icon/general/Vector-83-worms.svg'
import { ReactComponent as BackIcon } from '../../assets/icon/general/Vector-1-back.svg'
import { ReactComponent as UserIcon } from '../../assets/icon/general/Vector-23-profile.svg'
import { ReactComponent as PlusIcon } from '../../assets/icon/general/Vector-plus.svg'
import { ReactComponent as DolarIcon } from '../../assets/icon/general/Vector-21-coin.svg'
import { ReactComponent as ExclamationIcon } from '../../assets/icon/general/Vector-24-hexa-exclamation.svg'
import { ReactComponent as CheckIcon } from '../../assets/icon/general/Vector-42-check.svg'
import { ReactComponent as CrossIcon } from '../../assets/icon/general/Vector-9-close.svg'
import { ReactComponent as CalendarIcon } from '../../assets/icon/general/Vector-5-calendar.svg'
import { ReactComponent as HomeIcon } from '../../assets/icon/general/Vector-17-house.svg'
import { ReactComponent as InfoIcon } from '../../assets/icon/general/Vector-18-info.svg'
import { ReactComponent as VerifiedIcon } from '../../assets/icon/general/Vector-43-verified.svg'
import { ReactComponent as Star } from '../../assets/icon/general/Vector-44-star.svg'
import { ReactComponent as EyeIcon } from '../../assets/icon/general/Vector-30-eye.svg'
import { ReactComponent as FlagIcon } from '../../assets/icon/general/Vector-46-flag.svg'
import { ReactComponent as BinIcon } from '../../assets/icon/general/Vector-81-trash.svg'
import { ReactComponent as SquareCheckIcon } from '../../assets/icon/general/Vector-7-square-check.svg'
import { ReactComponent as StarHighlightIcon } from '../../assets/icon/general/Vector-47-star-highlight.svg'
import SignatureCanvas from 'react-signature-canvas'
import InfoBox from './InfoBox'
import { useForm } from 'react-hook-form'
import moment from 'moment'
import propertyViewingDateService from '../../services/propertyViewingDateService'

function Modal(props) {
  const navigate = useNavigate()
  const sigCanvas = useRef()
  const [imageURL, setImageURL] = useState(null)
  const [signature, setSignature] = useState(null)
  const [thisType, setThisType] = useState(props.type)
  // { show, hide, type, pay }
  console.log(props.type)

  const create = () => {
    const URL = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png')
    setImageURL(URL)
    props.pay()
    setThisType('payment')
  }

  const createOffer = () => {
    const URL = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png')
    setImageURL(URL)
    props.signOffer()
  }

  const handleSignatureEnd = () => {
    setSignature(sigCanvas.current.toDataURL())
    props.setSignedPhoto(sigCanvas.current.toDataURL())
  }

  const clearSignature = () => {
    sigCanvas.current.clear()
    setSignature(null)
    setImageURL(null)
  }

  const modalSignHandler = () => {
    setThisType('sign')
    props.setReview2()
  }

  const handleClickDirect = (url) => {
    navigate(url)
  }

  useEffect(() => {
    console.log(signature)
  }, [signature])

  const [monthlyRent, setMonthlyRent] = useState('3,600.00')
  const [paymentSchedule, setPaymentSchedule] = useState('')
  const [paymentPeriod, setPaymentPeriod] = useState('')
  const [firstPayment, setFirstPayment] = useState(null)
  const [commented, setCommented] = useState('')
  const [isEdited, setIsEdited] = useState(false)

  function handleMonthlyRentChange(event) {
    setMonthlyRent(event.target.value)
    setIsEdited(true)
  }

  function handlePaymentScheduleChange(event) {
    setPaymentSchedule(event.target.value)
    setIsEdited(true)
  }

  function handlePaymentPeriodChange(event) {
    setPaymentPeriod(event.target.value)
    setIsEdited(true)
  }

  function handleFirstPaymentChange(date) {
    setFirstPayment(date)
    setIsEdited(true)
  }

  function handleCommentedChange(event) {
    setCommented(event.target.value)
    setIsEdited(true)
  }

  function handleComment(event) {
    handleCommentedChange(event)
    setCharCount(event.target.value.length)
  }

  const [charCount, setCharCount] = useState(0)

  const [boostSelect, setBoostSelect] = useState(null)

  const [isInputed, setIsInputed] = useState(false)
  const [capacity, setCapacity] = useState('')
  const [dateValue, setDateValue] = useState('')
  const [timeslotCapacity, setTimeslotCapacity] = useState('')
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm()

  function handleTimeslotDate() {
    setIsInputed(true)
  }

  function handleTimeslotCapacity(event) {
    setTimeslotCapacity(event.target.value)
    setIsInputed(true)
  }

  const handleAddTimeSlot = async (data) => {
    const date = moment(data.datetime).format('YYYY-MM-DD')
    const time = moment(data.datetime).format('HH:mm')
    const endTime = moment(time, 'HH:mm').add(30, 'm').format('HH:mm')
    let quota = capacity

    if (quota === 'Unlimited') {
      quota = 9999
    } else {
      quota = parseInt(quota)
    }

    const nonInputData = {
      viewing_quota: quota,
      viewing_date: date,
      time_start: time,
      time_end: endTime,
      total_viewing_request: 0,
      property_id: props.Propid,
    }

    const response = await propertyViewingDateService.createOne(nonInputData)
    console.log(response)

    props.hide()
  }

  const handleEditTimeSlot = async (data) => {
    const date = moment(data.datetime).format('YYYY-MM-DD')
    const time = moment(data.datetime).format('HH:mm')
    const endTime = moment(time, 'HH:mm').add(30, 'm').format('HH:mm')
    let quota = capacity

    if (quota === 'Unlimited') {
      quota = 9999
    } else {
      quota = parseInt(quota)
    }

    const nonInputData = {
      viewing_quota: quota,
      viewing_date: date,
      time_start: time,
      time_end: endTime,
    }

    const response = await propertyViewingDateService.updateOne(
      props.timeId,
      nonInputData
    )
    console.log(response)

    props.hide()
  }

  const getEditTime = async () => {
    const response = await propertyViewingDateService.getOne(props.timeId)
    console.log("Data", response)
    setValue(
      'datetime',
      moment(response.data?.viewing_date).format('YYYY-DD-MM') +
        'T' +
        moment(response.data?.time_start, 'HH:mm:ss').format('HH:mm')
    )

    setDateValue(
      moment(response.data?.viewing_date).format('YYYY-DD-MM') +
        'T' +
        moment(response.data?.time_start, 'HH:mm:ss').format('HH:mm')
    )

    if (response.data.viewing_quota === 9999) {
      setCapacity('Unlimited')
    } else {
      setCapacity(response.data.viewing_quota?.toString())
    }

    handleTimeslotDate()
  }

  useEffect(() => {
    if(props.type === "editTimeSlot"){
      getEditTime()
    }
  }, [])

  console.log(watch())

  return ReactDOM.createPortal(
    <div className="modal">
      <div className="modal__overlay" onClick={props.hide}></div>
      {/* Modal onboarding user tenant and landlord (modal shared view 02) */}
      {thisType === 'onboarding' && (
        <div className="modal__content">
          <div className="content__illus">
            <VerifyIllus />
          </div>

          <div className="content__text">
            <h1>Sit tight! We are verifying your account...</h1>
            <p>
              We will notify you regarding the status of your account via email
              within 2 business days. In the meantime, feel free to browse our
              listings or set up your 2FA.
            </p>
          </div>

          <div className="content__handler">
            <button
              className="button__style-4--1"
              onClick={
                props.user === 'tenant'
                  ? () => handleClickDirect('/account')
                  : () => handleClickDirect('/account')
              } //Changed from "/browse" to "/account"
            >
              {props.user === 'tenant' ? <LensIcon /> : <PlusIcon />}
              {props.user === 'tenant' ? 'Browse listings' : 'List my property'}
            </button>
            <button
              className="button__style-4--2"
              onClick={() => handleClickDirect('/account/password')}
            >
              <FingerIcon />
              Set up 2FA
            </button>
          </div>
        </div>
      )}

      {/* Modal verify (modal shared view 01) */}
      {thisType === 'verify' && (
        <div className="modal__content">
          <div className="content__illus">
            <RocketIllus />
          </div>

          <div className="content__text">
            <h1>Awesome!</h1>
            <p>
              You have unlocked unlimited browsing and shortlists. To unlock all
              the other features such as chats, booking a viewing and making an
              offer, complete your profile to get your account verified.
            </p>
          </div>

          <div className="content__handler">
            <button
              className="button__style-4--1"
              onClick={() => handleClickDirect('/browse')}
            >
              <LensIcon />
              Browse listings
            </button>
            <button
              className="button__style-4--2"
              onClick={() => handleClickDirect('/account/profile')}
            >
              <UserIcon />
              Complete my profile
            </button>
          </div>
        </div>
      )}

      {/* Modal account verified (modal shared view 03) */}
      {thisType === 'verified' && (
        <div className="modal__content">
          <div className="content__illus">
            <RocketIllus />
          </div>

          <div className="content__text">
            <h1>Your account is verified!</h1>
            <p>
              All features unlocked - go forth and explore what P.Lease has in
              store.
            </p>
          </div>

          <div className="content__handler">
            <button
              className="button__style-4--1"
              onClick={
                props.user === 'tenant'
                  ? () => handleClickDirect('/browse')
                  : () => handleClickDirect('/browse')
              }
            >
              {props.user === 'tenant' ? <LensIcon /> : <PlusIcon />}
              {props.user === 'tenant' ? 'Browse listings' : 'List my property'}
            </button>
            <button
              className="button__style-4--2"
              onClick={() => handleClickDirect('/account/password')}
            >
              <FingerIcon />
              Set up 2FA
            </button>
          </div>
        </div>
      )}

      {thisType === 'review1' && (
        <div className="modal__content">
          <div className="content__illus">
            <VerifyIllus />
          </div>

          <div className="content__text">
            <h1>Thanks for reviewing!</h1>
            <p>
              You have reviewed and suggested edits to some terms in the
              agreement. We will notify the landlord to check out your
              revisions.
            </p>
          </div>

          <div className="content__handler">
            <button className="button__style-4--2" onClick={props.hideReview1}>
              <CloseIcon />
              Close message
            </button>
          </div>
        </div>
      )}

      {thisType === 'review2' && (
        <div className="modal__content">
          <div className="content__illus">
            <SignIllus />
          </div>

          <div className="content__text">
            <h1>You’re almost there!</h1>
            <p>
              All the terms in the Tenancy Agreement (TA) have been reviewed and
              accepted. You may now proceed to sign it off.
            </p>
          </div>

          <div className="content__handler">
            <button className="button__style-4--1" onClick={modalSignHandler}>
              <WormsIcon />
              Sign agreement
            </button>
            <button className="button__style-4--2" onClick={props.hideReview2}>
              <BackIcon />
              Back to overview
            </button>
          </div>
        </div>
      )}

      {thisType === 'payment' && (
        <div className="modal__content">
          <div className="content__illus">
            <PaymentIllus />
          </div>

          <div className="content__text">
            <h1>Just one more step!</h1>
            <p>
              You have signed off the Tenancy Agreement. You may now make
              payment for the security deposit and stamp duty as the final step.
            </p>
          </div>

          <div className="content__handler">
            <button
              className="button__style-4--1"
              onClick={() => handleClickDirect('/payment')}
            >
              <DolarIcon />
              Make payment
            </button>
            <button className="button__style-4--2" onClick={props.hide}>
              <BackIcon />
              Back to overview
            </button>
          </div>
        </div>
      )}

      {thisType === 'sign' && (
        <div className="modal__content">
          <div className="content__head">
            <span>Digital Signing</span>
            <span>Please sign within the white box.</span>
          </div>

          <div className="content__sign">
            <SignatureCanvas
              onEnd={handleSignatureEnd}
              penColor="black"
              ref={sigCanvas}
              canvasProps={{ className: 'sigCanvas' }}
            />
          </div>

          <div className="content__handler content__handler--sign">
            <button
              className={
                signature
                  ? 'button__style-4--2 button__style-4--2-active'
                  : 'button__style-4--2'
              }
              onClick={clearSignature}
            >
              Clear
            </button>
            <button className="button__style-4--1" onClick={create}>
              Confirm signature
            </button>
          </div>
        </div>
      )}

      {thisType === 'signOffer' && (
        <div className="modal__content">
          <div className="content__head">
            <span>Digital Signing</span>
            <span>Please sign within the white box.</span>
          </div>

          <div className="content__sign">
            <SignatureCanvas
              onEnd={handleSignatureEnd}
              penColor="black"
              ref={sigCanvas}
              canvasProps={{ className: 'sigCanvas' }}
            />
          </div>

          <div className="content__handler content__handler--sign">
            <button
              className={
                signature
                  ? 'button__style-4--2 button__style-4--2-active'
                  : 'button__style-4--2'
              }
              onClick={clearSignature}
            >
              Clear
            </button>
            <button className="button__style-4--1" onClick={createOffer}>
              Confirm signature
            </button>
          </div>
        </div>
      )}

      {thisType === 'reject-suggest' && (
        <div className="modal__content">
          <div className="modal__reject">
            <div className="modal__title">
              <ExclamationIcon />
              <p className="text-xl">Reject & Suggest Edit(s)</p>
              <InfoBox
                info={
                  'You may suggest edits to multiple fields if required and/or add comments. Your edits will be subjected to acceptance by the landlord.'
                }
              />
            </div>
            <div className="modal__reject-form">
              <form className="reject-form">
                <div className="form-input">
                  <label>
                    Monthly Rent
                    <input
                      className="input__text"
                      type="text"
                      defaultValue={'S$' + monthlyRent}
                      placeholder="Type here..."
                      onChange={handleMonthlyRentChange}
                    ></input>
                  </label>
                </div>

                <div className="form-input">
                  <label>
                    Payment Schedule
                    <select
                      name="payment-schedule"
                      id="payment-schedule"
                      className="date-picker-container"
                      defaultValue={''}
                      onChange={handlePaymentScheduleChange}
                    >
                      <option value="22">22</option>
                      <option value="23">23</option>
                      <option value="24">24</option>
                    </select>
                  </label>
                </div>

                <div className="form-input">
                  <label>
                    Payment Period
                    <select
                      name="payment-period"
                      id="payment-period"
                      className="date-picker-container"
                      defaultValue={''}
                      onChange={handlePaymentPeriodChange}
                    >
                      <option value="24 months">24 months</option>
                      <option value="12 months">12 months</option>
                    </select>
                  </label>
                </div>

                <div className="form-input">
                  <label>
                    First Payment
                    <ReactDatePicker
                      selected={firstPayment}
                      onChange={handleFirstPaymentChange}
                      dateFormat="dd MMMM yyyy"
                      placeholderText="Select a date"
                      className="date-picker-container date-pick-reject"
                    />
                    <div className="calendar-icon">
                      <CalendarIcon />
                    </div>
                  </label>
                </div>

                <div className="form-input">
                  <label>
                    Additional Comment (Optional)
                    <textarea
                      type="text"
                      className="form-comment"
                      maxLength="500"
                      onChange={handleComment}
                    />
                    <p className="comment-char-count text-xs">
                      {charCount} / 500
                    </p>
                  </label>
                </div>
              </form>
            </div>
            <div className="content__handler">
              <button className="button__style-4--1">
                <CheckIcon />
                Save Edits
              </button>
              {isEdited && (
                <button className="button__style-4--2">
                  <CrossIcon />
                  Reset Fields
                </button>
              )}
              <button className="button__style-4--2">
                <BackIcon />
                Exit without saving
              </button>
            </div>
          </div>
        </div>
      )}

      {thisType === 'addTimeSlot' && (
        <div className="modal__content">
          <div className="modal__title">
            <PlusIcon />
            <p className="text-xl">Add timeslot</p>
          </div>
          <InfoBox
            info={
              'It is recommended that the entire viewing period - first to last viewing day - does not exceed 10 days.'
            }
          />
          <div className="modal__timeSlot-form">
            <form className="timeSlot-form">
              <div className="form-input">
                <label>
                  Date & Time
                  <input
                    type="datetime-local"
                    name="timeslotDateTime"
                    id="timeslotDateTime"
                    className="date-picker-container"
                    {...register('datetime', {
                      onChange: handleTimeslotDate,
                    })}
                    // onChange={handleTimeslotDate}
                  />
                </label>
              </div>

              <div className="type-option-container">
                <div className="type-option_heading">
                  <p className="text-md bold">
                    Max Pax / Capacity <span>(optional)</span>
                  </p>
                </div>

                <div className="type-option_item-container">
                  <div
                    className={`${
                      capacity === '5'
                        ? 'type-option_item option-selected'
                        : 'type-option_item'
                    }`}
                    onClick={() => setCapacity('5')}
                  >
                    <p>5</p>
                  </div>

                  <div
                    className={`${
                      capacity === '10'
                        ? 'type-option_item option-selected'
                        : 'type-option_item'
                    }`}
                    onClick={() => setCapacity('10')}
                  >
                    <p>10</p>
                  </div>

                  <div
                    className={`${
                      capacity === '15'
                        ? 'type-option_item option-selected'
                        : 'type-option_item'
                    }`}
                    onClick={() => setCapacity('15')}
                  >
                    <p>15</p>
                  </div>

                  <div
                    className={`${
                      capacity === 'Unlimited'
                        ? 'type-option_item option-selected'
                        : 'type-option_item'
                    }`}
                    onClick={() => setCapacity('Unlimited')}
                  >
                    <p>Unlimited</p>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="content__handler">
            <button
              className={isInputed ? 'button__style-4--1' : 'button__style-5'}
              onClick={handleSubmit(handleAddTimeSlot)}
            >
              <PlusIcon />
              Add new timeslot
            </button>
            <button className="button__style-4--2" onClick={props.hide}>
              <BackIcon />
              Exit without saving
            </button>
          </div>
        </div>
      )}

      {thisType === 'editTimeSlot' && (
        <div className="modal__content">
          <div className="modal__title">
            <PlusIcon />
            <p className="text-xl">Edit timeslot</p>
          </div>
          <InfoBox
            info={
              'It is recommended that the entire viewing period - first to last viewing day - does not exceed 10 days.'
            }
          />
          <div className="modal__timeSlot-form">
            <form className="timeSlot-form">
              <div className="form-input">
                <label>
                  Date & Time
                  <input
                    type="datetime-local"
                    name="timeslotDateTime"
                    id="timeslotDateTime"
                    className="date-picker-container"
                    {...register('datetime', {
                      onChange: handleTimeslotDate,
                    })}
                    // onChange={handleTimeslotDate}
                  />
                </label>
              </div>

              <div className="type-option-container">
                <div className="type-option_heading">
                  <p className="text-md bold">
                    Max Pax / Capacity <span>(optional)</span>
                  </p>
                </div>

                <div className="type-option_item-container">
                  <div
                    className={`${
                      capacity === '5'
                        ? 'type-option_item option-selected'
                        : 'type-option_item'
                    }`}
                    onClick={() => setCapacity('5')}
                  >
                    <p>5</p>
                  </div>

                  <div
                    className={`${
                      capacity === '10'
                        ? 'type-option_item option-selected'
                        : 'type-option_item'
                    }`}
                    onClick={() => setCapacity('10')}
                  >
                    <p>10</p>
                  </div>

                  <div
                    className={`${
                      capacity === '15'
                        ? 'type-option_item option-selected'
                        : 'type-option_item'
                    }`}
                    onClick={() => setCapacity('15')}
                  >
                    <p>15</p>
                  </div>

                  <div
                    className={`${
                      capacity === 'Unlimited'
                        ? 'type-option_item option-selected'
                        : 'type-option_item'
                    }`}
                    onClick={() => setCapacity('Unlimited')}
                  >
                    <p>Unlimited</p>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="content__handler">
            <button
              className={isInputed ? 'button__style-4--1' : 'button__style-5'}
              onClick={handleSubmit(handleEditTimeSlot)}
            >
              <PlusIcon />
              Edit new timeslot
            </button>
            <button className="button__style-4--2" onClick={props.hide}>
              <BackIcon />
              Exit without saving
            </button>
          </div>
        </div>
      )}

      {thisType === 'offerSubmitCC' && (
        <div className="modal__content">
          <div className="content__illus">
            <RocketIllus />
          </div>

          <div className="content__text">
            <h1>We have forwarded your offer to the landlord...</h1>
            <p>
              You will receive SMS and app notifications on the status of your
              offer within 48 hours. In the meantime, you may view your offer
              details from the Manage New Lease page.
              <br />
              <br />
              P.Lease wishes you all the best!
            </p>
          </div>

          <div className="content__handler">
            <button
              className="button__style-4--1"
              onClick={() => handleClickDirect('/browse/tenant-detail')}
            >
              <HomeIcon />
              Back to listing
            </button>
          </div>
        </div>
      )}

      {thisType === 'offerSubmitOther' && (
        <div className="modal__content">
          <div className="content__illus">
            <MoneyTransferIllus />
          </div>

          <div className="content__text">
            <h1>Checking if we have received your booking deposit...</h1>
            <p>
              We will need to verify that your funds have been received. This
              should take at most 2 hours.
              <br />
              <br />
              Once we have confirmed your funds, we will forward your offer to
              the landlord and will notify you when we do so.
            </p>
          </div>

          <div className="content__handler">
            <button
              className="button__style-4--1"
              onClick={() => handleClickDirect(`/browse/tenant-detail/${props.propertyId}`)}
            >
              <HomeIcon />
              Back to listing
            </button>
          </div>
        </div>
      )}

      {thisType === 'scheduleViewing' && (
        <div className="modal__content">
          <div className="content__illus">
            <VerifyIllus />
          </div>

          <div className="content__text">
            <h1>We have sent your viewing request to the landlord...</h1>
            <p>
              You will receive SMS and app notifications on the status of your
              request within 48 hours.
              <br />
              <br />
              If your request is approved, this viewing will be added to your
              schedule, which you can access from the Manage New Lease page.
            </p>
          </div>

          <div className="content__handler">
            <button
              className="button__style-4--1"
              onClick={() => handleClickDirect('/lease/tenant')}
            >
              <HomeIcon />
              Back to listing
            </button>
          </div>
        </div>
      )}

      {thisType === 'TASigned' && (
        <div className="modal__content">
          <div className="content__illus">
            <RocketIllus />
          </div>

          <div className="content__text">
            <h1>You’re all set!</h1>
            <p>
              We will proceed to forward the signed Tenancy Agreement to your
              tenant. Once your tenant has signed as well, your tenancy will be
              in force.
              <br />
              <br />
              We will also subsequently release 3 months’ of rent - 2 months’
              security deposit and 1 month advance rental (after deduction of
              listing fee) - to you.
              <br />
              <br />
              The last step would be to conduct the actual handover with the
              tenant on 14 Dec 2022.
            </p>
          </div>
          <div className="content__handler">
            <button
              className="button__style-4--1"
              onClick={() => handleClickDirect('/browse/tenant-detail')}
            >
              <EyeIcon />
              View Handover Guide
            </button>
            <button className="button__style-4--2">
              <BackIcon />
              Back to listing summary
            </button>
          </div>
        </div>
      )}

      {thisType === 'chatUserProfile' && (
        <div className="modal__content">
          <div className="modal__chat">
            <div className="modal__title">
              <InfoIcon />
              <p className="text-xl">User Profile</p>
            </div>
            <div className="modal__chat-detail">
              <div className="chat-detail-user">
                <div className="chat-detail-user-username">
                  <div className="chat-detail-user-username-initial">
                    <p className="text-xs">WZ</p>
                  </div>
                  <p className="text-xl">Wong Zhenyi</p>
                  <VerifiedIcon />
                </div>
                <div className="chat-detail-user-rating">
                  <Star />
                  <p>No Rating Yet</p>
                </div>
              </div>
              <div className="chat-detail-row-section">
                <div className="chat-detail-col-section">
                  <p className="text-xs">VERIFIED SINCE</p>
                  <p className="text-md">3 OCT 2022</p>
                </div>
                <div className="chat-detail-col-section">
                  <p className="text-xs">LAST ONLINE</p>
                  <p className="text-md">4h ago</p>
                </div>
              </div>
              <div className="chat-detail-col-section">
                <p className="text-xs">NATIONALITY</p>
                <p className="text-md">Singaporean</p>
              </div>
              <div className="chat-detail-col-section">
                <p className="text-xs">INDUSTRY</p>
                <p className="text-md">Services</p>
              </div>
            </div>
          </div>
          <div className="content__handler">
            <button className="button__style-4--2">
              <BackIcon />
              Back to chat
            </button>
          </div>
        </div>
      )}

      {thisType === 'chatPropertySummary' && (
        <div className="modal__content">
          <div className="modal__chat">
            <div className="modal__title">
              <HomeIcon />
              <p className="text-xl">Property Summary</p>
            </div>
            <div className="modal__chat-detail">
              <div className="chat-detail-user">
                <p className="text-xs">Owner</p>
                <div className="chat-detail-user-username">
                  <div className="chat-detail-user-username-initial">
                    <p className="text-xs">ZH</p>
                  </div>
                  <p className="text-xl">Zheng Huiyuan</p>
                  <VerifiedIcon />
                </div>
                <div className="chat-detail-user-rating">
                  <Star />
                  <p>No Rating Yet</p>
                </div>
              </div>
              <div className="chat-detail-row-section">
                <div className="chat-detail-col-section">
                  <p className="text-xs">PROPERTY TYPE</p>
                  <p className="text-md">Condo</p>
                </div>
                <div className="chat-detail-col-section">
                  <p className="text-xs">BUILT YEAR</p>
                  <p className="text-md">2012</p>
                </div>
              </div>
              <div className="chat-detail-col-section">
                <p className="text-xs">DEVELOPMENT NAME</p>
                <p className="text-md">Suites @ Guillemard</p>
              </div>
              <div className="chat-detail-col-section">
                <p className="text-xs">DISTRICT</p>
                <div className="chat-detail-flag">
                  <div>D15</div>
                  <p className="text-md">East Coast / Marine Parade</p>
                </div>
              </div>
              <div className="chat-detail-col-section">
                <p className="text-xs">LISTED ON</p>
                <p className="text-md">2 December 2022</p>
              </div>
            </div>
          </div>
          <div className="content__handler">
            <button className="button__style-4--1">
              <EyeIcon />
              View listing
            </button>
            <button className="button__style-4--2">
              <BackIcon />
              Back to chat
            </button>
          </div>
        </div>
      )}

      {thisType === 'chatReportUser' && (
        <div className="modal__content">
          <div className="modal__chat">
            <div className="modal__title">
              <FlagIcon />
              <p className="text-xl">Report User</p>
            </div>
            <InfoBox
              info={
                'The last 5 messages from this user will be forwarded to P.Lease for investigation. The user will also be blocked from contacting you.'
              }
            />
          </div>
          <form className="report-user-form">
            <div className="form-input">
              <label>
                <div className="report-user-form-label">
                  Issue <span>(Optional)</span>
                </div>
                <select
                  name="issue"
                  id="issue"
                  className="date-picker-container"
                  defaultValue={''}
                >
                  <option value="">Please select...</option>
                  <option value="Scam">Scam</option>
                  <option value="Harrasment">Harrasment</option>
                  <option value="Disrespect">Disrespect Behavior</option>
                </select>
              </label>
            </div>
          </form>
          <div className="content__handler">
            <button className="button__style-4--1">
              <ExclamationIcon />
              Report and block
            </button>
            <button className="button__style-4--2">
              <BackIcon />
              Back to chat
            </button>
          </div>
        </div>
      )}

      {thisType === 'chatDelete' && (
        <div className="modal__content">
          <div className="modal__chat">
            <div className="modal__title">
              <BinIcon />
              <p className="text-xl">Delete Chat</p>
            </div>
            <InfoBox
              info={
                'Chat will be permanently removed from your device. You may still receive new messages from the other party.'
              }
            />
          </div>
          <div className="content__handler">
            <button className="button__style-4--1">
              <BinIcon />
              Delete chat
            </button>
            <button className="button__style-4--2">
              <BackIcon />
              Back to chat
            </button>
          </div>
        </div>
      )}

      {thisType === 'bankVerification' && (
        <div className="modal__content">
          <div className="content__illus">
            <MoneyTransferIllus />
          </div>

          <div className="content__text">
            <h1>Checking if we have received your listing fee...</h1>
            <div className="content__text-bankVerification">
              <p>
                We will need to verify that your funds have been received. This
                should take at most 2 hours.
              </p>
              <p>
                Once we have confirmed your funds, we will verify your property
                details and proceed to list it on the marketplace. We will keep
                you posted on your listing.
              </p>
              <p>
                In the meantime, you may configure additional items for your
                listing, such as your preferred viewing timeslots and etc.
              </p>
            </div>
          </div>

          <div className="content__handler">
            <button className="button__style-4--1">
              <HomeIcon />
              Configure additional details
            </button>
            <button className="button__style-4--2">
              <BackIcon />
              Back to listing summary
            </button>
          </div>
        </div>
      )}

      {thisType === 'reviewTA' && (
        <div className="modal__content">
          <div className="content__illus">
            <PartyIllus />
          </div>

          <div className="content__text">
            <h1>Congratulations on your offer!</h1>
            <p>
              We have notified your tenant, <span>Tay Chia Khoon</span>, on the
              good news as well! Let's get to the next step of formalising your
              lease by reviewing and signing the Tenancy Agreement (TA).
            </p>
          </div>

          <div className="content__illus">
            <ReviewTAIllus />
          </div>

          <div className="content__handler">
            <button className="button__style-4--1">
              <SquareCheckIcon />
              Review Tenancy Agreement
            </button>
            <button className="button__style-4--2">
              <BackIcon />
              Back to listing summary
            </button>
          </div>
        </div>
      )}

      {thisType === 'boostListing' && (
        <div className="modal__content">
          <div className="modal__title">
            <StarHighlightIcon />
            <p className="text-xl">Boost listing</p>
            <InfoBox
              info={
                'By boosting, your property will appear as a featured listing that will be displayed ahead of any other listings for the same search criteria.'
              }
            />
          </div>
          <div className="content__body">
            <p className="text-md">Select Boost Period</p>
            <div className="boost-row-section">
              <div
                className={
                  boostSelect === '7d'
                    ? 'boost-option-selected'
                    : 'boost-option'
                }
                onClick={() => setBoostSelect('7d')}
              >
                <div className="boost-option-plan">
                  <p className="text-md">7d</p>
                  <p className="text-xs">BOOST</p>
                </div>
                <div className="boost-option-price">
                  <p className="text-md">SGD $30</p>
                </div>
              </div>
              <div
                className={
                  boostSelect === '14d'
                    ? 'boost-option-selected'
                    : 'boost-option'
                }
                onClick={() => setBoostSelect('14d')}
              >
                <div className="boost-option-plan">
                  <p className="text-md">14d</p>
                  <p className="text-xs">BOOST</p>
                </div>
                <div className="boost-option-price">
                  <p className="text-md">SGD $50</p>
                </div>
              </div>
            </div>
            <div className="boost-row-section">
              <div
                className={
                  boostSelect === '21d'
                    ? 'boost-option-selected'
                    : 'boost-option'
                }
                onClick={() => setBoostSelect('21d')}
              >
                <div className="boost-option-plan">
                  <p className="text-md">21d</p>
                  <p className="text-xs">BOOST</p>
                </div>
                <div className="boost-option-price">
                  <p className="text-md">SGD $65</p>
                </div>
              </div>
              <div
                className={
                  boostSelect === '28d'
                    ? 'boost-option-selected'
                    : 'boost-option'
                }
                onClick={() => setBoostSelect('28d')}
              >
                <div className="boost-option-plan">
                  <p className="text-md">28d</p>
                  <p className="text-xs">BOOST</p>
                </div>
                <div className="boost-option-price">
                  <p className="text-md">SGD $80</p>
                </div>
              </div>
            </div>
          </div>
          <div className="content__handler">
            <button
              className={
                boostSelect != null ? 'button__style-4--1' : 'button__style-5'
              }
            >
              <StarHighlightIcon />
              Boost my listing
            </button>
            <button className="button__style-4--2">
              <BackIcon />
              Back to listing summary
            </button>
          </div>
        </div>
      )}

      {thisType === 'addInventory' && (
        <div className="modal__content">
          <div className="modal__reject">
            <div className="modal__title">
              <PlusIcon />
              <p className="text-xl">Add to Inventory</p>
            </div>
            <div className="modal__timeSlot-form">
              <form className="reject-form">
                <div className="form-input">
                  <label>
                    Room
                    <input
                      type="text"
                      name="addRoom"
                      id="addRoom"
                      className="timeSlot-container"
                    />
                  </label>
                </div>
                <div className="form-input">
                  <label>
                    Item Name
                    <input
                      type="text"
                      name="addItemName"
                      id="addItemName"
                      className="timeSlot-container"
                    />
                  </label>
                </div>
                <div className="form-input">
                  <label>
                    Brand (optional)
                    <input
                      type="text"
                      name="addBrand"
                      id="addBrand"
                      className="timeSlot-container"
                    />
                  </label>
                </div>
                <div className="form-input form-input--textarea">
                  <label>
                    Additional Remarks (optional)
                    <textarea
                      name="addRemarks"
                      rows="3"
                      id="addRemarks"
                      className="timeSlot-container"
                    />
                  </label>
                </div>
              </form>
            </div>
            <div className="content__handler">
              <button className="button__style-4--1">
                <PlusIcon />
                Add Item
              </button>
              <button className="button__style-4--2" onClick={props.hide}>
                <BackIcon />
                Exit without saving
              </button>
            </div>
          </div>
        </div>
      )}
      {thisType === 'logout' && (
        <div className="modal__content">
          <div class="content-logout">
            <p class="text-lg">Are you sure want to log out?</p>
          </div>
          <div className="content__handler">
            <button className="button__style-4--1">Yes</button>
            <button className="button__style-4--2">No</button>
          </div>
        </div>
      )}
    </div>,
    document.getElementById('portal')
  )
}

export default Modal
