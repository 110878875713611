import React from 'react'
import '../../../assets/css/components/LeasePage/Tenant/leaseTenantNewOutlet.css'
import LeaseTenantNewBoxInfo from './LeaseTenantNewBoxInfo'
import CardProperty from '../../ui/CardProperty'
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';

function LeaseTenantNewOutlet() {
	const isList = true
	
	return (
		<div className={isList? 'lease__t-body-outlet lease__t-body-outlet-1' : 'lease__t-body-outlet'}>
			<div className='outlet__head'>
				<h1>Shortlist</h1>

				<div className='head__count'>
					<p>0</p>
				</div>
			</div>

			{!isList ? 
			<div className='outlet__body'>
				<LeaseTenantNewBoxInfo newLease="shortlist" />
			</div> :

			<div className='outlet__body-slide'>
				<Splide 
					options={{ 
						perPage: 1,
						pagination:false, 
						arrows: false,
						gap: "1rem"
					}}
					className='lease__outlet-1-splide'
				>
					<SplideSlide className='lease__outlet-1-slide'>
						<CardProperty type="newleaseShortlist" />
					</SplideSlide>
					<SplideSlide className='lease__outlet-1-slide'>
						<CardProperty type="newleaseShortlist" />
					</SplideSlide>
				</Splide>
			</div>}
		</div> 
	)
}

export default LeaseTenantNewOutlet