import '../../assets/css/components/browse/browseSearchBody.css'
import { useState } from 'react'
import { ReactComponent as DropIcon } from '../../assets/icon/general/Vector-14-chevron-down.svg'
import { ReactComponent as UpIcon } from '../../assets/icon/general/Vector-13-chevron-up.svg'
import AccordionBrowse from '../../pages/BrowsePage/AccordionBrowse'
import SearchApply from '../../components/browse/SearchApply'

const BrowseSearchBody = (props) => {
  const [isTrue, setIsTrue] = useState(false)
  const [select, setSelect] = useState(null)
  // const [value, setValue] = useState(false)
  const [accordion, setAccordion] = useState(false)
  // props.value(value)
  const toggle = (i) => {
    // console.log(i)
    // if (select === i) {
    //   return setSelect(null)
    // }

    // setSelect(i)

    setAccordion(!accordion)
  }
  const value = (val) => {
    setIsTrue(val)
    console.log('checked' + isTrue)
  }
  const style = (id) => ({
    transform: select === id ? 'rotate(180deg)' : '',
    transition: 'transform 150ms ease',
  })

  const autoBg = (color) => ({
    backgroundColor: color,
  })
  return (
    <div className="search-body__height">
      <form className="search-body__container">
        <div className="accordion__wrapper">
          {data.map((item, i) => (
            // <div
            //   // className={
            //   //   select === i
            //   //     ? 'search-body__accordion-yellow'
            //   //     : 'search-body__accordion'
            //   // }

            //   className={
            //     accordion
            //       ? 'search-body__accordion-yellow'
            //       : 'search-body__accordion'
            //   }
            //   key={item.id}
            // >
            //   <div className="search-body__accordion-title" onClick={() => toggle(i)}>
            //     <div className="search_accordion__cover">
            //       <div
            //         className="search-accordion__code"
            //         style={item.clr !== null ? autoBg(item.clr) : ''}
            //       >
            //         <h1 className="search-accordion__line-code">{item.lne}</h1>
            //       </div>
            //       <h1
            //         // className={
            //         //   select === i
            //         //     ? 'search-accordion__line-name-yellow'
            //         //     : 'search-accordion__line-name'
            //         // }

            //         className={
            //           accordion
            //             ? 'search-accordion__line-name-yellow'
            //             : 'search-accordion__line-name'
            //         }
            //       >
            //         {item.line}
            //       </h1>
            //     </div>
            //     {/* {select === i ? (
            //       <UpIcon className="drop-icon__yellow" />
            //     ) : (
            //       <DropIcon style={style(item.id)} className="drop-icon" />
            //     )} */}

            //     {accordion ? (
            //       <UpIcon className="drop-icon__yellow" />
            //     ) : (
            //       <DropIcon style={style(item.id)} className="drop-icon" />
            //     )}
            //   </div>
            //   <div
            //     // className={
            //     //   select === i ? 'search-accordion__station-checkboxes' : 'hide'
            //     // }

            //     className={
            //       accordion ? 'search-accordion__station-checkboxes' : 'hide'
            //     }
            //   >
            //     {item.station.map((station) => (
            //       /* Station list per accordions */
            //       <div className="search-accordion__checkboxes-container">
            //         {/* checkboxes for station lists */}
            //         <input type="checkbox" id={station} />
            //         {/* label for station name, and lines that goes through */}
            //         <label
            //           for={station}
            //           className="station__label"
            //           onClick={() => setValue(true)}
            //         >
            //           <span className="station__name">{station} </span>{' '}
            //           <div className="station__line-container">
            //             <span
            //               className="station__line-color"
            //               /*colors for station line */
            //               style={{
            //                 backgroundColor: '#F99D25',
            //               }}
            //             ></span>
            //             <span
            //               className="station__line-color"
            //               /*colors for station line */
            //               style={{
            //                 backgroundColor: '#E22726',
            //               }}
            //             ></span>
            //             <span
            //               className="station__line-color"
            //               /*colors for station line */
            //               style={{
            //                 backgroundColor: '#8F4199',
            //               }}
            //             ></span>
            //           </div>
            //         </label>
            //       </div>
            //     ))}
            //   </div>
            // </div>

            <AccordionBrowse accData={item} value={value} />
          ))}
        </div>
        
        <SearchApply />
      </form>
    </div>
  )
}

const data = [
  {
    clr: '#00964D',
    lne: 'EWL',
    line: 'East-West Line',
    station: ['Sample 1', 'Sample 2'],
  },
  {
    clr: 'red',
    lne: 'NSL',
    line: 'North-South Line',
    station: ['Sample 3', 'Sample 4'],
  },
  {
    clr: '#F99D25',
    lne: 'CCL',
    line: 'Circle Line',
    station: ['Dhoby Ghaut', 'Bras Basah', 'Esplanade'],
  },
  {
    clr: '#005DA8',
    lne: 'DTL',
    line: 'Downtown Line',
    station: ['Dhoby Garut', 'Bras Kering', 'Esplendor'],
  },
  {
    clr: '#9D5B25',
    lne: 'TEL',
    line: 'Thomson-East Coast Line',
    station: ['Sample 5', 'Sample 6'],
  },
  {
    clr: '#718573',
    lne: 'BP',
    line: 'Bukit Panjang LRT',
    station: ['Sample 78', 'Sample 9'],
  },
  {
    clr: '#718573',
    lne: 'SK',
    line: 'Sengkang LRT',
    station: ['Sample 10', 'Sample 11'],
  },
  {
    clr: '#718573',
    lne: 'PG',
    line: 'Punggol LRT',
    station: ['Sample 12', 'Sample 13'],
  },
]

export default BrowseSearchBody
