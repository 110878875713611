import React from 'react'
import { useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import authService from '../../services/AuthService'
import userConstant from '../../utils/constants/user.constant'
import ModalSystemNotif from '../ui/ModalSystemNotif'

const Protected = ({ 
    children, 
    redirectPath = '/start',
    checkVerification = true, 
    role = null,
    roleRedirection = '/browse'
  }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(authService.isAuthenticated());
  const userData = authService.getLoggedUserData();
  const navigate = useNavigate();
  const [isShow, setIsShow] = useState(true)

  if (isLoggedIn && userData) {
    // Check email
    if (userData.is_email_verified === 1 && checkVerification) {
      return <Navigate to='/verify' replace />
    }

    // Check Role
    if(role && role !== userData.role){
      return <Navigate to={roleRedirection} />
    }

    return children
  }

  return (
    <>
      {isShow && (<ModalSystemNotif title={"You are not logged in!"} message={"Please login/register your account before accessing this feature"} hide={() => navigate('/start')} isProtectedRouteMessage={true} />)}
    </>
  )
}

export default Protected