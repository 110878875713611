import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { ReactComponent as BackIcon } from '../../assets/icon/general/Vector-1-back.svg'
import { ReactComponent as IconInfo } from '../../assets/icon/general/Vector-18-info.svg'
import '../../assets/css/pages/FirstTimePage/verify.css'
import Modal from '../../components/ui/Modal'
import OtpInput from 'react-otp-input'
import SnackBar from '../../components/ui/SnackBar'
import authService from '../../services/AuthService'

function Verify() {
  const [otp, setOtp] = useState('')
  const [isShow, setIsShow] = useState(false)
  const [messageSnackbarStatus, setMessageSnackbarStatus] = useState(true)
  const [snackbarMessage, setSnackbarMessage] = useState("Please verify your email address")

  const [userData, setUserData] = useState(authService.getLoggedUserData())
  
  useEffect(() => {
    authService.sendEmailOtp(userData.id, userData.email);
  }, [])
  
	const modalHandler = () => {
		setIsShow(true)
	}

  const handleVerifyOtp = async () => {
    if(otp) {
      const response = await authService.verifyOtp(userData.id, otp);
      console.log("response", response);

      if (!response.data){
        setSnackbarMessage(response.message)
        setMessageSnackbarStatus(true)
        return
      }

      setIsShow(true)
      
      // update logged user status
      authService.updateLoggedUserEmailVerified(response.data.is_email_verified)
    }
  }

  useEffect(() => {
    let timeout
    if (messageSnackbarStatus) {
      timeout = setTimeout(() => setMessageSnackbarStatus(false), 2000);
    }
    return () => clearTimeout(timeout);
  }, [messageSnackbarStatus]);

  return (
    <section className="verify">
      <SnackBar status={messageSnackbarStatus} message={snackbarMessage}  />

			{isShow && (<Modal show={isShow} type="verify" hide={() => setIsShow(false)} />)}

      <div className="verify__back">
        <NavLink to="/browse">
          <BackIcon />
        </NavLink>
      </div>

      <div className="verify__container">
        <div className="verify__otp">
          <h1 className="verify__title">Verify your email address</h1>

          <div className="verify__info">
            <IconInfo className="verify-info__icon" />
            <p>
              Please enter the OTP sent to <span>{userData.email}</span>.
            </p>
          </div>

          <OtpInput
						value={otp}
						onChange={setOtp}
						numInputs={4}
						renderSeparator={''}
						inputStyle="inputStyle"
						renderInput={(props) => <input {...props} />}
					/>

          <button
            className={`${
              otp !== ''
                ? 'button__style-2 button__style-2--active'
                : 'button__style-2'
            }`}
            type="button"
            onClick={handleVerifyOtp}
          >
            Continue
          </button>

          <p className="verify__resend">
            Didn’t receive the code? <span>Resend OTP</span>
          </p>
        </div>

        <NavLink className="verify__skip" to="/browse">
          Skip for now
        </NavLink>
      </div>
    </section>
  )
}

export default Verify
