import axios from 'axios'
import authService from './AuthService'
import { apiBaseURL } from '../utils'

const getAll = async (start = 0, end = 5) => {
  const response = await axios.get(`${apiBaseURL}/properties?_start=${start ?? 0}&_end=${end ?? 5}`)
  return response.data
}

const getOne = async (propertyId) => {
  const response = await axios.get(`${apiBaseURL}/properties/${propertyId}`)
  return response.data;
}

const getAllBasedOnId = async (id) => {
  const response = await axios.get(`${apiBaseURL}/users/${id}/properties`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem(authService._tokenKey)}`,
      'x-access-token': `${localStorage.getItem(authService._tokenKey)}`,
    },
  })

  return response.data
}

const getAllPhotos = async (id) => {
  const response = await axios.get(
    `${apiBaseURL}/properties/${id}/property_photos`
  )
  return response.data
}

const getFeaturedPhotos = async (id) => {
  const response = await axios.get(
    `${apiBaseURL}/properties/${id}/featured_photos`
  )
  return response.data
}

const createNewProperty = async (data) => {
  const response = await axios.post(`${apiBaseURL}/properties`, data)
  
  if(response.status === 200) return {
    data: response.data,
    message: response.data.message,
    status: response.status
  }

  return {
    data: null,
    message: response.data.message,
    status: response.status
  }
}

const getAllPropertyCategories = async () => {
  const response = await axios.get(`${apiBaseURL}/property_categories`);
  return response.data
}

const updateOne = async(id, data) => {
  const response = await axios.patch(`${apiBaseURL}/properties/${id}`, data)

  return response
}

const deleteOne = async(id) => {
  const response = await axios.delete(`${apiBaseURL}/properties/${id}`)

  return response
}

const propertyService = {
  getAll,
  getAllPhotos,
  getFeaturedPhotos,
  getAllBasedOnId,
  createNewProperty,
  getAllPropertyCategories,
  updateOne,
  deleteOne,
  getOne
}

export default propertyService
