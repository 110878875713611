import React from 'react'
import HeadBack from '../../ui/HeadBack'
import { usePaymentInputs } from 'react-payment-inputs'
import images from 'react-payment-inputs/images'
import '../../../assets/css/pages/OfferPage/addCard.css'
import { ReactComponent as CheckIcon } from '../../../assets/icon/general/Vector-42-check.svg';

function AddCard() {
  const {
    meta,
    getCardNumberProps,
    getExpiryDateProps,
    getCVCProps,
    getCardImageProps,
  } = usePaymentInputs()

  const cardNumber = null
  const expiryDate = null
  const cvc = null

  function handleChangeCardNumber(event) {
    console.log(event.target.value)
  }

  function handleChangeExpiryDate(event) {
    console.log(event.target.value)
  }

  function handleChangeCVC(event) {
    console.log(event.target.value)
  }

  return (
    <div className="addCard">
      <HeadBack directURL="/" title="Add a Card" />

      <form className="addCard__container">
        <div className="addCard__body">
          <div className="addCard__field">
            <div className="form-input">
              <label>
                Card Holder’s Name
                <input
                  className="input__text"
                  type="text"
                  placeholder="Type here..."
                ></input>
              </label>
            </div>

            <div className="form-input">
              <label>
                Card Number
                <div className="addCard__input-number">
                  <input
                    className="input__text"
                    {...getCardNumberProps({
                      onChange: handleChangeCardNumber,
                    })}
                    value={cardNumber}
                  />
                  <svg {...getCardImageProps({ images })} />
                </div>
              </label>
            </div>

            <div className="addCard__row">
              <div className="form-input">
                <label>
                  Expiry
                  <input
                    className="input__text"
                    {...getExpiryDateProps({
                      onChange: handleChangeExpiryDate,
                    })}
                    value={expiryDate}
                  />
                </label>
              </div>

              <div className="form-input">
                <label>
                  CVC
                  <input
                    className="input__text"
                    {...getCVCProps({ onChange: handleChangeCVC })}
                    value={cvc}
                  />
                </label>
              </div>
            </div>

            <div className="form-input form-input--checkbox">
              <label htmlFor="inputSave">
                Save this card for future payments

                <input type="checkbox" name="save" id="inputSave" />
                <span class="checkbox-mark"><CheckIcon /></span>
              </label>
            </div>
          </div>
        </div>

        <div className="onboarding__button">
          <button
            // onClick={modalHandler}
            className="button__style-3 button__style-3--active"
            type="button"
          >
            Add Card
          </button>
        </div>
      </form>
    </div>
  )
}

export default AddCard
