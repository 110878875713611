import React, { useState, useEffect } from 'react'
import { ReactComponent as Back } from '../../assets/icon/general/Vector-1-back.svg'
import { ReactComponent as Info } from '../../assets/icon/general/Vector-18-info.svg'
import { ReactComponent as CheckIcon } from '../../assets/icon/general/Vector-42-check.svg'
import '../../assets/css/pages/AccountPage/profile.css'
import Modal from '../../components/ui/Modal'
import { NavLink } from 'react-router-dom'
import BackButton from '../../components/ui/BackButton'
import userServices from '../../services/userService'
import authService from '../../services/AuthService'
import {
  identificationTypeOptions,
  industryTypeOptions,
} from '../../services/valuesService'
import { useForm } from 'react-hook-form'
import SnackBar from '../../components/ui/SnackBar'
import HeadBack from '../../components/ui/HeadBack'

function Profile() {
  const [isShow, setIsShow] = useState(false)
  const [account, setAccount] = useState([])
  const [userPhoto, setUserPhoto] = useState([])
  const { register, handleSubmit, watch, getValues } = useForm()

  const [snackbarStatus, setSnackbarStatus] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState('')

  const [fullName, setFullName] = useState('')
  const [mobileNumber, setMobileNumber] = useState('')
  const [email, setEmail] = useState('')

  const modalHandler = () => {
    try {
      if (_checkData()) {
        const data = {
          name: fullName,
          mobile_number: mobileNumber,
          email: email,
        }

        userServices.updateOneUser(account.id, data)
        setIsShow(true)
        return
      }
    } catch (error) {
      console.log(error)
    }
  }

  const _checkData = () => {
    if (fullName === '' || fullName === undefined) {
      setSnackbarStatus(true)
      setSnackbarMessage("Name can't be empty")
      return false
    }

    if (mobileNumber === '' || mobileNumber === undefined) {
      setSnackbarStatus(true)
      setSnackbarMessage("Mobile number can't be empty")
      return false
    }

    if (email === '' || email === undefined) {
      setSnackbarStatus(true)
      setSnackbarMessage("Email can't be empty")
      return false
    }

    return true
  }

  const fetchAccountData = async (id) => {
    const accountData = await userServices.getOneFromId(id)
    setAccount(accountData)
  }

  const fetchUserPhotoData = async (id) => {
    const userPhotoData = await userServices.getUserPhoto(id)
    setUserPhoto(userPhotoData)
  }

  useEffect(() => {
    fetchAccountData(localStorage.getItem(authService._userIdKey))
  }, [])

  useEffect(() => {
    fetchUserPhotoData(localStorage.getItem(authService._userIdKey))
  }, [])

  useEffect(() => {
    setFullName(account.name ?? '')
    setMobileNumber(account.mobile_number ?? '')
    setEmail(account.email ?? '')
  }, [account])

  console.log("photo", userPhoto)
  return (
    <section class="profile_main">
      <SnackBar status={snackbarStatus} message={snackbarMessage} />

      {isShow && (
        <Modal
          show={isShow}
          type="onboarding"
          user={'tenant'}
          hide={() => setIsShow(false)}
        />
      )}
      <HeadBack title="Profile" backLink="/account" />
      <div class="profile_body">
        <form class="profile_body-form">
          <h3>Contact Information</h3>
          <div className="form-input">
            <label>
              FULL NAME
              <input
                className="input__text"
                type="text"
                placeholder="John Doe"
                value={fullName}
                {...register('name')}
                onChange={(e) => setFullName(e.target.value)}
              ></input>
            </label>
          </div>

          <div className="form-input">
            <label>
              MOBILE NUMBER
              <div className="form-input__number">
                <select>
                  <option value="+62">+62</option>
                  <option value="+66">+66</option>
                </select>
                <div class="form-input__input-number">
                  <input
                    className="input__text"
                    type="text"
                    placeholder="9876 5432"
                    value={mobileNumber}
                    {...register('mobile_number')}
                    onChange={(e) => setMobileNumber(e.target.value)}
                  ></input>
                  <div class="input_check-icon">
                    <CheckIcon />
                  </div>
                </div>
              </div>
            </label>
          </div>

          <div className="form-input">
            <label>
              EMAIL ADDRESS
              <div class="form-input__email">
                <div class="form-input__input-email">
                  <input
                    className="input__text"
                    type="text"
                    placeholder="email@example.com"
                    {...register('email')}
                    value={email}
                    disabled
                  ></input>
                  <div class="input_check-icon">
                    <CheckIcon />
                  </div>
                </div>
              </div>
            </label>
          </div>
        </form>
        <div class="profile_body-details">
          <h3>Personal Details</h3>
          <div class="profile_body-details-container">
            <div class="profile_body-details-verifying">
              <Info />
              <p>We are currently verifying your account.</p>
            </div>
          </div>
          <div class="profile_body-details-container">
            <h3>Personal Information</h3>
            <div class="profile_body-details-information">
              <p className="text-xs">FULL NAME</p>
              <p className="text-lg">{account.name}</p>
            </div>
            <div class="profile_body-details-information">
              <p className="text-xs">NATIONALITY</p>
              <p className="text-lg">{account.nationality}</p>
            </div>
            <div class="profile_body-details-information">
              <p className="text-xs">IDENTIFICATION TYPE</p>
              <p className="text-lg">
                {identificationTypeOptions.map((ident) => {
                  if (account.identification_type === ident.id) {
                    return ident.label
                  }
                })}
              </p>
            </div>
            <div class="profile_body-details-information">
              <p className="text-xs">IDENTIFICATION NUMBER</p>
              <p className="text-lg">{account.identification_number}</p>
            </div>
            <div class="profile_body-details-information">
              <p className="text-xs">INDUSTRY / EMPLOYMENT SECTOR</p>
              <p className="text-lg">
                {industryTypeOptions.map((industry) => {
                  if (account.industry === industry.id) {
                    return industry.label
                  }
                })}
              </p>
            </div>
          </div>

          <div class="profile_body-details-container">
            <h3>Proof of Identity</h3>
            <div className="profile_body-details-identity">
              <p className="text-xs">IDENTITY DOCUMENT</p>

              <div className="profile_body-details-identity-preview">
                {userPhoto.map((photo) => {
                  if (photo.document_type === 1) {
                    return (
                      <div className="profile_body-details-identity-preview-image">
                        <img src={photo.asset_location} />
                      </div>
                    )
                  }
                })}
              </div>
            </div>

            <div className="profile_body-details-identity">
              <p className="text-xs">SELFIE VERIFICATION</p>
              <div className="profile_body-details-identity-preview">
              {userPhoto.map((photo) => {
                  if (photo.document_type === 2) {
                    return (
                      <div className="profile_body-details-identity-preview-image">
                        <img src={photo.asset_location} />
                      </div>
                    )
                  }
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="profile_button">
        <button
          onClick={modalHandler}
          className="button__style-3 button__style-3--active"
          type="button"
        >
          Update
        </button>
      </div>
    </section>
  )
}

export default Profile
