import React from 'react'
import { NavLink } from 'react-router-dom'
import { ReactComponent as BackIcon } from '../../assets/icon/general/Vector-1-back.svg'

function BackButton(props) {
	return (
		<NavLink to={props.directURL} className={props.size === "small" ? "back__button back__button--small" : "back__button"}>
			<BackIcon />
		</NavLink>
	)
}

export default BackButton