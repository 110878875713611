import React, { useState } from 'react'
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide'
import DummyImage from '../../assets/images/image 5.png'
// import { ReactComponent as Bed } from '../../assets/icon/general/Vector-4-bed.svg'
import { ReactComponent as PlusIcon } from '../../assets/icon/general/Vector-plus.svg'
import { ReactComponent as Plus } from '../../assets/icon/general/Vector-plus.svg'
import { ReactComponent as View } from '../../assets/icon/general/Vector-30-view.svg'
import Button from '../../pages/LeasePage/components/Button'
import '../../assets/css/components/ui/leaseAddHead.css'

function LeaseAddHead(props) {
  const [isOverlay, setIsOverlay] = useState(false)
  const [labelType, setLabelType] = useState('pending')

  const labelTypeHandler = () => {
    if (labelType === 'pending') {
      return 'overlay__content overlay__content--pending'
    } else if (labelType === 'offer') {
      return 'overlay__content overlay__content--offer'
    } else {
      return 'overlay__content'
    }
  }

  return (
    <div className="lease-add-list-container">
      <div className="lease-add-wrapper">
        <div className="box-add-lease">
          <Splide
            hasTrack={false}
            options={{
              perPage: 2,
              pagination: false,
              autoWidth: true,
              arrows: false,
            }}
          >
            <SplideTrack>
              <SplideSlide>
                <div className="box__lease">
                  <img src={DummyImage} alt="dummy" />
                  {isOverlay && (
                    <div className="overlay">
                      <div className={labelTypeHandler()}>
                        <div className="label"></div>
                        <p>Pending Verification</p>
                      </div>
                    </div>
                  )}
                </div>
              </SplideSlide>

              <SplideSlide>
                <div className="box__lease">
                  <img src={DummyImage} alt="dummy" />

                  <div className="overlay">
                    <div className="overlay__content overlay__content--pending">
                      <div className="label"></div>
                      <p>Pending Verification</p>
                    </div>
                  </div>
                </div>
              </SplideSlide>

              <SplideSlide>
                <div className="box__lease">
                  <img src={DummyImage} alt="dummy" />

                  <div className="overlay">
                    <div className="overlay__content overlay__content--offer">
                      <div className="label"></div>
                      <p>Pending Verification</p>
                    </div>
                  </div>
                </div>
              </SplideSlide>

              <SplideSlide>
                <div className="box__lease">
                  <img src={DummyImage} alt="dummy" />

                  <div className="overlay">
                    <div className="overlay__content overlay__content--offer">
                      <div className="label"></div>
                      <p>Pending Verification</p>
                    </div>
                  </div>
                </div>
              </SplideSlide>
            </SplideTrack>
          </Splide>
        </div>
        
        {props.type !== 'leased' && (
          <button className="btn-add-lease">
            <PlusIcon />
          </button>
        )}
      </div>

      {props.type !== 'leased' && (
        <div className="lease-add-actions">
          <Button
            type="secondary"
            buttonName="View guide"
            icon={
              <div>
                <View />
              </div>
            }
            directURL="/lease/guide"
          />

          <Button
            buttonName="List new property"
            icon={
              <div className="lease-button-icon-colored">
                <Plus />
              </div>
            }
            directURL="/lease/new-property"
          />
        </div>
      )}
    </div>
  )
}

export default LeaseAddHead
