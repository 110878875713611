import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { ReactComponent as PleaseLogo1 } from '../../assets/logo/Logo-c 2.svg'
import { ReactComponent as StartedIllus1 } from '../../assets/illustration/get started/1.svg'
import { ReactComponent as StartedIllus2 } from '../../assets/illustration/get started/2.svg'
import { ReactComponent as StartedIllus3 } from '../../assets/illustration/get started/3.svg'
import { ReactComponent as StartedIllus4 } from '../../assets/illustration/get started/4.svg'
import { ReactComponent as IconInfo } from '../../assets/icon/general/Vector-18-info.svg'
import '../../assets/css/pages/FirstTimePage/getStarted.css'

import Button from '../../components/ui/Button'
import GetStartedWeb from '../../components/Web/GetStarted/GetStartedWeb'

function GetStarted() {
  // const [userType, setUserType] = useState('')
  const [userSelect, setUserSelect] = useState(null)

  return (
    <div className='started-web'>
      <section className="started">
        <div className="started__logo">
          <PleaseLogo1 />
        </div>

        <div className="started__type">
          <div className="started__type-title">
            <h1>Let’s get started</h1>
            <p>I want to...</p>
          </div>

          <div className="started__type-pick">
            <button
              className={`${userSelect === 'tenant' ? 'pick__active' : ''}`}
              onClick={() => setUserSelect('tenant')}
            >
              <StartedIllus1 className="type-pick__icon" />
              <p>Look for a place to stay</p>
            </button>
            <button
              className={`${userSelect === 'landlord' ? 'pick__active' : ''}`}
              onClick={() => setUserSelect('landlord')}
            >
              <StartedIllus2 className="type-pick__icon" />
              <p>Rent out my property</p>
            </button>
            <button
              className={`${userSelect === 'agent' ? 'pick__active' : ''}`}
              onClick={() => setUserSelect('agent')}
            >
              <StartedIllus3 className="type-pick__icon" />
              <p>Manage my tenancy</p>
            </button>
            <button
              className={`${userSelect === 'user4' ? 'pick__active' : ''}`}
              onClick={() => setUserSelect('user4')}
            >
              <StartedIllus4 className="type-pick__icon" />
              <p>Join via an invite</p>
            </button>
          </div>

          <div className="started__type-info">
            <IconInfo className="type-info__icon" />
            <p>
              You can still switch roles later on (e.g. from tenant to landlord
              and vice versa) via the Profile tab after you have onboarded
              successfully.
            </p>
          </div>
        </div>

        <div className="started__button">
          <Button
            buttonName="Continue"
            allow={userSelect != null}
            directURL={`${'/signup/' + userSelect}`}
          />

          <div className="signup__policy">
            <p>
              Already have an account? <NavLink to="/login/tenant">Log in</NavLink>
            </p>
          </div>
        </div>
      </section>

      {/* <GetStartedWeb /> */}
    </div>
  )
}

export default GetStarted
