import React, { useState, useEffect } from 'react'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import BackButton from '../../components/ui/BackButton'
import { ReactComponent as Eye } from '../../assets/icon/general/Vector-30-eye.svg'
import SignUpIllus from '../../components/FirstTimePage/SignUpIllus'
import '../../assets/css/pages/FirstTimePage/logIn.css'
import { useForm } from 'react-hook-form'
import authService from '../../services/AuthService'
import SnackBar from '../../components/ui/SnackBar'
import LoginWeb from '../../components/Web/Login/LoginWeb'

function Login() {
	const { userType } = useParams()
	const [type, setType] = useState('password')
	const navigate = useNavigate();

	// Form Hook
	const { register, handleSubmit, watch, formState: { errors } } = useForm();

	const [statusLogin, setStatusLogin] = useState(false)
	const [message, setMessage] = useState()

	useEffect(() => {
		if (authService.isAuthenticated()) {
			navigate('/browse')
		}
	}, [])

	useEffect(() => {
		let timeout
		if (statusLogin) {
			timeout = setTimeout(() => setStatusLogin(false), 2000);
		}
		return () => clearTimeout(timeout);
	}, [statusLogin]);

	const togglePassword = () => {
		if (type === 'password') {
			setType('text')
			return
		}
		setType('password')
	}

	const _handleLogin = async (formData) => {
		try {
			const response = await authService.login(formData, userType);
			const { data, message } = response;
			const token = data?.data.token; // Add null check

			if (!data) { // Check if returning data is not empty
				setMessage(message)
				setStatusLogin(true)
			}

			if (token) {
				navigate(`/browse`)
			}

		} catch (error) {
			const errorMessage = "Invalid Credentials";
			console.log(error);
			setMessage(errorMessage)
			setStatusLogin(true)
		}

	}

	return (
		<div className="login-web">
			<section className="login">
				<SnackBar status={statusLogin} message={message} />

				{/* <div className="login__back">
					<NavLink to="/getstarted">
						<BackIcon />
					</NavLink>
				</div> */}
				<BackButton directURL={`${'/signup/' + userType}`} />

				<div className="login__wrapper">
					<div className="login__head">
						<div className="login__head-title">
							{/* <h1 onClick={() => setStatusLogin(!statusLogin)}>Login</h1> */}
							<h1>Login</h1>
							{userType !== 'user4' && <p>and unlock these features</p>}
						</div>

						<SignUpIllus userType={userType} />
					</div>

					<div className="login__form-wrapper">
						<form className="login__form" onSubmit={handleSubmit(_handleLogin)}>
							<div className="form-input">
								<label>
									EMAIL ADDRESS
									<input
										className="input__text"
										type="text"
										placeholder="Type here..."
										{...register("email", { required: true })}
									></input>
								</label>
							</div>

							<div className="form-input">
								<label>
									PASSWORD
									<div className="input__password-box">
										<input
											className="input__text"
											type="text"
											placeholder="Type here..."
											{...register("password", { required: true })}
										></input>
										<div className="input__eye-icon" onClick={togglePassword}>
											<Eye />
										</div>
									</div>
								</label>
							</div>

							<input className="button__style-2 button__style-2--active" type="submit" value="Continue" />
						</form>
					</div>

					<div className="login__policy">
						<p>
							Don't have an account? <NavLink to={`${'/signup/' + userType}`}>Sign up</NavLink>
						</p>
					</div>
				</div>

				<NavLink className="login__skip" to="/browse">
					Skip for now
				</NavLink>
			</section>

			{/* <LoginWeb /> */}
		</div>
	)
}

export default Login