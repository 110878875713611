import React, { useEffect, useState } from 'react'
import Button from '../components/Button'
import DummyImage from '../../../assets/images/image 5.png'
import { ReactComponent as Plus } from '../../../assets/icon/general/Vector-plus.svg'
import { ReactComponent as View } from '../../../assets/icon/general/Vector-30-view.svg'
import { ReactComponent as Bed } from '../../../assets/icon/general/Vector-4-bed.svg'
import LeaseCardProperty from '../../../components/LeasePage/LeaseCardProperty'
import axios from 'axios'
import authService from '../../../services/AuthService.js'
import propertyService from '../../../services/PropertyService.js'
import { apiBaseURL } from '../../../utils'
import LeaseAddHead from '../../../components/ui/LeaseAddHead'
import Skeleton from 'react-loading-skeleton'
import { NavLink } from 'react-router-dom'

const Listed = () => {
  // const [isHaveProperty, setIsHaveProperty] = useState(true)
  const [isLoading, setIsLoading] = useState(true)
  const [propertyList, setPropertyList] = useState([])
  const [featuredPhoto, setFeaturedPhoto] = useState([])

  const fetchPropertyList = async (id) => {
    const response = await propertyService.getAllBasedOnId(id)
    setPropertyList(response)
    setIsLoading(false)
    return
  }

  const fetchFeaturedPhoto = async () => {
    const data = [...featuredPhoto]
    for (let i = 0; i < propertyList.length; i++) {
      const response = await propertyService.getFeaturedPhotos(
        propertyList[i].id
      )
      data.push(response?.[0])
    }
    setFeaturedPhoto(data)
    return
  }

  useEffect(() => {
    const userId = localStorage.getItem(authService._userIdKey)
    fetchPropertyList(userId)
  }, [])

  useEffect(() => {
    fetchFeaturedPhoto()
  }, [propertyList])

  return (
    <div className="lease-listed-content">
      <LeaseAddHead type="listed" />

      <div className="lease-content">
        {isLoading ? (
          <>Loading...</>
        ) : (
          <>
            {propertyList.length !== 0 ? (
              <div className="lease-content-wrapper">
                {/* TODO: API To Call Summary */}
                <p className="text-2xl bold">Summary</p>
                <div className="lease-summary-information">
                  <div className="lease-summary-information_item">
                    <p>TOTAL SPEND</p>
                    <p>
                      <span className="text-xs normal">SGD</span>{' '}
                      <span className="text-lg normal">$-</span>
                    </p>
                  </div>

                  <div className="lease-summary-information_item">
                    <p>LISTING FEE</p>
                    <p>
                      <span className="text-xs normal">SGD</span>{' '}
                      <span className="text-lg normal">$-</span>
                    </p>
                  </div>

                  <div className="lease-summary-information_item">
                    <p>BOOSTS</p>
                    <p>
                      <span className="text-xs normal">SGD</span>{' '}
                      <span className="text-lg normal">$-</span>
                    </p>
                  </div>

                  <div className="lease-summary-information_item">
                    <p>MISC</p>
                    <p>
                      <span className="text-xs normal">SGD</span>{' '}
                      <span className="text-lg normal">$-</span>
                    </p>
                  </div>
                </div>
                {propertyList.map((data, index) => {
                  return (
                    <LeaseCardProperty
                      cardLeaseType="ladlordListed"
                      data={data}
                      photo={featuredPhoto[index]}
                    />
                  )
                })}
              </div>
            ) : (
              <div className="lease-listed-information-box">
                <p className="lease-listed-information-box_heading">
                  No properties listed yet!
                </p>
                <p className="lease-listed-information-box_desc">
                  It is highly recommended for you to read the listing guide
                  before you list your property. For the most efficient listing
                  process, please have your property photos and property
                  ownership documents ready.
                </p>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default Listed
