import axios from 'axios'
import { apiBaseURL } from '../utils'

const uploadPhoto = async (photo) => {
    let formData = new FormData()
    formData.append('file', photo)
    const response = axios.post(`${apiBaseURL}/user_photos/media`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  
    return response
}

const userPhotoService = {
  uploadPhoto
}

export default userPhotoService;