import React from 'react'
import { NavLink } from 'react-router-dom'
import './Button.css'

function Button(props) {
  return (
    <NavLink to={props.directURL}>
      <div
        className={`button-lease ${
          props.type === 'secondary' && `button-lease-secondary`
        }`}
      >
        {props.icon}
        <p
          style={{
            textAlign: 'center',
            width: '100%',
          }}
        >
          {props.buttonName}
        </p>
      </div>
    </NavLink>
  )
}

export default Button
