import React, { useEffect, useState } from 'react'
import { ReactComponent as House } from '../../../assets/icon/general/Vector-17-house.svg'
import { ReactComponent as Pin } from '../../../assets/icon/general/Vector-20-pin.svg'
import { ReactComponent as CloseIcon } from '../../../assets/icon/general/Vector-9-close.svg'
import { ReactComponent as CameraIcon } from '../../../assets/icon/general/Vector-57-camera.svg'
import { ReactComponent as Upload } from '../../../assets/icon/general/Vector-71-upload.svg'
import { ReactComponent as Bed } from '../../../assets/icon/general/Vector-4-bed.svg'
import { ReactComponent as Bathub } from '../../../assets/icon/general/Vector-85-bathtub.svg'
import { ReactComponent as Coin } from '../../../assets/icon/general/Vector-21-coin.svg'
import { ReactComponent as CalendarDot } from '../../../assets/icon/general/Vector-12-calendardoted.svg'
import { ReactComponent as Calendar } from '../../../assets/icon/general/Vector-5-calendar.svg'
import { ReactComponent as Pie } from '../../../assets/icon/general/Vector-84-piechart.svg'
import { ReactComponent as Furniture } from '../../../assets/icon/general/Vector-11-furniture.svg'
import { ReactComponent as Layer } from '../../../assets/icon/general/Vector-19-layer.svg'
import { ReactComponent as Highlight } from '../../../assets/icon/general/Vector-47-star-highlight.svg'
import { ReactComponent as CheckIcon } from '../../../assets/icon/general/Vector-42-check.svg'

import {
  amenities_data_bedroom,
  amenities_data_kitchen,
  amenities_data_living,
  amenities_data_study,
} from '../DummyData/amenities_data'
import { useForm } from 'react-hook-form'
import SnackBar from '../../ui/SnackBar'
import propertyPhotoConstants from '../../../utils/constants/property_photo.constants'
import furnishingService from '../../../services/furnishingService'
import moment from 'moment'
import propertyConstant from '../../../utils/constants/property.constant'
import propertyService from '../../../services/PropertyService'
import authService from '../../../services/AuthService'

const DetailsAndDocs = ({ setFormData, packageSelected }) => {
  const [propertyType, setPropertyType] = useState('')
  const [rentalType, setRentalType] = useState('')
  const [bedNumber, setBedNumber] = useState()
  const [bathNumber, setBathNumber] = useState('')
  const [furnishing, setFurnishing] = useState('')
  const [floor, setFloor] = useState('')
  const [files, setFiles] = useState([])
  const [documentFile, setDocumentFile] = useState([])
  const [isFilled, setIsFilled] = useState()

  const [propertyPhotoFiles, setPropertyPhotoFiles] = useState([])
  const [floorPlanPhotoFiles, setFloorPlanPhotoFiles] = useState([])
  const [proofPhotoFiles, setProofPhotoFiles] = useState([])

  const [furnishingOptions, setFurnishingOptions] = useState([])
  const [livingRoomFurnishings, setLivingRoomFurnishings] = useState([])
  const [kitchenServiceYardFurnishings, setKitchenServiceYardFurnishings] = useState([])
  const [bedroomFurnishings, setBedroomFurnishings] = useState([])
  const [studyFurnishings, setStudyFurnishings] = useState([])

  const { register, handleSubmit, watch, formState: { errors } } = useForm();

  // Error Message
  const [errorMessage, setErrorMessage] = useState("")
  const [errorSnackBarStatus, setErrorSnackBarStatus] = useState(false)

  const [propertyTypeOptions, setPropertyTypeOptions] = useState([])

  const [squareFt, setSquareFt] = useState()
  const [userProfileData, setUserProfileData] = useState([])
  const [userProfileLandlordId, setUserProfileLandlordId] = useState("")

  const [propertyTypeName, setPropertyTypeName] = useState("")

  const fetchData = async () => {
    const furnishings = await furnishingService.getAll();
    const propertyTypeOptions = await propertyService.getAllPropertyCategories();
    const userProfiles = await authService.getUserProfileData();
    setFurnishingOptions(furnishings)
    setPropertyTypeOptions(propertyTypeOptions)
    setUserProfileData(userProfiles)
  }

  function handleInputChange(event) {
    setSquareFt(event.target.value)
  }

  useEffect(() => {
    fetchData();
    // console.log(packageSelected);
  }, [])

  function squareFeetToSquareMeter(sqft) {
    // 1 square foot is equal to 0.092903 square meters
    const sqm = sqft * 0.092903
    return sqm
  }

  useEffect(() => {
    if (userProfileData.length > 0) {
      setUserProfileLandlordId(userProfileData.filter(profile => profile.user_type === 2)[0]?.id)
    }
  }, [userProfileData])


  useEffect(() => {
    if (furnishingOptions.length > 0) {
      setLivingRoomFurnishings(furnishingOptions.filter(furnishing => furnishing.furnishing_type === 1))
      setKitchenServiceYardFurnishings(furnishingOptions.filter(furnishing => furnishing.furnishing_type === 2))
      setBedroomFurnishings(furnishingOptions.filter(furnishing => furnishing.furnishing_type === 3))
      setStudyFurnishings(furnishingOptions.filter(furnishing => furnishing.furnishing_type === 4))
    }
  }, [furnishingOptions])


  useEffect(() => {
    let timeout
    if (errorSnackBarStatus) {
      timeout = setTimeout(() => setErrorSnackBarStatus(false), 2000);
    }
    return () => clearTimeout(timeout);
  }, [errorSnackBarStatus]);

  const handleUpload = (event, fileType) => {
    const selectedFiles = event.target.files

    switch (fileType) {
      case propertyPhotoConstants.type.PROPERTY_PHOTO:
        setPropertyPhotoFiles([...propertyPhotoFiles, ...selectedFiles])
        break;
      case propertyPhotoConstants.type.FLOOR_PLAN:
        setFloorPlanPhotoFiles([...floorPlanPhotoFiles, ...selectedFiles])
        break
      case propertyPhotoConstants.type.PROOF_OF_PROPERTY_OWNERSHIP:
        setProofPhotoFiles([...proofPhotoFiles, ...selectedFiles])
        break;
    }

    // setFiles([...files, ...selectedFiles])
  }

  // const handleUploadDocs = (event) => {
  //   const selectedFiles = event.target.files
  //   setDocumentFile([...documentFile, ...selectedFiles])
  // }

  const handleRemove = (index, type) => {
    // console.log(type);
    switch (type) {
      case propertyPhotoConstants.type.PROPERTY_PHOTO:
        const newPropertyPhotoFiles = [...propertyPhotoFiles]
        newPropertyPhotoFiles.splice(index, 1)
        setPropertyPhotoFiles(newPropertyPhotoFiles)
        break;
      case propertyPhotoConstants.type.FLOOR_PLAN:
        const newFloorPlanFiles = [...floorPlanPhotoFiles]
        newFloorPlanFiles.splice(index, 1)
        setFloorPlanPhotoFiles(newFloorPlanFiles)
        break
      case propertyPhotoConstants.type.PROOF_OF_PROPERTY_OWNERSHIP:
        const newProofFiles = [...proofPhotoFiles]
        newProofFiles.splice(index, 1)
        setProofPhotoFiles(newProofFiles)
        break;
    }
  }

  // TODO: Handle Form
  const _handleForm = async (data) => {

    // get all non form data
    const nonInputData = {
      property_category_id: propertyType === "" ? null : propertyType,
      rental_type: rentalType === "" ? null : rentalType,
      number_of_beds: bedNumber === "" ? null : bedNumber,
      number_of_baths: bathNumber === "" ? null : parseInt(bathNumber),
      furnishing_load: furnishing === "" ? null : furnishing,
      floor: floor === "" ? null : floor,
      property_photo_files: propertyPhotoFiles.length <= 0 ? null : propertyPhotoFiles,
      floor_plan_photo_files: floorPlanPhotoFiles.length <= 0 ? null : floorPlanPhotoFiles,
      proof_photo_files: proofPhotoFiles.length <= 0 ? null : proofPhotoFiles,
    }

    // Check if all of the non form data is filled
    let check = true;
    let checkedKeyErrorMessage = "";

    for (const [key, value] of Object.entries(nonInputData)) {
      checkedKeyErrorMessage = `${key} is required`;
      if (nonInputData[key] === null) {
        check = false;
        break;
      }
    }

    if (data.postal_code.length < 6 || data.postal_code.length > 6) {
      check = false;
      checkedKeyErrorMessage = "Postal code should be 6 digits"
    }

    // If all data is filled
    if (check) {
      // Adjustment for data
      data.build_year = parseInt(moment(data.build_year).format("YYYY"))
      data.availability_date = data.availability;
      data.address = data.street_name;
      data.user_profile_landlord_id = userProfileLandlordId;
      data.minimum_lease_month = 1;
      data.property_count_click = 1;
      data.property_view_count = 0;
      data.property_package = packageSelected;
      data.status = 1;
      data.rental_price_type = parseInt(data.rental_price_type);
      // data.user_profile_agent_id = userProfileData.filter((data) => data.user_type === 2);

      const returnData = {
        ...nonInputData,
        ...data
      }

      setFormData(returnData)
      console.log("😎 Form Data: ", returnData);
      // alert("All filled")
      return
    }

    // if not show error snackbar
    setErrorMessage(`${checkedKeyErrorMessage}`);
    setErrorSnackBarStatus(true);

  }

  useEffect(() => {
    if (propertyType) {
      setIsFilled(true)
    }
  }, [propertyType])

  return (
    <div className="details-container">
      <SnackBar status={errorSnackBarStatus} message={errorMessage} />
      <form onSubmit={handleSubmit(_handleForm)}>
        <div className="details-form-container">
          <p className="text-lg bold">Property Details</p>

          {/* Property Type */}
          <div className="type-option-container">
            <div className="type-option_heading">
              <div>
                <House />
              </div>
              <p className="text-md bold">Property Type</p>
            </div>

            <div className="type-option_item-container">
              {
                propertyTypeOptions.map((type) => {
                  return (
                    <div
                      className={`${propertyType === type.id
                        ? 'type-option_item option-selected'
                        : 'type-option_item'
                        }`}
                      onClick={() => {
                        setPropertyType(type.id)
                        setPropertyTypeName(type.name)
                      }}
                    >
                      <p>{type.name}</p>
                    </div>
                  )
                })
              }

            </div>

            {propertyTypeName === propertyConstant.propertyTypeName.LANDED && (
              <div className="type-option__extended">
                <p className="bold">LANDED TYPE</p>
                <div class="form-input_radio-container">
                  <label class="radio-option-container radio-input_item">
                    <input
                      type="radio"
                      name="landed-radio"
                      id="terrace"
                      value="Inter-Terrace"
                    />
                    <label class="radio-custom" for="terrace">
                      Inter-Terrace
                    </label>
                    <span class="checkmark"></span>
                  </label>

                  <label class="radio-option-container radio-input_item">
                    <input
                      type="radio"
                      name="landed-radio"
                      id="corner-terrace"
                      value="Corner Terrace"
                    />
                    <label class="radio-custom" for="corner-terrace">
                      Corner Terrace
                    </label>
                    <span class="checkmark"></span>
                  </label>
                </div>
                <div class="form-input_radio-container">
                  <label class="radio-option-container radio-input_item">
                    <input
                      type="radio"
                      name="landed-radio"
                      id="semi-detached"
                      value="Semi-Detached"
                    />
                    <label class="radio-custom" for="semi-detached">
                      Semi-Detached
                    </label>
                    <span class="checkmark"></span>
                  </label>

                  <label class="radio-option-container radio-input_item">
                    <input
                      type="radio"
                      name="landed-radio"
                      id="Detached"
                      value="Detached"
                    />
                    <label class="radio-custom" for="Detached">
                      Detached
                    </label>
                    <span class="checkmark"></span>
                  </label>
                </div>
                <div class="form-input_radio-container">
                  <label class="radio-option-container radio-input_item">
                    <input
                      type="radio"
                      name="landed-radio"
                      id="Good Class Bungalow"
                      value="Good Class Bungalow"
                    />
                    <label class="radio-custom" for="Good Class Bungalow">
                      Good Class Bungalow
                    </label>
                    <span class="checkmark"></span>
                  </label>
                </div>
              </div>
            )}
          </div>

          {/* Address */}
          <div className="type-option-container">
            <div className="type-option_heading">
              <div>
                <Pin />
              </div>
              <p className="text-md bold">Address</p>
            </div>

            <div className="type-option_item-container item-container--column">
              <div className="form-input-row">
                <div className="form-input" style={{ flex: 1 }}>
                  <label>
                    POSTAL CODE
                    <input
                      {...register("postal_code", { required: true })}
                      className="input__text"
                      type="number"
                      placeholder="Type here..."
                    ></input>
                  </label>
                </div>
                <div className="form-input">
                  <label>
                    STREET NAME
                    <input
                      {...register("street_name", { required: true })}
                      className="input__text" type="text"></input>
                  </label>
                </div>
              </div>
              {propertyType === 'condo' && (
                <div className="form-input">
                  <label>
                    DEVELOPMENT NAME
                    <input className="input__text" type="text"></input>
                  </label>
                </div>
              )}
            </div>
          </div>

          {/* Rental Type */}
          <div className="type-option-container">
            <div className="type-option_heading">
              <div>
                <Pie />
              </div>
              <p className="text-md bold">Rental Type</p>
            </div>

            <div className="type-option_item-container">
              <div
                className={`${rentalType === propertyConstant.rentalType.ENTIRE
                  ? 'type-option_item option-selected'
                  : 'type-option_item'
                  }`}
                onClick={() => setRentalType(propertyConstant.rentalType.ENTIRE)}
              >
                <p>Entire Unit</p>
              </div>

              <div
                className={`${rentalType === propertyConstant.rentalType.ENSUITE
                  ? 'type-option_item option-selected'
                  : 'type-option_item'
                  }`}
                onClick={() => setRentalType(propertyConstant.rentalType.ENSUITE)}
              >
                <p>Room with En-suite</p>
              </div>

              <div
                className={`${rentalType === propertyConstant.rentalType.ROOM
                  ? 'type-option_item option-selected'
                  : 'type-option_item'
                  }`}
                onClick={() => setRentalType(propertyConstant.rentalType.ROOM)}
              >
                <p>Room</p>
              </div>
            </div>
          </div>

          {rentalType === propertyConstant.rentalType.ENTIRE && (
            <>
              <div className="type-option__extended">
                <p className="bold">HELPER ROOM</p>
                <div class="form-input_radio-container">
                  <label class="radio-option-container radio-input_item">
                    <input
                      type="radio"
                      name="helper-radio"
                      id="helper-yes"
                      value="Yes"
                    />
                    <label class="radio-custom" for="helper-yes">
                      Yes
                    </label>
                    <span class="checkmark"></span>
                  </label>

                  <label class="radio-option-container radio-input_item">
                    <input
                      type="radio"
                      name="helper-radio"
                      id="helper-no"
                      value="No"
                    />
                    <label class="radio-custom" for="helper-no">
                      No
                    </label>
                    <span class="checkmark"></span>
                  </label>
                </div>
              </div>

              <div className="type-option__extended">
                <p className="bold">STORE ROOM</p>
                <div class="form-input_radio-container">
                  <label class="radio-option-container radio-input_item">
                    <input
                      type="radio"
                      name="store-room-radio"
                      id="store-room-yes"
                      value="Yes"
                    />
                    <label class="radio-custom" for="store-room-yes">
                      Yes
                    </label>
                    <span class="checkmark"></span>
                  </label>

                  <label class="radio-option-container radio-input_item">
                    <input
                      type="radio"
                      name="store-room-radio"
                      id="store-room-no"
                      value="No"
                    />
                    <label class="radio-custom" for="store-room-no">
                      No
                    </label>
                    <span class="checkmark"></span>
                  </label>
                </div>
              </div>
            </>
          )}

          <div className="type-option-container">
            <div className="type-option_heading">
              <div>
                <Bed />
              </div>
              <p className="text-md bold">Number of Beds</p>
            </div>

            <div className="type-option_item-container">
              <div
                className={`${bedNumber === 0
                  ? 'type-option_item option-selected'
                  : 'type-option_item'
                  }`}
                onClick={() => setBedNumber(0)}
              >
                <p>Studio</p>
              </div>

              <div
                className={`${bedNumber === 1
                  ? 'type-option_item option-selected'
                  : 'type-option_item'
                  }`}
                onClick={() => setBedNumber(1)}
              >
                <p>1</p>
              </div>

              <div
                className={`${bedNumber === 2
                  ? 'type-option_item option-selected'
                  : 'type-option_item'
                  }`}
                onClick={() => setBedNumber(2)}
              >
                <p>2</p>
              </div>

              <div
                className={`${bedNumber === 3
                  ? 'type-option_item option-selected'
                  : 'type-option_item'
                  }`}
                onClick={() => setBedNumber(3)}
              >
                <p>3</p>
              </div>

              <div
                className={`${bedNumber === 4
                  ? 'type-option_item option-selected'
                  : 'type-option_item'
                  }`}
                onClick={() => setBedNumber(4)}
              >
                <p>4</p>
              </div>

              <div
                className={`${bedNumber === 5
                  ? 'type-option_item option-selected'
                  : 'type-option_item'
                  }`}
                onClick={() => setBedNumber(5)}
              >
                <p>5+</p>
              </div>
            </div>
          </div>

          <div className="type-option-container">
            <div className="type-option_heading">
              <div>
                <Bathub />
              </div>
              <p className="text-md bold">Number of Baths</p>
            </div>

            <div className="type-option_item-container">
              <div
                className={`${bathNumber === '1'
                  ? 'type-option_item option-selected'
                  : 'type-option_item'
                  }`}
                onClick={() => setBathNumber('1')}
              >
                <p>1</p>
              </div>

              <div
                className={`${bathNumber === '2'
                  ? 'type-option_item option-selected'
                  : 'type-option_item'
                  }`}
                onClick={() => setBathNumber('2')}
              >
                <p>2</p>
              </div>

              <div
                className={`${bathNumber === '3'
                  ? 'type-option_item option-selected'
                  : 'type-option_item'
                  }`}
                onClick={() => setBathNumber('3')}
              >
                <p>3+</p>
              </div>
            </div>
          </div>

          <div className="type-option-container">
            <div className="type-option_heading">
              <div>
                <Coin />
              </div>
              <p className="text-md bold">Monthly Rental (nett SGD)</p>
            </div>

            <div className="form-input__currency">
              <p className="form-input__currency-label">S$</p>
              <input
                {...register("monthly_rental", { required: true, setValueAs: value => parseFloat(value) })}
                className="input__text" type="text"></input>
            </div>

            <div class="form-input_radio-container">
              <label class="radio-option-container radio-input_item">
                <input
                  type="radio"
                  name="price-radio"
                  id="fixed-price"
                  {...register("rental_price_type")}
                  value={1}
                />
                <label class="radio-custom" for="fixed-price">
                  Fixed Price
                </label>
                <span class="checkmark"></span>
              </label>

              <label class="radio-option-container radio-input_item">
                <input
                  type="radio"
                  name="price-radio"
                  id="negotiation"
                  {...register("rental_price_type")}
                  value={2}
                  className="input-radio_container"
                />
                <label class="radio-custom" for="negotiation">
                  Open for Negotiation
                </label>
                <span class="checkmark"></span>
              </label>
            </div>
          </div>

          <div className="type-option-container">
            <div className="type-option_heading">
              <div>
                <CalendarDot />
              </div>
              <p className="text-md bold">Availability</p>
            </div>

            <div className="form-availability-container">
              <div className="form-input_radio-container">
                <label class="radio-option-container radio-input_item">
                  <input
                    type="radio"
                    name="availability-radio"
                    id="now"
                    value="fixed"
                  />
                  <label class="radio-custom" for="now">
                    Now
                  </label>
                  <span class="checkmark"></span>
                </label>

                <label class="radio-option-container radio-input_item">
                  <input
                    type="radio"
                    name="availability-radio"
                    id="after"
                    value="negotiable"
                  />
                  <label class="radio-custom" for="after">
                    After
                  </label>
                  <span class="checkmark"></span>
                </label>
              </div>

              <div>
                <input
                  type="date"
                  id="availability"
                  name="availability"
                  {...register("availability", { required: true })}
                  className="date-picker-container"
                ></input>
              </div>
            </div>
          </div>

          <div className="type-option-container">
            <div className="type-option_heading">
              <div>
                <House />
              </div>
              <p className="text-md bold">
                Floor Area (estimated for rental area)
              </p>
            </div>

            <div className="type-option_item-container">
              <div className="form-input-row">
                <div className="form-input__floor-area">
                  <p className="form-input__floor-area-label">sqft</p>
                  <input
                    className="input__text"
                    {...register("floor_area", { required: true, setValueAs: value => parseFloat(value) })}
                    type="number"
                    placeholder="Type here..."
                  ></input>
                </div>
                <div>
                  <div className="floor-area-box">
                    <p>
                      {squareFeetToSquareMeter(squareFt).toFixed(2) || 0} sqm
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="type-option-container">
            <div className="type-option_heading">
              <div>
                <Furniture />
              </div>
              <p className="text-md bold">Furnishing Load</p>
            </div>

            <div className="type-option_item-container">
              <div
                className={`${furnishing === propertyConstant.furnishingLoad.FULL
                  ? 'type-option_item option-selected'
                  : 'type-option_item'
                  }`}
                onClick={() => setFurnishing(propertyConstant.furnishingLoad.FULL)}
              >
                <p>Full</p>
              </div>

              <div
                className={`${furnishing === propertyConstant.furnishingLoad.PARTIAL
                  ? 'type-option_item option-selected'
                  : 'type-option_item'
                  }`}
                onClick={() => setFurnishing(propertyConstant.furnishingLoad.PARTIAL)}
              >
                <p>Partial</p>
              </div>

              <div
                className={`${furnishing === propertyConstant.furnishingLoad.UNFURNISHED
                  ? 'type-option_item option-selected'
                  : 'type-option_item'
                  }`}
                onClick={() => setFurnishing(propertyConstant.furnishingLoad.UNFURNISHED)}
              >
                <p>Unfurnished</p>
              </div>
            </div>
          </div>

          <div className="type-option-container">
            <div className="type-option_heading">
              <div>
                <Layer />
              </div>
              <p className="text-md bold">Floor</p>
            </div>

            <div className="type-option_item-container">
              <div
                className={`${floor === propertyConstant.floor.LOW
                  ? 'type-option_item option-selected'
                  : 'type-option_item'
                  }`}
                onClick={() => setFloor(propertyConstant.floor.LOW)}
              >
                <p>Low</p>
              </div>

              <div
                className={`${floor === propertyConstant.floor.MID
                  ? 'type-option_item option-selected'
                  : 'type-option_item'
                  }`}
                onClick={() => setFloor(propertyConstant.floor.MID)}
              >
                <p>Mid</p>
              </div>

              <div
                className={`${floor === propertyConstant.floor.HIGH
                  ? 'type-option_item option-selected'
                  : 'type-option_item'
                  }`}
                onClick={() => setFloor(propertyConstant.floor.HIGH)}
              >
                <p>High</p>
              </div>
            </div>
          </div>

          <div className="type-option-container">
            <div className="type-option_heading">
              <div>
                <Calendar />
              </div>
              <p className="text-md bold">Built Year</p>
            </div>

            <div className="type-option_item-container">
              <div>
                <input
                  type="date"
                  id="build_year"
                  name="build_year"
                  className="date-picker-container"
                  {...register("build_year", { required: true })}
                ></input>
              </div>
            </div>
          </div>

          <div className="type-option-container">
            <div className="type-option_heading">
              <div>
                <Highlight />
              </div>
              <p className="text-md bold">Highlights</p>
            </div>

            <div className="type-option_item-container">
              <div>
                <textarea
                  id="w3review"
                  name="w3review"
                  {...register('highlight')}
                  rows="4"
                  cols="50"
                  className="date-picker-container"
                  placeholder="E.g Renovated <5 years ago, bright and windy, Available for immediate move-in. Furnishing all childs-proof. Utilities included."
                />
              </div>
            </div>
          </div>

          <div className="type-option-container">
            <div className="type-option_heading">
              <p className="text-md bold">Furnishing / Amenities</p>
            </div>

            <div className="type-option_item-container">
              <div
                style={{
                  display: 'flex',
                  flex: 1,
                }}
              >
                <div style={{ flex: 1 }}>
                  <p className="form-amenities_title">Living Room</p>
                  {livingRoomFurnishings.map((item, index) => (
                    <div
                      className="form-input form-input--checkbox form-input--checkbox_normal"
                      key={item.id}
                    >
                      <label htmlFor={`living-${item.furnishing_item}`}>
                        <input
                          type="checkbox"
                          name="save"
                          id={`living-${item.furnishing_item}`}
                          {...register("furnishings", { required: true })}
                          value={item.id}
                        />

                        <div className="amenities-label">{item?.furnishing_item}</div>
                        <span className="checkbox-mark">
                          <CheckIcon />
                        </span>
                      </label>
                    </div>
                  ))}
                </div>

                <div style={{ flex: 1 }}>
                  <p className="form-amenities_title">Kitchen/Service Yard</p>
                  {kitchenServiceYardFurnishings.map((item, index) => (
                    <div
                      className="form-input form-input--checkbox form-input--checkbox_normal"
                      key={index}
                    >
                      <label htmlFor={`${item.furnishing_item}`}>
                        <input
                          type="checkbox"
                          id={item?.furnishing_item}
                          {...register("furnishings", { required: true })}
                          value={item?.id}
                        />

                        <div className="amenities-label">{item?.furnishing_item}</div>
                        <span className="checkbox-mark">
                          <CheckIcon />
                        </span>
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="type-option_item-container">
              <div
                style={{
                  display: 'flex',
                  flex: 1,
                }}
              >
                <div style={{ flex: 1 }}>
                  <p className="form-amenities_title">Bedroom</p>
                  {bedroomFurnishings.map((item, index) => (
                    <div
                      className="form-input form-input--checkbox form-input--checkbox_normal"
                      key={index}
                    >
                      <label htmlFor={`${item.furnishing_item}`}>
                        <input
                          type="checkbox"
                          id={item?.furnishing_item}
                          {...register("furnishings", { required: true })}
                          value={item?.id}
                        />

                        <div className="amenities-label">{item?.furnishing_item}</div>
                        <span className="checkbox-mark">
                          <CheckIcon />
                        </span>
                      </label>
                    </div>
                  ))}
                </div>

                <div style={{ flex: 1 }}>
                  <p className="form-amenities_title">Study</p>
                  {studyFurnishings.map((item, index) => (
                    <div
                      className="form-input form-input--checkbox form-input--checkbox_normal"
                      key={index}
                    >
                      <label htmlFor={`${item.furnishing_item}`}>
                        <input
                          type="checkbox"
                          id={item?.furnishing_item}
                          {...register("furnishings", { required: true })}
                          value={item?.id}
                        />

                        <div className="amenities-label">{item?.furnishing_item}</div>
                        <span className="checkbox-mark">
                          <CheckIcon />
                        </span>
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="details-form-container">
          <div className="onboarding__outlet-2-identity">
            <div className="onboarding__outlet-2-head">
              <h1>Property Photos</h1>
              <p className="p1">
                You may upload multiple files. Max size per file is 10MB.
                Supported file types: .jpg, .png, .pdf
              </p>
            </div>

            <div className="onboarding__outlet-2-photo">
              {/* <div className="onboarding__outlet-2-file">
                <input
                  type="file"
                  id="identity"
                  accept="image/*"
                  multiple
                  onChange={handleUpload}
                />
                <label htmlFor="identity">
                  <CameraIcon /> <span>Take a photo</span>
                </label>
              </div>

              <div className="docs-type-separator">
                <p className="text-lg bold">or</p>
              </div> */}

              <div className="onboarding__outlet-2-file">
                <input
                  type="file"
                  id="property_photos_input"
                  name='property_photos'
                  accept="image/jpeg, image/png, image/jpg"
                  multiple
                  onChange={(e) => handleUpload(e, propertyPhotoConstants.type.PROPERTY_PHOTO)}
                />
                <label htmlFor="property_photos_input">
                  <Upload /> <span>Upload File</span>
                </label>
              </div>

              <div
                className={`onboarding__outlet-2-file-preview ${propertyPhotoFiles.length === 0 &&
                  'onboarding__outlet-2-file-preview--hide'
                  }`}
              >
                {propertyPhotoFiles.map((file, index) => (
                  <div className="image__preview" key={index}>
                    <div
                      className="image__preview-delete"
                      onClick={() => handleRemove(index, propertyPhotoConstants.type.PROPERTY_PHOTO)}
                    >
                      <CloseIcon />
                    </div>
                    <img src={URL.createObjectURL(file)} alt="file" />
                  </div>
                ))}
              </div>
            </div>

          </div>
        </div>

        <div className="details-form-container">
          <div className="onboarding__outlet-2-identity">
            <div className="onboarding__outlet-2-head">
              <h1>Floor-plan</h1>
              <p className="p1">
                You may upload multiple files. Max size per file is 10MB.
                Supported file types: .jpg, .png, .pdf
              </p>
            </div>

            <div className="onboarding__outlet-2-photo">
              {/* <div className="onboarding__outlet-2-file">
                <input
                  type="file"
                  id="identity"
                  accept="image/*"
                  multiple
                  onChange={handleUpload}
                />
                <label htmlFor="identity">
                  <CameraIcon /> <span>Take a photo</span>
                </label>
              </div>

              <div className="docs-type-separator">
                <p className="text-lg bold">or</p>
              </div> */}

              <div className="onboarding__outlet-2-file">
                <input
                  type="file"
                  id="floor_plan_photos_input"
                  name='floor_plan_photos'
                  // accept="application/pdf"
                  accept="image/jpeg, image/png, image/jpg"
                  multiple
                  onChange={(e) => handleUpload(e, propertyPhotoConstants.type.FLOOR_PLAN)}
                />
                <label htmlFor="floor_plan_photos_input">
                  <Upload /> <span>Upload File</span>
                </label>
              </div>

              <div
                className={`onboarding__outlet-2-file-preview ${floorPlanPhotoFiles.length === 0 &&
                  'onboarding__outlet-2-file-preview--hide'
                  }`}
              >
                {floorPlanPhotoFiles.map((file, index) => (
                  <div className="image__preview" key={index}>
                    <div
                      className="image__preview-delete"
                      onClick={() => handleRemove(index, propertyPhotoConstants.type.FLOOR_PLAN)}
                    >
                      <CloseIcon />
                    </div>
                    <img src={URL.createObjectURL(file)} alt="file" />
                  </div>
                ))}
              </div>
            </div>

          </div>
        </div>

        <div className="details-form-container">
          <div className="onboarding__outlet-2-identity">
            <div className="onboarding__outlet-2-head">
              <h1>Proof of Property Ownership</h1>
              <p className="p1">
                You may upload multiple files. Max size per file is 10MB.
                Supported file types: .jpg, .png, .pdf
              </p>
            </div>

            <div className="onboarding__outlet-2-photo">
              {/* <div className="onboarding__outlet-2-file">
                <input
                  type="file"
                  id="identity"
                  accept="image/*"
                  multiple
                  onChange={handleUpload}
                />
                <label htmlFor="identity">
                  <CameraIcon /> <span>Take a photo</span>
                </label>
              </div>

              <div className="docs-type-separator">
                <p className="text-lg bold">or</p>
              </div> */}

              <div className="onboarding__outlet-2-file">
                <input
                  type="file"
                  id="proof_photos_input"
                  name='proof_photos'
                  // accept="application/pdf"
                  accept="image/jpeg, image/png, image/jpg"
                  multiple
                  onChange={(e) => handleUpload(e, propertyPhotoConstants.type.PROOF_OF_PROPERTY_OWNERSHIP)}
                />
                <label htmlFor="proof_photos_input">
                  <Upload /> <span>Upload File</span>
                </label>
              </div>

              <div
                className={`onboarding__outlet-2-file-preview ${proofPhotoFiles.length === 0 &&
                  'onboarding__outlet-2-file-preview--hide'
                  }`}
              >
                {proofPhotoFiles.map((file, index) => (
                  <div className="image__preview" key={index}>
                    <div
                      className="image__preview-delete"
                      onClick={() => handleRemove(index, propertyPhotoConstants.type.PROOF_OF_PROPERTY_OWNERSHIP)}
                    >
                      <CloseIcon />
                    </div>
                    <img src={URL.createObjectURL(file)} alt="file" />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="onboarding__button">
          <button
            type='submit'
            className={`${isFilled
              ? 'button__style-3 button__style-3--active'
              : 'button__style-3'
              }`}
          >
            <p className="text-md" style={{ textAlign: 'center' }}>
              Continue
            </p>
          </button>
        </div>
      </form>
    </div>
  )
}

export default DetailsAndDocs
